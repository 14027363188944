import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Account } from 'src/model/account.model';
import { Loan } from 'src/model/loan.model';
import { User } from 'src/model/user.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DuitLebih } from 'src/model/duitLebih.model';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Payment } from 'src/model/payment.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.css']
})
export class AddPaymentComponent implements OnInit {

  @Input() batchDate: Date;
  companyAccountList: Account[] = new Array;
  loanList: Loan[] = new Array;
  userList: User[] = new Array;


  loggedInCompanyId: number;


  selectedAccountId: number;
  selectedHandler: string;

  //2-WAY BINDING INFO
  paymentDate: Date;
  paymentRemark: string;
  paymentAmount: number = 0;
  extrasAmount: number = 0;
  stampAmount: number = 0;
  scAmount: number = 0;
  handlingAmount: number = 0;
  otherCharges: number = 0;
  discount: number = 0;
  selectedDiscountType: string = "RM";
  discountAmount: number = 0;
  reserve: number = 0;
  duitLebihList: DuitLebih[] = new Array;

  minDate: Date;
  maxDate: Date = new Date();

  epfPaymentTerm: number;

  isPayForPrincipal: boolean = false;
  selectedPaymentTerm: number = 0;
  maximumTerms: Number[] = new Array;

  payInterestAfterPrincipal: boolean = false;
  interestAfterReducePrincipal = 0;
  principalAmount = 0;

  epfLoanCollectedAmount = 0;

  // //GET DATA FROM LOAN CARD
  @Input() selectedLoan: Loan;
  @Output() passLoanPaymentDetails: EventEmitter<any> = new EventEmitter();


  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService,
    private constructApi: ConstructAPI, private enums: Enums, private modalService: NgbModal, public sharedService: SharedService) { }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.batchDate) {
      this.paymentDate = this.batchDate;
    }


  }
  ngOnInit(): void {
    //setDate

    this.paymentDate = this.maxDate;

    //get all datas
    var isStaff = this.checkIsStaff();
    //push STAFF COMPANY
    this.loggedInCompanyId = Number(sessionStorage.getItem('companyId'));

    if (isStaff) {
      //push STAFF USERNAME
      var username = sessionStorage.getItem('username');
      this.userList.push(new User(null, null, null, null, null, username, null));
      this.selectedHandler = username;
    }
    else {
      this.getAllUsersOfCompany(this.loggedInCompanyId);
    }

    //if hutang scheme, prefill payment amount
    if (this.selectedLoan.Scheme == 'HUTANG')
      this.paymentAmount = this.selectedLoan.Balance;

    //epf scheme can only pay in full, cannot pay by term
    //default pay principal amount = loan balance
    else if (this.selectedLoan.Scheme == 'EPF' || this.selectedLoan.IsEPF == true) {
      this.isPayForPrincipal = true;
      this.principalAmount = this.selectedLoan.Balance;
    }


    this.processMaximumPaymentTerm();
    this.calculatePaymentAmount();

  }

  processMaximumPaymentTerm() {
    var terms = 0;

    if (this.selectedLoan.Scheme != 'A')
      terms = Number(this.sharedService.roundToDec(this.selectedLoan.Balance / this.selectedLoan.MonthlyPayment, 8));
    else
      terms = 20;

    for (let i = 0; i <= terms; i++) {
      this.maximumTerms.push(i);
    }
  }

  setPayInterestAfterPrincipal() {
    if (this.isPayForPrincipal == false) {
      this.payInterestAfterPrincipal = false;
      this.calculatePaymentAmount();
    }

  }

  calculatePaymentAmount() {
    this.paymentAmount = (this.selectedPaymentTerm * this.selectedLoan.MonthlyPayment);

    if (this.selectedLoan.SettlementCharges != undefined) {
      this.stampAmount = this.selectedLoan.SettlementCharges.Stamp * this.selectedPaymentTerm;
      this.scAmount = this.selectedLoan.SettlementCharges.ServiceCharge * this.selectedPaymentTerm;
      this.extrasAmount = this.selectedLoan.SettlementCharges.Extras * this.selectedPaymentTerm;
      this.duitLebihList = [new DuitLebih(this.extrasAmount)];
    }

  }



  roundTo2Dec(num: number) {

    return Math.round(num * 100) / 100

  }

  changeDiscountAmount(selectedDiscountType: string, paymentAmount: number) {
    if (selectedDiscountType != undefined)
      this.discountAmount = (selectedDiscountType == '%') ? Math.round(paymentAmount * this.discount / 100) : this.discount;
  }


  updatePaymentAmountAndCalculateStamp() {
    this.principalAmount = this.sharedService.roundTo2Dec(this.selectedLoan.MonthlyPayment);

    //REMEMBER TO UNCOMMENT THIS LINE!
    // this.calculateStampAndServiceCharge();
  }

  calculateStampAndServiceCharge() {
    //if is not pay for principal and is not underpayment,fix the amount of stamp and sc
    // if is underpayment, stamp and sc =0
    if (this.isPayForPrincipal == false && this.paymentAmount >= this.selectedLoan.MonthlyPayment) {
      //scheme A
      if (this.selectedLoan.Scheme == "A") {
        if (this.paymentAmount > 2000) {
          this.stampAmount = 20;
          this.scAmount = 6
        }
        else if (this.paymentAmount > 500) {
          this.stampAmount = 10;
          this.scAmount = 3;
        } else if (this.paymentAmount >= 50) {
          this.stampAmount = 3;
          this.scAmount = 5;
        }
        else {
          this.stampAmount = 0;
          this.scAmount = 0;
        }
      }

      //Scheme P
      else if (this.selectedLoan.Scheme == "P") {
        this.stampAmount = 20;
        this.scAmount = 6;
      }
      // Other than Scheme P and A
      else {
        this.stampAmount = 10;
        this.scAmount = 0;
      }
    }
    else {
      this.stampAmount = 0;
      this.scAmount = 0;
    }
  }

  calculatePaidTotal() {
    var total = 0;
    var duitLebihTotal = 0;
    if (this.duitLebihList.length > 0 && this.duitLebihList != undefined) {
      for (let i = 0; i < this.duitLebihList.length; i++) {
        duitLebihTotal += this.duitLebihList[i].Amount;
      }
    }

    if (this.isPayForPrincipal == true) {
      if (this.payInterestAfterPrincipal == true)
        this.paymentAmount = this.principalAmount + this.calculatePaymentWithInterest();
      else
        this.paymentAmount = this.principalAmount;
    }
    else if (this.selectedLoan.Scheme == 'HUTANG') {
      this.paymentAmount = this.principalAmount;
    }


    total = this.paymentAmount + duitLebihTotal + this.stampAmount + this.scAmount + this.otherCharges + this.reserve - this.discountAmount;

    return total;
  }

  calculatePaymentWithInterest() {
    var newPrincipal = this.selectedLoan.Balance - this.principalAmount;
    this.interestAfterReducePrincipal = this.roundTo2Dec((this.selectedLoan.InterestRate / 100) * newPrincipal);

    return this.interestAfterReducePrincipal;
    // if (this.payInterestAfterPrincipal == true) {
    //   this.paymentAmount = this.paymentAmount + this.interestAfterReducePrincipal;
    // }
    // else {
    //   this.paymentAmount = this.paymentAmount - this.interestAfterReducePrincipal;
    // }
    // this.interestAfterReducePrincipal = 0;
  }



  checkIsStaff() {
    var roleID = Number(sessionStorage.getItem('roleId'));
    if (roleID === this.enums.STAFF)
      return true
    else
      return false
  }


  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          var loggedInUser = sessionStorage.getItem("username");
          this.companyAccountList.push(account);

        }
        );

      }
    };

  }


  private getAllUsersOfCompany(customerId: number) {
    var xhttp = this.restapi.getRequest(this.constructApi.getUsersInCompany(this.loggedInCompanyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var user = new User(json.id, json.companyid, json.companyname, json.roleid, json.rolename, json.username, json.password);
          this.userList.push(user);
        }
        );

      }
    };
  }

  setSelectedAccount(accountId: number) {

    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }


  isFieldNull() {
    var paymentNull = (this.paymentAmount == undefined);
    var stampNull = (this.stampAmount == undefined);
    var scNull = (this.scAmount == undefined);
    var paymentRemarkNull = (this.paymentRemark == undefined);
    // var selectedAccountIdNull = (this.selectedAccountId == undefined);
    var paymentValid = isNaN(+this.paymentAmount);
    var stampValid = isNaN(+this.stampAmount);
    var scValid = isNaN(+this.scAmount);

    return paymentNull || stampNull || paymentRemarkNull || scNull || paymentValid || stampValid || scValid
  }


  confirmBeforePayment() {

    var confirmation = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You do not have your STAMP AMOUNT / DUIT LEBIH / SERVICE CHARGE. Wish to proceed?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      confirmation = entry;
      if (confirmation) {

        //make payment if confirm to proceed
        this.makePayment();
      }
    });

  }


  checkDuitLebihNegative() {
    var total = 0;
    for (let i = 0; i < this.duitLebihList.length; i++) {
      total += this.duitLebihList[i].Amount;
    }

    if (total < 0)
      return true;
    else
      return false;
  }

  submit() {

    if (this.isFieldNull() == false) {

      if (this.isPayForPrincipal == true && this.paymentAmount > this.selectedLoan.Balance) {
        this.sharedService.openAlert("Your payment amount is more than loan balance!", this.enums.DANGER_ALERT);
      }

      else if (this.checkDuitLebihNegative() == true) {
        this.sharedService.openAlert("Cannot key in NEGATIVE DUIT LEBIH when making payment!", this.enums.DANGER_ALERT);
      }

      //deny entry for DISCOUNT when there is no payment
      else if (this.paymentAmount == 0 && this.discountAmount > 0) {
        this.sharedService.openAlert("You should not have discount when there is no payment", this.enums.DANGER_ALERT);
      }
      // NOTE: for Scheme A under payment rule only imply to Interest charge but not principal.
      // means we only need to check when isPayForPrincipal is false

      //if underpayment and the payment amount !=0, STAMP, DUIT LEBIH and SERVICE CHAGRE should be 0 to make payment
      // if payment amount =0 should allow to make payment for STAMP, DUIT LEBIH and SERVICE CHAGRE
      else if (this.isPayForPrincipal == false && (this.paymentAmount < this.selectedLoan.MonthlyPayment && this.paymentAmount != 0)) {
        if ((this.stampAmount > 0) || (this.duitLebihList.length > 0) || (this.scAmount > 0)) {
          this.sharedService.openAlert("You should leave STAMP, DUIT LEBIH, SERVICE CHARGE = 0 when you are underpayment", this.enums.DANGER_ALERT);
        }
        else
          this.makePayment();

      }

      //Check STAMP and SC not over MAXIMUM LIMIT
      // if term payment, MAXIMUM LIMIT = LIMIT PER MONTH X TERM
      //if part payment, cannot charge STAMP and SC
      //This limit only applies on NON-EPF LOANS & CP LOANS
      else if (this.selectedLoan.Scheme != 'EPF' && this.selectedLoan.IsEPF == false && this.selectedLoan.Status != this.enums.CP_LOAN) {

        var maximumStamp;
        var maximumSC;
        if (this.selectedLoan.Scheme == 'A') {
          //if is settlement payment, can charge 1 term minimum stamp and sc
          if (this.selectedLoan.Balance - this.principalAmount == 0) {
            maximumStamp = (this.isPayForPrincipal == true) ? this.enums.MAXIMUM_STAMP_NORMAL_SCHEME : this.enums.MAXIMUM_STAMP_NORMAL_SCHEME * Number(this.selectedPaymentTerm);
            maximumSC = (this.isPayForPrincipal == true) ? this.enums.MAXIMUM_SC_NORMAL_SCHEME : this.enums.MAXIMUM_SC_NORMAL_SCHEME * Number(this.selectedPaymentTerm);
          }
          else {

            // if this is not settlement part payment, need to consider if this part payment has WITH MONTHLY selected
            // if WITH MONTHLY selected means need to charge 1 month interest after part payment
            // if this is not PART PAYMENT (isPayForPrincipal==false), only allow to get charges based on payment term
            // total chargable term include -  payment term selected + calculated term of interest charge payment 

            var term = (this.isPayForPrincipal == true) ? 1 : Number(this.selectedPaymentTerm);
            var intChargeTerm = (this.otherCharges > 0) ? Math.floor(this.otherCharges / this.selectedLoan.MonthlyPayment) : 0;
            var totalChargableTerm = term + intChargeTerm;

            maximumStamp = (this.isPayForPrincipal == true && this.payInterestAfterPrincipal == false) ? 0 : this.enums.MAXIMUM_STAMP_NORMAL_SCHEME * totalChargableTerm;
            maximumSC = (this.isPayForPrincipal == true && this.payInterestAfterPrincipal == false) ? 0 : this.enums.MAXIMUM_SC_NORMAL_SCHEME * totalChargableTerm;
          }

        }
        else {
          var term = (this.isPayForPrincipal == true) ? Math.floor(this.principalAmount / this.selectedLoan.MonthlyPayment) : Number(this.selectedPaymentTerm);
          var intChargeTerm = (this.otherCharges > 0) ? Math.floor(this.otherCharges / this.selectedLoan.MonthlyPayment) : 0;
          var totalChargableTerm = term + intChargeTerm;

          // make sure user at least pay 1 term (include int charge payment) to get charges 
          maximumStamp = (totalChargableTerm < 1) ? 0 : this.enums.MAXIMUM_STAMP_NORMAL_SCHEME * totalChargableTerm;
          maximumSC = (totalChargableTerm < 1) ? 0 : this.enums.MAXIMUM_SC_NORMAL_SCHEME * totalChargableTerm;
        }


        if ((this.scAmount > maximumSC) || (this.stampAmount > maximumStamp)) {
          this.sharedService.openAlert("Please make sure your STAMP not more than " + maximumStamp +
            " and SC not more than " + maximumSC, this.enums.DANGER_ALERT);
        }
        else
          this.makePayment();

      }

      //if it is EPF loan and LEGACY MANUAL KEY IN SETTLEMENT STAMP & SC, make sure they wont get stamp and sc more than preset amount when creating loan
      else if (this.selectedLoan.Scheme == 'EPF' && (this.selectedLoan.SettlementCharges == undefined || this.selectedLoan.SettlementCharges.Stamp == undefined)) {
        if (this.stampAmount > this.selectedLoan.SettlementStamp || this.scAmount > this.selectedLoan.SettlementServiceCharge)
          this.sharedService.openAlert("Please make sure your SETTLEMENT STAMP and SC is not more than preset amount", this.enums.DANGER_ALERT);
        else
          this.makePayment();
      }



      //if loan scheme =HUTANG no need to do have any STAMP DUIT LEBIH AND SERVICE CHARGE, so no need to check
      //if payment amount is enough or extra payment + STAMP, DUIT LEBIH and SERVICE CHAGRE empty, need to make confirmation before proceed
      else {
        if (this.selectedLoan.Scheme != "HUTANG" && ((this.stampAmount == 0) || (this.duitLebihList.length == 0 || this.duitLebihList == undefined) || (this.scAmount == 0))) {
          this.confirmBeforePayment();
        }
        else
          this.makePayment();
      }

    }
    else
      this.sharedService.openAlert("Please fill in all the fields", this.enums.DANGER_ALERT);

  }

  isUnderpaidAndInputInvalid() {

    if (this.paymentAmount < this.selectedLoan.MonthlyPayment) {
      if (this.scAmount > 0 || this.stampAmount > 0 || this.extrasAmount > 0) {
        return true;
      }
    }

    return false;
  }

  makePayment() {
    var data;
    if (this.selectedLoan.Scheme == "A") {

      if (this.isPayForPrincipal) {
        data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, 0, this.principalAmount, 0, this.payInterestAfterPrincipal, this.interestAfterReducePrincipal)
      }
      else {
        data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, this.selectedPaymentTerm, 0, this.selectedPaymentTerm * this.selectedLoan.MonthlyPayment)
      }
    }
    else if (this.selectedLoan.Scheme == "HUTANG") {

      var term = this.sharedService.roundToDec(this.paymentAmount / this.selectedLoan.Balance, 8);
      data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, term, 0, this.paymentAmount)

    }
    else {
      if (this.isPayForPrincipal) {
        var term = this.sharedService.roundToDec(this.principalAmount / this.selectedLoan.MonthlyPayment, 8);

        if (this.selectedLoan.Scheme == "EPF") {
          if (this.epfPaymentTerm == undefined)
            data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, term, 0, this.principalAmount, undefined, undefined, this.discount, this.handlingAmount)
          else
            data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, this.epfPaymentTerm, 0, this.principalAmount, undefined, undefined, this.discount, this.handlingAmount)

        }

        else
          data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, term, 0, this.principalAmount, undefined, undefined, undefined, this.handlingAmount)

      }
      else {
        data = new Payment(this.stampAmount, this.scAmount, this.otherCharges, this.discountAmount, this.paymentDate, this.paymentRemark, this.duitLebihList, this.reserve, this.selectedPaymentTerm, 0, this.selectedPaymentTerm * this.selectedLoan.MonthlyPayment, undefined, undefined, undefined, this.handlingAmount)
      }


    }
    this.passLoanPaymentDetails.emit(data);
    this.sharedService.openAlert("Added payment into queue", this.enums.SUCCESS_ALERT);
  }

  reset() {
    this.activeModal.close();
    this.selectedAccountId = 0;
    this.selectedHandler = "";
    this.selectedLoan = undefined;


  }

  getDuitLebihData(duitLebihList: DuitLebih[]) {
    this.duitLebihList = duitLebihList;
  }

  settleFullAndDistributeCharges() {
    this.epfLoanCollectedAmount = this.sharedService.roundTo2Dec(this.sharedService.calculateEPFSettlementAmountWithCharges(this.selectedLoan))
    this.calculateAndDistributeCharges();
  }



  calculateAndDistributeCharges() {

    //can change these amount to test function
    // this.selectedLoan = new Loan(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    // this.selectedLoan.Principal = 1400;
    // this.selectedLoan.InterestRate = 8;
    // this.selectedLoan.Term = 7;
    // this.selectedLoan.SettlementCharges = new SettlementCharges(57, 189, 562, 122);
    // this.epfLoanCollectedAmount = 3114;


    //calculate supposed settlement amount 
    var supposedSettlement = this.sharedService.calculateEPFSettlementAmountWithCharges(this.selectedLoan);



    var totalInterestCharge = this.selectedLoan.Principal * (this.selectedLoan.InterestRate / 100) * this.selectedLoan.Term;
    var totalChargesWithoutPrincipal = totalInterestCharge + this.selectedLoan.SettlementCharges.Stamp + this.selectedLoan.SettlementCharges.ServiceCharge + this.selectedLoan.SettlementCharges.Extras + (this.selectedLoan.SettlementCharges.HandlingCharge || 0);
    var monthlyInterestCharge = this.selectedLoan.Principal * (this.selectedLoan.InterestRate / 100);
    var totalChargesPerMonth = totalChargesWithoutPrincipal / this.selectedLoan.Term;




    var excessPayment = (this.epfLoanCollectedAmount > supposedSettlement) ? this.epfLoanCollectedAmount - supposedSettlement : 0;
    var totalPaidWithoutPrincipal = this.epfLoanCollectedAmount - this.selectedLoan.Principal;
    var paidMonthlyChargesTerm = (totalPaidWithoutPrincipal >= 0) ? totalPaidWithoutPrincipal / totalChargesPerMonth : 0;
    var chargesTerm = (paidMonthlyChargesTerm > this.selectedLoan.Term) ? this.selectedLoan.Term : paidMonthlyChargesTerm;



    var oriRepayment = monthlyInterestCharge * this.sharedService.roundToDec(chargesTerm, 8);


    var oriStampPaid = this.sharedService.roundToDec(((this.selectedLoan.SettlementCharges.Stamp / this.selectedLoan.Term) * this.sharedService.roundToDec(chargesTerm, 8)), 10);
    var oriSCPaid = this.sharedService.roundToDec(((this.selectedLoan.SettlementCharges.ServiceCharge / this.selectedLoan.Term) * this.sharedService.roundToDec(chargesTerm, 8)), 10);
    var oriExtrasPaid = this.sharedService.roundToDec(((this.selectedLoan.SettlementCharges.Extras / this.selectedLoan.Term) * this.sharedService.roundToDec(chargesTerm, 8)), 10);

    var handling = (this.selectedLoan.SettlementCharges.HandlingCharge == undefined) ? 0 : this.selectedLoan.SettlementCharges.HandlingCharge;
    var oriHandlingPaid = this.sharedService.roundToDec((handling / this.selectedLoan.Term) * this.sharedService.roundToDec(chargesTerm, 8), 10);



    // console.log(oriStampPaid)
    // console.log(oriSCPaid)
    // console.log(oriHandlingPaid)

    // floor to make sure the submitted charges amount no decimal
    this.stampAmount = Math.floor(oriStampPaid);
    this.scAmount = Math.floor(oriSCPaid);
    this.extrasAmount = Math.floor(oriExtrasPaid);
    this.handlingAmount = Math.floor(oriHandlingPaid);



    // deduct the decimal part from charges into discount 
    var decimalPart = (oriStampPaid - this.stampAmount) + (oriSCPaid - this.scAmount) + (oriExtrasPaid - this.extrasAmount) + (oriHandlingPaid - this.handlingAmount);
    var finalRepayment = this.roundTo2Dec(oriRepayment + decimalPart + this.selectedLoan.Principal);



    var totalRepayment = this.sharedService.calculateEPFSettlementAmount(this.selectedLoan.Principal, this.selectedLoan.InterestRate, this.selectedLoan.Term);

    var paymentAmount = (totalPaidWithoutPrincipal < 0) ? totalPaidWithoutPrincipal : 0;

    // console.log(totalRepayment)
    // console.log(decimalPart)
    // console.log(this.discount)

    this.discount = this.roundTo2Dec(totalRepayment - finalRepayment - paymentAmount);


    //if collected amount lesser than principal amount, then send discount 
    if (chargesTerm != 0)
      this.discountAmount = (this.epfLoanCollectedAmount >= this.principalAmount || chargesTerm > 0) ? 0 : this.roundTo2Dec(this.discount);
    else
      this.discountAmount = this.roundTo2Dec(this.selectedLoan.Principal - this.epfLoanCollectedAmount)

    //tackle -ve discount issue
    this.discountAmount = (this.discountAmount < 0) ? 0 : this.discountAmount;

    //put all the excess payment into extras
    this.extrasAmount += excessPayment;
    this.duitLebihList = [new DuitLebih(this.roundTo2Dec(this.extrasAmount))]

    this.epfPaymentTerm = chargesTerm;

    // console.log(chargesTerm)
    // console.log(this.discount)
    // console.log(this.discountAmount)
  }

}
