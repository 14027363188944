<div class="p-3">
    <div class="row">
        <div class="col-12 col-lg-2">
            <div class="group">
                <label> Username</label>
                <input type="text" required class="textInputLong" name="Username" [(ngModel)]="username"
                    #usernameFilter>
                <span class="highlight"></span>
                <span class="bar barLong"></span>

            </div>
        </div>
        <div class="col-12 col-lg-2">
            <div class="group">
                <label> IP</label>
                <input type="text" required class="textInputLong" name="ip" [(ngModel)]="ip" #ipFilter>
                <span class="highlight"></span>
                <span class="bar barLong"></span>

            </div>
        </div>
        <div class="col-12 col-lg-2">
            <div class="group">
                <label> Reason</label>
                <input type="text" required class="textInputLong" name="reason" [(ngModel)]="reason" #reasonFilter>
                <span class="highlight"></span>
                <span class="bar barLong"></span>
            </div>
        </div>


        <div class="col-12 col-lg-2">
            <div class="row">
                <div class="col-12">
                    <p>Start Date</p>
                </div>
                <div class="col-12">
                    <mat-form-field class="full-width-formField p-0">
                        <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                            [(ngModel)]="startDate" required>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker name="startDate"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="col-12 col-lg-2">
            <div class="row">
                <div class="col-12">
                    <p>End Date</p>
                </div>
                <div class="col-12">
                    <mat-form-field class="full-width-formField p-0">
                        <input matInput readonly [matDatepicker]="endDatePicker" name="endDate" [(ngModel)]="endDate"
                            [min]="startDate" required>
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                    </mat-form-field>
                </div>

            </div>
        </div>

        <div class="col-12 col-lg-2">
            <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="isFailed"
                name="isFailed ">
                Failed Only
            </mat-slide-toggle>
        </div>

        <div class="col-12 d-flex justify-content-end align-items-end">
            <button class="btn btn-warning" (click)="submitFilter()">Filter</button>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let log">
                {{log.date | date:'dd-MM-yyyy hh:mm:ss'}}
            </td>
        </ng-container>


        <ng-container matColumnDef="ip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>IP </th>
            <td mat-cell *matCellDef="let log">
                {{log.ip }} </td>
        </ng-container>

        <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason </th>
            <td mat-cell *matCellDef="let log">
                {{log.reason }} </td>
        </ng-container>

        <ng-container matColumnDef="remark">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Remark </th>
            <td mat-cell *matCellDef="let log">
                {{log.remark }} </td>
        </ng-container>

        <ng-container matColumnDef="success">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Success </th>
            <td mat-cell *matCellDef="let log">
                {{log.success }} </td>
        </ng-container>

        <ng-container matColumnDef="userAgent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>User Agent </th>
            <td mat-cell *matCellDef="let log">
                {{log.userAgent }} </td>
        </ng-container>

        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Username </th>
            <td mat-cell *matCellDef="let log">
                {{log.username }} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>