<div class="container-fluid m-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>



    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator></app-report-generator>
    </div>

    <h3>Usage Report</h3>
    <div class="row p-1">
        <div class="col d-flex justify-content-end align-items-right">
            <button class="btn btn-dark" (click)="refresh()"><i class="fas fa-sync-alt"></i> Refresh</button>
        </div>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="CompanyCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let usage" [style.background-color]="getWarningStatus(usage.LatestDate)">
                    {{usage.CompanyCode}}
                </td>
            </ng-container>

            <!-- LastInserted Column -->
            <ng-container matColumnDef="LastInsertedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Inserted Date </th>
                <td mat-cell *matCellDef="let usage" [style.background-color]="getWarningStatus(usage.LatestDate)">
                    {{usage.LastInsertedDate | date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <!-- LatestDate Column -->
            <ng-container matColumnDef="LatestDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Latest Date </th>
                <td class="reportCol" mat-cell *matCellDef="let usage"
                    [style.background-color]="getWarningStatus(usage.LatestDate)"
                    (click)="toDailyReport(usage.CompanyId,usage.LatestDate)">
                    {{usage.LatestDate | date:'dd-MM-yyyy'}}
                    <span class="badge text-bg-danger" *ngIf="usage.LatestDate==undefined">~ 1 MONTH NO
                        ACCOUNT</span>
                </td>
            </ng-container>

            <!-- LastManagerCheckedDate Column -->
            <ng-container matColumnDef="LastManagerCheckedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Manager Checked Date </th>
                <td mat-cell *matCellDef="let usage" [style.background-color]="getWarningStatus(usage.LatestDate)">
                    <span class="reportCol" *ngIf="usage.LastManagerCheckedDate!=undefined"
                        (click)="openCheckInHistoryModal(usage.CompanyId,usage.CompanyCode,enums.BRANCH_MANAGER)">{{usage.LastManagerCheckedDate
                        |
                        date:'dd-MM-yyyy'}}</span>
                </td>
            </ng-container>

            <!-- LastAdminCheckedDate Column -->
            <ng-container matColumnDef="LastAdminCheckedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Admin Checked Date </th>
                <td mat-cell *matCellDef="let usage" [style.background-color]="getWarningStatus(usage.LatestDate)">
                    <span class="reportCol" *ngIf="usage.LastAdminCheckedDate!=undefined"
                        (click)="openCheckInHistoryModal(usage.CompanyId,usage.CompanyCode,enums.ADMIN)">{{usage.LastAdminCheckedDate
                        |
                        date:'dd-MM-yyyy'}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[ 100,200,300,400,500]" [pageSize]="400" showFirstLastButtons></mat-paginator>
    </div>
</div>