import { CompanyAccess } from 'src/model/companyAccess.model';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoanRequestDetailsComponent } from 'src/app/LoanRequest/loan-request-details/loan-request-details.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanRequest } from 'src/model/loanRequest.model';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-bookmark-item',
  standalone: true,
  imports: [CommonModule, NgbPopoverModule],
  templateUrl: './bookmark-item.component.html',
  styleUrl: './bookmark-item.component.css'
})
export class BookmarkItemComponent {
  @Input() adminAccess: { [key: string]: CompanyAccess[] };
  @Input() searching: boolean;
  @Input() approved: boolean;
  @Input() bookmarkList: LoanRequest[];
  @Output() itemRemoved = new EventEmitter<number>();

  constructor(public sharedService: SharedService, private router: Router) { }

  ticketDateWarning(aDate: Date, limit: number): boolean {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffInTime = (new Date()).getTime() - (new Date(aDate)).getTime();
    return (Math.round(diffInTime / oneDay) >= limit);
  }

  goToCustomer(custId, profileId: string) {
    this.router.navigate(['/manageCustomer', custId, profileId]);
  }

  openLoanRequestModal(req: LoanRequest) {
    var modalRef = this.sharedService.openModal(LoanRequestDetailsComponent, 'largeModal');
    modalRef.componentInstance.request = req;
    modalRef.componentInstance.viewOnly = true;
    modalRef.componentInstance.isModal = true;
  }

  removeBookmark(id: number) {
    this.itemRemoved.emit(id);
  }

  getAdmins(compID: number) {
    return this.adminAccess[compID].map((a) => a.Priority + ': ' + a.Username).join('\n');
  }
}
