import { Injectable } from '@angular/core';
import { Contact } from 'src/model/contact.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { Loan } from 'src/model/loan.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

@Injectable({
  providedIn: 'root'
})
export class LoanAdapter {

  adapt(item): Loan {
    return new Loan(
      item.id,
      item.profileId,
      item.principal,
      item.stamp ?? 0,
      item.receiptNumber,
      item.status,
      item.dateCreated,
      item.companyId,
      item.companyCode,
      item.scheme,
      item.interestRate,
      item.term,
      item.customerName,
      item.icNumber,
      item.interestCharge,
      item.issuer,
      item.loanCode,
      item.arrear,
      item.principalPaid,
      item.repayment,
      item.reserve ?? 0,
      item.balance,
      item.monthlyPayment,
      item.arrearPaid,
      item.serviceCharge ?? 0,
      (item.extras && item.extras.map(p => this.adaptExtras(p))),
      item.isPendingCommission,
      item.monthlyInterest,
      item.discount,
      item.processingRate,
      item.processingCharge,
      item.isSelected,
      item.epf,
      item.settlementStamp ?? 0,
      item.settlementServiceCharge ?? 0,
      item.settlementExtras ?? 0,
      item.repaymentPaid,
      item.pnl,
      item.principalOut,
      item.termLeft,
      item.legacySettlement,
      item.loanDocExisted,
      (item.settlementCharges && this.adaptSC(item.settlementCharges)),
      item.sharedCompanies
    );
  }

  adaptExtras(item: any): DuitLebih {
    return new DuitLebih(item.amount, item.subtype);
  }

  adaptContact(item: any): Contact {
    return new Contact(
      item.id,
      item.name,
      item.icNumber,
      item.number,
      undefined,
      undefined,
      item.relation,
      item.remark
    );
  }

  adaptSC(loanItem: any): SettlementCharges {
    let result: SettlementCharges;
    if (loanItem.settlementCharges !== undefined) {
      const item = loanItem.settlementCharges;
      result = new SettlementCharges(
        item.serviceCharge ?? 0,
        item.stamp ?? 0,
        item.extras ?? 0,
        item.handlingCharge
      )
    } else {
      result = new SettlementCharges(
        loanItem.serviceCharge ?? 0,
        loanItem.stamp ?? 0,
        loanItem.extras ?? 0,
        loanItem.handlingCharge
      );
    }
    return result;
  }

}

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  constructor() { }
}
