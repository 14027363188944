<div class="container-fluid  ">
    <h4 class="text-primary fw-bold text-end">CashBook Balance: {{cashBookAmount}}</h4>

    <table class="table px-5">
        <thead>
            <tr>
                <th></th>
                <th>Scheme</th>
                <th class="text-end">Stock</th>
                <th class="text-end">Total Outstanding</th>
            @if (needStatusOutstanding) {
                <th class="text-end">Active Outstanding</th>
                <th class="text-end">Bad Debt Outstanding</th>
                <th class="text-end">CP Outstanding</th>
                <th class="text-end">LBD Outstanding</th>
            }
            </tr>
        </thead>
        
        <ng-container *ngFor="let stock of stockList">                
            <tbody class="table-light border-light-subtle">
                <ng-container *ngTemplateOutlet="itemTemplate; context: { item: stock }"></ng-container>
            </tbody>
        @if (stock.breakdown?.length > 0) {
            <tbody [id]="stock.schemeName.replaceAll(' ', '')" class="table-secondary border-secondary-subtle collapse" [ngClass]="{'show': !isCollapsed}">
                <ng-container *ngFor="let b of stock.breakdown">
                    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: b }"></ng-container>
                </ng-container>
            </tbody>
        }
        </ng-container>
        <tfoot style="border-top:2px solid black">
            <tr>
                <th></th>
                <th class="text-end">Total:</th>
                <th class="text-end">{{calculateTotalStock()| number:'1.2-2'}}</th>
                <th class="text-end">{{calculateTotalOutstanding()| number:'1.2-2'}}</th>
            @if (needStatusOutstanding) {
                <th class="text-end">{{calculateTotalActiveOutstanding() | number:'1.2-2'}}</th>
                <th class="text-end">{{calculateTotalBDOutstanding() | number:'1.2-2'}}</th>
                <th class="text-end">{{calculateTotalCPOutstanding() | number:'1.2-2'}}</th>
                <th class="text-end">{{calculateTotalLBDOutstanding() | number:'1.2-2'}}</th>
            }
            </tr>
        </tfoot>
    </table>
</div>

<!-- Reusable template for displaying table rows -->
<ng-template #itemTemplate let-item="item">
    <tr [ngClass]="{'fw-medium': item.breakdown?.length > 0}">
        <td class="text-center">@if (item.breakdown?.length > 0) { <a [title]="isCollapsed ? 'Show more' : 'Hide'" role="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#'+item.schemeName.replaceAll(' ', '')" (click)="isCollapsed = !isCollapsed"><i class="fas text-secondary"  [ngClass]="isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'"></i></a> }</td>
        @if (item.breakdown?.length > 0) {
            <td>{{item.schemeName}}</td>
        } @else {
            <td class="stockCol" (click)="goToLoanSchemeReport(item.schemeName)" >{{item.schemeName}}</td>
        }
        <td class="text-end">{{item.stock - item.stockReceived | number:'1.2-2'}}</td>
        <td class="text-end">{{item.activeOutstanding + item.bdOutstanding + item.cpOutstanding + item.lbdOutstanding | number:'1.2-2'}}</td>
    @if (needStatusOutstanding) {
        <td class="text-end">{{item.activeOutstanding | number:'1.2-2'}}</td>
        <td class="text-end">{{item.bdOutstanding | number:'1.2-2'}}</td>
        <td class="text-end">{{item.cpOutstanding | number:'1.2-2'}}</td>
        <td class="text-end">{{item.lbdOutstanding | number:'1.2-2'}}</td>
    }
    </tr>
</ng-template>