<form>
    <!-- Modal Header -->
    <div class="modal-header" *ngIf="isModal==true">
        <h4 class="modal-title">Loan List ({{viewCustomer.Name}} | {{sharedService.processICNum(viewCustomer.
            ICNumber)}})</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body" [ngClass]="{'p-1 m-1':isModal==true, 'p-0 m-0':isModal==false}">

        <ng-container *ngIf="!totalCustomerLoanList|| totalCustomerLoanList.length ===0;else loanCard">

            <p>There's no loan record for this customer.</p>
            <p>Add a loan? </p>


            <div class="dropdown ms-1"
                *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">
                <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    *ngIf="selectedProfile.SharedProfileId== selectedProfile.ID ||selectedProfile.SharedProfileId==undefined">
                    <i class=" fas fa-plus"></i> Add Loan
                </button>


                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="checkIfLegacySettlementCompleted()" *ngIf="allowAddLoan()==true">
                        <i class="fas fa-hand-holding-usd"></i>
                        <small class="d-none d-md-inline" style="font-size: 16px;">Scheme Loan</small>
                    </a>

                    <a class="dropdown-item" *ngIf="allowAddLoan()==false" (click)="openAddLoanModal()">
                        <i class="fas fa-hand-holding-usd"></i>
                        <small class="d-none d-md-inline" style="font-size: 16px;">Loan Request</small>
                    </a>
                    <a class="dropdown-item"
                        *ngIf="sharedService.checkIsAllowEPF(this.viewCustomer.DOB, this.epfDetails,this.selectedProfile.ID,this.selectedProfile.SharedProfileId)==true"
                        (click)="openAddSharedLoanModal()">
                        <i class="far fa-handshake"></i>
                        <small class="d-none d-md-inline" style="font-size: 16px;"> EPF Loan Request</small>
                    </a>

                    <a class="dropdown-item" (click)="openHutangLoanModal()" *ngIf="allowAddHutang()">
                        <i class="fas fa-receipt"></i>
                        Hutang</a>

                </div>
            </div>
        </ng-container>

        <ng-template #loanCard>

            <div class="row">
                <div class="col-12 col-md-4">
                    <h3 class="ps-2 m-0"><span class="badge" [ngClass]="getIfEarning(calculateTotalEarning())"
                            *ngIf="sharedService.isBranchManager()==true||sharedService.isBossAccount()==true || sharedService.isFinance() || sharedService.isAuditor()==true || sharedService.isAdmin()">P&L:
                            RM{{calculateTotalEarning()|number:'1.2-2'}}
                            <i class="far fa-question-circle ms-2" [ngbTooltip]="epfOverviewTooltip"
                                style="cursor:pointer;"
                                *ngIf="epfDetails!=undefined && epfDetails.Amount!=undefined && sharedService.isBranchAccountHandler()==false"></i>
                        </span>


                        <ng-template #epfOverviewTooltip>
                            <div class="text-start">
                                <p>Settlement (Include Settled): RM {{
                                    calculateTotalSettlement(totalCustomerLoanList)|number:"1.0-2" }}
                                    <span *ngIf="hasSharedLoan()">/
                                        {{ calculateTotalSettlement(totalCustomerLoanList)+
                                        calculateTotalSettlement(sharedLoanList)|number:"1.0-2" }}</span>
                                </p>
                                <p>
                                    Ratio: {{calculateSettlementRatio(totalCustomerLoanList) +
                                    calculateSettlementRatio(sharedLoanList)
                                    |number:'1.2-2'}}%
                                </p>
                            </div>

                        </ng-template>
                    </h3>
                    <h3>

                        <span class="badge text-bg-info" *ngIf="sharedService.isStaff()==false">
                            <button class="btn btn-secondary mr-1" (click)="goToSettlementLetter()" placement="top"
                                ngbTooltip="Print Surat Penyelesaian"
                                *ngIf="epfDetails!=undefined && epfDetails.Amount!=undefined && (selectedProfile.SharedProfileId== selectedProfile.ID ||selectedProfile.SharedProfileId==undefined)"><i
                                    class="fas fa-print"></i></button>

                            Settle:
                            RM{{sharedService.calculateTotalSettlementBalance(totalCustomerLoanList) |number:'1.2-2'}}

                            <i class="far fa-question-circle ms-2" [ngbTooltip]="tooltipContent" style="cursor:pointer;"
                                *ngIf="epfDetails!=undefined && epfDetails.Amount!=undefined "></i>
                            <ng-template #tooltipContent>
                                <div class="text-start">
                                    <p *ngIf="hasSharedLoan()">Total Settlement on EPF Date:
                                        RM
                                        {{sharedService.calculateTotalSettlementBalance(totalCustomerLoanList)|number:"1.0-2"}}
                                        <span>
                                            / {{sharedService.calculateTotalSettlementBalance(totalCustomerLoanList) +
                                            sharedService.calculateTotalSettlementBalance(sharedLoanList)
                                            |number:'1.2-2'}}
                                        </span>
                                    </p>
                                    <p>
                                    <p> Settle on {{sharedService.getTodayDate() | date:'dd-MM-yyyy'}}:
                                        RM{{getEPFEarlySettlement(customerLoanList)|number:'1.2-2'}}<span
                                            *ngIf="hasSharedLoan()"> /
                                            {{getEPFEarlySettlement(customerLoanList)
                                            + getEPFEarlySettlement(sharedLoanList)|number:'1.2-2'}}</span></p>
                                </div>

                            </ng-template>


                        </span>
                    </h3>
                </div>
                <div class="col-6 col-md-2 checkboxCol">
                    <div class="form-check" style="width: 100%!important;">
                        <input type="checkbox" class="form-check-input" id="checkHideSettled"
                            [(ngModel)]="isHidingSettled" (click)="hideSettledLoan()"
                            [ngModelOptions]="{standalone: true}">
                        <label class="form-check-label" for="checkHideSettled">Hide Settled</label>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-end align-items-end p-1">

                    <button class="btn btn-info"
                        *ngIf="sharedService.checkIsMainShareProfile(selectedProfile) && sharedService.isBranchManager() && sharedLoanList!=undefined"
                        (click)="openSharedLoanListModal()">
                        <i class="fas fa-hands-helping"></i> <small class="d-none d-md-inline" style="font-size: 16px;">
                            Show Shared Loans</small>
                    </button>

                    <button class="btn btn-warning" (click)="openBatchPaymentModal()"
                        *ngIf="viewCustomer.Status!=enums.SETTLED_CUST && sharedService.isAdmin()==false&& sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">
                        <i class="fas fa-dollar-sign"></i> <small class="d-none d-md-inline" style="font-size: 16px;">
                            Batch
                            Payment</small>
                    </button>

                    <button class="btn btn-primary" (click)="openMarkLegacyEPF()"
                        *ngIf="(sharedService.isAdmin()==true|| sharedService.isBossAccount()==true) && isModal==false">
                        <i class="fas fa-clipboard-check"></i> <small class="d-none d-md-inline"
                            style="font-size: 16px;"> Mark
                            Legacy EPF</small>
                    </button>




                    <div class="dropdown ms-1">
                        <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="allowSendCP(selectedProfile.Status) ">
                            <i class="far fa-paper-plane"></i>Send File to
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="confirmBeforeSendCP()">
                                <i class="fas fa-headset"></i>
                                <small class="d-none d-md-inline" style="font-size: 16px;"> CP Dept</small>
                            </a>
                            <!-- <a class="dropdown-item" (click)="confirmBeforeSendLegal()">
                        <i class="fas fa-balance-scale"></i> Legal
                        Dept</a> -->

                        </div>
                    </div>

                    <!-- <button class="btn btn-secondary d-flex justify-content-end align-items-center ms-1" type="button"
                (click)="confirmBeforeSendCP()" *ngIf="allowSendCP(selectedProfile.Status)">
                <i class="far fa-paper-plane"></i>
                <small class="d-none d-md-inline" style="font-size: 16px;"> Send To CP</small>
            </button> -->



                    <div class="dropdown ms-1"
                        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && sharedService.isAuditor()==false">
                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="selectedProfile.SharedProfileId== selectedProfile.ID ||selectedProfile.SharedProfileId==undefined">
                            <i class=" fas fa-plus"></i> Add Loan
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="checkIfLegacySettlementCompleted()"
                                *ngIf="allowAddLoan()==true">
                                <i class="fas fa-hand-holding-usd"></i>
                                <small class="d-none d-md-inline" style="font-size: 16px;"> Scheme Loan</small>
                            </a>
                            <a class="dropdown-item"
                                *ngIf="sharedService.checkIsAllowEPF(this.viewCustomer.DOB, this.epfDetails,this.selectedProfile.ID,this.selectedProfile.SharedProfileId)==true"
                                (click)="openAddSharedLoanModal()">
                                <i class="far fa-handshake"></i>
                                <small class="d-none d-md-inline" style="font-size: 16px;"> EPF Loan Request</small>
                            </a>

                            <a class="dropdown-item" *ngIf="allowAddLoan()==false" (click)="openAddLoanModal()">
                                <i class="fas fa-hand-holding-usd"></i>
                                <small class="d-none d-md-inline" style="font-size: 16px;"> Loan Request</small>
                            </a>
                            <a class="dropdown-item" (click)="openHutangLoanModal()" *ngIf="allowAddHutang()">
                                <i class="fas fa-receipt"></i>
                                Hutang</a>

                        </div>
                    </div>


                    <!-- <button class="btn btn-info d-flex justify-content-end align-items-center ms-1" type="button"
                (click)="checkIfLegacySettlementCompleted()"
                *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false">
                <i class=" fas fa-plus"></i>
                <small class="d-none d-md-inline" style="font-size: 16px;"> Add Loan</small>
            </button> -->
                </div>

            </div>
            <!-- <div class="d-flex align-items-end justify-content-end">

    </div> -->

            <ng-container *ngIf="viewCustomer && selectedProfile">
                <ng-container *ngFor="let loan of customerLoanList">

                    <!-- <app-loan-card [loan]="loan" (click)="openLoanDetailsModal(loan)"></app-loan-card> -->
                    <app-loan-card [loan]="loan" [reserveAmount]="selectedProfile.Reserve"
                        [selectedCustomer]="viewCustomer" [selectedProfile]="selectedProfile"
                        [needRestrictionCustomer]="checkRestrict()">
                    </app-loan-card>



                </ng-container>
            </ng-container>
        </ng-template>
    </div>
</form>