import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CompanyAccess } from 'src/model/companyAccess.model';
import { AdminAccessService } from 'src/services/admin-access.service';
import { AccessActionComponent } from '../access-action/access-action.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-admin-company-list',
  templateUrl: './admin-company-list.component.html',
  styleUrl: './admin-company-list.component.css'
})
export class AdminCompanyListComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums, public adminAccessService: AdminAccessService, private cdr: ChangeDetectorRef) { }

  @Input() companyAccessList: CompanyAccess[] = new Array;
  @Input() adminName: string;
  @Input() companyCode: string;
  temporaryAccessList: CompanyAccess[] = new Array;
  permanentAccessList: CompanyAccess[] = new Array;

  ngOnInit(): void {
    if (this.companyAccessList != undefined && this.companyAccessList.length > 0) {
      this.temporaryAccessList = this.companyAccessList.filter(access => this.adminAccessService.isTemporaryPriority(access.Priority));
      this.permanentAccessList = this.companyAccessList.filter(access => !this.adminAccessService.isTemporaryPriority(access.Priority));
    }
  }

  reset() {
    this.activeModal.close();
  }


  openChangeAccessModal(access: CompanyAccess) {
    const modalRef = this.sharedService.openModal(AccessActionComponent, "regularModal");
    modalRef.componentInstance.action = "ChangeAccess";
    var filtered = [access];
    modalRef.componentInstance.companyAccessList = filtered;
    
  }

  openRevokePriorityModal(access: CompanyAccess, index: number) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to revoke access?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        var adminName = (this.adminName != undefined) ? this.adminName : access.Username;
        let revoked = this.adminAccessService.revokeAccess(adminName, [access.CompanyId]);
        if (revoked) {
          this.companyAccessList.splice(Number(index), 1);
          this.cdr.detectChanges();
        }
      }
    });
  }

  openRevokeTemporaryAccessModal() {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to revoke all temporary access?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe(async (entry) => {
      var confirmation = entry;

      // use the confirmation to do something
      if (confirmation == true) {
        var revoked = false;

        if (this.adminName == undefined) {
          for (let i = 0; i < this.temporaryAccessList.length; i++) {
            revoked = await this.adminAccessService.revokeAccess(this.temporaryAccessList[i].Username, [this.temporaryAccessList[i].CompanyId]);

            if (revoked == false)
              break;
          }
        } else {
          let temporaryList = this.temporaryAccessList.map(access => access.CompanyId);
          revoked = await this.adminAccessService.revokeAccess(this.adminName, temporaryList);
        }


        if (revoked) {
          this.temporaryAccessList = [];
          this.cdr.detectChanges();
        } else {
          this.sharedService.openAlert("There are some error(s) when revoking access, please try again later", this.enums.DANGER_ALERT);
        }

      }
    });
  }

}
