import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { AdminAccess, CompanyAccess, CompanyAdmins } from 'src/model/companyAccess.model';
import { AccessActionComponent } from '../access-action/access-action.component';




@Component({
  selector: 'app-admin-access-management',
  templateUrl: './admin-access-management.component.html',
  styleUrl: './admin-access-management.component.css'
})




export class AdminAccessManagementComponent implements OnInit {
  companyAccessList: CompanyAccess[] = new Array();
  adminList: AdminAccess[] = new Array();
  companyAdminList: CompanyAdmins[] = new Array();
  companyList: Company[] = new Array();




  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private cdr: ChangeDetectorRef, private enums: Enums) {

  }

  ngOnInit(): void {
    this.refresh();

  }



  async refresh() {
    this.companyAccessList = await this.getAllAdminAccess();
    this.adminList = await this.getAllAdmins();

    this.adminList.sort((a, b) =>
      a.username.toLowerCase().localeCompare(b.username.toLowerCase())
    );

    this.cdr.detectChanges();
  }



  getAllAdminAccess() {
    return new Promise<CompanyAccess[]>((resolve) => {
      var xhttp = this.restApi.getRequest(
        this.constructApi.getCompaniesAccess()
      );
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse(xhttp.responseText);
          let result: CompanyAccess[] = new Array();
          jsonArray.forEach((json) => {
            var access = new CompanyAccess(
              json.companyCode,
              json.companyId,
              json.priority,
              json.username
            );
            result.push(access);
          });
          resolve(result);
        }
      };
    });
  }



  getAllAdmins() {
    return new Promise<AdminAccess[]>((resolve) => {
      var xhttp = this.restApi.getRequest(this.constructApi.getAllUsersByRoles([this.enums.ADMIN], true))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse(xhttp.responseText);
          let result: AdminAccess[] = new Array();
          jsonArray.forEach((json) => {
            // var admin: AdminAccess = { username: json.username, companyAccess: [], companyCount: 0, tempAccess: [], tempAccessCount: 0, onDutyAccess: [], onDutyAccessCount: 0 };
            var admin: AdminAccess = this.getAdminAccess(json.username);
            result.push(admin);
          });
          resolve(result);
        }
      };
    });
  }

  getAdminAccess(username: string) {
    let companyAccess = this.companyAccessList
      // .filter((c) => c.Username == username && this.enums.ADMIN_PRIORITY.includes(c.Priority))
      .filter((c) => c.Username == username && c.Priority % 10 == 0)
      .map((c) => c.CompanyId);
    let tempAccess = this.companyAccessList
      // .filter((c) => c.Username == username && this.enums.TEMP_PRIORITY.includes(c.Priority))
      .filter((c) => c.Username == username && c.Priority % 10 == 1 && c.Priority !== this.enums.ON_DUTY_PRIORITY)
      .map((c) => c.CompanyId);
    let onDutyAccess = this.companyAccessList
      .filter((c) => c.Username == username && c.Priority === this.enums.ON_DUTY_PRIORITY)
      .map((c) => c.CompanyId);
    return { username: username, companyAccess: companyAccess, companyCount: companyAccess.length, tempAccess: tempAccess, tempAccessCount: tempAccess.length, onDutyAccess: onDutyAccess, onDutyAccessCount: onDutyAccess.length };
  }



  openAddOnDutyAdminModal() {
    const modalRef = this.sharedService.openModal(AccessActionComponent);
    modalRef.componentInstance.action = "Duty";
    modalRef.componentInstance.currentOnDutyAdmins = this.adminList.filter(admin => admin.onDutyAccess.length > 0).map(admin => admin.username);
    // console.log(this.adminList.filter(admin => admin.onDutyAccess.length > 0))

    modalRef.componentInstance.shouldReload.subscribe((reload) => {
      if (reload == true)
        window.location.reload();
    });
  }
}
