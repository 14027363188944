import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ViewCpDetailsComponent } from '../../CP/view-cp-details/view-cp-details.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-global-search-customer',
  templateUrl: './global-search-customer.component.html',
  styleUrls: ['./global-search-customer.component.css']
})

export class GlobalSearchCustomerComponent implements OnInit {
  customerList: GlobalCustomer[] = new Array;

  cpCustomerList: GlobalCustomer[];
  guarantorList: GlobalCustomer[];
  blacklistRecordList: GlobalCustomer[];

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() isGlobal: boolean;
  @Input() needRedirect: boolean = true;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  searchICNum: string = "";
  searchName: string = "";
  searchCustCode: string = "";
  disableSubmit = false;


  includeCPSearch = false;
  includeGuarantorSearch = false;
  includeBlacklistSearch = false;

  displayedCPColumns: string[];
  cpDataSource = new MatTableDataSource;

  displayedGuarantorColumns: string[];
  guarantorDataSource = new MatTableDataSource;

  displayedBlacklistColumns: string[];
  blacklistDataSource = new MatTableDataSource;

  searchHistoryList: GlobalCustomer[] = new Array;



  constructor(private modalService: NgbModal, private router: Router, private constructApi: ConstructAPI,
    private restApi: RestApiService, private activeModal: NgbActiveModal, public enums: Enums, public sharedService: SharedService, private datepipe: DatePipe) { }

  ngOnInit(): void {

    if (this.isGlobal) {
      if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance()) {
        this.includeCPSearch = true;
        this.includeGuarantorSearch = true;
        this.includeBlacklistSearch = true;
        this.displayedColumns = ['Name', 'ICNumber', 'Status', 'CompanyName', 'DOB'];
        this.displayedCPColumns = ['CustName', 'CustIC', 'CustCode', 'CaseStatus', 'EmployeeName'];
        this.displayedGuarantorColumns = ['GuarantorName', 'GuarantorICNumber', 'GuarantorCompanyCode', 'CustomerName', 'CustomerIC', 'HasLoan', 'LastApprovedOn'];
        this.displayedBlacklistColumns = ['Name', 'ICNumber', 'Remark']
      }

      else {
        this.includeCPSearch = (!this.sharedService.isStaff());
        this.includeBlacklistSearch = true;

        if (this.searchICNum != undefined && this.searchICNum != '')
          this.displayedColumns = ['Name', 'ICNumber', 'DOB'];
        else
          this.displayedColumns = ['Name', 'ICNumber', 'CompanyName', 'DOB'];

        this.displayedCPColumns = ['CustName', 'CustIC', 'CaseStatus', 'EmployeeName']
        this.displayedBlacklistColumns = ['Name', 'ICNumber', 'Remark']
      }

    }

    else
      this.displayedColumns = ['Name', 'ICNumber', 'CustCode', 'Occupation', 'Status'];


    //load search history list
    if (sessionStorage.getItem("searchHistory") != undefined) {
      var jsonArr = JSON.parse(sessionStorage.getItem("searchHistory"));
      jsonArr.forEach(json => {
        var history = new GlobalCustomer(json._name, json._icNumber, undefined, undefined, undefined, undefined, undefined, json._custCode);
        this.searchHistoryList.push(history);
      });
    }

  }


  goToCustomer(customerID: number, profileID: number) {
    if (profileID == undefined)
      this.router.navigate(['/manageCustomer', customerID]);
    else
      this.router.navigate(['/manageCustomer', customerID, profileID]);
    this.reset();
  }


  checkRedirectOrReturn(customer: GlobalCustomer) {
    if (this.needRedirect == true) {
      if (this.isGlobal == true) {
        if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance())
          this.goToCustomer(customer.CustomerID, customer.ProfileID);
      } else
        this.goToCustomer(customer.CustomerID, customer.ProfileID);
    } else {
      this.passEntry.emit(customer);
      this.reset();
    }

  }

  detectEnableSearch() {
    if (this.isGlobal == true) {
      if (this.searchICNum == "" && this.searchName == "")
        this.disableSubmit = true;
      else
        this.disableSubmit = false;
    }
    else {
      if (this.searchICNum == "" && this.searchName == "" && this.searchCustCode == "")
        this.disableSubmit = true;
      else
        this.disableSubmit = false;
    }

    return this.disableSubmit;
  }

  getStatusColor(status: number) {
    var color: string;
    switch (status) {
      case this.enums.ACTIVE_CUST:
        color = "bg-primary";
        break;
      case this.enums.WARNING_CUST:
        color = "bg-warning";
        break;
      case this.enums.BAD_DEBT_CUST:
        color = "bg-dark";
        break;
      case this.enums.CP_CUST:
        color = "bg-info";
        break;
      default:
        color = "bg-success";
        break;
    }
    return color;
  }



  searchCPCustomer(name: string, ic: string, custCode?: string) {
    this.disableSubmit = true;
    this.cpCustomerList = [];

    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    var companyId = Number(sessionStorage.getItem('companyId'));
    xhr = this.restApi.getRequest(this.constructApi.getSearchCPCustomer(name, icNumberFormat, custCode));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              var customer;
              // create customer instance with all response data
              customer = new GlobalCustomer(json.CustomerName, json.CustomerIC, undefined, json.CustomerId, json.caseStatus, undefined, undefined, json.CustomerCode, undefined, undefined, undefined, json.EmployeeName);


              //add customer instance into customer list
              this.cpCustomerList.push(customer);
            }
            );



            //make customer list as the datasource
            this.cpDataSource = new MatTableDataSource<GlobalCustomer>(this.cpCustomerList);
            this.cpDataSource.paginator = this.paginator;
            this.cpDataSource.sort = this.sort;
          }


          if (this.includeBlacklistSearch == true && ic != undefined && ic != "")
            this.searchBlacklist(icNumberFormat);

        }
        else {
          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error, this.enums.DANGER_ALERT)
        }

      }
    }

  }


  searchGuarantor(ic: string) {
    this.disableSubmit = true;
    this.guarantorList = [];

    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    var companyId = Number(sessionStorage.getItem('companyId'));
    xhr = this.restApi.getRequest(this.constructApi.getSearchGuarantor(icNumberFormat));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              var guarantor;
              // create customer instance with all response data
              var hasLoan = (json.hasLoan == 'true');
              guarantor = new GlobalCustomer(json.customerName, json.CustomerIcNumber, json.companyCode, json.customerId,
                undefined, undefined, undefined, undefined, undefined, json.name, json.icNumber, undefined, undefined, hasLoan, new Date(json.lastApprovedOn));


              //add customer instance into customer list
              this.guarantorList.push(guarantor);
            }
            );

            //make customer list as the datasource
            this.guarantorDataSource = new MatTableDataSource<GlobalCustomer>(this.guarantorList);
            this.guarantorDataSource.paginator = this.paginator;
            this.guarantorDataSource.sort = this.sort;


          }


        }

      }
    }

  }

  searchBlacklist(ic: string) {
    this.disableSubmit = true;
    this.blacklistRecordList = [];

    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    xhr = this.restApi.getRequest(this.constructApi.getAllBlacklist(icNumberFormat, false));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              var record;
              // create customer instance with all response data

              let remark = (this.sharedService.isAdmin() || this.sharedService.isBossAccount()) ? json.remark : json.status;
              record = new GlobalCustomer(json.name, json.icNumber, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, remark);


              //add customer instance into customer list
              this.blacklistRecordList.push(record);
            }
            );

            //make customer list as the datasource
            this.blacklistDataSource = new MatTableDataSource<GlobalCustomer>(this.blacklistRecordList);

            this.blacklistDataSource.paginator = this.paginator;
            this.blacklistDataSource.sort = this.sort;


          }
          if (this.includeGuarantorSearch == true && ic != undefined && ic != "")
            this.searchGuarantor(icNumberFormat);

        }

      }
    }

  }


  ifContainsInvalidSymbol(name: string, ic: string, custCode?: string, isSearchAgain?: boolean) {
    let regexp: RegExp = /[&;?!:+%]/g;
    var isInvalid = regexp.test(name) || regexp.test(ic) || regexp.test(custCode);


    if (isInvalid) {
      this.sharedService.openAlert("Please make sure your search text does not contain symbol: &;?!:+%!", this.enums.DANGER_ALERT);
    } else
      this.findCustomer(name, ic, custCode, isSearchAgain);
  }

  findCustomer(name: string, ic: string, custCode?: string, isSearchAgain?: boolean) {



    this.disableSubmit = true;
    this.customerList = [];
    this.cpCustomerList = [];
    this.guarantorList = [];

    if (isSearchAgain == true) {
      this.searchName = name;
      this.searchICNum = ic;
    }

    if (ic != undefined)
      var icNumberFormat = this.sharedService.sanitizeICNumInput(ic)
    var xhr;
    var companyId = Number(sessionStorage.getItem('companyId'));
    if (this.isGlobal)
      xhr = this.restApi.getRequest(this.constructApi.getGlobalSearch(name, icNumberFormat));
    else
      xhr = this.restApi.getRequest(this.constructApi.getCompanyProfileSearch(companyId, name, icNumberFormat, custCode));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length == 0) {

            if (this.includeCPSearch == true && ic != undefined && ic != '')
              this.searchCPCustomer(name, ic, custCode);
            else
              this.sharedService.openAlert("Customer not found!", this.enums.DANGER_ALERT)
          }
          else {
            jsonArray.forEach(json => {
              var customer;
              // create customer instance with all response data
              if (this.isGlobal == false) {
                customer = new GlobalCustomer(json.name, json.icNumber, json.companyName, json.customerId, json.status, json.occupation,
                  json.id, json.customerCode, json.isLead, undefined, undefined, undefined, undefined, undefined, undefined, undefined, json.companyId, json.sharedProfileId);
              }
              else {

                customer = new GlobalCustomer(json.name, json.icNumber, json.companyCode, json.id, json.status, undefined, undefined, undefined, undefined,
                  undefined, undefined, undefined, undefined, undefined, undefined, json.dob, json.companyId, json.sharedProfileId);
              }

              //add customer instance into customer list
              this.customerList.push(customer);
            }
            );

            //sort the customer list
            if (this.isGlobal == false)
              this.customerList.sort((a, b) => (a.IsLead > b.IsLead) ? 1 : -1);

            //make customer list as the datasource
            this.dataSource = new MatTableDataSource<GlobalCustomer>(this.customerList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;



            if (this.includeCPSearch == true && ic != undefined && ic != '')
              this.searchCPCustomer(name, ic, custCode);


            //if user is not searching back using previous history
            if (isSearchAgain == false || isSearchAgain == undefined) {
              //add search into session storage to save history
              //search history keep maximum 3 records, if over then remove the oldest record first
              if (this.searchHistoryList.length >= 3) {
                this.searchHistoryList.shift();
              }
              this.searchHistoryList.push(new GlobalCustomer(this.sharedService.processStringStripInvalidSymbol(name), this.sharedService.processStringStripInvalidSymbol(ic), undefined, undefined, undefined, undefined, undefined, this.sharedService.processStringStripInvalidSymbol(custCode)));
              sessionStorage.setItem("searchHistory", JSON.stringify(this.searchHistoryList));
            }
          }



        }
        else {

          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error, this.enums.DANGER_ALERT)
        }

      }
    }
  }

  reset() {
    this.activeModal.close();
    this.searchICNum = "";
    this.searchName = "";


  }

  checkIsLead(customer: GlobalCustomer) {

    if (customer.IsLead == true)
      return 'lead-col';

  }

  openCPDetailsModal(customerID: string) {
    if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance() || this.sharedService.isBranchAccountHandler()) {
      var modalRef = this.sharedService.openModal(ViewCpDetailsComponent, "largeModal");
      modalRef.componentInstance.customerId = customerID;
    }
    else {
      this.sharedService.openAlert("You're not authorized to check CP customer details", this.enums.DANGER_ALERT);
    }

  }



}
