import { Injectable } from "@angular/core";
import { SharedService } from "../Shared/shared-service.service";
import { APIAddress } from "./apiAddresses";
import { request } from "http";
import { share } from "rxjs/operators";

type TxSetOptions = {
  startDate?: string,
  endDate?: string,
  valid?: boolean,
  checked?: boolean,
  pending?: boolean,
  includePending?: boolean,
  type?: number,
  priority?: number,
  subtype?: number,
  saved?: boolean,
  limit?: number,
  sortASC?: boolean
}

@Injectable({
  providedIn: "root",
})
export class ConstructAPI {
  constructor(
    private apiAddress: APIAddress,
    private sharedService: SharedService
  ) { }

  getChangePassword() {
    return this.apiAddress.CHANGE_PASSWORD;
  }

  getChangeCompanyUserRole(companyId: number, username: string) {
    return (
      this.apiAddress.COMPANY + "/" + companyId + "/user/" + username + "/role"
    );
  }

  getAllRoles() {
    return this.apiAddress.ALL_ROLES;
  }

  getAllLoans() {
    return this.apiAddress.ALL_LOANS;
  }

  getAllProfileLoans(
    profileID: number,
    scheme?: string,
    status?: Number[],
    withLegacyEPF?: boolean,
    inclShared?: boolean
  ) {
    var address = this.apiAddress.PROFILE + "/" + profileID + "/loans";

    if (
      scheme != undefined ||
      status != undefined ||
      withLegacyEPF != undefined ||
      inclShared != undefined
    )
      address += "?";
    if (scheme != undefined) address += "scheme=" + scheme + "&";

    if (status != undefined) {
      for (let i = 0; i < status.length; i++) {
        address += "status=" + status[i] + "&";
      }
    }

    if (withLegacyEPF != undefined)
      address += "withLegacyEPF=" + withLegacyEPF + "&";

    if (inclShared != undefined) address += "inclShared=" + inclShared + "&";

    return address;
  }

  getLoanSettlementInfo(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/settlementInfo";
  }

  getAllTasks(type?: number) {
    var address = this.apiAddress.ALL_TASKS;

    if (type != undefined) address += "?type=" + type;
    return address;
  }

  getAllCompanies(sharable?: boolean) {
    var address = this.apiAddress.ALL_COMPANIES;

    if (sharable != undefined) {
      var sharableStr = sharable == true ? "true" : "false";
      address += "?sharable =" + sharableStr;
    }

    return address;
  }

  getAllCompaniesDetails(lastId?: number, limit?: number) {
    var address = this.apiAddress.ALL_COMPANIES + "/details";

    if (lastId != undefined || limit != undefined) address += "?";

    if (lastId != undefined) address += "lastId=" + lastId + "&";
    if (limit != undefined) address += "limit=" + limit;

    return address;
  }

  getCompanyGroupSummaries(groupId: number) {
    return this.apiAddress.COMPANY_GROUP + "/" + groupId + "/stats/summaries";
  }

  getAllCompanySummaires() {
    return this.apiAddress.ALL_STATS + "/summaries";
  }

  getAllAccounts() {
    return this.apiAddress.ALL_ACCOUNTS;
  }

  getAllLoanSchemes() {
    return this.apiAddress.ALL_LOANSCHEMES;
  }

  getAllLeads() {
    return this.apiAddress.ALL_LEADS;
  }

  getAllLeadsProfiles() {
    return this.apiAddress.ALL_LEADS + "/profiles";
  }

  getCompanyLeadsProfiles(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/leads/profiles";
  }

  getLeadDetails(id: number) {
    return this.apiAddress.LEAD + "/" + id;
  }

  getAllCustomers() {
    return this.apiAddress.ALL_CUSTOMERS;
  }

  getCompanyAllCustomers(companyId: number, verified?: boolean) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/customers";
    if (verified != undefined) address += "?verified=" + verified;

    return address;
  }

  getCompanyAllLoans(companyId: number, scheme?: string, status?: number[]) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/loans";
    if (scheme != undefined) address += "?scheme=" + scheme;

    if (status != undefined) {
      for (let i = 0; i < status.length; i++) {
        address += "&status=" + status[i];
      }
    }

    return address;
  }

  getCustomerAllLoans(customerId: number, status?: number[]) {
    var address = this.apiAddress.CUSTOMER + "/" + customerId + "/loans";

    if (status != undefined) {
      for (let i = 0; i < status.length; i++) {
        address += "?status=" + status[i];
      }
    }

    return address;
  }

  getAllAdvances(
    paid?: boolean,
    minAmount?: number,
    maxAmount?: number,
    companyId?: number
  ) {
    var address = this.apiAddress.ALL_ADVANCES;

    if (
      paid != undefined ||
      minAmount != undefined ||
      maxAmount != undefined ||
      companyId != undefined
    )
      address += "?";
    if (paid != undefined) address += "paid=" + paid + "&";
    if (minAmount != undefined) address += "minAmount=" + minAmount + "&";
    if (maxAmount != undefined) address += "maxAmount=" + maxAmount + "&";
    if (companyId != undefined) address += "companyId=" + companyId + "&";

    return address;
  }

  getAllAdvancesOfCompany(
    companyID: number,
    paid?: boolean,
    minAmount?: number,
    maxAmount?: number,
    endDate?: string
  ) {
    var address = this.apiAddress.COMPANY + "/" + companyID + "/advances";

    if (
      paid != undefined ||
      minAmount != undefined ||
      maxAmount != undefined ||
      companyID != undefined ||
      endDate != undefined
    )
      address += "?";

    if (endDate != undefined) address += "?endDate=" + endDate;

    if (paid != undefined) address += "paid=" + paid + "&";

    if (minAmount != undefined) address += "minAmount=" + minAmount + "&";

    if (maxAmount != undefined) address += "maxAmount=" + maxAmount + "&";

    if (companyID != undefined) address += "companyId=" + companyID + "&";
    return address;
  }

  getAllUsers(companyId?: number, roleId?: number, isActive?: boolean) {
    var address = this.apiAddress.ALL_USERS;

    if (companyId != undefined || roleId != undefined || isActive != undefined)
      address += "?";
    if (companyId != undefined) address += "companyId=" + companyId + "&";

    if (roleId != undefined) address += "roleId=" + roleId;

    if (isActive != undefined) address += "active=" + isActive;

    return address;
  }

  getAllUsersByRoles(roleIds?: number[], active?: boolean) {
    var address = this.apiAddress.ALL_USERS;

    if (roleIds != undefined) address += "?";

    if (roleIds != undefined) {
      for (let i = 0; i < roleIds.length; i++) {
        address += "roleId=" + roleIds[i] + "&";
      }
    }

    if (active != undefined)
      address += "active=" + active;

    return address;
  }

  getAllCompanyGroups() {
    return this.apiAddress.ALL_COMPANY_GROUPS;
  }

  getCompanyGroupCompanies(companyId: number) {
    return this.apiAddress.COMPANY_GROUP + "/" + companyId + "/companies";
  }

  getAllPendingCommissions(profileId?: number, assignable?: boolean) {
    var address = this.apiAddress.ALL_COMMISSION + "/pending";
    if (profileId != undefined || assignable != undefined) address += "?";

    if (profileId != undefined) address += "profileId=" + profileId + "&";

    if (assignable != undefined) address += "assignable=" + assignable;

    return address;
  }

  getAllCommissions() {
    return this.apiAddress.ALL_COMMISSION;
  }

  getEditCommission(loanId: Number) {
    return this.apiAddress.LOAN + "/" + loanId + "/commission";
  }

  getDeleteLoanCommission(loanId: number, commissionId: Number) {
    return this.apiAddress.LOAN + "/" + loanId + "/commission/" + commissionId;
  }

  getLoanCommissions(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/commission";
  }

  getAllExpenses() {
    return this.apiAddress.ALL_EXPENSES;
  }

  getAllTransactionTypes() {
    return this.apiAddress.TRANSACTION_TYPES;
  }

  getAllTransactionSubTypes() {
    return this.apiAddress.ALL_SUBTYPES;
  }

  getTransactionSubTypes(type: number) {
    return this.apiAddress.TRANSACTION_TYPE + "/" + type + "/subtypes";
  }

  getAllAssetsOfCompany(companyId: number, endDate?: string) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/assets";

    if (endDate != undefined) address += "?endDate=" + endDate;

    return address;
  }

  getProfilesReserveReceipts(
    startDate?: string,
    endDate?: string,
    companyId?: number
  ) {
    // var address = this.apiAddress.ALL_PROFILES + "/reserve";
    var address = this.apiAddress.ALL_RESERVES + "/receipts";
    if (
      startDate != undefined ||
      endDate != undefined ||
      companyId != undefined
    )
      address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (companyId != undefined) address += "companyId=" + companyId;

    return address;
  }

  getCompanyActiveReserve(
    companyId: number,
    endDate?: string,
    minAmount?: number
  ) {
    // var address = this.apiAddress.ALL_PROFILES + "/reserve";
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/report/activeReserves";
    if (endDate != undefined || minAmount != undefined) address += "?";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (minAmount != undefined) address += "minAmount=" + minAmount;

    return address;
  }

  getProfileAllLoansTransactions(
    profileId: number,
    startDate?: string,
    endDate?: string,
    scheme?: string,
    type?: number[],
    accountId?: number,
    handledBy?: string
  ) {
    var address =
      this.apiAddress.PROFILE + "/" + profileId + "/transactions/loans";

    if (
      scheme != undefined ||
      startDate != undefined ||
      endDate != undefined ||
      type != undefined ||
      handledBy != undefined
    )
      address += "?";

    if (type != undefined) {
      for (let i = 0; i < type.length; i++) {
        address += "type=" + type[i] + "&";
      }
    }

    if (scheme != undefined) address += "scheme=" + scheme + "&";
    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";
    if (accountId != undefined) address += "accountId=" + accountId + "&";

    if (handledBy != undefined && handledBy != "")
      address += "handledBy=" + handledBy + "&";
    return address;
  }

  getAllTransactionsForTransactionGroup(companyId: number, groupId: number) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/transactions/group/" +
      groupId
    );
  }

  getCompanyAllLoansTransactions(
    companyId: number,
    startDate?: string,
    endDate?: string,
    scheme?: string,
    type?: number[],
    accountId?: number,
    handledBy?: string,
    customerId?: number,
    minAmount?: number,
    maxAmount?: number,
    inSet?: boolean
  ) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/transactions/loans";

    if (
      scheme != undefined ||
      startDate != undefined ||
      endDate != undefined ||
      type != undefined ||
      handledBy != undefined ||
      customerId != undefined ||
      minAmount != undefined ||
      maxAmount != undefined ||
      inSet != undefined
    )
      address += "?";

    if (type != undefined) {
      for (let i = 0; i < type.length; i++) {
        address += "type=" + type[i] + "&";
      }
    }

    if (scheme != undefined) address += "scheme=" + scheme + "&";
    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";
    if (accountId != undefined) address += "accountId=" + accountId + "&";

    if (handledBy != undefined && handledBy != "")
      address += "handledBy=" + handledBy + "&";
    if (customerId != undefined) address += "customerId=" + customerId + "&";
    if (minAmount != undefined) address += "minAmount=" + minAmount + "&";
    if (maxAmount != undefined) address += "maxAmount=" + maxAmount + "&";
    if (inSet != undefined) address += "inSet=" + inSet;
    return address;
  }

  getCompanyCashBookBalance(companyId: number, endDate: string) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/cashBookBalance";
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }

  getSchemeSummaries(companyId: number, endDate: string) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/stats/schemeSummaries";
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }

  getCompaniesOutstandingSummaries(endDate?: string) {
    var address = this.apiAddress.ALL_STATS + "/outstandingSummaries";
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }
  getCompaniesOutstandingSummariesByGroup(
    companyGroup: number,
    endDate?: string
  ) {
    var address =
      this.apiAddress.COMPANY_GROUP +
      "/" +
      companyGroup +
      "/stats/outstandingSummaries";
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }

  getCompaniesCustomerCount(endDate?: string) {
    var address = this.apiAddress.ALL_STATS + "/customerCountByStatus";
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }

  getCompaniesCustomerCountByGroup(companyGroup: number, endDate?: string) {
    var address =
      this.apiAddress.COMPANY_GROUP +
      "/" +
      companyGroup +
      "/stats/customerCountByStatus";
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }

  getCompaniesCashBalance(endDate?: string, limit?: number) {
    var address = this.apiAddress.ALL_COMPANIES + "/balance";

    if (endDate != undefined || limit != undefined) address += "?";
    if (endDate != undefined) address += "endDate=" + endDate;
    if (limit != undefined) address += "&limit=" + limit;
    return address;
  }

  getCompanyGroupCompaniesCashBalance(
    groupId: number,
    endDate?: string,
    limit?: number
  ) {
    var address =
      this.apiAddress.COMPANY_GROUP + "/" + groupId + "/companies/balance";

    if (endDate != undefined || limit != undefined) address += "?";
    if (endDate != undefined) address += "endDate=" + endDate;
    if (limit != undefined) address += "&limit=" + limit;
    return address;
  }

  getCompanyLoanScehmeLoans(companyId: number, schemes: string[]) {
    if (schemes.length == 1)
      return (
        this.apiAddress.COMPANY +
        "/" +
        companyId +
        "/loans?scheme=" +
        schemes[0]
      );
    else {
      var address = this.apiAddress.COMPANY + "/" + companyId + "/loans?";

      for (let i = 0; i < schemes.length; i++) {
        address += "scheme=" + schemes[i] + "&";
      }

      return address;
    }
  }

  getAccount(accountId: number, endDate?: string) {
    var address = this.apiAddress.ACCOUNT + "/" + accountId;
    if (endDate != undefined) address += "?endDate=" + endDate;

    return address;
  }

  getCompanyLeads(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/leads";
  }

  getCustomemerOfUser(username: string) {
    return this.apiAddress.USER_CUSTOMERS + username;
  }

  getTaskOfUser(username: string) {
    return this.apiAddress.USER_TASK + username;
  }

  getLoanOfCustomer(
    customerID: number,
    status?: number[],
    schemes?: string[],
    withLegacyEPF?: boolean,
    sharedProfileId?: number,
    includedSharedProfile?: boolean
  ) {
    var address = this.apiAddress.CUSTOMER + "/" + customerID + "/loans";

    if (
      status != undefined ||
      schemes != undefined ||
      withLegacyEPF != undefined ||
      sharedProfileId != undefined ||
      includedSharedProfile != undefined
    )
      address += "?";

    if (status != undefined) {
      for (let i = 0; i < status.length; i++) {
        address += "status=" + status[i] + "&";
      }
    }
    if (schemes != undefined) {
      for (let i = 0; i < schemes.length; i++) {
        address += "scheme=" + schemes[i] + "&";
      }
    }

    if (withLegacyEPF != undefined) address += "withLegacyEPF=" + withLegacyEPF;

    if (sharedProfileId != undefined)
      address += "sharedProfileId=" + sharedProfileId + "&";

    if (includedSharedProfile != undefined)
      address += "inclShared=" + includedSharedProfile + "&";

    return address;
  }

  getLoanOfCustomerProfile(
    profileID: number,
    status?: number[],
    schemes?: string[],
    withLegacyEPF?: boolean,
    includeShared?: boolean
  ) {
    var address = this.apiAddress.PROFILE + "/" + profileID + "/loans";

    if (
      status != undefined ||
      schemes != undefined ||
      withLegacyEPF != undefined ||
      includeShared != undefined
    )
      address += "?";

    if (status != undefined) {
      for (let i = 0; i < status.length; i++) {
        address += "status=" + status[i] + "&";
      }
    }
    if (schemes != undefined) {
      for (let i = 0; i < schemes.length; i++) {
        address += "scheme=" + schemes[i] + "&";
      }
    }

    if (withLegacyEPF != undefined) address += "withLegacyEPF=" + withLegacyEPF;

    if (includeShared != undefined) address += "inclShared=" + includeShared;

    return address;
  }

  getCompanyLoans(
    companyId: number,
    status?: number[],
    schemes?: string[],
    withLegacyEPF?: boolean,
    sharedProfileId?: number,
    includedSharedProfile?: boolean,
    customerId?: number
  ) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/loans";

    if (
      status != undefined ||
      schemes != undefined ||
      withLegacyEPF != undefined ||
      sharedProfileId != undefined ||
      includedSharedProfile != undefined ||
      customerId != undefined
    )
      address += "?";

    if (status != undefined) {
      for (let i = 0; i < status.length; i++) {
        address += "status=" + status[i] + "&";
      }
    }
    if (schemes != undefined) {
      for (let i = 0; i < schemes.length; i++) {
        address += "scheme=" + schemes[i] + "&";
      }
    }

    if (withLegacyEPF != undefined)
      address += "withLegacyEPF=" + withLegacyEPF + "&";

    if (sharedProfileId != undefined)
      address += "sharedProfileId=" + sharedProfileId + "&";

    if (includedSharedProfile != undefined)
      address += "inclShared=" + includedSharedProfile + "&";
    if (customerId != undefined) address += "customerId=" + customerId;

    return address;
  }

  getLoanDetails(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId;
  }

  getCustomerLoanAllFiles(customerId: number, loanId: number) {
    return (
      this.apiAddress.CUSTOMER + "/" + customerId + "/loan/" + loanId + "/files"
    );
  }

  getTransactionOfUser(username: string) {
    return this.apiAddress.USER_TRANSACTIONS + username;
  }

  getTransactionOfAccount(accountId: number) {
    return this.apiAddress.ACCOUNT_TRANSACTIONS + accountId;
  }

  getUsersInCompany(companyId: number) {
    return this.apiAddress.COMPANY_USERS + companyId;
  }

  getStatusOfLoan(loanId: number) {
    return this.apiAddress.LOAN_STATS + loanId;
  }

  getCustomerDetails(customerId: number) {
    return this.apiAddress.CUSTOMER_DETAILS + customerId;
  }

  getATransactionByID(transactionId: number) {
    return this.apiAddress.TRANSACTION + "/" + transactionId;
  }

  getTaskDetails(taskId: number) {
    return this.apiAddress.TASK + "/" + taskId;
  }

  getTaskOfCustomer(customerID: number) {
    return this.apiAddress.CUSTOMER_DETAILS + customerID + "/tasks";
  }

  getTaskOfProfile(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID + "/tasks";
  }

  getAddApprovedAsset() {
    return this.apiAddress.ADD_APPROVEDASSET;
  }

  getAddLoan() {
    return this.apiAddress.ADD_LOAN;
  }
  getAddLoans() {
    return this.apiAddress.ALL_LOANS;
  }

  getAddAsset() {
    return this.apiAddress.ADD_ASSET;
  }

  getApproveAsset(assetId: number) {
    return this.apiAddress.ADD_ASSET + "/" + assetId + "/approve";
  }

  getAddCustomer() {
    return this.apiAddress.ADD_CUSTOMER;
  }

  getAddCPSettledCustomer() {
    return this.apiAddress.ADD_CUSTOMER + "/CP";
  }

  getAddProfile() {
    return this.apiAddress.PROFILE;
  }

  getAddAcccount() {
    return this.apiAddress.ADD_ACCOUNT;
  }

  getAddReserve(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID + "/reserve/income";
  }

  //withdraw simpan to duit lebih
  getWithdrawReserve(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID + "/reserve/withdrawal";
  }

  getTransferReserve(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID + "/reserve/transfer";
  }

  getExpenseReserve(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID + "/reserve/expense";
  }

  getAddCompany() {
    return this.apiAddress.ADD_COMPANY;
  }

  getAddTask() {
    return this.apiAddress.TASK;
  }

  getAddIncome() {
    return this.apiAddress.INCOME;
  }

  getAddIncomeOfExtras() {
    return this.apiAddress.INCOME_EXTRAS;
  }

  getAddLoanScheme() {
    return this.apiAddress.LOANSCHEME;
  }
  getUpdateCompany(companyID: number) {
    return this.apiAddress.UPDATE_COMPANY + companyID;
  }

  getUpdateCustomer(customerID: number) {
    return this.apiAddress.UPDATE_CUSTOMER + customerID;
  }

  getUpdateProfile(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID;
  }

  getGlobalSearch(name: string, ic: string) {
    var address = this.apiAddress.GLOBAL_SEARCH;
    if (name && name != "") {
      address +=
        "name=" + this.sharedService.processStringStripInvalidSymbol(name);

      if (ic && ic != "") {
        address +=
          "&icNumber=" +
          this.sharedService.processStringStripInvalidSymbol(ic) +
          "";
      }
    } else {
      if (ic && ic != "") {
        address +=
          "icNumber=" + this.sharedService.processStringStripInvalidSymbol(ic);
      }
    }

    return address;
  }

  getCompanyCustomerSearch(companyId: number, name?: string, ic?: string) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/customers/search?";
    if (name && name != "") {
      address +=
        "name=" + this.sharedService.processStringStripInvalidSymbol(name);

      if (ic && ic != "") {
        address +=
          "&icNumber=" +
          this.sharedService.processStringStripInvalidSymbol(ic) +
          "";
      }
    } else {
      if (ic && ic != "") {
        address +=
          "icNumber=" + this.sharedService.processStringStripInvalidSymbol(ic);
      }
    }

    return address;
  }

  getCompanyProfileSearch(
    companyId: number,
    name: string,
    ic: string,
    custCode: string
  ) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/profiles/search?";

    if (name != undefined && name != "")
      address +=
        "name=" +
        this.sharedService.processStringStripInvalidSymbol(name) +
        "&";

    if (ic != undefined && ic != "")
      address +=
        "icNumber=" +
        this.sharedService.processStringStripInvalidSymbol(ic) +
        "&";

    if (custCode != undefined && custCode != "")
      address +=
        "customerCode=" +
        this.sharedService.processStringStripInvalidSymbol(custCode) +
        "&";
    return address;
  }

  getSearchCustomerByReceipt(companyId: number, receiptNumber: string) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/loan/receipt/" +
      receiptNumber
    );
  }

  getCompanyAccountsOfCompany(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/accounts";
  }

  getAccountsOfCompany(companyId: number, endDateStr?: string) {
    var address = this.apiAddress.ALL_ACCOUNTS + "?companyId=" + companyId;

    if (endDateStr != undefined) address += "&endDate=" + endDateStr;

    return address;
  }

  getAddPayment(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/payment";
  }

  getAddBatchPayment() {
    return this.apiAddress.ALL_LOANS + "/payments";
  }

  getAddBatchSimpanPayment() {
    return this.apiAddress.ALL_LOANS + "/payments/reserve";
  }

  getAddPaymentWithReserve(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/payment/reserve";
  }

  getAddTopUp(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/topup";
  }

  getAddReserveWithdrawal(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/withdrawal";
  }

  getTasksForAday(date: string, type?: number) {
    var address = this.apiAddress.ALL_TASKS + "/" + date;
    if (type != undefined) address += "?type=" + type;

    return address;
  }

  getUserCashAccount(username: string) {
    return this.apiAddress.USER + "/" + username + "/account";
  }

  getUpdateTask(taskID: number) {
    return this.apiAddress.TASK + "/" + taskID;
  }

  getDeleteLoanScheme(schemeID: number) {
    return this.apiAddress.LOANSCHEME + "/" + schemeID;
  }
  getDeleteTransactionSubType(subTypeId: number) {
    return this.apiAddress.SUBTYPE + "/" + subTypeId;
  }

  getDeleteTransaction(transactionId: number) {
    return this.apiAddress.TRANSACTION + "/" + transactionId;
  }

  getDeleteLoanTransaction(transactionId: number) {
    return this.apiAddress.ALL_LOANS + "/receipts/transaction/" + transactionId;
  }

  getDeleteGroupTransaction(companyId: number, groupId: number) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/transactions/group/" +
      groupId
    );
  }

  getDeleteReserveTransaction(transactionId: number) {
    return (
      this.apiAddress.ALL_RESERVES + "/receipts/transaction/" + transactionId
    );
  }

  getDeleteBadDebtTransaction(transactionId: number) {
    return (
      this.apiAddress.ALL_BADDEBTS + "/receipts/transaction/" + transactionId
    );
  }

  getDeleteAdvanceTransaction(transactionId: number) {
    return (
      this.apiAddress.ALL_ADVANCES + "/receipts/transaction/" + transactionId
    );
  }

  getDeleteAssetTransaction(transactionId: number) {
    return (
      this.apiAddress.ALL_ASSETS + "/receipts/transaction/" + transactionId
    );
  }

  getDeleteCheckIn(companyId: number, checkInId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/checkIn/" + checkInId;
  }

  getDeleteCheckInSinceDate(companyId: number, checkInDate: Date) {
    var dateStr = new Date(checkInDate);
    var encodedStr =
      this.sharedService.createLocalISODateEncodedString(dateStr);
    // var encodedStr = dateStr.replace(':', "%3A").replace('+', "%2B").replace('Z', '');
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/checkIns?sinceDate=" +
      encodedStr
    );
  }

  getTaskCollection(companyId: number, dateStr: string) {
    return this.apiAddress.COMPANY + "/" + companyId + "/collection/" + dateStr;
  }

  getLoanReceipts(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/receipts";
  }

  getDuitLebihReceipts(companyID: number) {
    return this.apiAddress.COMPANY + "/" + companyID + "/extras";
  }

  getAllTransactions(
    companyID: number,
    type: number[],
    startDate?: string,
    endDate?: string,
    maxAmount?: number,
    minAmount?: number,
    accountId?: number,
    handledBy?: string,
    inSet?: boolean
  ) {
    var address =
      this.apiAddress.COMPANY + "/" + companyID + "/transactions" + "?";
    for (let i = 0; i < type.length; i++) {
      address += "type=" + type[i] + "&";
    }

    if (startDate != undefined) {
      address += "startDate=" + startDate + "&";
    }

    if (endDate != undefined) {
      address += "endDate=" + endDate + "&";
    }

    if (minAmount != undefined) {
      address += "minAmount=" + minAmount + "&";
    }

    if (maxAmount != undefined) {
      address += "maxAmount=" + maxAmount + "&";
    }
    if (accountId != undefined) {
      address += "accountId=" + accountId + "&";
    }

    if (handledBy != undefined) address += "handledBy=" + handledBy;

    if (inSet != undefined) address += "inSet=" + inSet;

    return address;
  }

  getAllCompanyGroupTransactions(
    groupId: number,
    type: number[],
    startDate?: string,
    endDate?: string,
    maxAmount?: number,
    minAmount?: number
  ) {
    var address =
      this.apiAddress.COMPANY_GROUP + "/" + groupId + "/transactions" + "?";
    for (let i = 0; i < type.length; i++) {
      address += "type=" + type[i] + "&";
    }

    if (startDate != undefined) {
      address += "startDate=" + startDate + "&";
    }

    if (endDate != undefined) {
      address += "endDate=" + endDate + "&";
    }

    if (minAmount != undefined) {
      address += "minAmount=" + minAmount + "&";
    }

    if (maxAmount != undefined) {
      address += "maxAmount=" + maxAmount + "&";
    }
    return address;
  }

  getTransactionBalance(type: number, endDate?: string) {
    var address = this.apiAddress.TRANSACTION_BALANCE + "/" + type;
    if (endDate != undefined) address += "?endDate=" + endDate;
    return address;
  }

  getCompanyTransactionBalances(
    companyId: number,
    endDate?: string,
    type?: number
  ) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/transactionBalances";

    if (endDate != undefined || type != undefined) address += "?";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (type != undefined) address += "type=" + type;
    return address;
  }

  getCompanyTransactionBalancesDetails(
    companyId: number,
    startDate?: string,
    endDate?: string,
    types?: number[]
  ) {
    var address =
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/transactionBalances/details";

    if (endDate != undefined || types != undefined || startDate != undefined)
      address += "?";
    if (startDate != undefined) address += "startDate=" + startDate + "&";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (types != undefined) {
      for (let i = 0; i < types.length; i++) {
        address += "type=" + types[i] + "&";
      }
    }

    return address;
  }

  getTransactionBalances(
    companyGroup?: number,
    endDate?: string,
    types?: number[]
  ) {
    var address = this.apiAddress.ALL_TRANSACTION_BALANCES;

    if (companyGroup != undefined || endDate != undefined || types != undefined)
      address += "?";

    if (companyGroup != undefined) address += "groupId=" + companyGroup + "&";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    for (let i = 0; i < types.length; i++) {
      address += "type=" + types[i] + "&";
    }
    return address;
  }

  getTransactionTotal(
    startDate: string,
    endDate: string,
    types: number[],
    companyGroup?: number
  ) {
    var address = this.apiAddress.ALL_TRANSCACTION_TOTAL;

    if (
      companyGroup != undefined ||
      startDate != undefined ||
      endDate != undefined ||
      types != undefined
    )
      address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (companyGroup != undefined) address += "groupId=" + companyGroup + "&";

    for (let i = 0; i < types.length; i++) {
      address += "type=" + types[i] + "&";
    }
    return address;
  }

  getTransactionDetails(
    startDate: string,
    endDate: string,
    types: number[],
    companyGroup?: number
  ) {
    var address = this.apiAddress.ALL_TRANSCACTION_TOTAL + "/details";

    if (
      startDate != undefined ||
      endDate != undefined ||
      types != undefined ||
      companyGroup != undefined
    )
      address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (companyGroup != undefined) address += "groupId=" + companyGroup + "&";

    for (let i = 0; i < types.length; i++) {
      address += "type=" + types[i] + "&";
    }
    return address;
  }

  getCompanyTransactionDetails(
    companyId: number,
    startDate: string,
    endDate: string,
    types: number[],
    companyGroup?: number
  ) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/transactionTotals/details";

    if (
      startDate != undefined ||
      endDate != undefined ||
      types != undefined ||
      companyGroup != undefined
    )
      address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (companyGroup != undefined) address += "groupId=" + companyGroup + "&";

    for (let i = 0; i < types.length; i++) {
      address += "type=" + types[i] + "&";
    }
    return address;
  }

  getTransactionBalanceDetails(
    companyIds: number[],
    types?: number[],
    startDate?: string,
    endDate?: string,
    companyGroupID?: number
  ) {
    var address = this.apiAddress.ALL_TRANASACTION_BALANCES_DETAILS + "?";

    if (companyIds != undefined) {
      for (let i = 0; i < companyIds.length; i++) {
        address += "companyId=" + companyIds[i] + "&";
      }
    }

    if (companyGroupID != undefined)
      address += "groupId=" + companyGroupID + "&";

    if (types != undefined) {
      for (let i = 0; i < types.length; i++) {
        address += "type=" + types[i] + "&";
      }
    }

    if (startDate != undefined) address += "startDate=" + startDate + "&";

    if (endDate != undefined) address += "endDate=" + endDate + "&";
    return address;
  }

  getCustomerReserveReceipts(
    customerId: number,
    startDate?: string,
    endDate?: string
  ) {
    var address = this.apiAddress.CUSTOMER + "/" + customerId + "/reserves";

    if (startDate != undefined || endDate != undefined) address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate;

    return address;
  }

  getProfileReserveReceipts(
    profileID: number,
    startDate?: string,
    endDate?: string
  ) {
    var address = this.apiAddress.PROFILE + "/" + profileID + "/reserves";

    if (startDate != undefined || endDate != undefined) address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate;

    return address;
  }

  getCompanyReserveReceipts(
    companyId: number,
    startDate?: string,
    endDate?: string
  ) {
    var address =
      this.apiAddress.ALL_RESERVES + "/receipts?companyId=" + companyId;

    if (startDate != undefined || endDate != undefined) address += "&";

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate;

    return address;
  }

  getCompanyBDReserveReceipts(
    companyId: number,
    startDate?: string,
    endDate?: string
  ) {
    var address =
      this.apiAddress.ALL_BADDEBTS + "/receipts?companyId=" + companyId;

    if (startDate != undefined || endDate != undefined) address += "&";

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate;

    return address;
  }

  getProfileBDReceipts(
    profileID: number,
    startDate?: string,
    endDate?: string
  ) {
    var address = this.apiAddress.PROFILE + "/" + profileID + "/badDebts";

    if (startDate != undefined || endDate != undefined) address += "?";

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate;

    return address;
  }

  getLoanArrear(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/arrears";
  }

  getLoanArrearPayment(arrearId: number) {
    return this.apiAddress.ARREAR + "/" + arrearId + "/payment";
  }

  getAddContact() {
    return this.apiAddress.CONTACT;
  }

  getDeleteContact(id: number) {
    return this.apiAddress.CONTACT + "/" + id;
  }

  getUpdateContact(id: number) {
    return this.apiAddress.CONTACT + "/" + id;
  }

  getDeleteCompany(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId;
  }

  getCustomerContact(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/contacts";
  }

  getCustomerContactAllFiles(customerId: number, contactId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/files"
    );
  }

  getContactDetails(contactId: number) {
    return this.apiAddress.CONTACT + "/" + contactId;
  }

  getUpdateDoneTask(taskID: number) {
    return this.apiAddress.TASK + "/" + taskID + "/done";
  }

  getAccountTransactions(
    accountId: number,
    startDate?: string,
    endDate?: string,
    type?: number[],
    handledBy?: string
  ) {
    var address = this.apiAddress.ACCOUNT + "/" + accountId + "/transactions";

    if (
      startDate != undefined ||
      endDate != undefined ||
      type != undefined ||
      handledBy != undefined
    )
      address += "?";

    if (type != undefined) {
      for (let i = 0; i < type.length; i++) {
        address += "type=" + type[i] + "&";
      }
    }

    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (handledBy != undefined || handledBy != "")
      address += "handledBy=" + handledBy + "&";

    return address;
  }

  getUserYearMonthLoanStat(username: string, year: number, month: number) {
    if (month < 10)
      return (
        this.apiAddress.USER +
        "/" +
        username +
        "/stats/loans/" +
        year +
        "-0" +
        month
      );
    else
      return (
        this.apiAddress.USER +
        "/" +
        username +
        "/stats/loans/" +
        year +
        "-" +
        month
      );
  }

  getYearMonthLoanStat(year: number, month: number) {
    if (month < 10)
      return this.apiAddress.STAT_LOANS + "/" + year + "-0" + month;
    else return this.apiAddress.STAT_LOANS + "/" + year + "-" + month;
  }

  getAddExpenses() {
    return this.apiAddress.EXPENSES;
  }

  getAddCompanyExpense() {
    return this.apiAddress.EXPENSE;
  }

  getAddExtraExpenses() {
    return this.apiAddress.EXPENSES_EXTRAS;
  }

  getAddCompanyExpenseByExtras() {
    return this.apiAddress.EXPENSE_EXTRAS;
  }

  getPostFundAllocation() {
    return this.apiAddress.REALLOCATION;
  }

  getPostFundTransfer(accountId: number) {
    return this.apiAddress.ACCOUNT + "/" + accountId + "/transfer";
  }

  getAddCapital() {
    return this.apiAddress.CAPITAL;
  }

  getReturnCapital() {
    return this.apiAddress.CAPITAL + "/return";
  }

  getAddToting() {
    return this.apiAddress.TOTING;
  }

  getReturnToting() {
    return this.apiAddress.TOTING + "/return";
  }

  getAddSaving() {
    return this.apiAddress.SAVING;
  }

  getExtraBalance(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/extras/balance";
  }

  getAdvanceById(advanceId: number) {
    return this.apiAddress.ADVANCE + "/" + advanceId;
  }

  getAddTransactionSubType() {
    return this.apiAddress.SUBTYPE;
  }

  getUploadFile(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/file";
  }

  getDeleteFile(customerId: number, filename: string) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/file/" + filename;
  }

  getUploadApplicationForm(customerId: number, supportingDocType: string) {
    return (
      this.apiAddress.CUSTOMER + "/" + customerId + "/file/" + supportingDocType
    );
  }

  getUploadContactGuarantorForm(customerId: number, contactId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/file/guarantorForm"
    );
  }

  getUploadContactPhoto(customerId: number, contactId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/file/photo"
    );
  }

  getUploadContactTypedFile(
    customerId: number,
    contactId: number,
    type: string
  ) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/file/" +
      type
    );
  }

  getUploadLoanTypedFile(customerId: number, loanId: number, type: string) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/loan/" +
      loanId +
      "/file/" +
      type
    );
  }

  getUploadReserveGroupFile(reserveGroupId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + reserveGroupId + "/file";
  }

  getReserveGroupAllFiles(reserveGroupId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + reserveGroupId + "/files";
  }

  getReserveGroupFileFull(reserveGroupId: number, filename: string) {
    return (
      this.apiAddress.RESERVE_GROUP + "/" + reserveGroupId + "/file/" + filename
    );
  }

  getUploadLoanNormalFile(customerId: number, loanId: number) {
    return (
      this.apiAddress.CUSTOMER + "/" + customerId + "/loan/" + loanId + "/file"
    );
  }

  getAllFiles(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/files";
  }

  getAllContactPhotos(customerId: number, contactId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/files"
    );
  }
  getCustomerContactGuarantorForms(customerId: number, contactId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/files/guarantorForm"
    );
  }

  getFullFile(customerId: number, fileName: string) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/file/" + fileName;
  }

  getSendToCP(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/CP";
  }

  getSendProfileLoansToCP(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/CP";
  }

  getSendProfileLoansToLBD(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/lbd";
  }

  getRemoveFromCP(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/unCP";
  }

  getPayCustomerBadDebt(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/badDebt/payment";
  }

  getPayProfileBadDebt(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/badDebt/payment";
  }

  getPayCompanyUnpaidBadDebts(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/badDebts/payment";
  }

  getCompanyUnpaidBadDebts(companyId: number, endDate?: string) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/badDebts";

    if (endDate != undefined) address += "?endDate=" + endDate;

    return address;
  }

  getWithdrawBadDebtsToSimpan(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/badDebt/withdrawal";
  }

  getSearchTaskByCriteria(
    username: string,
    done: boolean,
    customerId: number,
    customerName: string,
    icNumber: string
  ) {
    var address = this.apiAddress.ALL_TASKS + "?";
    if (username && username != "") {
      address += "username=" + username + "&";
    }
    if (done != undefined) {
      address += "done=" + done + "&";
    }

    if (customerId != undefined) {
      address += "customerId=" + customerId + "&";
    }

    if (customerName != undefined && customerName != "") {
      address += "customerName=" + customerName + "&";
    }

    if (icNumber != undefined && icNumber != "") {
      address += "icNumber=" + icNumber + "&";
    }
    return address;
  }

  getSignUp() {
    return this.apiAddress.SIGN_UP;
  }

  getSignIn() {
    return this.apiAddress.SIGN_IN;
  }

  getCompanyUnpaidCommission(companyId: number, endDate?: string) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/commissions?paid=false";

    if (endDate != undefined) address += "&endDate=" + endDate;

    return address;
  }

  getCompanyDetails(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId;
  }

  getCompanyPaidCommission(
    companyId: number,
    startDate?: string,
    endDate?: string
  ) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/commissions?paid=true";

    if (startDate != undefined) address += "&startDate=" + startDate;

    if (endDate != undefined) address += "&endDate=" + endDate;

    return address;

    // return this.apiAddress.COMPANY + "/" + companyId;
  }

  getAddCommissionPayout() {
    return this.apiAddress.ALL_COMMISSION + "/payment";
  }

  getAssignCommission() {
    return this.apiAddress.COMMISSION;
  }

  getAssignLoanCommission(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/commission";
  }

  getPaidLoanDetail(loanId: number, Ids?: Number[]) {
    if (Ids == undefined)
      return this.apiAddress.ALL_LOANS + "/paid?id=" + loanId;
    else {
      var address = this.apiAddress.ALL_LOANS + "/paid";
      var idStr = "";
      for (let i = 0; i < Ids.length; i++) {
        if (idStr == "") idStr += "?id=" + Ids[i];
        else idStr += "&id=" + Ids[i];
      }

      return address + idStr;
    }
  }

  getAllPaidLoanDetail() {
    return this.apiAddress.ALL_LOANS + "/paid";
  }

  getCompanyPaidLoanDetail(companyId: number, endDateStr?: string) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/loans/paid";

    if (endDateStr != undefined) {
      address += "?endDate=" + endDateStr;
    }

    return address;
  }

  getUpdateUserSalary(username: string) {
    return this.apiAddress.USER + "/" + username + "/salary";
  }

  getWithdrawStamp() {
    return this.apiAddress.STAMP;
  }

  getWithdrawServiceCharge() {
    return this.apiAddress.SERVICE_CHARGE;
  }

  getWithdrawHandling() {
    return this.apiAddress.HANDLING_CHARGE;
  }

  getAddImportFile(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/import";
  }

  getAddImportBalance(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/import/balances";
  }

  getMidMonth(
    companyId: number,
    startDate: string,
    endDate: string,
    omitSchemeA: boolean
  ) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/report/midMonth?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&omitSchemeA=" +
      omitSchemeA
    );
  }

  getMidMonthV1(
    companyId: number,
    startDate: string,
    endDate: string,
    omitSchemeA: boolean
  ) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/report/midMonth/v1?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&omitSchemeA=" +
      omitSchemeA
    );
  }

  getAddFormJ() {
    return this.apiAddress.FORMJ;
  }

  getDeleteFormJ(id: number) {
    return this.apiAddress.FORMJ + "/" + id;
  }

  getAddAdminRemark(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/remark";
  }

  getFormJDetails(formJID: number) {
    return this.apiAddress.FORMJ + "/" + formJID;
  }
  getCustomerFormJs(customerID: number) {
    return this.apiAddress.All_FORMJ + "?customerId=" + customerID;
  }

  getCompanyFormJLatestDate(companyID: number) {
    return this.apiAddress.COMPANY + "/" + companyID + "/latestFormJs";
  }

  getCustomerAdminRemarks(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/remarks";
  }

  getCustomerProfile(customerID: number, profileID: number) {
    return (
      this.apiAddress.CUSTOMER + "/" + customerID + "/profile/" + profileID
    );
  }

  getProfile(profileID: number) {
    return this.apiAddress.PROFILE + "/" + profileID;
  }

  getCompanyCustomerProfile(
    companyId: number,
    customerId: number,
    profileID: number
  ) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/customer/" +
      customerId +
      "/profile/" +
      profileID
    );
  }

  getAllProfiles(profileIDs: Number[]) {
    var address = this.apiAddress.ALL_PROFILES + "?";

    for (let i = 0; i < profileIDs.length; i++) {
      address += "id=" + profileIDs[i] + "&";
    }
    return address;
  }

  getAllCustomersProfiles() {
    return this.apiAddress.ALL_CUSTOMERS + "/profiles";
  }

  getAllUsageStats(refresh: boolean) {
    var address = this.apiAddress.ALL_STATS + "/usage";

    if (refresh == true) address += "?refresh=" + refresh;

    return address;
  }

  getCompaniesLatestCheckIns(
    companyId?: number,
    endDate?: string,
    roleId?: number
  ) {
    var address = this.apiAddress.ALL_COMPANIES + "/checkIns/latest";

    if (companyId != undefined || endDate != undefined || roleId != undefined)
      address += "?";

    if (companyId != undefined) address += "companyId=" + companyId;
    if (endDate != undefined) address += "&endDate=" + endDate;
    if (roleId != undefined) address += "&roleId=" + roleId;

    return address;
  }

  getAddCompanyCheckIn(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/checkIn";
  }

  getCompanyCheckInHistory(companyId: number, roleId?: number) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/checkIns";

    if (roleId != undefined) address += "?roleId=" + roleId;

    return address;
  }

  getCompanyDeletedCheckInHistory(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/checkIns/deleted";
  }

  getTokenRefresh() {
    return this.apiAddress.REFRESH_TOKEN;
  }
  getChangeStatus(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/status";
  }

  getMergeProfiles() {
    return this.apiAddress.MERGE_PROFILES;
  }

  getMoveProfile(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/move";
  }

  getForceMoveProfile(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/move/force";
  }

  getEditUserRole(companyId: number, username: string) {
    return (
      this.apiAddress.COMPANY + "/" + companyId + "/user/" + username + "/role"
    );
  }

  getComapnyDeactivateUser(companyId: number, username: string) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/user/" +
      username +
      "/deactivate"
    );
  }

  getDeactivateUser(username: string) {
    return this.apiAddress.USER + "/" + username + "/deactivate";
  }

  getOnMaintenanceMode() {
    return this.apiAddress.MAINTENANCE_ON;
  }

  getOffMaintenanceMode() {
    return this.apiAddress.MAINTENANCE_OFF;
  }

  getCheckCustomerExistence(customerIC: string, customerCode: string) {
    return (
      this.apiAddress.CHCECK_CUSTOMER_CODE +
      "/" +
      customerIC +
      "/" +
      customerCode
    );
  }

  getCustomerContactGuarantorFormExistence(
    customerId: number,
    contactId: number
  ) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/files/guarantorForm/exists"
    );
  }

  getCustomerContactPhotoExistence(customerId: number, contactId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/files/photo/exists"
    );
  }

  getCompanyCustomerApplicationFormExistence(
    companyId: number,
    customerId: number
  ) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/customer/" +
      customerId +
      "/files/application/exists"
    );
  }

  getCompanyCustomerSignedFormJExistence(
    companyId: number,
    customerId: number
  ) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/customer/" +
      customerId +
      "/files/formj/exists"
    );
  }

  getCompanyCustomerEPFStatementExistence(
    companyId: number,
    customerId: number
  ) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/customer/" +
      customerId +
      "/files/epfStatement/exists"
    );
  }

  getCompanyCustomerTypedFileExistence(customerId: number, type: string) {
    return (
      this.apiAddress.CUSTOMER + "/" + customerId + "/files/" + type + "/exists"
    );
  }

  getCustomerCustomerLoanTypedFileExistence(
    customerId: number,
    loanId: number,
    type: string
  ) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/loan/" +
      loanId +
      "/files/" +
      type +
      "/exists"
    );
  }

  getCustomerLoansWithFiles(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/loans/hasFiles";
  }

  getUpdateEPFDetails(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/epf";
  }

  getUpdateDOB(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/dob";
  }

  getCompanyOverdueEPFCustomer(companyID: number, endDate?: string) {
    var address =
      this.apiAddress.COMPANY + "/" + companyID + "/report/overdueEPFCustomers";

    if (endDate != undefined) address += "?date=" + endDate;
    return address;
  }

  getAddLoanRequest() {
    return this.apiAddress.LOAN_APPROVAL_REQUEST;
  }

  getAddElevatedEPFLoanRequest() {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/elevated";
  }

  getUpdateEPFRequestRemark(id: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + id + "/remark";
  }

  getUpdateEPFLoanRequest(id: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + id;
  }

  getProfileLoanApprovalRequest(
    profileId: number,
    approved?: boolean,
    withLoan?: boolean
  ) {
    var address =
      this.apiAddress.PROFILE + "/" + profileId + "/loanApprovalRequests";

    if (approved != undefined || withLoan != undefined) address += "?";
    if (approved != undefined) address += "approved=" + approved;

    if (withLoan != undefined) address += "withLoan=" + false;
    return address;
  }

  getCustomerEPF(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/epf";
  }

  getAddApprovedLoanToAccount(requestId: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + requestId + "/loan";
  }

  getLoanApprovalRequestById(id: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + id;
  }
  getLoanApprovalRequestByIds(ids: number[]) {
    return (
      this.apiAddress.ALL_LOAN_APPROVAL_REQUEST + "?id=" + ids.join("&id=")
    );
  }
  getAllSharedLoanApprovalRequest(
    approved?: boolean,
    myProcessed?: boolean,
    processed?: boolean
  ) {
    var address = this.apiAddress.ALL_SHARED_LOAN_APPROVAL_AGREEMENT;
    if (
      approved != undefined ||
      myProcessed != undefined ||
      processed != undefined
    )
      address += "?";
    if (approved != undefined) address += "approved=" + approved + "&";

    if (myProcessed != undefined) address += "myProcessed=" + myProcessed + "&";
    if (processed != undefined) address += "processed=" + processed + "&";

    return address;
  }

  getAllLoanApprovalRequest(
    endDate?: string,
    withLoan?: boolean,
    approved?: boolean,
    includeUnprocessed?: boolean,
    authorized?: boolean
  ) {
    var address = this.apiAddress.ALL_LOAN_APPROVAL_REQUEST;
    if (
      endDate != undefined ||
      withLoan != undefined ||
      approved != undefined ||
      authorized != undefined
    )
      address += "?";

    if (endDate != undefined) address += "endDate=" + endDate + "&";

    if (withLoan != undefined) address += "withLoan=" + withLoan + "&";

    if (approved != undefined) {
      address += "approved=" + approved + "&";
    }

    if (includeUnprocessed != undefined) {
      address += "inclUnprocessed=" + includeUnprocessed + "&";
    }
    if (authorized != undefined) {
      address += "authorized=" + authorized + "&";
    }

    return address;
  }

  getApproveLoanApprovalRequest(id: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + id + "/approve";
  }

  getDenyLoanApprovalRequest(id: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + id + "/deny";
  }

  getApproveGroupLoanApprovalRequest(groupId: number) {
    return (
      this.apiAddress.LOAN_APPROVAL_REQUEST + "/group/" + groupId + "/approve"
    );
  }

  getDenyGroupLoanApprovalRequest(groupId: number) {
    return (
      this.apiAddress.LOAN_APPROVAL_REQUEST + "/group/" + groupId + "/deny"
    );
  }

  getAuthorizeLoanApprovalRequest(id: number) {
    return this.apiAddress.LOAN_APPROVAL_REQUEST + "/" + id + "/authorize";
  }

  getMarkProfileTaskAsDone(profileId: number, taskId: number) {
    return (
      this.apiAddress.PROFILE + "/" + profileId + "/task/" + taskId + "/done"
    );
  }

  getMarkLegacyEPF() {
    return this.apiAddress.ALL_LOANS + "/EPF";
  }

  getAddLegacyEPFSettlement(id: number) {
    return this.apiAddress.LOAN + "/" + id + "/legacySettlement";
  }

  getCompanyCustomerReport(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/report/customerRecord";
  }

  getServerStatus() {
    return this.apiAddress.SERVER + "/status";
  }

  getErrorLog(code?: string) {
    var address = this.apiAddress.SERVER + "/log/errors";

    if (code != undefined) address += "?code=" + code;
    return address;
  }

  getSignedInUserList(activeWithin?: string) {
    var address = this.apiAddress.SIGNED_IN_USERS;

    if (activeWithin != undefined) address += "?activeWithin=" + activeWithin;

    return address;
  }

  getCompanyAllSets(
    companyId: number,
    startDate?: string,
    endDate?: string,
    valid?: boolean,
    checked?: boolean,
    pending?: boolean,
    includePending?: boolean,
    type?: number,
    subtype?: number,
    saved?: boolean
  ) {
    var address = this.apiAddress.COMPANY + "/" + companyId + "/sets";

    if (
      startDate != undefined ||
      endDate != undefined ||
      valid != undefined ||
      checked != undefined ||
      pending != undefined ||
      includePending != undefined ||
      (type != undefined && type != 0) ||
      (subtype != undefined && subtype != 0) ||
      saved != undefined
    )
      address += "?";
    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";
    if (valid != undefined) address += "valid=" + valid + "&";
    if (checked != undefined) address += "checked=" + checked + "&";
    if (pending != undefined) address += "pending=" + pending + "&";
    if (includePending != undefined)
      address += "inclPending=" + includePending + "&";

    if (type != undefined && type != 0) address += "type=" + type + "&";

    if (subtype != undefined && subtype != 0)
      address += "subtype=" + subtype + "&";

    if (saved != undefined && saved != false) address += "saved=" + saved + "&";
    return address;
  }

  getAllSets(
    startDate?: string,
    endDate?: string,
    valid?: boolean,
    checked?: boolean,
    pending?: boolean,
    includePending?: boolean,
    type?: number,
    priority?: number,
    subtype?: number,
    saved?: boolean,
    limit?: number,
    sortASC?: boolean,
  ) {
    var address = this.apiAddress.ALL_SETS;

    const params: { [key: string]: any } = {};
    if (startDate !== undefined) params.startDate = startDate;
    if (endDate !== undefined) params.endDate = endDate;
    if (valid !== undefined) params.valid = valid;
    if (checked !== undefined) params.checked = checked;
    if (pending !== undefined) params.pending = pending;
    if (includePending !== undefined) params.inclPending = includePending;
    if (type !== undefined && type != 0) params.type = type;
    if (priority !== undefined) params.priority = priority;
    if (subtype !== undefined && subtype != 0) params.subtype = subtype;
    if (saved !== undefined) params.saved = saved;
    if (limit !== undefined) params.limit = limit;
    if (sortASC !== undefined) params.sortOrder = sortASC ? 'ASC' : 'DESC';

    const paramsString = new URLSearchParams(params).toString();
    address = [address, paramsString].join('?');
    // console.log(address);
    return address;
  }

  getAddOrRemoveCompanySetTransactions(companyId: number, setId: number) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/set/" +
      setId +
      "/transactions"
    );
  }

  getAddNewCompanySet(companyId: number) {
    return this.apiAddress.COMPANY + "/" + companyId + "/set";
  }

  getSubmitSet(companyId: number, setId: number) {
    return (
      this.apiAddress.COMPANY + "/" + companyId + "/set/" + setId + "/submit"
    );
  }

  getValidateSet(companyId: number, setId: number) {
    return (
      this.apiAddress.COMPANY + "/" + companyId + "/set/" + setId + "/validate"
    );
  }

  getInvalidateSet(companyId: number, setId: number) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/set/" +
      setId +
      "/invalidate"
    );
  }

  getEditSet(setId: number) {
    return this.apiAddress.SET + "/" + setId;
  }

  getUploadSetFile(setId: number) {
    return this.apiAddress.SET + "/" + setId + "/file";
  }

  getAllSetFiles(setId: number) {
    return this.apiAddress.SET + "/" + setId + "/files";
  }

  getSetFileFull(setId: number, filename: string) {
    return this.apiAddress.SET + "/" + setId + "/file/" + filename;
  }

  getSingleSetDetails(setId: number) {
    return this.apiAddress.SET + "/" + setId;
  }

  getSingleLoanArrear(loanId: number, arrearId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/arrear/" + arrearId;
  }

  getMoveLoan(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/move";
  }

  getForceMoveLoan(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/move/force";
  }

  getAddAdvanceBorrow() {
    return this.apiAddress.ADVANCE + "/borrow";
  }
  getAddAdvanceDeposit() {
    return this.apiAddress.ADVANCE + "/deposit";
  }

  getAddAdvanceReturn(advanceId: number) {
    return this.apiAddress.ADVANCE + "/" + advanceId + "/return";
  }

  getAddAdvanceWithdraw(advanceId: number) {
    return this.apiAddress.ADVANCE + "/" + advanceId + "/withdrawal";
  }

  getAddAdvanceDepositMore(advanceId: number) {
    return this.apiAddress.ADVANCE + "/" + advanceId + "/deposit";
  }

  getAddAdvanceBorrowMore(advanceId: number) {
    return this.apiAddress.ADVANCE + "/" + advanceId + "/borrow";
  }

  getTypedTransaction(receipts: string, transactionId: number) {
    //receiptType: loans, badDebts, reserves, assets, advances

    return receipts + "/receipts/transaction/" + transactionId;
  }

  getSetsCount(
    pending?: boolean,
    valid?: boolean,
    includePending?: boolean,
    type?: number,
    subtype?: number,
    companyId?: number,
    startDate?: string,
    endDate?: string,
    priority?: number,
    maxPriority?: number,
    minPriority?: number
  ) {
    var address = this.apiAddress.ALL_SETS + "/count";

    if (
      startDate != undefined ||
      endDate != undefined ||
      valid != undefined ||
      pending != undefined ||
      includePending != undefined ||
      (type != undefined && type != 0) ||
      companyId != undefined ||
      (subtype != undefined && subtype != 0) ||
      priority != undefined ||
      maxPriority != undefined ||
      minPriority != undefined
    )
      address += "?";
    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";
    if (valid != undefined) address += "valid=" + valid + "&";
    if (pending != undefined) address += "pending=" + pending + "&";
    if (includePending != undefined)
      address += "inclPending=" + includePending + "&";
    if (type != undefined && type != 0) address += "type=" + type + "&";
    if (subtype != undefined && subtype != 0)
      address += "subtype=" + subtype + "&";
    if (companyId != undefined) address += "companyId=" + companyId + "&";
    if (priority != undefined) address += "priority=" + priority + "&";
    if (maxPriority != undefined) address += "maxPriority=" + maxPriority + "&";
    if (minPriority != undefined) address += "minPriority=" + minPriority + "&";

    return address;
  }

  getCustomerCount(
    verified?: boolean,
    status?: string,
    priority?: number,
    minPriority?: number,
    maxPriority?: number
  ) {
    var address = this.apiAddress.ALL_CUSTOMERS + "/count";

    if (
      verified != undefined ||
      status != undefined ||
      priority != undefined ||
      minPriority != undefined ||
      maxPriority != undefined
    )
      address += "?";

    if (verified != undefined) address += "verified=" + verified;
    if (status != undefined) address += "status=" + status;
    if (priority != undefined) address += "priority=" + priority;
    if (minPriority != undefined) address += "minPriority=" + minPriority;
    if (maxPriority != undefined) address += "maxPriority=" + maxPriority;

    return address;
  }

  getVerifyCustomer(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/verify";
  }

  getUnverifyCustomer(customerID: number) {
    return this.apiAddress.CUSTOMER + "/" + customerID + "/unverify";
  }

  getChangeFileType(customerId: number, filename: string, targetType: string) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/files/" +
      targetType +
      "/" +
      filename
    );
  }

  getChangeContactFileType(
    customerId: number,
    contactId: number,
    filename: string,
    targetType: string
  ) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/contact/" +
      contactId +
      "/files/" +
      targetType +
      "/" +
      filename
    );
  }

  getWebsiteReleases() {
    return this.apiAddress.WEBAPP_RELEASES;
  }

  getUpdateWebAppRelease(tag: string, filename: string) {
    return this.apiAddress.WEBAPP_RELEASES + "/" + tag + "/" + filename;
  }

  getCompaniesAccess(companyId?: number) {
    var address = this.apiAddress.ALL_USERS + "/companies";

    if (companyId != undefined) address += "?companyId=" + companyId;

    return address;
  }

  getGrantUserCompanyAccess(username: string, priority: number) {
    return this.apiAddress.USER + "/" + username + "/companies/" + priority;
  }

  getRevokeUserCompanyAccess(username: string) {
    return this.apiAddress.USER + "/" + username + "/companies";
  }

  getSettledLoansSettlementInfos() {
    return this.apiAddress.SETTLED_LOAN_SETTLEMENT_INFOS;
  }

  getCheckLoanSettlementInfo(loanId: number) {
    return (
      this.apiAddress.SETTLED_LOAN_SETTLEMENT_INFO + "/" + loanId + "/check"
    );
  }

  getUnheckLoanSettlementInfo(loanId: number) {
    return (
      this.apiAddress.SETTLED_LOAN_SETTLEMENT_INFO + "/" + loanId + "/uncheck"
    );
  }

  getAddSetRemark(companyId: number, setId: number) {
    return (
      this.apiAddress.COMPANY + "/" + companyId + "/set/" + setId + "/remark"
    );
  }

  getEditTransactionSubtype(transactionId: number) {
    return this.apiAddress.TRANSACTION + "/" + transactionId + "/subtype";
  }

  getSearchCPCustomer(name?: string, icNumber?: string, code?: string) {
    var address = this.apiAddress.CP_SEARCH;

    if (name != undefined || icNumber != undefined || code != undefined)
      address += "?";

    if (name != undefined && name != "")
      address +=
        "name=" +
        this.sharedService.processStringStripInvalidSymbol(name) +
        "&";
    if (icNumber != undefined && icNumber != "")
      address +=
        "icNumber=" +
        this.sharedService.processStringStripInvalidSymbol(icNumber) +
        "&";
    if (code != undefined && code != "")
      address +=
        "code=" +
        this.sharedService.processStringStripInvalidSymbol(code) +
        "&";

    return address;
  }

  getSearchGuarantor(
    icNumber: string,
    excludeCustomerId?: string,
    companyId?: string
  ) {
    var address = this.apiAddress.ALL_CONTACTS + "/guarantors/search";

    if (
      excludeCustomerId != undefined ||
      icNumber != undefined ||
      companyId != undefined
    )
      address += "?";

    if (excludeCustomerId != undefined && excludeCustomerId != "")
      address +=
        "excludeCustomerId=" +
        this.sharedService.processStringStripInvalidSymbol(excludeCustomerId) +
        "&";
    if (icNumber != undefined && icNumber != "")
      address +=
        "icNumber=" +
        this.sharedService.processStringStripInvalidSymbol(icNumber) +
        "&";
    if (companyId != undefined && companyId != "")
      address +=
        "companyId=" +
        this.sharedService.processStringStripInvalidSymbol(companyId) +
        "&";

    return address;
  }

  getSearchConflictedTicketCustomer(
    icNumber: string,
    excludeCustomerId?: number,
    approved?: boolean,
    inclUnprocessed?: boolean,
    withLoan?: false
  ) {
    var address = this.apiAddress.ALL_LOAN_APPROVAL_REQUEST;

    if (
      excludeCustomerId != undefined ||
      icNumber != undefined ||
      approved != undefined ||
      inclUnprocessed != undefined ||
      withLoan != undefined
    )
      address += "?";

    if (excludeCustomerId != undefined)
      address += "exclCustomerId=" + excludeCustomerId + "&";
    if (icNumber != undefined && icNumber != "")
      address += "icNumber=" + icNumber + "&";
    if (approved != undefined) address += "approved=" + approved + "&";
    if (inclUnprocessed != undefined)
      address += "inclUnprocessed=" + inclUnprocessed + "&";
    if (withLoan != undefined) address += "withLoan=" + withLoan + "&";

    return address;
  }

  getCPCustomerDetails(customerId: number) {
    return this.apiAddress.CP_CUSTOMER + "/" + customerId;
  }

  getCPCustomerRemarks(customerId: number) {
    return this.apiAddress.CP_CUSTOMER + "/" + customerId + "/remarks";
  }

  getCompanyAllCPCustomer(companyId: number) {
    return this.apiAddress.CP_COMPANY + "/" + companyId + "/customers";
  }

  getCompanyAllCPTransactions(
    companyId: number,
    confirmed?: boolean,
    startDate?: string,
    endDate?: string,
    year?: number
  ) {
    var address =
      this.apiAddress.CP_COMPANY + "/" + companyId + "/transactions";
    if (
      confirmed != undefined ||
      startDate != undefined ||
      endDate != undefined ||
      year != undefined
    )
      address += "?";

    if (confirmed != undefined) address += "confirmed=" + confirmed + "&";
    if (startDate != undefined) address += "startDate=" + startDate + "&";
    if (endDate != undefined) address += "endDate=" + endDate + "&";
    if (year != undefined) address += "year=" + year;

    return address;
  }

  getAddTransactionRequest() {
    return this.apiAddress.TRANSACTIONS_REQUEST;
  }

  getAllTransactionRequests(hasTransaction?: boolean) {
    var address = this.apiAddress.ALL_TRANSACTION_REQUEST;
    if (hasTransaction != undefined)
      address += "?hasTransactions=" + hasTransaction;

    return address;
  }

  getAddAssignedTransactionToAccount(requestId: number) {
    return this.apiAddress.TRANSACTIONS_REQUEST + "/" + requestId;
  }

  getDeleteTransactionRequest(requestId: number) {
    return this.apiAddress.TRANSACTIONS_REQUEST + "/" + requestId;
  }

  getCustomerEPFChangeHistory(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/epf/history";
  }

  getCustomerICChangeHistory(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/ic/history";
  }

  getCompanyToggleConsentFormRequirement(companyId: number) {
    return (
      this.apiAddress.COMPANY +
      "/" +
      companyId +
      "/toggleConsentFormRequirement"
    );
  }

  getAddPulledCustomerRecord(customerId: number) {
    return this.apiAddress.CUSTOMER + "/" + customerId + "/recordPull";
  }

  getAddBlacklistCustomer() {
    return this.apiAddress.BLACKLIST;
  }

  updateBlacklistCustomer(id: number) {
    return this.apiAddress.BLACKLIST + '/' + id;
  }

  getAllBlacklist(icNumber?: string, released?: boolean) {
    var address = this.apiAddress.ALL_BLACKLIST;
    if (icNumber != undefined) address += "?icNumber=" + icNumber;

    if (released != undefined) address += "&released=" + released;

    return address;
  }

  getPatchReleaseCustomer(id: number) {
    return this.apiAddress.BLACKLIST + "/" + id + "/release";
  }

  getValidateEPFStatement() {
    return this.apiAddress.ALL_FILES + "/validateEpfStatement";
  }

  getSharedLoanProcess(id: number, action: string) {
    return (
      this.apiAddress.SHARED_LOAN_APPROVAL_REQUEST + "/" + id + "/" + action
    );
  }

  getAddProfileReserveGroup(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/reserveGroup";
  }

  getAllProfileReserveGroups(profileId: number) {
    return this.apiAddress.PROFILE + "/" + profileId + "/reserveGroups";
  }

  getAcceptReserveGroup(rgId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + rgId + "/accept";
  }

  getSubmitReserveGroup(rgId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + rgId + "/submit";
  }

  getValidateReserveGroup(rgId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + rgId + "/validate";
  }

  getInvalidateReserveGroup(rgId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + rgId + "/invalidate";
  }

  getSharedProfile(profileID: number) {
    return this.apiAddress.SHARED_PROFILE + "/" + profileID;
  }

  getReserveGroupDetails(rgId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + rgId;
  }

  getCompanyByUID(uid: string) {
    return this.apiAddress.COMPANY + "/uid/" + uid;
  }

  getAddSharedLoanRequest() {
    return this.apiAddress.SHARED_LOAN_APPROVAL_REQUEST;
  }

  getAddSharedElevatedEPFLoanRequest() {
    return this.apiAddress.SHARED_LOAN_APPROVAL_REQUEST + "/elevated";
  }

  getLoanCommissionUsers(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/commissionUsers";
  }

  getCompanyLoanApprovalRequests(companyId: number, loanGroupId?: number) {
    var address =
      this.apiAddress.COMPANY + "/" + companyId + "/loanApprovalRequests";

    if (loanGroupId != undefined) address += "?loanGroupId=" + loanGroupId;

    return address;
  }

  getLoanGroupApprovalRequests(loanGroupId: number) {
    var address =
      this.apiAddress.LOAN_GROUP + "/" + loanGroupId + "/loanApprovalRequests";

    return address;
  }

  getShareProfileToAnotherProfile(mainProfileId: number, toProfileId: number) {
    return (
      this.apiAddress.SHARED_PROFILE +
      "/" +
      mainProfileId +
      "/shareWith/" +
      toProfileId
    );
  }

  getUnshareProfile(profileIdToUnshare: number) {
    return (
      this.apiAddress.SHARED_PROFILE + "/" + profileIdToUnshare + "/unshare"
    );
  }

  getMarkProfileAsMainShared(customerId: number, profileId: number) {
    return (
      this.apiAddress.CUSTOMER +
      "/" +
      customerId +
      "/profile/" +
      profileId +
      "/shareAsShared"
    );
  }

  getProfilePossibleSharedProfile(profileId: number) {
    return (
      this.apiAddress.PROFILE + "/" + profileId + "/possibleSharedProfiles"
    );
  }

  getDeleteReserveGroup(rgId: number) {
    return this.apiAddress.RESERVE_GROUP + "/" + rgId;
  }

  getMoveCentsToInterest(loanId: number) {
    return this.apiAddress.LOAN + "/" + loanId + "/moveSettlementChargesCentsToInterest";
  }

}
