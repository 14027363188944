<div class="m-2 receipt" style="font-family: 'Times New Roman', Times, serif;line-height: 150%;font-size: 11pt;">
    <div style="width: 50%;">
        <p>{{selectedCompany.Name}}</p>
        <p>{{selectedCompany.Address}}</p>
        <br />
        <p>TUAN/PUAN,</p>
    </div>
    <br><br>
    <div class="row m-0 p-0">
        <div class="col-5 "></div>
        <div class="col-3 ">
            <p class="d-flex justify-content-end align-items-right ">Tarikh:</p>
        </div>
    </div>
    <p style="text-decoration: underline;font-weight: bold;font-size: 14pt;">PENGESAHAN PENERIMAAN SALINAN PERJANJIAN
        PINJAMAN (JADUAL
        J)</p>
    <br />
    <p> Saya, <span style="text-decoration: underline;font-weight: bold;">{{selectedCustomer.Name}}</span></p>
    <p>No. Kad Pengenalan : <span
            style="text-decoration:underline">{{sharedService.processICNum(selectedCustomer.ICNumber)}}</span> telah
        membuat
        pinjaman di Syarikat Pemberi Pinjam Wang di atas.</p>
    <p>Saya dengan ini mengaku dan mengesahkan:</p>

    <table>
        <tr>
            <td> 1) </td>
            <td class="ps-2"> Saya datang membuat pinjaman dari syarikat pinjaman wang ini atas kehendak saya sendiri
                dan bukan
                paksaan atau pengaruh pihak lain.</td>
            <td style="padding-top:100px"> ………………………..</td>
        </tr>
        <tr>
            <td> 2) </td>
            <td class="ps-2"> Saya telah dijelaskan oleh pihak peminjam tentang jumlah Pinjaman,
                jumlah Bayaran dan tempoh Bayaran dan seperti yang dinyatakan di dalam Perjanjian Pinjaman(Jadual J).
            </td>
            <td style="padding-top:100px"> ………………………..</td>
        </tr>
        <tr>
            <td> 3) </td>
            <td class="ps-2"> Saya difahamkan bahawa salinan Perjanjian Pinjaman (Jadual J) yang telah disetemkan
                (stamping) perlu
                diberikan kepada saya untuk tujuan simpanan saya
            </td>
            <td></td>
        </tr>
    </table>

    <br /><br />
    <p>Saya mengesahkan bahawa salinan Perjanjian Pinjaman (Jadual J) telah diterima melalui serahan tangan dari pejabat
        Syarikat Pemberi Pinjam Wang</p>

    <br />
    <p>Yang benar,</p>

    <div class="row my-3">
        <div class="col-8">
            <p style="margin-top: 70px;"> ………………………………………………………………</p>
            <p>Nama: {{selectedCustomer.Name}}</p>
            <p>No. K.P.:{{sharedService.processICNum(selectedCustomer.ICNumber)}}</p>
        </div>
        <!-- <div class="col-3">
            <div class="card" style="border: 1px solid black; width: 200px;height: 200px;">

            </div>
        </div> -->
    </div>
</div>