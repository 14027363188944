import { Injectable } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AdminAccess, CompanyAccess } from 'src/model/companyAccess.model';

@Injectable({
  providedIn: 'root'
})
export class AdminAccessService {

  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }


  getAllCompanies() {
    return new Promise<number[]>((resolve) => {
      var xhttp = this.restApi.getRequest(this.constructApi.getAllCompanies())
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse(xhttp.responseText);
          let result: number[] = new Array();
          jsonArray.forEach((json) => {
            result.push(json.id);
          });
          resolve(result);
        }
      };
    });

  }

  revokeAccess(username: string, companies: number[]) {
    var data = {
      companyIds: companies,
    };
    return new Promise<boolean>((resolve) => {
      var xhr = this.restApi.deleteRequest(
        this.constructApi.getRevokeUserCompanyAccess(username),
        data
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(true);
          }
          else {
            this.sharedService.openErrorMessage(xhr);
          }
        }
      };
    });
  }


  grantAccessRequest(username: string, priority: number, companies: number[]) {
    var data = {
      companyIds: companies,
    };
    return new Promise<string>((resolve) => {
      var xhr = this.restApi.putRequest(
        this.constructApi.getGrantUserCompanyAccess(username, priority),
        data
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(xhr.responseText);
          } else {
            this.sharedService.openErrorMessage(xhr);
          }
        }
      };
    });
  }



  deduceCompanyAccessMap(username: string, companiesAccessToChange: CompanyAccess[]) {
    return companiesAccessToChange.filter(a => a.Username == username).reduce((result: Map<number, number[]>, a) => {
      result.has(a.Priority) ? result.get(a.Priority).push(a.CompanyId) : result.set(a.Priority, [a.CompanyId]);
      return result;
    }, new Map());
  }

  async changeAccessTo(cloneFrom: string, cloneTo: string, companiesAccessToChange: CompanyAccess[]) {

    let access = await this.deduceCompanyAccessMap(cloneFrom, companiesAccessToChange);

    var success = false;
    for (let [k, v] of access) {
      const res = await this.grantAccessRequest(cloneTo, k, v)
      success = await this.revokeAccess(cloneFrom, v);
    }

    if (success)
      window.location.reload();
  }


  async cloneAccess(cloneFrom: string, cloneTo: string, companiesAccessToClone: CompanyAccess[], isTemp: boolean) {
    let access = await this.deduceCompanyAccessMap(cloneFrom, companiesAccessToClone);

    var grantedComp = [];

    for (let [priority, companies] of access) {

      var newPriority = (isTemp) ? priority + 1 : priority;
      const res = await this.grantAccessRequest(cloneTo, newPriority, companies)
      if (res !== null) {
        grantedComp = grantedComp.concat(JSON.parse(res)['companyIds']);
      }
    }


  }

  async assignNewOnDuty(dutyStaff: string) {
    var companyIdsList = await this.getAllCompanies();


    let success = await this.grantAccessRequest(dutyStaff, this.enums.ON_DUTY_PRIORITY, companyIdsList);

    return success;
  }

  isEssentialPriority(priority: number) {
    return (priority == this.enums.FIRST_PRIORITY) || (priority == this.enums.SECOND_PRIORITY) || (priority == this.enums.THIRD_PRIORITY)
  }

  isTemporaryPriority(priority: number) {
    return priority % 10 == 1 && priority != this.enums.ON_DUTY_PRIORITY;
  }

  isOnDutyPriority(priority: number) {
    return priority == this.enums.ON_DUTY_PRIORITY;
  }
}
