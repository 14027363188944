import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { main } from '@popperjs/core';
import { request } from 'http';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AddContactComponent } from 'src/app/AddThings/add-contact/add-contact.component';
import { AddLoanComponent } from 'src/app/AddThings/add-loan/add-loan.component';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { ElevateConfirmationComponent } from 'src/app/LoanRequest/elevate-confirmation/elevate-confirmation.component';
import { LoanRequestDetailsComponent } from 'src/app/LoanRequest/loan-request-details/loan-request-details.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { Company } from 'src/model/company.model';
import { Contact } from 'src/model/contact.model';
import { Customer } from 'src/model/customer.model';
import { DuitLebih } from 'src/model/duitLebih.model';
import { EPF } from 'src/model/epf.model';
import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { Profile } from 'src/model/profile.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

interface AddedLoanRequest {
  companyId: number,
  companyName: string,
  request: LoanRequest
}

@Component({
  selector: 'app-shared-loan',
  templateUrl: './shared-loan.component.html',
  styleUrls: ['./shared-loan.component.css'],
  providers: [DatePipe]
})
export class SharedLoanComponent implements OnInit {



  requestList: AddedLoanRequest[] = new Array;
  isEPF = true

  @Input() selectedCustomer: Customer;
  @Input() selectedProfile: Profile;


  mainLoanRequest: AddedLoanRequest;
  epfDetails: EPF;
  epfFormGroup: UntypedFormGroup;

  elevateError: string;

  @Input() isEPFExtra = false;
  isManualElevate = false;
  isShared = false;
  isManualKeyIn = false;

  hasEPFStatement = false;
  hasEPFProof = false;
  hasCustomerAcknowledgement = false;

  contactList: Contact[] = new Array;
  contactFormGroup: UntypedFormGroup;

  loggedInCompanyName: string;
  loggedInCompanyId: number;
  loggedInUser: string;
  loggedInRole: number;

  isLead: boolean;

  nonExistedNecessaryFileTypeList: string[] = new Array;

  epfDetailsExpansionIsOpen = true;
  guarantorExpansionIsOpen = true;

  disabledSubmit = false;

  elevateLoanError: string = "";
  elevateSuggestedSettlement: string = "";
  previousEPFLoans: Loan[] = new Array;


  companyList: Company[] = new Array;
  selectedCompany: number;
  selectedCompanyCode: string;

  myControl = new UntypedFormControl();
  filteredCompany: Observable<Company[]>;

  combinedLoan: Loan;

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums, private cdr: ChangeDetectorRef, private datepipe: DatePipe,
    private restApi: RestApiService, private constructApi: ConstructAPI, private _formBuilder: UntypedFormBuilder, private router: Router) {

    //filter company
    this.filteredCompany = this.myControl.valueChanges.pipe(
      startWith(''),
      map(companyCode => this._filterCompanyCodeOptions(companyCode))
    );
  }

  ngOnInit(): void {
    this.loggedInCompanyName = sessionStorage.getItem('companyName');
    this.loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.loggedInUser = sessionStorage.getItem('username');
    this.loggedInRole = Number(sessionStorage.getItem('roleId'));

    if (this.sharedService.isOperatingUser()) {

      // if dont have epf details initialize epf information
      if (this.epfDetails == undefined) {

        if (this.selectedCustomer.EPFDate != undefined)
          this.getCustomerEPF();
        else {
          // if this customer is actually a lead, get lead info first
          if (this.isLead == true)
            this.getLeadDetails(this.selectedCustomer.ID);


          //if this customer dont have any details
          else {
            //calculate customer EPF if do not have old record
            if (this.selectedCustomer.DOB != undefined)
              var epfDate = this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB));
            this.epfDetails = new EPF(undefined, undefined, new Date(epfDate), undefined, undefined, undefined, undefined)

            this.bindFormGroupInfo();
          }

        }

      }
      // if epf details is found: plug in epf information
      else {
        this.bindFormGroupInfo();

      }


      //check if is a previously shared customer, default make the loan to be a shared loan
      if (this.selectedProfile != undefined && this.selectedProfile.SharedProfileId != undefined) {
        this.isShared = true;
        this.isManualKeyIn = true;
      }

    }

    this.getContactInfo();

    //if requireConsentForm property is not found in session storage, get this info
    var requireConsentForm = sessionStorage.getItem("requireConsentForm")
    if (requireConsentForm == undefined)
      this.getCompanyInfo(this.loggedInCompanyId);


    this.getEPFStatementExistence(this.loggedInCompanyId);

    this.epfDetailsExpansionIsOpen = !this.isEPFDetailStepCompleted();

  }


  private getEPFStatementExistence(companyId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCustomerEPFStatementExistence(companyId, this.selectedCustomer.ID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var result = JSON.parse(xhttp.responseText);
        this.hasEPFStatement = Boolean(result);



        //for EPF PROOF and CUSTOMER ACKNOWLDGEMENT, ONLY CHECK NEW CUSTOMER 
        // ignore If is EXTRA cutsomer
        if (this.isEPFExtra == false)
          this.getTypedFileExistence(this.enums.EPF_PROOF);

      }
    };
  }



  private getTypedFileExistence(type: string) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCustomerTypedFileExistence(this.selectedCustomer.ID, type))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var result = JSON.parse(xhttp.responseText);

        switch (type) {
          case this.enums.EPF_PROOF:
            this.hasEPFProof = Boolean(result);

            //check customer acknowledgement existence
            this.getTypedFileExistence(this.enums.ACKNOWLEDGEMENT);
            break;
          case this.enums.ACKNOWLEDGEMENT:
            this.hasCustomerAcknowledgement = Boolean(result);
            break;
        }

      }
    };
  }



  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }

  bindFormGroupInfo() {
    var epfDate;

    if (this.epfDetails.Date == undefined && this.selectedCustomer.DOB != undefined)
      epfDate = this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB));
    else
      epfDate = this.epfDetails.Date;


    this.epfFormGroup = this._formBuilder.group({
      epfDate: [epfDate, Validators.required],
      epfAmount: [this.epfDetails.Amount, Validators.required],
      hasiAccount: [this.epfDetails.HasIAccount],
      iAccountUsername: [this.epfDetails.IAccountUsername],
      iAccountPassword: [this.epfDetails.IAccountPassword],
      remark: [this.epfDetails.Remark],
      otherId: [this.selectedCustomer.OtherID],
      gender: [this.selectedCustomer.Gender],
      race: [this.selectedCustomer.Race]
    });


    // this.loanFormGroup = this._formBuilder.group({
    //   addedLoanList: [this.addedLoanList]
    // });

    this.contactFormGroup = this._formBuilder.group({
      contactList: [this.contactList]
    });
  }


  getCompanyInfo(companyId: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyDetails(companyId))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var json = JSON.parse(xhttp.responseText);

        sessionStorage.setItem("requireConsentForm", json.requireConsentForm);

        this.getAllFileExitence();

      }
    };
  }

  getAllFileExitence() {
    this.nonExistedNecessaryFileTypeList = [];
    var fileList;
    //for this case, CONSENT FORM is not necessary, leave for server to check
    var requireConsentForm = sessionStorage.getItem("requireConsentForm")
    if (requireConsentForm == "true")
      fileList = this.enums.customerNecessaryDocs;
    else
      fileList = this.enums.customerNecessaryDocs.slice(0, 4);

    for (let i = 0; i < fileList.length; i++) {
      this.getFileExistence(fileList[i], i);
    }
  }

  getFileExistence(type: string, index: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getCompanyCustomerTypedFileExistence(this.selectedCustomer.ID, type))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var result = JSON.parse(xhttp.responseText);

        if (Boolean(result) == false)
          this.nonExistedNecessaryFileTypeList.push(this.enums.customerNecessaryDocsString[index]);

      }
    };
  }


  reset() {
    this.activeModal.close()
  }
  getLeadDetails(id: number) {
    var xhttp = this.restApi.getRequest(this.constructApi.getLeadDetails(this.selectedCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));

        //if this lead epf details is found
        if (json.epfDate != undefined) {
          this.selectedCustomer.EPFDate = json.epfDate;
          this.getCustomerEPF();
        }

        //if this lead dont have any epf details
        else {
          if (this.selectedCustomer.DOB != undefined)
            var epfDate = new Date(this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB)));
          this.epfDetails = new EPF(undefined, undefined, epfDate, undefined, undefined, undefined, undefined)
          this.bindFormGroupInfo();
        }
      }
    };
  }
  getCustomerEPF() {

    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerEPF(this.selectedCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.epfDetails = new EPF(json.amount, json.customerId, json.date, json.hasIAccount, json.iAccountPassword, json.iAccountUsername, json.remark);
        this.bindFormGroupInfo();
      }
    };
  }



  isEPFDetailStepCompleted() {
    if (this.checkEPFDetailsValidity() != undefined && this.checkEPFDetailsValidity() == '')
      return true;
    else {
      return false;
    }

  }
  getFormValue(valueString: string) {
    return this.epfFormGroup.get(valueString).value;
  }

  checkEPFAmountLimit() {
    var epfDate = this.getFormValue('epfDate');
    var epfAmount = this.getFormValue('epfAmount');
    var string = "";

    //only restrict to NEW customer: NEW CUSTOMER that epf amount which is less than MINIMUM EPF AMOUNT of each category should be stopped
    // epf amount limit category can be found in enums
    if (this.isEPFExtra == false) {
      var epfAmountLimitCategory = this.sharedService.checkEPFAmountLimitCategory(new Date(epfDate));
      if (epfAmount < this.enums.epfAmountCategory[epfAmountLimitCategory].MinimumEPFAmount) {
        if (this.isManualElevate == false) {
          string += "\n\n - TERM " + this.enums.epfAmountCategory[epfAmountLimitCategory].LowerLimit + "-" + this.enums.epfAmountCategory[epfAmountLimitCategory].UpperLimit +
            " months AND EPF Amount less than RM " + this.sharedService.roundTo2Dec(this.enums.epfAmountCategory[epfAmountLimitCategory].MinimumEPFAmount) + " is not allowed";
        }
        else
          this.elevateError += "\n\n - TERM " + this.enums.epfAmountCategory[epfAmountLimitCategory].LowerLimit + "-" + this.enums.epfAmountCategory[epfAmountLimitCategory].UpperLimit +
            " months AND EPF Amount less than RM " + this.sharedService.roundTo2Dec(this.enums.epfAmountCategory[epfAmountLimitCategory].MinimumEPFAmount) + " is not allowed";

      }
    }
    return string;
  }

  checkEPFDetailsValidity() {
    this.elevateError = "";
    var string = "";

    var epfDate = this.getFormValue('epfDate');
    var epfAmount = this.getFormValue('epfAmount');

    var gender = this.getFormValue('gender');
    var race = this.getFormValue('race');

    if (epfDate == undefined || epfDate == "")
      string += "\n - Missing EPF Date";
    else if (epfAmount == undefined || epfAmount == "" || epfAmount == 0)
      string += "\n - Missing EPF Amount"
    else if (new Date(epfDate) <= new Date())
      string += "\n - Customer's EPF date is over."

    if (gender == undefined || gender == "")
      string += "\n - Missing Gender"

    if (race == undefined || race == "")
      string += "\n - Missing Race"


    if (this.hasEPFStatement == false)
      string += "\n - Please upload EPF Statement."

    if (this.hasEPFProof == false && this.isEPFExtra == false)
      string += "\n - Please upload EPF Proof."
    if (this.hasCustomerAcknowledgement == false && this.isEPFExtra == false)
      string += "\n - Please upload Customer Acknowledgement."



    //when fill in epf details, make sure epf loan amount fulfill the minimum epf amount of each category
    //else could not proceed
    string += this.checkEPFAmountLimit();



    return string;
  }

  openUploadEPFSupportingDoc(supportingDocType: string) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.supportingDocType = supportingDocType;
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      if (entry != undefined) {

        switch (supportingDocType) {
          case this.enums.EPF_STATEMENT:
            this.hasEPFStatement = true;
            break;
          case this.enums.EPF_PROOF:
            this.hasEPFProof = true;
            break;
          case this.enums.ACKNOWLEDGEMENT:
            this.hasCustomerAcknowledgement = true;
            break;
        }

      }

    });

    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();
    })
  }


  checkIfManualEPFDateIsValid() {
    if (this.epfDetails.Date != undefined && this.selectedCustomer.DOB != undefined) {
      var calculatedEPFDate = this.sharedService.calculateEPFDate(new Date(this.selectedCustomer.DOB));
      var formattedEPFDate = this.datepipe.transform(new Date(this.epfDetails.Date), "yyyy-MM-dd");
      var formattedCalculateEPFDate = this.datepipe.transform(new Date(calculatedEPFDate), "yyyy-MM-dd");
      if (formattedEPFDate == formattedCalculateEPFDate)
        return true;
      else
        return false;
    }
  }

  validateEPFDetails() {
    var string = this.checkEPFDetailsValidity();

    if (string != '') {
      this.sharedService.openAlert("Please fill in all the necessary fields CORRECTLY" + string, this.enums.DANGER_ALERT);
    }
    else {

      this.epfDetailsExpansionIsOpen = false;
    }

  }


  isEPFGurantorStepCompleted() {
    if (this.checkGuarantorValidity() != "")
      return false;
    else return true;
  }

  checkGuarantorValidity() {
    var string = "";
    if (this.contactList.length == 0 || this.contactList == undefined)
      string += "Please at least add a guarantor!";
    else {
      var selectedContact = this.contactList.filter((contact: Contact) => (contact.IsSelected == true));
      if (selectedContact.length == 0 || selectedContact == undefined) {
        string += "Please at least add a guarantor!";
      }

      else {
        var selectedContactWithoutPicture = selectedContact.filter((contact: Contact) => (contact.PhotoExisted == false || contact.PhotoExisted == undefined));
        if (selectedContactWithoutPicture.length > 0)
          string += "Please make sure all your guarantors contains picture!";
      }
    }

    return string
  }


  addContact() {
    const modalRef = this.sharedService.openModal(AddContactComponent);
    modalRef.componentInstance.viewCustomerId = this.selectedCustomer.ID;

    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var contact = entry;

      //append added loan to loan list
      this.contactList.push(contact);
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();
    })
  }

  validateCheckGurantorValidity() {
    var string = this.checkGuarantorValidity();
    if (string == "") {
      // this.constructReqeust();
      this.guarantorExpansionIsOpen = false;
    }

    else {
      this.sharedService.openAlert(string, this.enums.DANGER_ALERT);
    }
  }





  openLoanDetailsModal(request: LoanRequest) {
    const modalRef = this.sharedService.openModal(LoanRequestDetailsComponent, "regularModal");
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.request = request;
  }


  openAddLoanModal(isMain: boolean, shareLoanRequestIndex?: number) {

    const modalRef = this.sharedService.openModal(AddLoanComponent, "regularModal");
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;

    if (this.epfDetails != undefined)
      modalRef.componentInstance.epfDetails = this.epfDetails;
    else {
      var epf = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined)
      modalRef.componentInstance.epfDetails = epf;
    }


    modalRef.componentInstance.isEPF = true;
    modalRef.componentInstance.isEPFExtra = this.isEPFExtra;

    modalRef.componentInstance.needRestrictedRequest = this.sharedService.checkIsSeniorRestrictedCustomer(new Date(this.selectedCustomer.DOB));
    // modalRef.componentInstance.allowEPF = this.sharedService.checkIsAllowEPF(this.selectedCustomer.DOB, this.epfDetails, this.selectedProfile.ID, this.selectedProfile.SharedProfileId);

    modalRef.componentInstance.isManualElevate = this.isManualElevate;
    modalRef.componentInstance.isManualKeyIn = this.isManualKeyIn;

    //if this is not logged in user's company loan
    if (shareLoanRequestIndex != undefined && this.requestList[shareLoanRequestIndex].companyId != this.loggedInCompanyId)
      modalRef.componentInstance.oboCompanyId = this.requestList[shareLoanRequestIndex].companyId;

    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var request = entry;

      if (shareLoanRequestIndex == undefined) {
        var loanRequest = { request: request, companyId: this.loggedInCompanyId, companyName: this.loggedInCompanyName }
        this.mainLoanRequest = loanRequest;
        // this.requestList.push(loanRequest);
      }

      else {
        // this.requestList[shareLoanRequestIndex].companyId = this.selectedCompany;
        // this.requestList[shareLoanRequestIndex].companyName = this.selectedCompanyCode;
        this.requestList[shareLoanRequestIndex].request = request
      }




      this.cdr.detectChanges();
    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.reattach();
    })
  }


  checkHandlingChargeEligibility() {
    var isEligible = true;


    if (this.isShared && this.requestList.length > 0) {
      let totalPrincipal: number = this.mainLoanRequest.request.LoanInfo.Principal;


      this.requestList.forEach(request => {
        if (request.request != undefined)
          totalPrincipal += request.request.LoanInfo.Principal;
      });


      // if either one of the shared loan portion is higher than minimum shared loan portion = non shared loan
      if ((this.mainLoanRequest.request.LoanInfo.Principal / totalPrincipal) > this.enums.MINIMUM_SHARED_LOAN_PORTION)
        return false;

      this.requestList.forEach(request => {
        if (request.request != undefined && (request.request.LoanInfo.Principal / totalPrincipal) > this.enums.MINIMUM_SHARED_LOAN_PORTION) {
          return false;
        }
      });
    }
    else
      return false


    return isEligible;
  }



  createLoanInfoData(request: LoanRequest) {
    var extras = request.LoanInfo.SettlementCharges.Extras;
    var handlingCharge = undefined;

    //if is shared account only can get handling charge, else add handling charge into extras
    // if (this.isShared && this.requestList.length > 0) {
    //   handlingCharge = request.LoanInfo.SettlementCharges.HandlingCharge;
    // }
    // else {
    //   extras += (request.LoanInfo.SettlementCharges.HandlingCharge || 0);
    //   request.LoanInfo.SettlementCharges.HandlingCharge = undefined;
    // }

    //if is shared account only can get handling charge, else add handling charge into extras
    if (this.checkHandlingChargeEligibility() == false) {
      request.LoanInfo.SettlementCharges.Extras = request.LoanInfo.SettlementCharges.Extras + (request.LoanInfo.SettlementCharges.HandlingCharge || 0);
      request.LoanInfo.SettlementCharges.HandlingCharge = undefined;
    }


    var settlementCharges = {
      "stamp": request.LoanInfo.SettlementCharges.Stamp,
      "serviceCharge": request.LoanInfo.SettlementCharges.ServiceCharge,
      "extras": request.LoanInfo.SettlementCharges.Extras,
      "handlingCharge": request.LoanInfo.SettlementCharges.HandlingCharge,
    }



    var loanInfoData = {
      "principal": request.LoanInfo.Principal,
      "scheme": request.LoanInfo.Scheme,
      "term": request.LoanInfo.Term,
      "interestRate": this.sharedService.roundToDec(request.LoanInfo.InterestRate, 11),
      "stamp": request.LoanInfo.StampAmount,
      "serviceCharge": request.LoanInfo.ServiceCharge,
      "extras": request.LoanInfo.Extras,
      "processingRate": request.LoanInfo.ProcessingRate,
      "reserve": -request.LoanInfo.Reserve,
      "settlementCharges": settlementCharges
    }
    return loanInfoData;
  }

  addEPFRequest(requireElevate: boolean) {


    //create remark data
    var requestRemark = this.mainLoanRequest.request.Remark + " ";
    this.requestList.forEach(sharedRequest => {
      requestRemark += sharedRequest.request.Remark + " ";
    });

    var remarkStr = (requestRemark == undefined || requestRemark == "" || requestRemark == null) ? "" : requestRemark;
    var remark = (requireElevate == true) ? remarkStr + "=" + this.elevateError + this.elevateLoanError + this.elevateSuggestedSettlement : remarkStr;

    if (this.isShared) {

      var loanInfoData = this.createLoanInfoData(this.mainLoanRequest.request);
      var request = { companyId: this.mainLoanRequest.companyId, loanInfo: loanInfoData, remark: remark, requestedOn: new Date().toISOString() }
      var loanRequestArr = [request];

      this.requestList.forEach(addedRequest => {
        loanInfoData = this.createLoanInfoData(addedRequest.request);
        request = { companyId: addedRequest.companyId, loanInfo: loanInfoData, remark: remark, requestedOn: new Date().toISOString() }
        loanRequestArr.push(request);
      });
    }
    else
      var loanInfoData = this.createLoanInfoData(this.mainLoanRequest.request);



    //create guarantor data
    var guarantorIdData: Number[] = new Array;
    var guarantorList: Contact[] = new Array;
    for (let i = 0; i < this.contactList.length; i++) {
      if (this.contactList[i].IsSelected == true) {
        guarantorIdData.push(this.contactList[i].ID);
        guarantorList.push(this.contactList[i]);
      }

    }



    var data;

    if (this.isShared == false) {
      data = {
        "profileId": this.selectedProfile.ID,
        "loanInfo": loanInfoData,
        "remark": remark,
        "requestOn": new Date().toISOString(),
        "guarantorIds": guarantorIdData
      }
    } else {

      data = {
        "profileId": this.selectedProfile.ID,
        "guarantorIds": guarantorIdData,
        "requests": loanRequestArr,

      }
    }


    var xhr;
    if (this.isShared == false)
      xhr = (requireElevate == true) ? this.restApi.postRequest(this.constructApi.getAddElevatedEPFLoanRequest(), data) : this.restApi.postRequest(this.constructApi.getAddLoanRequest(), data);
    else
      xhr = (requireElevate == true) ? this.restApi.postRequest(this.constructApi.getAddSharedElevatedEPFLoanRequest(), data) : this.restApi.postRequest(this.constructApi.getAddSharedLoanRequest(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        //if success , then proceed
        if (xhr.status == 200) {

          if (this.isShared == false) {
            var json = JSON.parse(xhr.responseText);
            this.mainLoanRequest.request.ID = Number(json.id);


            if (this.sharedService.isBranchAccountHandler()) {
              if (remark != undefined || remark != "")
                this.mainLoanRequest.request.Remark = remark;

              var otherId = this.getFormValue('otherId')
              if (otherId != undefined)
                this.mainLoanRequest.request.OtherId = otherId;

              this.mainLoanRequest.request.DOB = this.selectedCustomer.DOB;
              this.mainLoanRequest.request.EpfAmount = this.epfDetails.Amount;
              this.mainLoanRequest.request.EPFDate = this.epfDetails.Date;
              this.mainLoanRequest.request.HasIAccount = this.getFormValue('hasiAccount');

              this.mainLoanRequest.request.Guarantors = this.contactList.filter(contact => contact.IsSelected == true)



              var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);



              modalRef.componentInstance.requestList = [this.mainLoanRequest.request];
              modalRef.componentInstance.isDirty = true;
              modalRef.componentInstance.isEPFRequest = true;
              this.reset();
            }
            else {
              window.location.reload();
            }

          }
          else {
            var json = JSON.parse(xhr.responseText);
            var loanGroupId = json.loanGroupId;
            this.agreeShareLoanAgreement(loanGroupId);
          }




        }
        else {
          this.disabledSubmit = false;
          var json = JSON.parse(xhr.responseText);

          if (json.requireElevation != undefined && json.requireElevation == true) {
            this.elevateError += json.error
            this.submitEPFLoanRequest(true);
          } else
            //show error message
            this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)

        }
      }
    }
  }


  agreeShareLoanAgreement(loanGroupId: number) {
    var data = {
      "remark": ""
    };


    var xhr = this.restApi.postRequest(this.constructApi.getSharedLoanProcess(loanGroupId, "agree"), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            this.activeModal.close();
            //after agree, redirect to dashboard
            this.router.navigate(['/dashboard-calendar']);

          }
          else {
            this.sharedService.openErrorMessage(xhr);
          }
        }
      }

    }
  }

  confirmBeforeElevatingCase() {

    const modalRef = this.sharedService.openModal(ElevateConfirmationComponent);

    modalRef.componentInstance.message = this.elevateError + this.elevateLoanError + this.elevateSuggestedSettlement;

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {

      if (entry == true)
        this.submitEPFLoanRequest(true);

    });
  }


  async submitSharedRequests() {
    var error = await this.validateLoans();
    if (error == "") {
      this.checkIfRequireElevate();
    }
    else
      this.sharedService.openAlert("Please fill in all the necessary fields CORRECTLY" + error, this.enums.DANGER_ALERT);

  }

  checkIfRequireElevate() {
    var epfDate = this.getFormValue("epfDate");
    var epfAmount = this.getFormValue("epfAmount");
    var epfCategory = this.sharedService.checkEPFLimitCategory(epfDate, epfAmount);


    //calculate settlement of this newly created loan request
    var currentLoanSettlement = this.sharedService.calculateEPFSettlementAmountWithCharges(this.combinedLoan);


    // EPF set Limit
    //if previous + new loan request settlement ratio overlimit
    if (this.isEPF == true && this.enums.epfLimitList[epfCategory].ElevatedSettlementRate != undefined &&
      this.sharedService.calculatePreviousEPFLoanSettlement(this.previousEPFLoans) + currentLoanSettlement > this.sharedService.getEPFElevateSettlement(epfDate, epfAmount)) {
      this.elevateSuggestedSettlement += "\n\n- The loan requested has the settlement ratio more than " + this.enums.epfLimitList[epfCategory].ElevatedSettlementRate * 100 + "%. This case will be sent to BOSS FOR APPROVAL"
      this.confirmBeforeElevatingCase();
    }
    else if (this.isManualElevate == true)
      this.confirmBeforeElevatingCase();
    else
      this.submitEPFLoanRequest(false)
  }


  updateCustomerDetails(requireElevate: boolean) {
    var data;
    var otherId = this.getFormValue('otherId')
    var gender = this.getFormValue('gender')
    var race = this.getFormValue('race')
    data = {
      "name": this.selectedCustomer.Name,
      "icNumber": this.selectedCustomer.ICNumber,
      "contactNo": this.selectedCustomer.ContactNo,
      "ctosNumber": this.selectedCustomer.CtosNumber,
      "ctosStatus": this.selectedCustomer.CtosStatus,
      "status": this.selectedCustomer.Status,
      "dateCreated": this.selectedCustomer.DateCreated,
      "handler": this.selectedCustomer.HandlerId,
      "companyId": this.selectedCustomer.CompanyId,
      "address": this.selectedCustomer.Address,
      "address2": this.selectedCustomer.Address2,
      "otherId": otherId,
      "gender": gender,
      "race": race
    }
    var xhr = this.restApi.putRequest(this.constructApi.getUpdateCustomer(this.selectedCustomer.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        //if success , then proceed
        if (xhr.status == 200) {

        }
        else {
          this.disabledSubmit = false;

          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }

  submitEPFLoanRequest(requireElevate: boolean) {
    this.disabledSubmit = true;


    //if there is changes in other id, update first only send request
    var otherId = this.getFormValue('otherId')

    if (this.selectedCustomer.OtherID == undefined || this.selectedCustomer.OtherID != otherId || this.selectedCustomer.Gender == '' || this.selectedCustomer.Race == '')

      this.updateCustomerDetails(requireElevate);

    //DETECT IF EPF INFO IS CHANGED
    if (this.epfDetails.Date == undefined)
      this.updateEPFDetails(requireElevate);
    else {
      if (this.checkEPFInfoChanged() == true && this.sharedService.isBranchAccountHandler())
        this.updateEPFDetails(requireElevate);
      else
        this.addEPFRequest(requireElevate);
    }

  }

  checkEPFInfoChanged() {

    if (this.epfDetails.Date != this.getFormValue('epfDate'))
      return true;

    else if (this.epfDetails.Amount != this.getFormValue('epfAmount'))
      return true;

    else if (this.epfDetails.HasIAccount != this.getFormValue('hasiAccount'))
      return true;

    else if (this.epfDetails.IAccountUsername != this.getFormValue('iAccountUsername'))
      return true;

    else if (this.epfDetails.IAccountPassword != this.getFormValue('iAccountPassword'))
      return true;

    else
      return false;
  }

  updateEPFDetails(requireElevate: boolean) {
    var data;

    if (this.epfDetails.Date != undefined) {
      data = {
        "hasIAccount": this.getFormValue('hasiAccount'),
        "iAccountUsername": this.getFormValue('iAccountUsername'),
        "iAccountPassword": this.getFormValue('iAccountPassword'),
        "remark": ""
      }

    } else {
      var epfStr = this.datepipe.transform(this.getFormValue('epfDate'), 'yyyy-MM-dd');
      data = {
        "date": epfStr,
        "amount": this.getFormValue('epfAmount'),
        "hasIAccount": this.getFormValue('hasiAccount'),
        "iAccountUsername": this.getFormValue('iAccountUsername'),
        "iAccountPassword": this.getFormValue('iAccountPassword'),
        "remark": ""
      }
    }



    var xhr;
    if (this.sharedService.isBranchAccountHandler())
      xhr = (this.epfDetails.Date == undefined) ? this.restApi.postRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data) : this.restApi.putRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data)
    else
      xhr = this.restApi.postRequest(this.constructApi.getUpdateEPFDetails(this.selectedCustomer.ID), data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {

          this.epfDetails.Date = new Date(this.datepipe.transform(this.getFormValue('epfDate'), 'yyyy-MM-dd'));
          this.epfDetails.Amount = this.getFormValue('epfAmount');

          this.addEPFRequest(requireElevate);
        }
        else {
          this.disabledSubmit = false;

          //show error message
          this.sharedService.openAlert(JSON.parse(xhr.responseText).error, this.enums.DANGER_ALERT)

        }
      }
    }
  }


  getEPFDate() {
    if (this.epfDetails.Date != undefined)
      return this.epfDetails.Date;
    else
      return this.getFormValue('epfDate');
  }


  getEPFAmount() {
    if (this.epfDetails.Amount != undefined)
      return this.epfDetails.Amount;
    else
      return this.getFormValue('epfAmount');
  }

  checkIfEPFDateOver() {
    if (this.getEPFDate() != undefined) {
      if (new Date(this.getEPFDate()) <= new Date())
        return true;
      else
        return false;
    } else
      return false;
  }


  getContactClass(isSelected: boolean) {
    if (isSelected == true)
      return 'selectedContactCard';
    else
      return 'contactCard'
  }

  changeIsContactSelected(i: number) {
    this.contactList[i].IsSelected = !this.contactList[i].IsSelected;
  }



  openContactUploadFileDialog(contact: Contact, docType: string, index: number) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.contactId = contact.ID;
    modalRef.componentInstance.contactDocType = docType;
    //get added loan
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      this.contactList[index].PhotoExisted = true;
    });
  }

  getContactInfo() {
    var index = 0;
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerContact(this.selectedCustomer.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          let contact = new Contact(json.id, json.name, json.icNumber, json.number, json.address, json.customerId, json.relation, json.remark, false);
          //add customer instance into customer list
          this.contactList.push(contact);
          this.getCustomerContactPhotoExisted(contact, index)
          index++;

        }
        );
      }
    };

  }

  getCustomerContactPhotoExisted(contact: Contact, index: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerContactPhotoExistence(this.selectedCustomer.ID, contact.ID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var result = Boolean(JSON.parse((xhttp.responseText)));
        this.contactList[index].PhotoExisted = result
      }
    };

  }

  createNewRequestObject() {
    var request = { companyId: undefined, companyName: undefined, request: undefined }
    this.requestList.push(request)

  }


  combineAllLoans() {

    var mainLoan = this.mainLoanRequest.request.LoanInfo;
    var loanJson = JSON.parse(JSON.stringify(mainLoan))
    var extrasJsonArr = JSON.parse(JSON.stringify(mainLoan.Extras));
    var settlementChargesJson = JSON.parse(JSON.stringify(mainLoan.SettlementCharges));

    var loanSettlementWithoutCharge = this.sharedService.calculateEPFSettlementAmount(mainLoan.Principal, mainLoan.InterestRate, mainLoan.Term);


    var dlList: DuitLebih[] = new Array;
    extrasJsonArr.forEach(extras => {
      dlList.push(new DuitLebih(extras.amount, extras.subtype))
    });


    var settlementCharges = new SettlementCharges(settlementChargesJson._serviceCharge, settlementChargesJson._stamp, settlementChargesJson._extras, settlementChargesJson._handlingCharge)

    var loan: Loan = new Loan(undefined, undefined, loanJson._principal, loanJson._stampAmount, undefined, undefined, undefined, undefined, undefined, undefined, loanJson._interestRate,
      loanJson._term, undefined, undefined, loanJson._interestCharge, undefined, undefined, undefined, undefined, loanJson._repayment, loanJson._reserve, loanJson._balance, loanJson._monthlyPayment, undefined, loanJson._serviceCharge,
      dlList, undefined, loanJson._monthlyInterest, undefined, loanJson._processingRate, loanJson._processingCharge, undefined, undefined, loanJson._settlementStamp,
      loanJson._settlementServiceCharge, loanJson._settlementExtras, undefined, undefined, undefined, undefined, undefined, undefined, settlementCharges);



    this.requestList.forEach(addedRequest => {

      loan.Principal += addedRequest.request.LoanInfo.Principal;

      loan.Balance += addedRequest.request.LoanInfo.Balance;
      loan.InterestCharge += addedRequest.request.LoanInfo.InterestCharge;
      loan.Extras.push(addedRequest.request.LoanInfo.Extras[0])
      loan.ServiceCharge += addedRequest.request.LoanInfo.ServiceCharge;
      loan.StampAmount += addedRequest.request.LoanInfo.StampAmount;

      //recalculate combined loan's interest rate: if never recalculate when use combined loan to calculate settlement will have issue
      loanSettlementWithoutCharge += this.sharedService.calculateEPFSettlementAmount(addedRequest.request.LoanInfo.Principal, addedRequest.request.LoanInfo.InterestRate, addedRequest.request.LoanInfo.Term);
      let flexiMonthlyAmount = (loanSettlementWithoutCharge) / loan.Term;
      loan.InterestRate = this.sharedService.calculateInterestRate(flexiMonthlyAmount, loan.Principal, loan.Term)

      loan.SettlementCharges.Stamp += addedRequest.request.LoanInfo.SettlementCharges.Stamp;
      loan.SettlementCharges.ServiceCharge += addedRequest.request.LoanInfo.SettlementCharges.ServiceCharge;
      loan.SettlementCharges.Extras += addedRequest.request.LoanInfo.SettlementCharges.Extras;
      loan.SettlementCharges.HandlingCharge += addedRequest.request.LoanInfo.SettlementCharges.HandlingCharge;

      loan.SettlementExtra += addedRequest.request.LoanInfo.SettlementExtra;
      loan.SettlementStamp += addedRequest.request.LoanInfo.SettlementStamp;
      loan.SettlementServiceCharge += addedRequest.request.LoanInfo.SettlementServiceCharge;

    });


 
    return loan;

  }

  async validateLoans() {


    this.combinedLoan = await this.combineAllLoans();
    var error = "";
    this.elevateLoanError = "";


    //SETTLEMENT CHARGES CHECK
    if (this.isEPF == true) {
      if (this.combinedLoan.SettlementCharges == undefined) {
        error += "\n - Settlement Charges cannnot leave empty!"
      }

      else {

        //if any of the amount is negative
        if (this.combinedLoan.SettlementCharges.Stamp < 0 || this.combinedLoan.SettlementCharges.ServiceCharge < 0 || this.combinedLoan.SettlementCharges.Extras < 0) {
          error += "\n - Settlement charges details (STAMP / SC / DL) cannot be negative";
        }

        //if set settlement charges less than minimum charges per term
        if (this.combinedLoan.SettlementCharges.Stamp + this.combinedLoan.SettlementCharges.ServiceCharge + this.combinedLoan.SettlementCharges.Extras < (this.enums.MIN_SSC_PER_TERM * this.combinedLoan.Term)) {
          if (this.isManualElevate == true)
            this.elevateLoanError += "\n - ⚠️ Settlement charges less than RM " + (this.enums.MIN_SSC_PER_TERM * this.combinedLoan.Term);
          else
            error += "\n - ⚠️ Settlement charges cannot be less than RM " + (this.enums.MIN_SSC_PER_TERM * this.combinedLoan.Term);
        }


        //CHECK IF IT'S  EPF LOAN, IF YES, CHECK SETTLEMENT CHARGES VALUE 
        if (this.isEPF == true) {

          var firstLoanMaximumSettlementCharges = new SettlementCharges(this.enums.MINIMUM_SC * this.combinedLoan.Term, this.enums.MINIMUM_STAMP * this.combinedLoan.Term, 0);
          var manualKeyInExtraCharges = this.combinedLoan.SettlementCharges.Stamp + this.combinedLoan.SettlementCharges.ServiceCharge + this.combinedLoan.SettlementCharges.Extras;

          //get extra distribution based on total charges collected (sum of all the charges manual key in)
          var extraSupposedDistribution = this.sharedService.calculateAndReturnSettlementCharges(this.combinedLoan.Principal, manualKeyInExtraCharges, this.combinedLoan.Term);

          //get supposed settlement charges amount 
          var settlementChargesWarningValue = (this.isEPFExtra == false) ? firstLoanMaximumSettlementCharges : extraSupposedDistribution;


          //check whether settlement stamp  = permitted stamp
          if (this.combinedLoan.SettlementCharges.Stamp != settlementChargesWarningValue.Stamp) {
            if (this.isManualElevate == true)
              this.elevateLoanError += "\n - 🚨 Settlement charges STAMP RM " + (this.combinedLoan.SettlementCharges.Stamp) + " is not equal to permitted Settlement Stamp: RM" + settlementChargesWarningValue.Stamp + " :" + " (RM" + this.sharedService.roundTo2Dec(settlementChargesWarningValue.Stamp / this.combinedLoan.Term) + " x " + this.combinedLoan.Term + ")";
            else
              error += "\n - 🚨 Settlement charges STAMP RM " + (this.combinedLoan.SettlementCharges.Stamp) + " is not equal to permitted Settlement Stamp: RM" + settlementChargesWarningValue.Stamp + " :" + " (RM" + this.sharedService.roundTo2Dec(settlementChargesWarningValue.Stamp / this.combinedLoan.Term) + " x " + this.combinedLoan.Term + ")";
          }

          //check whether settlement sc  = permitted sc
          if (this.combinedLoan.SettlementCharges.ServiceCharge != settlementChargesWarningValue.ServiceCharge) {
            if (this.isManualElevate == true)
              this.elevateLoanError += "\n - 🚨 Settlement charges SERVICE CHARGE RM " + (this.combinedLoan.SettlementCharges.ServiceCharge) + " is not equal to permitted Settlement Service Charge: RM" + settlementChargesWarningValue.ServiceCharge + " :" + " (RM" + this.sharedService.roundTo2Dec(settlementChargesWarningValue.ServiceCharge / this.combinedLoan.Term) + " x " + this.combinedLoan.Term + ")";
            else
              error += "\n - 🚨 Settlement charges SERVICE CHARGE RM " + (this.combinedLoan.SettlementCharges.ServiceCharge) + " is not equal to permitted Settlement Service Charge: RM" + settlementChargesWarningValue.ServiceCharge + " :" + " (RM" + this.sharedService.roundTo2Dec(settlementChargesWarningValue.ServiceCharge / this.combinedLoan.Term) + " x " + this.combinedLoan.Term + ")";
          }

          //check whether handling charge = handling rate*principal

          if (this.isEPFExtra) {
            if (this.combinedLoan.SettlementCharges.HandlingCharge == undefined) {
              error += "\n - 🚨 Handling Charge cannot left empty!!";
            }
            else if (this.combinedLoan.SettlementCharges.HandlingCharge != Math.floor(this.combinedLoan.Principal * this.enums.MININUM_HANDLING_RATE * this.combinedLoan.Term)) {
              error += "\n - 🚨 Handling Charge of RM" + this.combinedLoan.SettlementCharges.HandlingCharge + " is not equal to 1% of principal x " + this.combinedLoan.Term + "! (RM " + Math.floor(this.combinedLoan.Principal * this.enums.MININUM_HANDLING_RATE * this.combinedLoan.Term) + ")";

            }
          }



          //remove SETTLEMENT CHARGES DL WARNING
          // if (settlementChargesWarningValue.Extras >= 0 && this.combinedLoan.SettlementCharges.Extras > settlementChargesWarningValue.Extras) {
          //   if (this.isManualElevate == true)
          //     this.elevateLoanError += "\n - ⚠️ Settlement charges DL MORE THAN SUGGESTED of RM " + (settlementChargesWarningValue.Extras) + " (RM" + this.sharedService.roundTo2Dec(settlementChargesWarningValue.Extras / this.combinedLoan.Term) + " x " + this.combinedLoan.Term + ")";
          //   else
          //     string += "\n - ⚠️ Settlement charges DL MORE THAN SUGGESTED of RM " + (settlementChargesWarningValue.Extras) + " (RM" + this.sharedService.roundTo2Dec(settlementChargesWarningValue.Extras / this.combinedLoan.Term) + " x " + this.combinedLoan.Term + ")";
          // }

        }


        //PRINCIPAL MUST BE MULTIPLE OF 50
        if (this.isEPF == true && this.combinedLoan.Principal % 50 != 0) {
          if (this.isManualElevate == true)
            this.elevateLoanError += "\n- Please make sure PRINCIPAL is MULTIPLE of 50";
          else
            error += "\n-  Please make sure PRINCIPAL is MULTIPLE of 50";
        }

        //CASH ON HAND MUST BE MULTIPLE OF 100 (EPF FIRST LOAN)
        var cashOnHand = this.combinedLoan.Principal - this.combinedLoan.InterestCharge - this.combinedLoan.StampAmount - this.combinedLoan.ServiceCharge
          - this.sharedService.calculateDuitLebihAmount(this.combinedLoan.Extras);


        if (this.isEPF == true && this.isEPFExtra == false && cashOnHand % 100 != 0) {
          if (this.isManualElevate == true)
            this.elevateLoanError += "\n- Please make sure CASH ON HAND is MULTIPLE of 100";
          else
            error += "\n-  Please make sure CASH ON HAND is MULTIPLE of 100";
        }


        var epfDate = this.getFormValue("epfDate");
        var epfAmount = this.getFormValue("epfAmount");
        var epfCategory = this.sharedService.checkEPFLimitCategory(epfDate, epfAmount);
        var currentLoanSettlement = this.sharedService.calculateEPFSettlementAmountWithCharges(this.combinedLoan);



        //STAMP LIMIT
        //If EPF, make sure at least collect MINIMUM STAMP FOR EPF SCHEME WHEN OUT LOAN
        if (this.isEPF == true) {
          if ((this.combinedLoan.StampAmount == undefined || this.combinedLoan.StampAmount != this.enums.MINIMUM_STAMP)) {
            if (this.isManualElevate == true)
              this.elevateLoanError += "\n- Please make sure you key in RM" + this.enums.MINIMUM_STAMP + " for Stamp when adding loan";
            else
              error += "\n- Please make sure you key in RM" + this.enums.MINIMUM_STAMP + " for Stamp when adding loan";
          }
        }

        //SC LIMIT
        //If EPF, make sure at least collect MINIMUM SC FOR EPF SCHEME WHEN OUT LOAN
        if (this.isEPF == true) {
          if ((this.combinedLoan.ServiceCharge == undefined || this.combinedLoan.ServiceCharge != this.enums.MINIMUM_SC)) {
            if (this.isManualElevate == true)
              this.elevateLoanError += "\n- Please make sure you key in RM" + this.enums.MINIMUM_SC + " for Service Charge when adding loan";
            else
              error += "\n- Please make sure you key in RM" + this.enums.MINIMUM_SC + " for Service Charge when adding loan";
          }
        }



        //EPF first loan Limit
        if (this.isEPF == true && this.enums.epfLimitList[epfCategory].FirstLoanRate != undefined && this.isEPFExtra == false && this.combinedLoan.Principal > this.sharedService.getEPFLimitFirstLoan(epfDate, epfAmount)) {
          if (this.isManualElevate == true)
            this.elevateLoanError += "\n\n- 🚨 Loan borrow within  term " + this.enums.epfLimitList[epfCategory].LowerLimit + " - " + this.enums.epfLimitList[epfCategory].UpperLimit + " can only borrow "
              + this.enums.epfLimitList[epfCategory].FirstLoanRate * 100 + "% of epf amount on FIRST LOAN. In this case = RM" + this.sharedService.getEPFLimitFirstLoan(epfDate, epfAmount);
          else
            error += "\n\n- 🚨 Loan borrow within  term " + this.enums.epfLimitList[epfCategory].LowerLimit + " - " + this.enums.epfLimitList[epfCategory].UpperLimit + " can only borrow "
              + this.enums.epfLimitList[epfCategory].FirstLoanRate * 100 + "% of epf amount on FIRST LOAN. In this case = RM" + this.sharedService.getEPFLimitFirstLoan(epfDate, epfAmount);


        }

        // EPF overall loan Limit
        //Only consider EXTRA because first loan limit is there to control NEW loan in every category
        let previousLoanPrincipal = Number(this.sharedService.calculatePreviousEPFLoanPrincipal(this.previousEPFLoans));
        let totalUserPrincipal = previousLoanPrincipal + this.combinedLoan.Principal;

        if (this.isEPF == true && this.isEPFExtra == true && this.enums.epfLimitList[epfCategory].OverallLoanRate != undefined &&
          totalUserPrincipal > this.sharedService.getEPFLimitPrincipal(epfDate, epfAmount)) {

          if (this.isManualElevate == true)
            this.elevateLoanError += "\n\n- 🚨 Loan borrow within  term " + this.enums.epfLimitList[epfCategory].LowerLimit + " - " + this.enums.epfLimitList[epfCategory].UpperLimit + " can only borrow "
              + this.enums.epfLimitList[epfCategory].OverallLoanRate * 100 + "% of epf amount IN TOTAL. In this case : Limit= RM" + this.sharedService.getEPFLimitPrincipal(epfDate, epfAmount) + "| User= RM " + totalUserPrincipal + "(Previous: RM " + previousLoanPrincipal + " + RM " + this.combinedLoan.Principal + ")";
          else
            error += "\n\n- 🚨 Loan borrow within  term " + this.enums.epfLimitList[epfCategory].LowerLimit + " - " + this.enums.epfLimitList[epfCategory].UpperLimit + " can only borrow "
              + this.enums.epfLimitList[epfCategory].OverallLoanRate * 100 + "% of epf amount IN TOTAL. In this case : Limit= RM" + this.sharedService.getEPFLimitPrincipal(epfDate, epfAmount) + "| User= RM " + totalUserPrincipal + "(Previous: RM " + previousLoanPrincipal + " + RM " + this.combinedLoan.Principal + ")";
        }


        // EPF set Limit
        let previousSettlement = Number(this.sharedService.calculatePreviousEPFLoanSettlement(this.previousEPFLoans));
        let totalUserSettlement = previousSettlement + currentLoanSettlement;

        if (this.isEPF == true && this.enums.epfLimitList[epfCategory].MaximumSettlementRate != undefined &&
          totalUserSettlement > this.sharedService.getEPFLimitSettlement(epfDate, epfAmount)) {



          if (this.isManualElevate == true)
            this.elevateLoanError += "\n\n- 🚨 Loan borrow within  term " + this.enums.epfLimitList[epfCategory].LowerLimit + " - " + this.enums.epfLimitList[epfCategory].UpperLimit + " can only borrow "
              + this.enums.epfLimitList[epfCategory].MaximumSettlementRate * 100 + "% of SETTLEMENT RATIO IN TOTAL. In this case :Limit= RM" + this.sharedService.getEPFLimitSettlement(epfDate, epfAmount) + " | User= RM " + this.sharedService.roundTo2Dec(totalUserSettlement) + "(Previous: RM " + this.sharedService.roundTo2Dec(previousSettlement) + " + RM " + this.sharedService.roundTo2Dec(currentLoanSettlement) + ")";
          else
            error += "\n\n- 🚨 Loan borrow within  term " + this.enums.epfLimitList[epfCategory].LowerLimit + " - " + this.enums.epfLimitList[epfCategory].UpperLimit + " can only borrow "
              + this.enums.epfLimitList[epfCategory].MaximumSettlementRate * 100 + "% of SETTLEMENT RATIO IN TOTAL. In this case :Limit= RM" + this.sharedService.getEPFLimitSettlement(epfDate, epfAmount) + " | User= RM " + this.sharedService.roundTo2Dec(totalUserSettlement) + "(Previous: RM " + this.sharedService.roundTo2Dec(previousSettlement) + " + RM " + this.sharedService.roundTo2Dec(currentLoanSettlement) + ")";


        }

        //WARN MANUAL KEY IN - FIRST LOAN (not shared)
        if (this.isShared == false) {
          if (this.isEPF == true && this.isEPFExtra == false && this.isManualKeyIn == true) {
            if (this.isManualElevate == true)
              this.elevateLoanError += "\n\n-⌨️  THIS IS MANUAL KEY IN FIRST LOAN, NOT SYSTEM GENERATED";
            else
              error += "\n\n- ⌨️ THIS IS MANUAL KEY IN FIRST LOAN, NOT SYSTEM GENERATED";

          }
        }


      }
    }
    return error;
  }





  getSelectedCompany(company: Company, requestIndex: number) {
    if (this.loggedInCompanyId != company.ID) {
      this.requestList[requestIndex].companyId = company.ID;
      this.requestList[requestIndex].companyName = company.CompanyCode;

      this.cdr.detectChanges();
    }
    else {
      var modalRef = this.sharedService.openAlert("Cannot open another loan for main comany in same request", this.enums.DANGER_ALERT);
      this.deleteNewLoan(requestIndex)

    }

  }



  elevateConfirmation() {

    if (this.isManualElevate == true) {
      var modalRef = this.sharedService.openModal(ElevateConfirmationComponent, "regularModal");

      modalRef.componentInstance.passEntry.subscribe((entry) => {
        this.isManualElevate = entry;

      });
    }
  }

  manualKeyInConfirmation() {

    if (this.isManualKeyIn == true) {
      var modalRef = this.sharedService.openModal(ElevateConfirmationComponent, "regularModal");

      modalRef.componentInstance.message = "WARNING! You decided to use manual key in instead of system calculated value. This might increase risk of case denial!"
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        this.isManualKeyIn = entry;

      });
    }
  }

  deleteNewLoan(index: number) {

    if (this.mainLoanRequest != undefined) {
      this.requestList.splice(index, 1);
      this.cdr.detectChanges();
    } else {
      this.sharedService.openAlert("Main request missing!", this.enums.DANGER_ALERT)
    }

    console.log(this.requestList)
  }

  deleteMainLoan() {

    if (this.requestList.length == 0) {
      this.mainLoanRequest = undefined;
      this.cdr.detectChanges();
    } else {
      this.sharedService.openAlert("Cannot delete main loan when there's request!", this.enums.DANGER_ALERT)
    }

  }

}
