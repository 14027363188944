import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AdminAccess, CompanyAccess } from 'src/model/companyAccess.model';
import { AccessActionComponent } from '../access-action/access-action.component';
import { AdminCompanyListComponent } from '../admin-company-list/admin-company-list.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AdminAccessService } from 'src/services/admin-access.service';

@Component({
  selector: 'app-admin-access-item',
  templateUrl: './admin-access-item.component.html',
  styleUrl: './admin-access-item.component.css'
})
export class AdminAccessItemComponent {

  @Input() adminAccessItem: AdminAccess;
  @Input() companyAccessList: CompanyAccess[] = new Array;
  @Input() isOnDuty: boolean = false;



  constructor(private sharedService: SharedService, private adminAccessService: AdminAccessService) { }
  openCloneAdminModal() {
    var modalRef = this.sharedService.openModal(AccessActionComponent);
    modalRef.componentInstance.companyAccessList = this.companyAccessList;
    modalRef.componentInstance.action = 'Clone';
    modalRef.componentInstance.currentAdmin = this.adminAccessItem;

    modalRef.componentInstance.shouldReload.subscribe((reload) => {
      if (reload == true)
        window.location.reload();
    });
  }

  openRevokeAdminModal() {

    if (this.isOnDuty == false) {
      var modalRef = this.sharedService.openModal(AccessActionComponent);
      modalRef.componentInstance.companyAccessList = this.companyAccessList;
      modalRef.componentInstance.action = 'RevokeTransfer';
      modalRef.componentInstance.currentAdmin = this.adminAccessItem;
    }

    else {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Are you sure you want to revoke on duty access for this admin?";

      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;

        // use the confirmation to do something
        if (confirmation == true) {
          let revoked = this.adminAccessService.revokeAccess(this.adminAccessItem.username, this.adminAccessItem.onDutyAccess);

          if (revoked)
            window.location.reload();
        }
      });
    }


  }

  openAdminCompanyListModal() {
    var modalRef = this.sharedService.openModal(AdminCompanyListComponent);
    modalRef.componentInstance.companyAccessList = this.companyAccessList.filter(access => access.Username == this.adminAccessItem.username).sort((a, b) => a.Priority - b.Priority)
    modalRef.componentInstance.adminName = this.adminAccessItem.username;
  }
}
