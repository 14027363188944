import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { end } from '@popperjs/core';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { TxSet } from 'src/model/set.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { TransactionType } from 'src/model/transactionType.model';
import { SetService } from 'src/services/set.service';
import { TransactionService } from 'src/services/transaction.service';

type CompSet = {
  CompanyCode: string,
  CompanyID: number,
  TotalCount: number,
  DoneCount: number,
}

@Component({
  selector: 'app-receipt-todo',
  templateUrl: './receipt-todo.component.html',
  styleUrls: ['./receipt-todo.component.css']
})

export class ReceiptTodoComponent implements OnInit {
  readonly BookmarkFilter = 'Saved';
  readonly TransTypeFilter = 'TransactionList.Type';
  readonly TransSubTypeFilter = 'TransactionList.Subtype';
  readonly SubtypeShortcut = [9, 10]; // Comm, Ads
  
  setList: TxSet[] = new Array();
  filteredSetList: TxSet[] = new Array();
  
  transTypeList: number[] = new Array();
  subtypeList: { [key: number]: TransactionSubType[] } = {};

  allTransType: TransactionType[] = new Array();
  allTransTypeDict: { [key: number]: string } = {};
  allTransSubType: TransactionSubType[] = new Array();
  allTransSubTypeDict: { [key: number]: string } = {};
  expenseSubtypeList: TransactionSubType[];
  dlSubtypeList: TransactionSubType[];

  compSetList: CompSet[] = new Array();

  setFilterList: { [key: string]: any[] } = {};
  compFilterList: string[] = [];
  flattenedFilters: { name: string; value: string; label: string }[] = new Array();
  flattenedShortcut: { name: string; value: string; label: string }[] = new Array();
  dateShortcut: { value: Date; label: string }[] = new Array();
  
  prevSetList: TxSet[] = new Array();
  prevFilter = {};
  prevCount: number = 0;
  prevDonePercentage: number = 0;

  searchText: string = '';
  doneImgLink: string = '';

  allDone: boolean = false;
  pendingOnly: boolean = true;
  aDate: Date = new Date();
  prevDate: Date = new Date();

  constructor(public sharedService: SharedService, public enums: Enums, private setService: SetService, private transService: TransactionService, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['date']) this.aDate = new Date(params['date']);
    });    
    await Promise.all([
      this.getTodayReceiptSet(),
      this.getAllTransType(),
      this.getAllTransSubType()
    ]);
    if (this.pendingOnly) this.getPendingOnly();
    this.setupTransType();
    this.setupTransSubType();
    this.setupFilterShortcut();
    this.setupDateShortcut();
    this.getPreviousPendingDate();
  }

  async refresh() {
    await this.getTodayReceiptSet();
    if (this.pendingOnly) this.getPendingOnly();
    this.setupTransType();
    this.setupTransSubType();
    this.setupFilterShortcut();
    this.getPreviousPendingDate();
    this.filterSets();
  }

  async getTodayReceiptSet() {
    this.setList = await this.setService.getAllTxSet({startDate: this.aDate, endDate: this.aDate, priority: this.enums.FIRST_PRIORITY});
    this.groupByCompany();
    this.allDone = this.setList.every(s => s.Valid !== undefined);
    if (this.allDone || this.setList.length === 0) {      
      this.doneImgLink = this.sharedService.getImage(this.enums.doneImages);
      this.getPreviousReceipt();
    } else {
      this.filteredSetList = this.setList;
      this.filteredSetList.sort((a, b) =>
        (a.Saved === true ? -1 : 1) - (b.Saved === true ? -1 : 1)
      );
    }
  }

  async getAllTransType() {
    this.allTransType = await this.transService.getAllTransactionTypes();
    this.allTransTypeDict = this.sharedService.mapToDict(this.allTransType, 'ID', 'Name');
  }

  async getAllTransSubType() {
    this.allTransSubType = await this.transService.getAllTransactionSubTypes();
    this.allTransSubTypeDict = this.sharedService.mapToDict(this.allTransSubType, 'ID', 'Name');
    this.expenseSubtypeList = this.allTransSubType.filter(x => x.Type === this.enums.EXPENSE);
    this.dlSubtypeList = this.allTransSubType.filter(x => x.Type === this.enums.EXTRAS);
  }

  async getPreviousReceipt(o: Object = {}) {
    const defaultParams = { limit: 6, pending: true, priority: this.enums.FIRST_PRIORITY }
    o = { ...o, ...defaultParams };
    this.prevFilter = o;
    this.prevSetList = await this.setService.getAllTxSet(o);
    if (this.prevSetList.length == 0) {
      this.prevSetList = await this.setService.getAllTxSet(defaultParams);
    }
    if (this.prevSetList.length > 0) {      
      this.prevCount = this.prevSetList.length;
      this.prevDonePercentage = 0;
    }

  }

  async prevRefresh(id: number) {
    this.prevSetList = this.prevSetList.filter(x => x.ID !== id);
    this.prevDonePercentage = Math.round((this.prevCount - this.prevSetList.length) / this.prevCount * 100);
    if (this.prevSetList.length === 0) {
      await this.sharedService.sleep(5000);
      this.getPreviousReceipt(this.prevFilter);
    }
  }

  setupTransType() {
    this.transTypeList = this.getTransactionDetails('Type');
    this.transTypeList.sort((a, b) => this.allTransTypeDict[a].toLowerCase().localeCompare(this.allTransTypeDict[b].toLowerCase()));
  }

  setupTransSubType() {
    const transSubtypes = this.getTransactionDetails('Subtype');
    const s = this.allTransSubType.filter(x => transSubtypes.includes(x.ID));
    this.subtypeList = this.sharedService.groupBy(s, 'Type');
  }

  getTransactionDetails(key: string) {    
    let l = this.setList.map(s => s.TransactionList.map(t => t[key]))
    .reduce((arr: number[], a) => {
      return arr.concat(a);
    }, []);
    return l.filter((v, i) => v && l.indexOf(v) === i);
  }
  
  groupByCompany() {
    const groupedItems = this.sharedService.groupBy(this.setList, 'CompanyCode');
    this.compSetList = Object.keys(groupedItems).map(key => ({
      CompanyCode: key,
      CompanyID: groupedItems[key][0].CompanyId,
      TotalCount: groupedItems[key].length,
      DoneCount: groupedItems[key].filter(x => x.Valid !== undefined).length,
    }));
    this.compSetList.sort((a, b) => (b.TotalCount - b.DoneCount) - (a.TotalCount - a.DoneCount));
  }

  searchList() {
    this.filteredSetList = this.filteredSetList.filter((set: TxSet) => (set.Name.toUpperCase().includes(this.searchText.toUpperCase())) || String(set.ID).includes(this.searchText) || set.Remark.toString().toUpperCase().includes(this.searchText.toUpperCase()));
  }

  isFiltersEmpty(filter: { [key: string]: any[] }): boolean {
    return Object.keys(filter).length === 0 || Object.values(filter).every(values => values.length === 0);
  }

  filterBy(name: string, value: any) {
    if (this.setFilterList[name] == undefined) this.setFilterList[name] = [];
    if (this.setFilterList[name].includes(value)) {
      // create new reference to update ngModel in html, splice wont update ngModel
      this.setFilterList[name] = this.setFilterList[name].filter((_, i) => i !== this.setFilterList[name].indexOf(value)); 
      if (this.setFilterList[name].length == 0) delete this.setFilterList[name];
    } else this.setFilterList[name] = [ ...this.setFilterList[name], value ];
    this.filterSets();
  }

  filterComp(compCode: string) {
    if (this.compFilterList.includes(compCode)) this.compFilterList = this.compFilterList.filter(x => x != compCode)
    else this.compFilterList.push(compCode);
    this.filterSets();
  }

  filterSets() {
    if (this.isFiltersEmpty(this.setFilterList)) {
      this.filteredSetList = this.setList; // Show all products
    } else {
      this.filteredSetList = this.setList.filter(x => this.applyDynamicFilter(x, this.setFilterList));
    }
    if (this.compFilterList.length > 0) {
      this.filteredSetList = this.filteredSetList.filter(x => this.compFilterList.includes(x.CompanyCode));
    }
    if (this.pendingOnly) this.getPendingOnly();
    if (this.searchText != "") this.searchList();
    this.flattenedFilters = this.flattenFilterList(this.setFilterList);
  }

  applyDynamicFilter(set: TxSet, filters: { [key: string]: any[] }): boolean {
    for (const key in filters) {
      const filterValues = filters[key];
      if (Array.isArray(filterValues) && filterValues.length > 0) {
        if (key.includes('.')) {
          const [mainKey, subKey] = key.split('.');
          const matches = set[mainKey]?.some(item => filterValues.includes(item[subKey]));
          if (matches) return true;
        } else if (key in set) {
          const val = set[key];
          if (this.matchesFilter(val, filterValues)) return true;
        }
      }
    }
    return false;
  }
  
  matchesFilter(value: any, filterValues: any[]): boolean {
    if (typeof value === 'string') {
      return filterValues.some(filterValue => value.toLowerCase().includes(filterValue.toLowerCase()));
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      return filterValues.includes(value);
    }
    return false;
  }

  setupFilterShortcut() {
    let shortcut : { [key: string]: any[] } = {}
    shortcut[this.TransSubTypeFilter] = this.SubtypeShortcut.filter(x => this.getTransactionDetails('Subtype').includes(x));
    this.flattenedShortcut = this.flattenFilterList(shortcut);
  }

  setupDateShortcut() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.dateShortcut = [
      { value: new Date(), label: 'Today' },
      { value: yesterday, label: 'Yesterday' },
    ]
  }

  async getPreviousPendingDate() {
    const endDate = new Date(this.aDate);
    endDate.setDate(endDate.getDate() - 1);
    this.dateShortcut = this.dateShortcut.filter(x => x.label !== 'Previous Pending');

    const a = await this.setService.getAllTxSet({priority: this.enums.FIRST_PRIORITY, limit: 1, sortASC: false, pending: true, endDate: endDate});
    if (a.length > 0) {
      const d = a[0].CreatedOn;
      d.setHours(0,0,0,0);
      this.prevDate = d;

      this.dateShortcut.push({ value: this.prevDate, label: 'Previous Pending' });
    }

  }

  flattenFilterList(filters: { [key: number]: any[] }) {
    const result: { name: string; value: string; label: string }[] = [];
    for (const key in filters) {
      const values = filters[key];
      if (values && values.length > 0) {
        const dict = (key === this.TransTypeFilter) ? this.allTransTypeDict : (key === this.TransSubTypeFilter) ? this.allTransSubTypeDict : {};
        values.forEach(value => {
            const label = dict[value] || value;
            result.push({ name: key, value, label });
        });
      }
    }
    return result;
  }

  changeDate(newDate: Date, addNoOfDay: number = 0) {
    newDate.setDate(newDate.getDate() + addNoOfDay);
    this.aDate = new Date(newDate);
    this.refresh();
  }

  isToday() {
    const today = new Date();
    return this.aDate.toDateString() === today.toDateString();
  }

  inFilter(name: string, value: any) {
    return this.setFilterList[name] && this.setFilterList[name].includes(value);
  }

  getPendingOnly() {
    this.filteredSetList = this.filteredSetList.filter(x => x.Valid === undefined);
  }

  getObjectKey(dict: { [key: number]: any[] }): string[] {
    return Object.keys(dict);
  }
}
