<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">{{actionTitle}}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"
            aria-label="Close"></button>
    </div>

    <div class="modal-body">
        <p>{{actionMessage}}</p>

        <form class="admin-form ms-2">
            <mat-form-field class="example-full-width">
                <mat-label>Admin</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="adminFC"
                    [matAutocomplete]="admin" [(ngModel)]="selectedAdmin">
                <mat-autocomplete #admin="matAutocomplete">
                    <mat-option [value]="admin.Username"
                        *ngFor="let admin of filteredAdmin |async">{{admin.Username}}</mat-option>

                </mat-autocomplete>
            </mat-form-field>
        </form>

        <br>

        <p>{{actionWarning}}</p>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()"
            [disabled]="selectedAdmin==undefined">Confirm</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>