import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Transaction } from 'src/model/transaction.model';
import { TxSet } from 'src/model/set.model';
import { Router } from '@angular/router';



interface SetTransaction {
  isChecked: boolean,
  transaction: Transaction;
}

interface SetInterface {
  isChecked: boolean,
  set: TxSet;
}

@Component({
  selector: 'app-add-receipt-set',
  templateUrl: './add-receipt-set.component.html',
  styleUrls: ['./add-receipt-set.component.css']
})
export class AddReceiptSetComponent implements OnInit {

  constructor(private router: Router, private activeModal: NgbActiveModal, public sharedService: SharedService,
    public enums: Enums, private restapi: RestApiService, private constructApi: ConstructAPI,
    private cdr: ChangeDetectorRef) { }


  @Input() setId: number;
  remark: string;
  name: string;
  @Input() transactionIDList: number[] = new Array;

  availableSetList: SetInterface[] = new Array;
  transactionList: SetTransaction[] = new Array;
  disabledSubmit: boolean = false;

  @Input() startDate: Date = new Date();
  endDate: Date = new Date();

  isChecked: boolean = false;


  ngOnInit(): void {

    this.getCompanyLatestCheckInDate();
  }

  isCheckedAll() {
    var checkedAll = true;

    for (let i = 0; i < this.transactionList.length; i++) {
      if (this.transactionList[i].isChecked == false)
        checkedAll = false;
    }

    return checkedAll;
  }

  checkAll() {
    for (let i = 0; i < this.transactionList.length; i++) {
      if (this.isChecked == true)
        this.transactionList[i].isChecked = true
      else
        this.transactionList[i].isChecked = false

    }
  }


  getCompanyLatestCheckInDate() {

    var selectedCompany = Number(sessionStorage.getItem("companyId"));

    var xhttp = this.restapi.getRequest(this.constructApi.getCompaniesLatestCheckIns(selectedCompany))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {




        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          //start date = 1 day after latest check in 
          //assume no longer have unprocessed transaction after check in
          this.startDate = new Date(Number(new Date(json.date)) + (3600 * 1000 * 24) - 1);
          this.endDate = new Date();

        }
        );


        if (this.transactionIDList.length == 0)
          this.getUnprocessedTransactions();
        else
          this.getAllSets();
      }
    };
  }

  getUnprocessedTransactions() {

    var selectedCompany = Number(sessionStorage.getItem("companyId"));
    this.transactionList.splice(0, this.transactionList.length);
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var types = [this.enums.EXTRAS, this.enums.ADVANCE, this.enums.EXPENSE, this.enums.DISCOUNT, this.enums.RESERVE]
    var xhttp = this.restapi.getRequest(this.constructApi.getAllTransactions(selectedCompany, types, startDateStr, endDateStr, 0, undefined, undefined, undefined, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          // if (json.setId == undefined) {

          var transaction = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
            json.receiptId, json.customerId, json.customerName, json.icNumber, undefined, json.subtype, json.groupId, json.setId);

          if (json.loanId == undefined) {
            if (json.type == this.enums.EXTRAS) {

              if (Number(json.amount) < 0) {
                let setTransaction = { isChecked: false, transaction: transaction };
                this.transactionList.push(setTransaction);
              }
            }
            else if (json.type == this.enums.ADVANCE) {
              if (json.subtype == this.enums.BORROW_ADV_SUBTYPE_ID) {
                let setTransaction = { isChecked: false, transaction: transaction };
                this.transactionList.push(setTransaction);
              }
            }
            else if (json.type == this.enums.RESERVE) {
              if (json.subtype == this.enums.RESERVE_EXPENSE_SUBTYPE_ID) {
                let setTransaction = { isChecked: false, transaction: transaction };
                this.transactionList.push(setTransaction);
              }
            }
            else {
              let setTransaction = { isChecked: false, transaction: transaction };
              this.transactionList.push(setTransaction);
            }
          }
          // }

        }
        );

        this.getUnprocessedLoanTransactions(selectedCompany);
      }
    };
  }

  getUnprocessedLoanTransactions(companyId: number) {
    var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.endDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.endDate == undefined) ? undefined : addADayDate.toISOString();

    var types = [this.enums.EXTRAS, this.enums.DISCOUNT]
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllLoansTransactions(companyId, startDateStr, endDateStr, undefined, types,
      undefined, undefined, undefined, undefined, 0, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          // if (json.setId == undefined) {


          var transaction = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
            json.receiptId, json.customerId, json.customerName, json.icNumber, undefined, json.subtype, json.groupId, json.setId);

          let setTransaction = { isChecked: false, transaction: transaction };
          this.transactionList.push(setTransaction);

          // }

        }
        );
        this.transactionList.sort((a, b) => (b.transaction.Type > a.transaction.Type) ? 1 : -1);
      }
    };
  }



  confirmBeforeAddingTransactionsIntoSet() {
    var proceed = false;
    var idToPay: number[] = new Array;
    var filtered = this.transactionList.filter((st: SetTransaction) => (st.isChecked == true));

    if (filtered.length == 0 && this.transactionIDList.length > 0)
      idToPay = this.transactionIDList;

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    var numberToAdd = (this.transactionIDList.length > 0) ? this.transactionIDList.length : filtered.length;
    modalRef.componentInstance.message = "You are adding " + numberToAdd + " transaction(s) to set, are you sure?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {

        if (idToPay.length == 0) {
          for (let i = 0; i < filtered.length; i++) {
            idToPay.push(filtered[i].transaction.ID);
          }
        }

        this.addTransactionsIntoSet(idToPay);
      }
    });

    return proceed;

  }


  getSelectedTransactions() {
    var transactionIds: number[] = new Array;
    for (let i = 0; i < this.transactionList.length; i++) {
      if (this.transactionList[i].isChecked == true)
        transactionIds.push(this.transactionList[i].transaction.ID);
    }
  }



  addNewSet(idsToAdd: number[]) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": this.remark,
      "name": this.name
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          window.location.reload();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }


  addTransactionsIntoSet(idsToAdd: number[]) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd
    }


    var xhr = this.restapi.putRequest(this.constructApi.getAddOrRemoveCompanySetTransactions(companyId, this.setId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          window.location.reload();
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }


  submit() {
    var idToAdd = new Array;
    var filtered = this.transactionList.filter((st: SetTransaction) => (st.isChecked == true));

    if (filtered.length > 0) {
      for (let i = 0; i < filtered.length; i++) {
        idToAdd.push(filtered[i].transaction.ID);
      }
    }
    else if (this.transactionIDList.length > 0)
      idToAdd = this.transactionIDList;

    if (this.name == "" || this.name == undefined) {
      this.sharedService.openAlert("Please fill in receipt name", this.enums.DANGER_ALERT);
    }
    else if (filtered.length == 0 && this.transactionIDList.length == 0) {
      this.sharedService.openAlert("Please select at least 1 transaction", this.enums.DANGER_ALERT);
    }
    else
      this.addNewSet(idToAdd);

  }


  getAllSets() {
    this.availableSetList.splice(0, this.availableSetList.length);
    // var startDateStr = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).toISOString();

    if (this.startDate != undefined)
      //in order to get the correct result, end date need to add 1 day and minus 1 millisecond 
      // 3600*1000*24 is 1 day in millisecond, then reduced by 1 millisecond
      var addADayDate = new Date(Number(this.startDate) + (3600 * 1000 * 24) - 1);
    var endDateStr = (this.startDate == undefined) ? undefined : addADayDate.toISOString();

    var selectedCompany = Number(sessionStorage.getItem('companyId'))
    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllSets(selectedCompany, undefined, undefined, false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var transactions = this.processSetTransactions(json.transactions);

          var set = new TxSet(json.id, json.name, json.remark, json.companyId, transactions, json.createdBy, json.createdOn, json.checkerRemark, json.valid, json.checkedBy)

          var setInt = { isChecked: false, set: set }
          this.availableSetList.push(setInt);
        }
        );
        this.availableSetList.sort((a, b) => (b.set.CreatedOn > a.set.CreatedOn) ? 1 : -1);
      }
    };
  }

  processSetTransactions(json: any) {
    var setTransactions: Transaction[] = new Array;

    json.forEach(json => {
      var transcation = new Transaction(json.id, json.remark, json.amount, undefined, undefined, json.date, json.type, json.handleBy,
        json.receiptId, json.customerId, json.customerName, json.customerIC, undefined, json.subtype, json.groupId, json.setId);


      setTransactions.push(transcation);
    }
    );
    return setTransactions;
  }


  setSelectedSet(index: number, setId: number) {

    if (this.availableSetList[index].isChecked == true) {
      this.availableSetList[index].isChecked = false;
      this.setId = undefined;
    }
    else {
      for (let i = 0; i < this.availableSetList.length; i++) {
        if (i != index)
          this.availableSetList[i].isChecked = false;
        else
          this.availableSetList[i].isChecked = true;

      }

      this.setId = setId;
    }


  }


  goToCustomer(id: number) {
    this.activeModal.close();
    this.router.navigate(['/manageCustomer', id]);
  }

  reset() {
    this.activeModal.close();
  }
}
