<div class="m-3">
    <h3>Blacklist Records</h3>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
            </mat-form-field>
        </div>
    </div>
    <div class="mat-elevation-z8 m-3">


        <table mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let record">
                    {{record.Name}}
                </td>
            </ng-container>

            <!-- ICNumber Column -->
            <ng-container matColumnDef="icNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ICNumber </th>
                <td mat-cell *matCellDef="let record">
                    {{sharedService.processICNum(record.ICNumber)}}
                </td>
            </ng-container>

            <!-- Other Id Column -->
            <ng-container matColumnDef="otherId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Other Id </th>
                <td mat-cell *matCellDef="let record">
                    {{record.OtherID}}
                </td>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let record">
                    {{record.Status}}
                </td>
            </ng-container>

            <!-- CreatedBy Column -->
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CreatedBy </th>
                <td mat-cell *matCellDef="let record">
                    {{record.CreatedBy}}
                </td>
            </ng-container>

            <!-- ReleasedBy Column -->
            <ng-container matColumnDef="releasedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ReleasedBy </th>
                <td mat-cell *matCellDef="let record">
                    @if (record.ReleasedBy!=undefined) { 
                        <h6 class="mb-0"><span class="badge text-success-emphasis bg-success-subtle border-success-subtle"> {{record.ReleasedBy}} </span></h6>
                    }
                </td>
            </ng-container>

            <!-- CreatedOn Column -->
            <!-- <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CreatedOn </th>
                <td mat-cell *matCellDef="let record">
                    {{record.CreatedOn | date:'dd-MM-yyyy'}}
                </td>
            </ng-container> -->

            <!-- Remark Column -->
            <ng-container matColumnDef="remark">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Remark </th>
                <td mat-cell *matCellDef="let record">
                    {{record.Remark }}
                </td>
            </ng-container>


            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let record">
                    <div class="d-flex align-items-baseline gap-2 my-2">
                    @if (sharedService.isBossAccount()) {
                        <button class="btn btn-warning" #tooltip="matTooltip" matTooltip="Edit" matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black" (click)="editBlacklistCustomer(record)">
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                        <button class="btn btn-danger" [disabled]="disabledSubmit" *ngIf="record.ReleasedBy!==undefined && false"
                            #tooltip="matTooltip" matTooltip="Unrelease Customer" matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black" (click)="unreleaseCustomer(record)">
                            <i class="fas fa-ban"></i>
                        </button>
                    }
                        <button class="btn btn-success" [disabled]="disabledSubmit" *ngIf="record.ReleasedBy==undefined"
                            #tooltip="matTooltip" matTooltip="Release Customer" matTooltipPosition="above" matTooltipHideDelay="100" matTooltipClass="tooltip-black" (click)="releaseCustomer(record)">
                            <i class="far fa-check-circle"></i>
                        </button>
                    </div>
                    
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[100,200,300]" [pageSize]="100" showFirstLastButtons></mat-paginator>
    </div>
</div>