import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthLog, AuthLogService } from 'src/services/auth-log-service.service';

@Component({
  selector: 'app-auth-log',
  templateUrl: './auth-log.component.html',
  styleUrl: './auth-log.component.css'
})
export class AuthLogComponent implements OnInit {

  authLogList: AuthLog[] = new Array;

  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  username: string;
  ip: string;
  reason: string;
  isFailed: boolean;
  startDate: Date;
  endDate: Date;

  @ViewChild(MatSort, { static: false }) sort: MatSort;


  constructor(private authLogService: AuthLogService) {

  }

  async ngOnInit(): Promise<void> {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.


    this.displayedColumns = ['date', "ip", "reason", "remark", "success", "userAgent", "username"];

    //initially get auth log of today
    const today = new Date();

    this.startDate = new Date(today.setHours(0, 0, 0, 0));
    this.endDate = new Date(today.setHours(23, 59, 59, 999));
    let startDateStr = this.startDate.toISOString();
    let endDateStr = this.endDate.toISOString();


    this.authLogList = await this.authLogService.getAuthLog(undefined, undefined, undefined, undefined, undefined, startDateStr, endDateStr);
    this.dataSource = new MatTableDataSource<AuthLog>(this.authLogList);
    this.dataSource.sort = this.sort;


    // this.dataSource.filterPredicate = (data: AuthLog, filter: string): boolean => {
    //   const filterValues = filter.split('|');
    //   const [nameFilter, ipFilter, reasonFilter] = filterValues;

    //   return (
    //     (nameFilter ? data.username.toLowerCase().includes(nameFilter.toLowerCase()) : true) &&
    //     (ipFilter ? data.ip.toLowerCase().includes(ipFilter.toLowerCase()) : true) &&
    //     (reasonFilter ? data.reason.toLowerCase().includes(reasonFilter.toLowerCase()) : true)
    //   );
    // };
  }



  async submitFilter() {
    this.authLogList = [];

    let startDateStr = (this.startDate == undefined) ? undefined : new Date(this.startDate.setHours(0, 0, 0, 0)).toISOString();
    let endDateStr = (this.startDate == undefined) ? undefined : new Date(this.endDate.setHours(23, 59, 59, 999)).toISOString();

    var success = (this.isFailed == true) ? !this.isFailed : undefined;
    this.authLogList = await this.authLogService.getAuthLog(this.username, this.ip, undefined, this.reason, success, startDateStr, endDateStr);
    this.dataSource = new MatTableDataSource<AuthLog>(this.authLogList);
    this.dataSource.sort = this.sort;


  }
  // applyFilter(nameFilter: string, ipFilter: string, reasonFilter: string) {
  //   const filter = `${nameFilter}|${ipFilter}|${reasonFilter}`;
  //   this.dataSource.filter = filter.trim().toLowerCase();
  // }
}

