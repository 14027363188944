import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Enums } from '../../Shared/enums';
import { SharedService } from '../../Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { EditBlacklistComponent } from 'src/app/edit-blacklist/edit-blacklist.component';
import { BlacklistService } from 'src/services/blacklist.service';
import { Blacklist } from 'src/model/blacklist';

@Component({
  selector: 'app-blacklist-customer-list',
  templateUrl: './blacklist-customer-list.component.html',
  styleUrls: ['./blacklist-customer-list.component.css']
})
export class BlacklistCustomerListComponent implements OnInit {

  constructor(public sharedService: SharedService, private enums: Enums, private blacklistService: BlacklistService) { }
  displayedColumns: string[];
  dataSource = new MatTableDataSource;
  innerWidth: any;
  disabledSubmit = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  blacklistCustList: Blacklist[] = new Array;

  ngOnInit(): void {
    if (this.sharedService.isBossAccount() || this.sharedService.isAdmin())
      this.displayedColumns = ['name', 'icNumber', 'otherId', 'remark', 'status', 'createdBy', 'releasedBy', 'action'];
    else
    this.displayedColumns = ['name', 'icNumber', 'otherId', 'status'];
    
    this.getAllBlacklistRecords();
  }

  async getAllBlacklistRecords() {
    this.blacklistCustList = await this.blacklistService.getAllBlacklist();
    this.blacklistCustList.sort((a, b) => (new Date(a.CreatedOn) > new Date(b.CreatedOn)) ? -1 : 1);

    //make customer list as the datasource
    this.dataSource = new MatTableDataSource<Blacklist>(this.blacklistCustList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editBlacklistCustomer(record: Blacklist) {
    const modalRef = this.sharedService.openModal(EditBlacklistComponent);
    modalRef.componentInstance.blacklist = record;
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });
  }

  confirmationBeforeAction(msg: string): Promise<boolean> {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = msg;
    return new Promise((resolve) => {
      modalRef.componentInstance.passEntry.subscribe((confirm) => {
        resolve(confirm);
      });
    });
  }

  // confirmBeforeReleaseCustomer(blacklist: Blacklist) {
  //   const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
  //   modalRef.componentInstance.message = "Confirm to release this customer: " + blacklist.Name + "?";
  //   //get confirmation
  //   modalRef.componentInstance.passEntry.subscribe((entry) => {
  //     var confirmation = entry;

  //     if (confirmation == true)
  //       this.releaseCustomer(blacklist.ID);
  //   });
  // }

  async releaseCustomer(blacklist: Blacklist) {
    const confirm = await this.confirmationBeforeAction(`Confirm to release this customer: ${blacklist.Name}?`);
    if (confirm) {
      const b = await this.blacklistService.getPatchReleaseCustomer(blacklist.ID);
      this.sharedService.openAlert("Successfully release customer: " + b.Name, this.enums.SUCCESS_ALERT);
      window.location.reload();
    }
  }

  async unreleaseCustomer(blacklist: Blacklist) {
    // Wait for API
    // const confirm = await this.confirmationBeforeAction(`Confirm to unrelease this customer: ${blacklist.Name}?`);
    // if (confirm) {
      // TODO: unrelease customer
    // }
  }
}
