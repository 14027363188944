import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Commission } from 'src/model/commission.model';
import { Loan } from 'src/model/loan.model';

interface LoanCommissionUser {
  companyCode: string,
  usernameList: string[]
}

@Component({
  selector: 'app-add-commission',
  templateUrl: './add-commission.component.html',
  styleUrls: ['./add-commission.component.css']
})


export class AddCommissionComponent implements OnInit {




  amount: number;
  assignee: string;
  @Input() loan: Loan;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  // usernameList: string[] = new Array;

  loanCommissionUserList: LoanCommissionUser[] = new Array;

  assignedCommisionList: Commission[] = new Array;
  disabledSubmit = false;
  previousAssignedCommission: number = 0;
  commissionLimit: number = 0;

  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restapi: RestApiService, private constructAPI: ConstructAPI,
    public sharedService: SharedService, private enums: Enums, private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    this.getAllUser();
    this.previousAssignedCommission = await this.getLoanCommissions();
    this.commissionLimit = this.sharedService.getMaxCommissionAmount(this.loan);

    console.log(this.commissionLimit)
    console.log(this.previousAssignedCommission)
  }

  getAllUser() {
    var xhttp = this.restapi.getRequest(this.constructAPI.getLoanCommissionUsers(this.loan.ID))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText))
        var userArray = json.users;
        userArray.forEach(json => {
          let user = json.username;


          var index = this.loanCommissionUserList.findIndex(commissionUser => commissionUser.companyCode == json.companyCode)

          if (index != -1)
            this.loanCommissionUserList[index].usernameList.push(user);
          else {
            var loanCommissionUser = { companyCode: json.companyCode, usernameList: [json.username] };
            this.loanCommissionUserList.push(loanCommissionUser);
          }

          // this.usernameList.push(user);
        }
        );
      }
    };
  }

  isAssignedByLoggedInUser(commission: Commission) {
    var loggedInUsername = sessionStorage.getItem("username");
    if (loggedInUsername == commission.Assignor)
      return true;

    else
      return false;
  }


  confirmBeforeDeletingCommission(commission: Commission, commIndex: number) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete the commission of " + commission.Assignee + "- RM " + commission.Amount + "?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        this.deleteCommission(commission.LoanId, commission.ID, commIndex);
      }
    });
  }

  deleteCommission(loanId: number, commissionId: number, commIndex: number) {
    var xhttp = this.restapi.deleteRequest(this.constructAPI.getDeleteLoanCommission(loanId, commissionId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        //remove the commission from list
        this.assignedCommisionList.splice(commIndex, 1);

        this.cdr.detectChanges();
      }
    };
  }


  async getLoanCommissions() {
    return new Promise<number>(resolve => {
      var xhttp = this.restapi.getRequest(this.constructAPI.getLoanCommissions(this.loan.ID))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {

          var previousAssignedCommission = 0;
          var jsonArray = JSON.parse((xhttp.responseText))
          jsonArray.forEach(json => {
            var comm = new Commission(json.id, json.loanId, json.amount, json.assignee, json.assignor, undefined, json.dateCreated, undefined, undefined, undefined, undefined, undefined, json.paid);
            this.assignedCommisionList.push(comm);

            previousAssignedCommission += json.amount;

          }
          );
          resolve(previousAssignedCommission)
        }
      }
    });

  }

  checkNull() {
    return (this.amount == undefined) || (this.assignee == undefined) || isNaN(+this.amount)
  }

  submit() {
    if (this.checkNull() == false) {
      this.assignCommission();
    }
    else
      this.sharedService.openAlert("Please fill in amount and assignee!", this.enums.DANGER_ALERT)
  }

  assignCommission() {
    this.disabledSubmit = true;

    var data = {
      "amount": this.amount,
      "loanId": this.loan.ID,
      "assignee": this.assignee,
      "dateCreated": new Date().toISOString()
    };


    var xhr = this.restapi.postRequest(this.constructAPI.getAssignCommission(), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {
          this.passEntry.emit();
          this.reset();
          this.sharedService.openAlert("Successfully added commission!", this.enums.SUCCESS_ALERT);

        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }
      }

    }
  }

  reset() {
    this.activeModal.close();
  }
}
