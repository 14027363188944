import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoanScheme } from 'src/model/loanScheme.model';
import { AccountListComponent } from './Account/account-list/account-list.component';
import { AdvanceListComponent } from './Advance/advance-list/advance-list.component';
import { AuthGuard } from './auth/auth.guard';
import { ComissionListComponent } from './Comission/comission-list/comission-list.component';
import { CompanyListComponent } from './Company/company-list/company-list.component';
import { CustomerListComponent } from './Customer/customer-list/customer-list.component';
import { ManageCustomerComponent } from './Customer/manage-customer/manage-customer.component';
import { DashboardAdminComponent } from './Dashboards/dashboard-admin/dashboard-admin.component';
import { DashboardCalendarComponent } from './Dashboards/dashboard-calendar/dashboard-calendar.component';
import { ImportNewCompanyComponent } from './Import/import-new-company/import-new-company.component';
import { ContactListComponent } from './Others/contact-list/contact-list.component';
import { FormJComponent } from './FormJ/form-j/form-j.component';
import { LoginPageComponent } from './Others/login-page/login-page.component';
import { RegisterUserComponent } from './Others/register-user/register-user.component';
import { TaskDetailsComponent } from './Others/task-details/task-details.component';
import { AssetSummaryReportComponent } from './Report/asset-summary-report/asset-summary-report.component';
import { CashBookReportComponent } from './Report/cash-book-report/cash-book-report.component';
import { DuitLebihReportComponent } from './Report/duit-lebih-report/duit-lebih-report.component';
import { ListHutangReportComponent } from './Report/list-hutang-report/list-hutang-report.component';
import { LoanSchemeReportComponent } from './Report/loan-scheme-report/loan-scheme-report.component';
import { ReportMenuComponent } from './Report/report-menu/report-menu.component';
import { SimpleReportComponent } from './Report/simple-report/simple-report.component';
import { StockReportComponent } from './Report/stock-report/stock-report.component';
import { UserListComponent } from './User/user-list/user-list.component';
import { CashBalanceReportComponent } from './Report/cash-balance-report/cash-balance-report.component';
import { MidMonthReportComponent } from './Report/mid-month-report/mid-month-report.component';
import { UsageReportComponent } from './Report/usage-report/usage-report.component';
import { DailyReportComponent } from './Report/daily-report/daily-report.component';
import { FormJReportComponent } from './Report/form-j-report/form-j-report.component';
import { CompanySummaryReportComponent } from './Report/company-summary-report/company-summary-report.component';
import { CustomerRecordReportComponent } from './Report/customer-record-report/customer-record-report.component';
import { ServerStatusPageComponent } from './Others/server-status-page/server-status-page.component';
import { OverdueEpfReportComponent } from './Report/overdue-epf-report/overdue-epf-report.component';
import { GuarantorFormComponent } from './Customer/guarantor-form/guarantor-form.component';
import { ReceiptSetsListComponent } from './Sets/receipt-sets-list/receipt-sets-list.component';
import { CompareSettlementInfoListComponent } from './Others/compare-settlement-info-list/compare-settlement-info-list.component';
import { CpOverviewComponent } from './CP/cp-overview/cp-overview.component';
import { BlacklistCustomerListComponent } from './Blacklist/blacklist-customer-list/blacklist-customer-list.component';
import { BookmarkComponent } from './Others/bookmark/bookmark.component';
import { DraftCalculatorComponent } from './LoanRequest/draft-calculator/draft-calculator.component';
import { EpfSettlementBreakdownLetterComponent } from './Loans/epf-settlement-breakdown-letter/epf-settlement-breakdown-letter.component';
import { AdminAccessManagementComponent } from './AdminAccess/admin-access-management/admin-access-management.component';
import { ReceiptTodoComponent } from './Sets/receipt-todo/receipt-todo.component';
import { AuthLogComponent } from './Others/auth-log/auth-log.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'loginPage'
  },
  {
    path: 'dashboard-admin',
    component: DashboardAdminComponent,
    canActivate: [AuthGuard],
    title: 'Admin Dashboard - RG'
  },
  {
    path: 'dashboard-calendar',
    component: DashboardCalendarComponent,
    canActivate: [AuthGuard],
    title: 'Dashboard - RG'
  },
  {
    path: 'contact',
    component: ContactListComponent,
    canActivate: [AuthGuard],
    title: 'Contact List - RG'
  },
  // {
  //   path: 'manageCustomer',
  //   component: ManageCustomerComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'manageCustomer/:customerId/:profileId',
    component: ManageCustomerComponent,
    canActivate: [AuthGuard],
    title: 'Manage Customer - RG'
  },
  {
    path: 'manageCustomer/:customerId',
    component: ManageCustomerComponent,
    canActivate: [AuthGuard],
    title: 'Manage Customer - RG'

  },
  // {
  //   path: 'customerList',
  //   component: CustomerListComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'customerList/:statusCode',
  //   component: CustomerListComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'customerList/:companyId',
    component: CustomerListComponent,
    canActivate: [AuthGuard],
    title: 'Customer List - RG'
  },


  {
    path: 'customerList/:companyId/:statusCode',
    component: CustomerListComponent,
    canActivate: [AuthGuard],
    title: 'Customer List - RG'
  },
  {
    path: 'unverfiedCustomerList/:companyId/:verified/:statusCode',
    component: CustomerListComponent,
    canActivate: [AuthGuard],
    title: 'Customer List - RG'
  }, {
    path: 'unverfiedCustomerList/:companyId/:verified',
    component: CustomerListComponent,
    canActivate: [AuthGuard],
    title: 'Customer List - RG'
  },

  {
    path: 'companyList',
    component: CompanyListComponent,
    canActivate: [AuthGuard],
    title: 'Company List - RG'
  },

  {
    path: 'userList',
    component: UserListComponent,
    canActivate: [AuthGuard],
    title: 'User List - RG'
  },
  {
    path: 'accountList',
    component: AccountListComponent,
    canActivate: [AuthGuard],
    title: 'Account List - RG'
  },
  // {
  //   path: 'taskDetails',
  //   component: TaskDetailsComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'taskDetails/:taskId/:customerId/:profileId',
    component: TaskDetailsComponent,
    canActivate: [AuthGuard],
    title: 'Tasks - RG'
  },
  {
    path: 'taskDetails/:taskId/:customerId',
    component: TaskDetailsComponent,
    canActivate: [AuthGuard],
    title: 'Tasks - RG'
  },
  {
    path: 'loginPage',
    component: LoginPageComponent,
    title: 'Login - RG'
    // canActivate: [AuthGuard]
  }, {
    path: 'register',
    component: RegisterUserComponent,
    canActivate: [AuthGuard],
    title: 'Register - RG'
  },
  {
    path: 'dashboard-admin',
    component: DashboardAdminComponent,
    canActivate: [AuthGuard],
    title: 'Admin Dashboard - RG'
  },
  {
    path: 'reportMenu',
    component: ReportMenuComponent,
    canActivate: [AuthGuard],
    title: 'Reports - RG'
  },
  {
    path: 'stockReport/:companyId/:startDate/:endDate',
    component: StockReportComponent,
    canActivate: [AuthGuard],
    title: 'Stock Report - RG'
  }, {
    path: 'advanceList/:companyId',
    component: AdvanceListComponent,
    canActivate: [AuthGuard],
    title: 'Advance List - RG'
  },
  {
    path: 'commissionList',
    component: ComissionListComponent,
    canActivate: [AuthGuard],
    title: 'Commission List - RG'
  },

  {
    path: 'simpleReport/:companyId/:reportId/:startDate/:endDate',
    component: SimpleReportComponent,
    canActivate: [AuthGuard],
    title: 'Report - RG'
  }, {
    path: 'duitLebihReport/:companyId/:startDate/:endDate',
    component: DuitLebihReportComponent,
    canActivate: [AuthGuard],
    title: 'DL Report - RG'
  }, {
    path: 'loanSchemeReport/:companyId/:scheme',
    component: LoanSchemeReportComponent,
    canActivate: [AuthGuard],
    title: 'Scheme Report - RG'
  }, {
    path: 'stockReport/:companyId',
    component: StockReportComponent,
    canActivate: [AuthGuard],
    title: 'Stock Report - RG'
  }, {
    path: 'cashBookReport/:companyId/:startDate/:endDate',
    component: CashBookReportComponent,
    canActivate: [AuthGuard],
    title: 'Cashbook - RG'
  },
  {
    path: 'dailyReport/:companyId/:startDate',
    component: DailyReportComponent,
    canActivate: [AuthGuard],
    title: 'Daily - RG'
  },
  {
    path: 'cashBookReport/:companyId',
    component: CashBookReportComponent,
    canActivate: [AuthGuard],
    title: 'Cashbook - RG'
  },
  {
    path: 'simpleReport/:companyId/:reportId',
    component: SimpleReportComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'simpleReport/:companyId/:reportId/:endDate',
    component: SimpleReportComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'cashBalanceReport/:endDate',
    component: CashBalanceReportComponent,
    canActivate: [AuthGuard],
    title: 'Cash Balance - RG'
  }, {
    path: 'formJReport/:companyId',
    component: FormJReportComponent,
    canActivate: [AuthGuard],
    title: 'Form J Report - RG'
  }, {
    path: 'overdueEPFReport/:companyId',
    component: OverdueEpfReportComponent,
    canActivate: [AuthGuard],
    title: 'Overdue EPF - RG'
  }, {
    path: 'listHutang/:companyId/:endDate',
    component: ListHutangReportComponent,
    canActivate: [AuthGuard],
    title: 'List Hutang - RG'
  }, {
    path: 'assetSummary/:companyGroupId/:startDate/:endDate',
    component: AssetSummaryReportComponent,
    canActivate: [AuthGuard],
    title: 'Asset Summary - RG'
  }, {
    path: 'usageReport',
    component: UsageReportComponent,
    canActivate: [AuthGuard],
    title: 'Usage Report - RG'
  }, {
    path: 'midMonth/:companyId',
    component: MidMonthReportComponent,
    canActivate: [AuthGuard],
    title: 'Midmonth - RG'
  }, {
    path: 'midMonth/:companyId/:endDate',
    component: MidMonthReportComponent,
    canActivate: [AuthGuard],
    title: 'Midmonth - RG'
  }, {
    path: 'import',
    component: ImportNewCompanyComponent,
    canActivate: [AuthGuard],
    title: 'Import - RG'
  }, {
    path: 'formJ/:companyId/:customerId/:formJId',
    component: FormJComponent,
    canActivate: [AuthGuard],
    title: 'Form J - RG'
  },
  {
    path: 'guarantorForm/:companyId/:customerId/:contactId',
    component: GuarantorFormComponent,
    canActivate: [AuthGuard],
    title: 'Gurantor Form - RG'
  }, {
    path: 'companiesSummaryReport',
    component: CompanySummaryReportComponent,
    canActivate: [AuthGuard],
    title: 'Companies Summary - RG'
  }
  , {
    path: 'companiesSummaryReport/:companyGroup/:endDate',
    component: CompanySummaryReportComponent,
    canActivate: [AuthGuard],
    title: 'Companies Summary - RG'
  }, {
    path: 'officeCashReport/:companyGroup/:endDate',
    component: CashBalanceReportComponent,
    canActivate: [AuthGuard],
    title: 'Office Cash - RG'
  }, {
    path: 'customerRecord/:companyId',
    component: CustomerRecordReportComponent,
    canActivate: [AuthGuard],
    title: 'Customer Record - RG'
  }
  , {
    path: 'serverStatus',
    component: ServerStatusPageComponent,
    canActivate: [AuthGuard],
    title: 'Server Status - RG'
  },
  {
    path: 'receiptList',
    component: ReceiptSetsListComponent,
    canActivate: [AuthGuard],
    title: 'Receipt List - RG'
  },
  {
    path: 'receiptTodo',
    component: ReceiptTodoComponent,
    canActivate: [AuthGuard],
    title: 'To do Receipt - RG'
  },
  {
    path: 'receiptList/:companyId',
    component: ReceiptSetsListComponent,
    canActivate: [AuthGuard],
    title: 'Receipt List - RG'
  },
  {
    path: 'receiptList/:companyId/:startDate/:endDate/:showValid/:type/:subtype/:saved',
    component: ReceiptSetsListComponent,
    canActivate: [AuthGuard],
    title: 'Receipt List - RG'
  },
  {
    path: 'receiptList/:companyId/:showValid/:type/:subtype/:saved',
    component: ReceiptSetsListComponent,
    canActivate: [AuthGuard],
    title: 'Receipt List - RG'
  },
  {
    path: 'receiptList/:companyId/:showValid',
    component: ReceiptSetsListComponent,
    canActivate: [AuthGuard],
    title: 'Receipt List - RG'
  },
  {
    path: 'settlementInfoCheck',
    component: CompareSettlementInfoListComponent,
    canActivate: [AuthGuard],
    title: 'Settlement Info Check - RG'
  },
  {
    path: 'cpReports',
    component: CpOverviewComponent,
    canActivate: [AuthGuard],
    title: 'CP - RG'
  },
  {
    path: 'blacklistRecords',
    component: BlacklistCustomerListComponent,
    canActivate: [AuthGuard],
    title: 'Blacklist - RG'
  },
  {
    path: 'bookmarks',
    component: BookmarkComponent,
    canActivate: [AuthGuard],
    title: 'Bookmarks - RG'
  },
  {
    path: 'draft-calculator',
    component: DraftCalculatorComponent,
    canActivate: [AuthGuard],
    title: 'Draft Calculator - RG'
  },
  {
    path: 'settlementBreakdown/:companyId/:customerId',
    component: EpfSettlementBreakdownLetterComponent,
    canActivate: [AuthGuard],
    title: 'Settlement Breakdown - RG'

  },
  {
    path: 'adminAccessManagement',
    component: AdminAccessManagementComponent,
    canActivate: [AuthGuard],
    title: 'Admin Access Management - RG'
  },
  {
    path: 'authLog',
    component: AuthLogComponent,
    canActivate: [AuthGuard],
    title: 'Auth Log - RG'
  }

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
