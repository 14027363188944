import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Company } from 'src/model/company.model';
import { AdminAccess, CompanyAccess, CompanyAdmins } from 'src/model/companyAccess.model';
import { User } from 'src/model/user.model';
import { AdminAccessService } from 'src/services/admin-access.service';
import { AccessActionComponent } from '../access-action/access-action.component';
import { MatPaginator } from '@angular/material/paginator';
import { AdminCompanyListComponent } from '../admin-company-list/admin-company-list.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-admin-access-table',
  templateUrl: './admin-access-table.component.html',
  styleUrl: './admin-access-table.component.css'
})
export class AdminAccessTableComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource();
  formGroup: FormGroup;

  @ViewChild(MatSort) sort: MatSort;
  @Input() companyAccessList: CompanyAccess[] = new Array();
  companyAdminList: CompanyAdmins[] = new Array();
  companyList: Company[] = new Array;
  adminList: User[] = new Array;


  adminFC = new FormControl('');
  companyFC = new FormControl('');
  filteredCompany: Observable<Company[]>;
  filteredAdmin: Observable<User[]>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private fb: FormBuilder, private _formBuilder: FormBuilder, private restApi: RestApiService, private constructApi: ConstructAPI,
    public enums: Enums, public sharedService: SharedService, private adminAccessService: AdminAccessService) { }

  async ngOnInit(): Promise<void> {


    this.companyList = await this.getCompanyList();
    this.adminList = await this.getAdminList();
    await this.getCompanies();


    this.dataSource = new MatTableDataSource(this.companyAdminList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.displayedColumns = ["companyCode", "admin1", "admin2", "admin3", "others"];

    //filter company
    this.filteredCompany = this.companyFC.valueChanges.pipe(
      startWith(''),
      map(companyCode => this._filterCompanyCodeOptions(companyCode))
    );

    //filter admin
    this.filteredAdmin = this.adminFC.valueChanges.pipe(
      startWith(''),
      map(username => this._filterAdminCodeOptions(username))
    );
  }


  private _filterCompanyCodeOptions(value: string): Company[] {
    const filterValue = value.toLowerCase();
    var filtered = this.companyList.filter(company => { return company.CompanyCode.toLowerCase().includes(filterValue) });

    return filtered;
  }

  private _filterAdminCodeOptions(value: string): User[] {
    const filterValue = value.toLowerCase();
    var filtered = this.adminList.filter(admin => { return admin.Username.toLowerCase().includes(filterValue) });

    return filtered;
  }


  getCompanyList() {
    return new Promise<Company[]>((resolve) => {
      var xhttp = this.restApi.getRequest(this.constructApi.getAllCompanies())
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse(xhttp.responseText);
          let result: Company[] = new Array();
          jsonArray.forEach((json) => {
            var company = new Company(json.id, json.name, json.address, json.companyCode, json.contactNo, json.regNo, json.group)
            result.push(company);
          });
          resolve(result);
        }
      };
    });
  }



  getAdminList() {
    return new Promise<User[]>((resolve) => {
      var xhttp = this.restApi.getRequest(this.constructApi.getAllUsersByRoles([this.enums.ADMIN], true))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse(xhttp.responseText);
          let result: User[] = new Array();
          jsonArray.forEach((json) => {
            var user = new User(json.id, json.companyId, json.companyName, json.roleId, json.roleName, json.username, undefined);
            result.push(user);
          });
          resolve(result);
        }
      };
    });
  }


  getCompanies() {
    this.companyAdminList = [];
    let companies = Array.from(
      new Set(this.companyList.map((c) => c.CompanyCode))
    );


    companies.forEach((a) => {


      let admin1 =
        this.companyAccessList.find(
          (c) => c.CompanyCode == a && c.Priority == this.enums.FIRST_PRIORITY
        ) ?? undefined;
      let admin2 =
        this.companyAccessList.find(
          (c) => c.CompanyCode == a && c.Priority == this.enums.SECOND_PRIORITY
        ) ?? undefined;
      let admin3 =
        this.companyAccessList.find(
          (c) => c.CompanyCode == a && c.Priority == this.enums.THIRD_PRIORITY
        ) ?? undefined;

      let others = this.companyAccessList.filter(access => {
        if (access.CompanyCode == a && !this.adminAccessService.isEssentialPriority(access.Priority) && !this.adminAccessService.isOnDutyPriority(access.Priority))
          return access
      })

      let companyId = this.companyAccessList.find((c) => c.CompanyCode == a).CompanyId ?? undefined;

      this.companyAdminList.push({
        companyCode: a,
        admin1: admin1 == undefined ? "" : admin1.Username,
        admin2: admin2 == undefined ? "" : admin2.Username,
        admin3: admin3 == undefined ? "" : admin3.Username,
        companyId: companyId,
        others: others

      });
    });

  }



  openChangeAccessModal(companyCode: string, priority: number) {
    const modalRef = this.sharedService.openModal(AccessActionComponent, "regularModal");
    modalRef.componentInstance.action = "ChangeAccess";
    var filtered = this.companyAccessList.filter(access => { return access.CompanyCode == companyCode && access.Priority == priority });
    // console.log(filtered)
    modalRef.componentInstance.companyAccessList = filtered;
  }

  openAssignAccessModal(companyCode: string, priority: number) {
    const modalRef = this.sharedService.openModal(AccessActionComponent, "regularModal");
    modalRef.componentInstance.action = "AddAccess";
    var filtered = this.companyAccessList.filter(access => { return access.CompanyCode == companyCode });
    modalRef.componentInstance.companyAccessList = filtered;
    modalRef.componentInstance.targetPriority = priority;

    modalRef.componentInstance.shouldReload.subscribe((reload) => {
      if (reload == true)
        window.location.reload();
    });
  }

  applyFilter(event: any) {

    const filterValue = event.option.value;
    if (filterValue != '')
      this.dataSource.filter = filterValue.trim().toLowerCase();
    else
      this.dataSource.filter = ''
  }


  openOtherPriorityModal(others: CompanyAccess[], companyCode: string) {

    var modalRef = this.sharedService.openModal(AdminCompanyListComponent);
    modalRef.componentInstance.companyAccessList = others;
    modalRef.componentInstance.companyCode = companyCode;
  }
}
