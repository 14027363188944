import { RestApiService } from 'src/app/API/restapi';
import { IAPISet, TxSet } from './../model/set.model';
import { Injectable } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { map } from 'rxjs/operators';
import { TransactionAdapter } from './transaction.service';

export type SetCount = {
  companyCode: string,
  count: number,
  id: number,
  priority: number
}

type TxSetOptions = {
  startDate?: Date,
  endDate?: Date,
  valid?: boolean,
  checked?: boolean,
  pending?: boolean,
  includePending?: boolean,
  type?: number,
  priority?: number,
  subtype?: number,
  saved?: boolean,
  limit?: number,
  sortASC?: boolean,
  companyId?: number,
  maxPriority?: number,
  minPriority?: number
}

@Injectable({
  providedIn: 'root'
})
export class SetAdapter {

  constructor(private transAdapter: TransactionAdapter) {}

  adaptCount(item: any): SetCount {
    let result: SetCount = {
      companyCode: item.companyCode,
      count: item.count,
      id: item.id,
      priority: item.priority
    }
    return result;
  }

  adapt(item: any): TxSet {
    return new TxSet(
      item.id,
      item.name,
      item.remark,
      item.companyId,
      item.transactions.map(t => this.transAdapter.adapt(t)),
      item.created_by,
      new Date(item.created_on),
      item.checker_remark,
      item.valid,
      item.checked_by,
      item.transactionIdList,
      item.companyCode,
      item.saved,
      item.setRemark,
      item.tags,
      item.priority
    )
  }

  toApiModel(set: TxSet): IAPISet {
    return {
      id: set.ID,
      name: set.Name,
      remark: set.Remark,
      companyId: set.CompanyId,
      // transactions: set.TransactionList,
      createdBy: set.CreatedBy,
      createdOn: set.CreatedOn.toISOString(),
      checkerRemark: set.CheckerRemark,
      valid: set.Valid,
      checkedBy: set.CheckedBy,
      transactionIdList: set.TransactionIdList,
      companyCode: set.CompanyCode,
      saved: set.Saved,
      setRemark: set.SetRemark,
      tags: set.Tags
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SetService {

  constructor(private api: RestApiService, private constructApi: ConstructAPI, private adapter: SetAdapter, private sharedService: SharedService) { }

  getTodayReceiptCount(o?: TxSetOptions):Promise<SetCount[]> {
    const startDate = new Date();
    startDate.setHours(0,0,0,0);
    const endDate = this.sharedService.getLastMsOfDate(startDate);
    return new Promise<SetCount[]>((resolve, reject) => {
      this.api.get<SetCount[]>(this.constructApi.getSetsCount(o?.pending, o?.valid, o?.includePending, o?.type, o?.subtype, o?.companyId, startDate.toISOString(), endDate.toISOString(), o?.priority, o?.maxPriority, o?.minPriority)).pipe(
        map(data => data.map(d => this.adapter.adaptCount(d))),
      ).subscribe({
        next: response => resolve(response),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    })
  }
  
  getAllTxSet(o?: TxSetOptions) {
    o?.startDate?.setHours(0,0,0,0);
    const endDate = (o?.endDate !== undefined) ? this.sharedService.getLastMsOfDate(o?.endDate) : undefined;
    return new Promise<TxSet[]>((resolve, reject) => {
      this.api.get<TxSet[]>(this.constructApi.getAllSets(o?.startDate?.toISOString(), endDate?.toISOString(), o?.valid, o?.checked, o?.pending, o?.includePending, o?.type, o?.priority, o?.subtype, o?.saved, o?.limit, o?.sortASC)).pipe(
        map(data => data.map(d => this.adapter.adapt(d))),
      ).subscribe({
        next: response => resolve(response),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    })
  }
}
