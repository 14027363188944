export interface AdminAccess {
    username: string;
    companyAccess: number[];
    companyCount: number;
    tempAccess: number[];
    tempAccessCount: number;
    onDutyAccess: number[];
    onDutyAccessCount: number;
}

export interface CompanyAdmins {
    companyCode: string;
    admin1: string;
    admin2: string;
    admin3: string;
    companyId: number,
    others: CompanyAccess[]
}




export class CompanyAccess {
    constructor(private companyCode: string, private companyId: number, private priority: number, private username: string) {

    }


    set CompanyCode(companyCode: string) { this.companyCode = companyCode }
    get CompanyCode(): string { return this.companyCode }

    set CompanyId(companyId: number) { this.companyId = companyId }
    get CompanyId(): number { return this.companyId }

    set Priority(priority: number) { this.priority = priority }
    get Priority(): number { return this.priority }

    set Username(username: string) { this.username = username }
    get Username(): string { return this.username }

}

