import { Injectable } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { LoanRequest } from 'src/model/loanRequest.model';
import { LoanAdapter } from './loan.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoanRequestAdapter {

  constructor(private loanAdapter: LoanAdapter) { }

  adapt(item: any): LoanRequest {
    return new LoanRequest(
      item.customerId,
      item.epfAmount,
      item.epfDate,
      item.guarantors?.map(t => this.loanAdapter.adaptContact(t)).sort((a, b) => (a.Name > b.Name) ? 1 : -1),
      item.hasIAccount,
      item.id,
      (item.loanInfo && this.loanAdapter.adapt(item.loanInfo)),
      item.processedRemark,
      item.profileId,
      item.remark,
      item.requestBy,
      item.requestOn,
      item.updatedOn,
      item.approved,
      item.loanId,
      item.companyCode,
      item.icNumber,
      item.name,
      item.companyId,
      item.processedBy,
      item.processedOn,
      item.authorizedBy,
      item.processorRoleRequired,
      item.priority,
      item.verifiedBy,
      item.verifiedOn,
      item.dob,
      item.otherId,
      undefined,
      undefined,
      undefined,
      item.sharedProfileId,
      item.loanGroupId
    );
  }
}

type loanRequestOptions = {
  endDate?: string,
  withLoan?: boolean,
  approved?: boolean,
  includeUnprocessed?: boolean,
  authorized?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LoanRequestService {

  constructor(private api: RestApiService, private constructApi: ConstructAPI, private adapter: LoanRequestAdapter) { }

  getAllLoanApprovalRequest(o?: loanRequestOptions): Promise<LoanRequest[]> {
    return new Promise<LoanRequest[]>((resolve, reject) => {
      this.api.get<LoanRequest[]>(this.constructApi.getAllLoanApprovalRequest(o?.endDate, o?.withLoan, o?.approved, o?.includeUnprocessed, o?.authorized)).pipe(
        map(data => data.map(d => this.adapter.adapt(d)))
      ).subscribe({
        next: response => resolve(response),
        error: err => {
          console.error(err);
          this.api.handleError(err);
          reject(err);
        }
      });
    });
  }

  getLoanApprovalRequestByIds(ids: number[]): Promise<LoanRequest[]> {
    return new Promise<LoanRequest[]>((resolve, reject) => {
      this.api.get<LoanRequest[]>(this.constructApi.getLoanApprovalRequestByIds(ids)).pipe(
        map(data => data.map(d => this.adapter.adapt(d)))
      ).subscribe({
        next: response => resolve(response),
        error: err => {
          console.error(err);
          this.api.handleError(err);
          reject(err);
        }
      });
    });
  }

  getLoanApprovalRequestById(id: number): Promise<LoanRequest> {
    return new Promise<LoanRequest>((resolve, reject) => {
      this.api.get<LoanRequest>(this.constructApi.getLoanApprovalRequestById(id)).pipe(
        map(data => this.adapter.adapt(data))
      ).subscribe({
        next: response => resolve(response),
        error: err => {
          if (err.status !== 404) {
            this.api.handleError(err);
            console.error(err);
          }
          reject(err);
        }
      });
    });
  }
}
