<form>
    <!-- Modal Header -->
    <div class="modal-header" style="background-color:lightskyblue;">
        <h4 class="modal-title">Manage Commission</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="card shadow mb-3">
            <div class="card-body">
                <table class="table-borderless">
                    <tbody>
                        <tr *ngIf="loan.CustomerName!=undefined">
                            <td><strong>Customer Name</strong></td>
                            <td>:</td>
                            <td class="ps-2">{{loan.CustomerName}}</td>
                        </tr>
                        <tr>
                        <tr>
                            <td><strong>Receipt Number</strong></td>
                            <td>:</td>
                            <td class="ps-2">{{loan.ReceiptNumber}}</td>
                        </tr>
                        <tr>
                            <td><strong>Principal</strong></td>
                            <td>:</td>
                            <td class="ps-2">RM {{loan.Principal}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>



        @if(previousAssignedCommission< commissionLimit){ <!--Assignee-->
            <ng-container *ngIf="loanCommissionUserList.length>0">
                <mat-form-field>
                    <mat-label>Assignee</mat-label>
                    <select matNativeControl [(ngModel)]="assignee" name="assignee">
                        <ng-container *ngFor="let companyUsers of loanCommissionUserList">
                            <optgroup [label]="companyUsers.companyCode">
                                <option [value]="user" *ngFor="let user of companyUsers.usernameList">
                                    {{user}}
                                </option>
                            </optgroup>
                        </ng-container>
                    </select>
                </mat-form-field>
            </ng-container>


            <!--Amount-->
            <div class="group  mt-2">
                <input type="number" required class="textInputLong" name="amount" [(ngModel)]="amount" min=0
                    (keypress)="sharedService.isNumberKey($event)">
                <span class="highlight"></span>
                <span class="bar barLong"></span>
                <label>Amount</label>
            </div>
            <span class="text-danger"
                [ngClass]="{'text-danger':sharedService.checkCommissionOverpay(loan,amount)==true , 'text-info': sharedService.checkCommissionOverpay(loan,amount)==false   }"
                [hidden]="amount==undefined"><strong>{{(amount/loan.Principal)*100 |number :'1.2-2'}}%
                    of
                    principal</strong></span>

            <div class="d-flex justify-content-end">
                <button class="btn btn-outline-success me-2" type="button" (click)="submit()"
                    [disabled]="disabledSubmit" *ngIf="sharedService.checkCommissionOverpay(loan,amount)==false">Assign
                    Commission</button>
                <button class="btn btn-outline-danger" type="button" (click)="reset()">Cancel</button>
            </div>

            }
            @else{
            <p class="fw-bold text-danger"> Cannot assign commission anymore </p>
            <p class="fw-bold text-danger">Assigned : RM {{previousAssignedCommission|number:'1.2-2'}} / Limit: RM
                {{commissionLimit|number:'1.2-2'}} </p>


            }

            <div>
                <hr />
                <h4>Previously Assigned Commission</h4>
                @if(assignedCommisionList!=undefined && assignedCommisionList.length>0){
                <table class="table table-boredered table-custom">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Amount (Balance/Total)</th>
                            <th>Assignee</th>
                        </tr>
                    </thead>


                    <tbody>
                        <tr *ngFor="let comm of assignedCommisionList;let i =index">
                            <td> @if(isAssignedByLoggedInUser(comm)==true){
                                <button class="btn btn-danger" (click)="confirmBeforeDeletingCommission(comm,i)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                }
                            </td>
                            <td>{{comm.DateCreated |date:'dd-MM-yyyy'}}</td>
                            <td>RM{{comm.Amount- comm.Paid}} / RM {{comm.Amount}}</td>
                            <td>{{comm.Assignee}}</td>
                        </tr>
                    </tbody>
                </table> }
                @else{
                <p class="fw-bold text-danger"> This loan do not have any assigned commission.</p>
                }
            </div>
    </div>




    <!-- 
    <div class="modal-footer">

    </div> -->

</form>