import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { GlobalSearchCustomerComponent } from 'src/app/Others/global-search-customer/global-search-customer.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { EPFLimit } from 'src/model/epfLimit.model';
import { GlobalCustomer } from 'src/model/globalCustomer.model';
import { Loan } from 'src/model/loan.model';
import { SettlementCharges } from 'src/model/settlementCharges.model';

@Component({
  selector: 'app-epf-request-calculator',
  templateUrl: './epf-request-calculator.component.html',
  styleUrls: ['./epf-request-calculator.component.css'],
  providers: [DatePipe]
})
export class EpfRequestCalculatorComponent {

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums,
    private restApi: RestApiService, private constructAPI: ConstructAPI, private datepipe: DatePipe) { }


  @Input() epfLimitRuleSet: EPFLimit[];

  epfDate: Date;
  epfAmount: number;


  loanTerm: number = 0;
  loanAmount: number = 0;
  settlementAmount: number = 0;
  interestRate: number = 0;

  processStamp = 0;
  processSc = 0;
  processDL = 0;

  isCollapsed = false;

  isExtra = false;
  MINIMUM_INTEREST = 0.05

  // previousPrincipal = 0;
  previousEPFLoans: Loan[] = new Array;
  customer: GlobalCustomer;

  cashOnHand: number;

  extraChargesCollected = 0;
  settlementCharges: SettlementCharges;
  totalDeductions = 0;

  settlementStamp: number = 0;
  settlementSC: number = 0;
  settlementExtras: number = 0;


  summaryOpenState = true;

  plannedExtraPrincipal: number = 0;
  plannedExtraSettlementCharges: number = 0;
  plannedSettlementCharges: SettlementCharges;

  maximumFirstPrincipalByOnHand: number = 0;
  maximumExtraPrincipal: number = 0;
  maximumFirstCashOnHand: number = 0;
  isPlannedExtra = false;

  calculatedBalancePlannedExtra: number = 0;


  calculateLoanAmountByCOH() {
    this.loanAmount = this.sharedService.calculateRoundedPrincipalByOnHand(this.cashOnHand);
    this.setMaximumExtraCharges();

    this.updateMaxPlannedExtraPrincipal();

    if ((this.cashOnHand == 0 || this.cashOnHand == undefined) && this.isPlannedExtra == true)
      this.resetPlannedExtra();
  }

  updateMaxPlannedExtraPrincipal() {
    var settlement = this.calculateSettlementAmountWithMinimumCharges(this.loanAmount, this.MINIMUM_INTEREST, false);
    this.calculatedBalancePlannedExtra = this.calculateMaximumExtraPrincipal(this.loanAmount, settlement, this.loanTerm);
  }


  setMaximumExtraCharges() {
    //set maximum extra charge
    this.extraChargesCollected = this.enums.MIN_SSC_PER_TERM * this.loanTerm;
    this.getSettlementCharges();
  }

  checkIsMinimumCharges(charge: Number) {
    if (charge == this.enums.MIN_SSC_PER_TERM * this.loanTerm)
      return true
    else
      return false

  }

  useFirstMaxPlan() {
    this.isPlannedExtra = true;
    this.loanAmount = this.maximumFirstPrincipalByOnHand;
    this.cashOnHand = this.maximumFirstCashOnHand;
    this.plannedExtraPrincipal = this.maximumExtraPrincipal;
    this.plannedExtraSettlementCharges = this.enums.MIN_SSC_PER_TERM * this.loanTerm;
    this.getPlannedSettlementCharges();
    this.setMaximumExtraCharges();
    this.calculatedBalancePlannedExtra = this.maximumExtraPrincipal;
  }

  checkIsMaxPlan() {

    var firstLoanSame = (this.loanAmount == this.maximumFirstPrincipalByOnHand);
    var extraLoanSame = (this.plannedExtraPrincipal == this.maximumExtraPrincipal);
    return this.isExtra == false && firstLoanSame && extraLoanSame;
  }

  getFirstPlanTMaxTotal() {
    return this.maximumFirstPrincipalByOnHand + this.maximumExtraPrincipal;
  }

  getFirstPlanMaxSettlement() {
    var firstLoanInterest = this.MINIMUM_INTEREST;
    var plannedExtraLoanInterst = this.enums.MINIMUM_EXTRA_EPF_RATE;
    return this.calculateSettlementAmountWithMinimumCharges(this.maximumFirstPrincipalByOnHand, firstLoanInterest, false) + this.calculateSettlementAmountWithMinimumCharges(this.maximumExtraPrincipal, plannedExtraLoanInterst, true)
  }

  getFirstPlanMaxOnHand() {

    var firstLoanOnHand = this.sharedService.getEPFLimitCashOnHand(this.epfDate, this.epfAmount,
      this.maximumFirstPrincipalByOnHand);
    var plannedExtraOnHand = this.maximumExtraPrincipal - this.calculateMinimumDeductionWithoutDL(this.maximumExtraPrincipal);

    return firstLoanOnHand + plannedExtraOnHand;
  }

  getUserFirstPlannedPrincipals() {
    return this.loanAmount + (this.plannedExtraPrincipal || 0)
  }

  getUserFirstPlannedSettlement() {
    var userFirstSettlement = (this.calculateTotalSettlement(this.loanAmount) + this.calculateTotalCharges() || 0);
    var extraInterestRate = this.enums.MINIMUM_EXTRA_EPF_RATE * 100;
    var userPlannedExtraSettlement = (this.sharedService.calculateEPFSettlementAmount(this.plannedExtraPrincipal, extraInterestRate, this.loanTerm) + (this.plannedExtraSettlementCharges || 0) + this.calculateHandlingCharge(true, this.plannedExtraPrincipal));

    return userFirstSettlement + userPlannedExtraSettlement;
  }

  getUserFirstPlannedOnHand() {

    var userFirstOnHand = this.sharedService.getEPFLimitCashOnHand(this.epfDate, this.epfAmount, this.loanAmount);
    var userPlannedExtraOnHand = (this.plannedExtraPrincipal > 0) ? this.plannedExtraPrincipal - this.calculateMinimumDeductionWithoutDL((this.plannedExtraPrincipal || 0)) : 0;
    return userFirstOnHand + userPlannedExtraOnHand;
  }

  calculateFirstLoanPlan() {
    var maximumFirstPrincipal = this.sharedService.getEPFLimitFirstLoan(this.epfDate, this.epfAmount, this.epfLimitRuleSet);
    this.maximumFirstCashOnHand = this.sharedService.getEPFLimitCashOnHand(this.epfDate, this.epfAmount, maximumFirstPrincipal);
    this.maximumFirstPrincipalByOnHand = this.sharedService.calculateRoundedPrincipalByOnHand(this.maximumFirstCashOnHand);

    var term = this.sharedService.getEPFTerm(this.epfDate);

    if (this.maximumFirstCashOnHand > 0) {
      var firstLoanSettlementCharges = this.enums.MIN_SSC_PER_TERM * term;
      var maximumFirstLoanSettlement = this.sharedService.calculateEPFSettlementAmount(this.maximumFirstPrincipalByOnHand, this.MINIMUM_INTEREST * 100, term) + firstLoanSettlementCharges;

      this.maximumExtraPrincipal = this.calculateMaximumExtraPrincipal(this.maximumFirstPrincipalByOnHand, maximumFirstLoanSettlement, this.loanTerm);
      this.calculatedBalancePlannedExtra = this.maximumExtraPrincipal;

    }
  }


  calculateMaximumExtraPrincipal(firstPrincipal: number, firstSettlement: number, term: number) {
    var maxExtraByLimit = this.sharedService.getEPFLimitPrincipal(this.epfDate, this.epfAmount, this.epfLimitRuleSet) - firstPrincipal;

    var settlementAfterFirstLoan = this.sharedService.getEPFLimitSettlement(this.epfDate, this.epfAmount, this.epfLimitRuleSet) - firstSettlement;
    var maxExtraBySettlement = Number(this.sharedService.calculatePrincipalBySettlementLeft(settlementAfterFirstLoan, term, true));

    return (maxExtraByLimit < maxExtraBySettlement) ? this.sharedService.calculateRoundedPrincipal(maxExtraByLimit) : this.sharedService.calculateRoundedPrincipal(maxExtraBySettlement);

  }

  calculateSettlementAmountWithMinimumCharges(principal: number, interestRate: number, isExtra: boolean) {
    if (principal > 0) {
      var minimumSettlementCharge = this.enums.MIN_SSC_PER_TERM * this.loanTerm;
      return this.sharedService.calculateEPFSettlementAmount(principal, (interestRate * 100), this.loanTerm) + minimumSettlementCharge + this.calculateHandlingCharge(isExtra, principal);
    }
    else
      return 0;
  }

  resetExtra() {
    // this.isExtra = !this.isExtra;
    this.customer = undefined;
    this.epfAmount = undefined;
    this.epfDate = undefined;
    this.loanTerm = undefined;
    this.previousEPFLoans = new Array;
    this.settlementCharges = undefined;
    this.settlementStamp = undefined;
    this.settlementSC = undefined;
    this.settlementExtras = undefined;
    this.totalDeductions = undefined;
    this.extraChargesCollected = undefined;

    this.maximumFirstPrincipalByOnHand = undefined;
    this.maximumExtraPrincipal = undefined;

  }

  getCustomerLoanInfo(customer: GlobalCustomer) {

    var status = [this.enums.ACTIVE_LOAN];
    var schemes = ["EPF"]
    this.previousEPFLoans = new Array;

    // var xhttp = this.restApi.getRequest(this.constructAPI.getLoanOfCustomer(customer.CustomerID, status, schemes, true));

    var companyId = (this.sharedService.isOperatingUser()) ? Number(sessionStorage.getItem("companyId")) : customer.CompanyId;
    // var xhttp = this.restApi.getRequest(this.constructAPI.getCompanyLoans(companyId, status, schemes, true, customer.SharedProfileId, true));
    var xhttp = this.restApi.getRequest(this.constructAPI.getLoanOfCustomer(customer.CustomerID, status, schemes, true, customer.SharedProfileId, true));
    // var xhttp = this.restApi.getRequest(this.constructAPI.getAllProfileLoans(customer.ProfileID, "EPF", status, true,true))


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));
        if (jsonArray.length == 0) {
          this.sharedService.openAlert("This profile do not have active EPF loan(s)", this.enums.DANGER_ALERT);
          this.resetExtra();
        }

        else {
          jsonArray.forEach(json => {


            var settlementCharges = (json.settlementCharges.serviceCharge == undefined || json.settlementCharges.stamp == undefined) ? undefined : new SettlementCharges(json.settlementCharges.serviceCharge, json.settlementCharges.stamp, json.settlementCharges.extras, json.settlementCharges.handlingCharge)

            var monthlyPayment = this.sharedService.calculateLoanMonthlyPayment(json.scheme, json.principal, json.principalPaid, json.term, json.interestRate);



            let loan = new Loan(json.id, json.profileId, json.principal, json.stampAmount, json.receiptNumber,
              json.status, json.dateCreated, json.companyId, json.companyName,
              json.scheme, json.interestRate, json.term, json.customerName,
              json.icNumber, json.extraInterest, json.issuer, json.loanCode, json.arrearCount,
              json.principalPaid, json.repayment, json.reserve, json.balance, monthlyPayment, json.arrearPaid, undefined, undefined, undefined,
              json.monthlyInterest, json.discount, undefined, json.processingCharge, false, json.epf,
              json.settlementStamp, json.settlementServiceCharge, json.settlementExtras, undefined, undefined, undefined, undefined,
              json.legacySettlement, undefined, settlementCharges, json.sharedCompanies)


            //add customer instance into customer list
            this.previousEPFLoans.push(loan);
          }
          );

          this.getCutsomerEPFInfo(customer.CustomerID);
        }

      }
    };

  }


  checkIfHittingRatioLimit(epfDate: Date, epfAmount: number) {
    if (this.epfDate != undefined && epfAmount != undefined) {
      var currentSettlement = this.sharedService.roundToDec((this.calculateTotalSettlement(this.loanAmount) + this.calculateTotalCharges()), 2);
      var limitMaximumSettlement = this.sharedService.roundToDec(this.sharedService.getEPFLimitSettlement(epfDate, epfAmount, this.epfLimitRuleSet), 2);


      if (currentSettlement > limitMaximumSettlement)
        return true;
    }
    return false;
  }



  getCutsomerEPFInfo(customerID: number) {
    var xhttp = this.restApi.getRequest(this.constructAPI.getCustomerEPF(customerID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        this.epfDate = new Date(json.date);
        this.epfAmount = Number(json.amount);


        if (this.epfDate < new Date()) {
          var formattedDate = this.datepipe.transform(this.epfDate, "dd-MM-yyyy");
          this.sharedService.openAlert("EPF Date already over! EPF DATE:  " + formattedDate, this.enums.DANGER_ALERT)
          this.resetExtra();
        }
        else
          this.calculateLoanTerm();
      }
    };
  }

  changeInterestRate(reset: boolean) {


    if (this.isExtra == true) {
      if (reset == true)
        this.resetExtra();
      else
        this.MINIMUM_INTEREST = this.enums.MINIMUM_EXTRA_EPF_RATE;
    }

    else {
      if (reset == true)
        this.resetExtra();
      if (this.loanTerm <= 3)
        this.MINIMUM_INTEREST = this.enums.MINIMUM_EXTRA_EPF_RATE;
      else
        this.MINIMUM_INTEREST = this.enums.MINIMUM_NEW_EPF_RATE;
    }

  }

  calculateLoanTerm() {
    this.loanTerm = this.sharedService.getEPFTerm(this.epfDate);
    this.changeInterestRate(false)
  }

  getLoanCalculatedProperties() {
    this.calculateLoanTerm();
    if (this.isExtra == false)
      this.calculateFirstLoanPlan();
  }


  calculateMinimumDeduction(principal: number, isExtra: boolean) {

    if (isExtra == true)
      return this.processStamp + this.processSc + (principal * this.enums.MINIMUM_ADS_RATE) + this.processDL + (principal * (this.enums.FIXED_SCHEME_PROCESSING_RATE / 100));
    else
      return this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC + (principal * this.enums.MINIMUM_ADS_RATE) + (this.sharedService.calculateRoundedDLByOnHand(this.cashOnHand) || 0) + (principal * (this.enums.FIXED_SCHEME_PROCESSING_RATE / 100));
  }

  calculateMinimumDeductionWithoutDL(principal: number) {
    return this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC + (principal * this.enums.MINIMUM_ADS_RATE) + (principal * (this.enums.FIXED_SCHEME_PROCESSING_RATE / 100));
  }

  calculateTotalSettlement(principal: number, userDefinedInterestRate?: number) {
    var interestRate = (userDefinedInterestRate == undefined) ? this.MINIMUM_INTEREST : userDefinedInterestRate;

    var settlement = (interestRate * principal * this.loanTerm) + principal;
    return settlement
  }

  calculateHandlingCharge(isExtra: boolean, loanAmount: number) {
    if (isExtra == true)
      return Math.floor(this.enums.MININUM_HANDLING_RATE * loanAmount * this.loanTerm);
    else
      return 0;
  }

  calculateTotalCharges() {
    if (this.isExtra == true)
      return this.settlementStamp + this.settlementSC + this.settlementExtras + this.calculateHandlingCharge(this.isExtra, this.loanAmount);
    else
      return this.enums.MIN_SSC_PER_TERM * this.loanTerm;
  }

  calculateMinimumSettlement(principal: number, userDefinedInterestRate?: number) {
    var ir = (userDefinedInterestRate == undefined) ? this.MINIMUM_INTEREST : userDefinedInterestRate;

    return this.calculateTotalSettlement(this.loanAmount, userDefinedInterestRate) + this.calculateTotalCharges();
  }


  searchCustomer() {
    const modalRef = this.sharedService.openModal(GlobalSearchCustomerComponent, "largeModal")
    modalRef.componentInstance.isGlobal = this.sharedService.isAdmin() || this.sharedService.isBossAccount();
    modalRef.componentInstance.needRedirect = false;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      this.customer = entry;
      this.getCustomerLoanInfo(this.customer);


    });
  }


  reset() {
    this.activeModal.close();
  }


  calculateSettlementCharges(isExtra: boolean, principal: number, collectedAmount: number) {

    var settlementCharges: SettlementCharges;
    if (isExtra == true)
      settlementCharges = this.sharedService.calculateAndReturnSettlementCharges(principal, collectedAmount, this.loanTerm);
    else
      settlementCharges = this.sharedService.calculateEPFFirstLoanSettlementCharges(collectedAmount, this.loanTerm);

    return settlementCharges;
  }

  getPlannedSettlementCharges() {
    this.plannedSettlementCharges = this.calculateSettlementCharges(true, this.plannedExtraPrincipal, this.plannedExtraSettlementCharges);
    // console.log(this.plannedSettlementCharges)

  }

  async getSettlementCharges() {

    this.settlementCharges = await this.calculateSettlementCharges(this.isExtra, this.loanAmount, this.extraChargesCollected);

    this.settlementStamp = this.settlementCharges.Stamp;
    this.settlementSC = this.settlementCharges.ServiceCharge;
    this.settlementExtras = (this.settlementCharges.Extras == undefined) ? 0 : this.settlementCharges.Extras;

    if (this.isExtra == true)
      this.calculateProcessDeductions();


  }

  calculateProcessDeductions() {
    this.processStamp = this.enums.MINIMUM_STAMP;
    this.processSc = this.enums.MINIMUM_SC;
  }


  resetPlannedExtra() {
    this.isPlannedExtra = !this.isPlannedExtra;
    if (this.isPlannedExtra == false) {
      this.plannedExtraPrincipal = 0;
      this.plannedExtraSettlementCharges = 0;
      this.plannedSettlementCharges = undefined;
    }
  }

  isCashOnHandOverlimit() {
    return this.cashOnHand > this.sharedService.getEPFLimitCashOnHand(this.epfDate, this.epfAmount, this.sharedService.getEPFLimitFirstLoan(this.epfDate, this.epfAmount, this.epfLimitRuleSet));
  }

  isPlannedExtraOverlimit() {
    return this.plannedExtraPrincipal > this.calculatedBalancePlannedExtra;
  }

  isPlannedSettlementChargesOverlimit() {
    var settlementDiff = (this.plannedExtraPrincipal == 0) ? 0 : this.getFirstPlanMaxSettlement() - this.getUserFirstPlannedSettlement();

    if (settlementDiff < 0)
      return true;
    else
      return false;
  }


}
