import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPlanComponent } from './AddThings/add-plan/add-plan.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth/auth.service';
import { SharedService } from './Shared/shared-service.service';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { IdleTimeoutComponent } from './Others/idle-timeout/idle-timeout.component';
import { ServerService } from 'src/services/server.service';
import { Enums } from './Shared/enums';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'theCoolestProject';
  toggled: boolean = false;
  // key: any;

  isLoggedIn: boolean = false;


  //idle timeout
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  modalRef: any;


  idleSubcription: any;
  // @HostListener('document:keydown.escape', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   this.key = event.key;

  //   // alert(this.key);
  //   if (event.keyCode == 27) {
  //     $(".modal").toggleClass('show');
  //     $(".modal-backdrop").toggleClass('show');
  //   }

  // }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    // ...
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    // this._auth.logout();
  }

  constructor(private router: Router, private _auth: AuthService, private sharedService: SharedService,
    private idle: Idle, private keepalive: Keepalive) {

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
      this.isLoggedIn = this._auth.loggedIn();
    });



    //IDLE TIMEOUT
    // sets an idle timeout of 870 seconds = 15 minutes - 30 seconds
    idle.setIdle(870);
    // sets a timeout period of 30 seconds. after 15 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idleSubcription = idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      // console.log(this.idleState);
      this.reset();
    });

    this.idleSubcription = idle.onTimeout.subscribe(() => {
      // this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // console.log(this.idleState);
      this.modalRef.close();
      // this.router.navigate(['/loginPage']);
      this._auth.logout();
    });

    this.idleSubcription = idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      // console.log(this.idleState);
      this.modalRef = this.sharedService.openModal(IdleTimeoutComponent);
      this.modalRef.componentInstance.idleResult.subscribe((result) => {

        //REFRESH TOKEN / REFRESH SESSION
        if (result == true) {
          this._auth.refreshToken();

          this.reset();
        }

        //LOGOUT
        else
          this._auth.logout();

      })
    });

    this.idleSubcription = idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Due to inactivity, you will be logged out in ' + countdown + ' seconds!'
      this.modalRef.componentInstance.message = this.idleState;
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this._auth.getUserLoggedIn().subscribe(userLoggedIn => {

      // console.log(userLoggedIn)
      if (userLoggedIn) {
        this.reset()
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
    })


    // this.reset()
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  async ngOnInit() {
    this.isLoggedIn = this._auth.loggedIn();

    if (this.isLoggedIn == false)
      this.router.navigateByUrl['loginPage']

  }


  openAddPlanModal() {
    const modalRef = this.sharedService.openModal(AddPlanComponent);

  }



  toggleMenu() {
    $('#sidebar').toggleClass('active');
  }

  toggleSidebar() {
    this.toggled = !this.toggled;
    $("#wrapper").toggleClass("toggled");
    (this.toggled) ? $('.overlay').fadeIn() : $('.overlay').fadeOut();
    $('.rotate').toggleClass('down');
  }

  ngOnDestroy() {
    this.idleSubcription.unsubscribe();
  }

}

