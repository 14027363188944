import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AdminAccess, CompanyAccess, CompanyAdmins } from 'src/model/companyAccess.model';
import { User } from 'src/model/user.model';
import { AdminAccessService } from 'src/services/admin-access.service';

@Component({
  selector: 'app-access-action',
  templateUrl: './access-action.component.html',
  styleUrl: './access-action.component.css'
})
export class AccessActionComponent implements OnInit {


  @Input() action: string = "";
  @Input() currentAdmin: AdminAccess;
  @Input() companyAccessList: CompanyAccess[] = new Array;
  @Input() companyAdmin: CompanyAdmins;
  @Input() targetPriority: number;

  @Input() revokeCompanyIdList: number[];
  @Input() currentOnDutyAdmins: string[];

  @Output() shouldReload: EventEmitter<any> = new EventEmitter();


  actionTitle: string = "";
  actionMessage: string = "";
  adminList: User[] = new Array();
  actionWarning: string = "";
  disabledSubmit = false;

  adminFC = new FormControl('');
  selectedAdmin: string;
  successMessage: string = "";
  skipMessage: string = "";
  failedMessage: string = "";

  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI,
    public sharedService: SharedService, public enums: Enums, private adminAccessService: AdminAccessService) { }

  async ngOnInit(): Promise<void> {
    this.adminList = await this.getAdminList();

    if (this.currentAdmin != undefined)
      this.setActionMessages(this.action, this.currentAdmin.username);
    else if (this.companyAdmin != undefined)
      this.setActionMessages(this.action, this.companyAdmin.admin1 || this.companyAdmin.admin2 || this.companyAdmin.admin3)
    else
      this.setActionMessages(this.action, undefined);

  }

  getAdminList() {
    return new Promise<User[]>((resolve) => {
      var xhttp = this.restApi.getRequest(this.constructApi.getAllUsersByRoles([this.enums.ADMIN]))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse(xhttp.responseText);
          let result: User[] = new Array();
          jsonArray.forEach((json) => {

            var user = new User(json.id, json.companyId, json.companyName, json.roleId, json.roleName, json.username, undefined);
            if (this.currentOnDutyAdmins != undefined) {
              if (!this.currentOnDutyAdmins.includes(user.Username))
                result.push(user);
            } else
              result.push(user);

          });
          resolve(result);
        }
      };
    });
  }

  setActionMessages(action: string, admin?: string) {

    switch (action) {
      case 'Clone':
        this.actionTitle = "Clone access right to temporary admin";
        this.actionMessage = "Clone access rights of " + admin + " as temporary access for:";
        this.actionWarning = "";
        break;
      case 'RevokeTransfer':
        this.actionTitle = "Revoke & Transfer admin access right";
        this.actionMessage = "Transfer access rights of " + admin + " to:";
        this.actionWarning = "Are you sure to revoke all access rights of " + admin;
        break;
      case 'Revoke':
        this.actionTitle = "Revoke admin access right";
        this.actionMessage = "TRevoke admin access right of " + admin;
        this.actionWarning = "Are you sure to revoke acces rights of " + admin;
        break;
      case 'RevokeDuty':
        this.actionTitle = "Revoke on duty access right";
        this.actionMessage = "";
        this.actionWarning = "Are you sure to revoke on duty access rights of " + admin;
        break;
      case 'ChangeAccess':
        this.actionTitle = "Change admin access";
        this.actionMessage = "Change admin access to:";
        this.actionWarning = "Are you sure to change the access?";
        break;
      case 'AddAccess':
        this.actionTitle = "Add admin access " + this.targetPriority;
        this.actionMessage = "Add admin access to:";
        this.actionWarning = (this.selectedAdmin == undefined) ? "" : "Are you sure to add access rights of " + this.targetPriority + " to " + this.selectedAdmin;
        break;
      case 'Duty':
        this.actionTitle = "Add duty admin";
        this.actionMessage = "Add admin as on duty admin:";
        this.actionWarning = "Are you sure to add this admin as on duty admin?";
        break;

    }
  }

  getAdmin(username: string) {
    let companyAccess = this.companyAccessList
      // .filter((c) => c.Username == username && this.enums.ADMIN_PRIORITY.includes(c.Priority))
      .filter((c) => c.Username == username && c.Priority % 10 == 0)
      .map((c) => c.CompanyId);
    let tempAccess = this.companyAccessList
      // .filter((c) => c.Username == username && this.enums.TEMP_PRIORITY.includes(c.Priority))
      .filter((c) => c.Username == username && c.Priority % 10 == 1 && c.Priority !== this.enums.ON_DUTY_PRIORITY)
      .map((c) => c.CompanyId);
    let onDutyAccess = this.companyAccessList
      .filter((c) => c.Username == username && c.Priority === this.enums.ON_DUTY_PRIORITY)
      .map((c) => c.CompanyId);
    return { username: username, companyAccess: companyAccess, companyCount: companyAccess.length, tempAccess: tempAccess, tempAccessCount: tempAccess.length, onDutyAccess: onDutyAccess, onDutyAccessCount: onDutyAccess.length };
  }



  getCompanyCode(id: number) {
    return new Promise<CompanyAccess[]>((resolve) => {
      var xhttp = this.restApi.getRequest(this.constructApi.getCompanyDetails(id))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var json = JSON.parse((xhttp.responseText));
          resolve(json.companyCode);
        }
      };
    });
  }

  async cloneOnDutyAccess(cloneFrom: string) {
    let cloneTo = this.getAdmin(this.selectedAdmin);
    let comp = this.getAdmin(cloneFrom).onDutyAccess;
    let skip = comp.filter(item => [...cloneTo.companyAccess, ...cloneTo.tempAccess, ...cloneTo.onDutyAccess].indexOf(item) >= 0);



    let shouldTransfer = comp.length - skip.length;

    let grantedComp = [];
    const res = await this.adminAccessService.grantAccessRequest(cloneTo.username, this.enums.ON_DUTY_PRIORITY, comp)
    if (res !== null) {
      grantedComp = grantedComp.concat(JSON.parse(res)['companyIds']);
    }

    let successMsg = '';
    if (grantedComp.length > 0) successMsg = `Cloned access of ${grantedComp.length} ${grantedComp.length > 0 ? 'companies' : 'company'} to ${cloneTo.username}.`;

    let skipMsg = '';
    if (skip.length > 0) {
      let skipComp = [];
      for (let s of skip) {
        skipComp.push(await this.getCompanyCode(s))
      }
      skipMsg = `Skipped ${skip.length} ${skip.length > 0 ? 'companies' : 'company'}: ${skipComp.join(', ')}`
    }

    let failedMsg = '';
    if (shouldTransfer > grantedComp.length) {
      let failedComp = [];
      let failed = comp.filter(item => skip.indexOf(item) < 0 && grantedComp.indexOf(item) < 0);
      for (let f of failed) failedComp.push(await this.getCompanyCode(f));
      failedMsg = `Failed ${failed.length} ${failed.length > 0 ? 'companies' : 'company'}: ${failedComp.join(', ')}`;
    }

  }




  async cloneTempAccess() {
    let cloneTo = this.getAdmin(this.selectedAdmin);
    let cloneFrom = this.currentAdmin.username;
    let comp = [...this.currentAdmin.companyAccess, ...this.currentAdmin.tempAccess];
    let skip = comp.filter(item => [...cloneTo.companyAccess, ...cloneTo.tempAccess].indexOf(item) >= 0);
    let shouldClone = comp.length - skip.length;


    let access = this.adminAccessService.deduceCompanyAccessMap(cloneFrom, this.companyAccessList);

    let grantedComp = [];
    for (let [k, v] of access) {
      const res = await this.adminAccessService.grantAccessRequest(cloneTo.username, (k % 10 !== 0 ? k : k + 1), v)
      if (res !== null) {
        grantedComp = grantedComp.concat(JSON.parse(res)['companyIds']);
      }
    }

    this.successMessage = '';
    if (grantedComp.length > 0) this.successMessage = `Cloned ${grantedComp.length} ${grantedComp.length > 0 ? 'companies' : 'company'} temporary access to ${cloneTo.username}.`;

    this.skipMessage = '';
    if (skip.length > 0) {
      let skipComp = [];
      for (let s of skip) {
        skipComp.push(await this.getCompanyCode(s))
      }
      this.skipMessage = `Skipped ${skip.length} ${skip.length > 0 ? 'companies' : 'company'}: ${skipComp.join(', ')}`
    }

    this.failedMessage = '';
    if (shouldClone > grantedComp.length) {
      let failedComp = [];
      let failed = comp.filter(item => skip.indexOf(item) < 0 && grantedComp.indexOf(item) < 0);
      for (let f of failed) failedComp.push(await this.getCompanyCode(f));
      this.failedMessage = `Failed ${failed.length} ${failed.length > 0 ? 'companies' : 'company'}: ${failedComp.join(', ')}`;
    }

    if (this.successMessage != "" || this.skipMessage != "" || this.failedMessage != "") {
      if (this.successMessage != "" || this.skipMessage != "")
        this.sharedService.openAlert(this.successMessage + this.skipMessage, this.enums.SUCCESS_ALERT);
      else
        this.sharedService.openAlert(this.failedMessage, this.enums.DANGER_ALERT);

      this.shouldReload.emit(true);

    }

  }


  processResultMessages() {

  }





  async submit() {
    switch (this.action) {
      case 'Clone':
        this.cloneTempAccess();
        break;
      case 'RevokeTransfer':
        let revokeAdminAccess = this.getAdmin(this.currentAdmin.username);
        let revokeAdminAccessList = this.companyAccessList.filter(access => { return access.Username == revokeAdminAccess.username })
        await this.adminAccessService.changeAccessTo(revokeAdminAccess.username, this.selectedAdmin, revokeAdminAccessList);
        await this.adminAccessService.revokeAccess(this.currentAdmin.username, revokeAdminAccess.companyAccess)
        break;
      case 'Revoke':
        await this.adminAccessService.revokeAccess(this.currentAdmin.username, this.currentAdmin.companyAccess)
        break;
      case 'ChangeAccess':
        this.adminAccessService.changeAccessTo(this.companyAccessList[0].Username, this.selectedAdmin, this.companyAccessList);
        break;
      case 'AddAccess':
        var compId = this.companyAccessList.map(access => access.CompanyId)
        const result = await this.adminAccessService.grantAccessRequest(this.selectedAdmin, this.targetPriority, compId);
        if (result != undefined)
          this.shouldReload.emit(true);
        break;
      case 'Duty':
        const dutyResult = await this.adminAccessService.assignNewOnDuty(this.selectedAdmin);
        if (dutyResult != undefined)
          this.shouldReload.emit(true);
        break;
    }
  }


  reset() {
    this.activeModal.close();

  }
}

