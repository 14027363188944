import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Transaction } from 'src/model/transaction.model';
import { Receipt } from 'src/model/loanReceipt.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DeleteReasonRemarkComponent } from 'src/app/Account/delete-reason-remark/delete-reason-remark.component';
import { Profile } from 'src/model/profile.model';
import { Customer } from 'src/model/customer.model';
import { Enums } from 'src/app/Shared/enums';
import { AddReserveGroupComponent } from '../add-reserve-group/add-reserve-group.component';
import { ReserveGroup } from 'src/model/reserveGroup.model';
import { ViewReserveGroupsComponent } from '../view-reserve-groups/view-reserve-groups.component';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';

@Component({
  selector: 'app-reserve-history',
  templateUrl: './reserve-history.component.html',
  styleUrls: ['./reserve-history.component.css']
})
export class ReserveHistoryComponent implements OnInit {

  constructor(private restApi: RestApiService, private constructApi: ConstructAPI, private activeModal: NgbActiveModal,
    public sharedService: SharedService, private cdr: ChangeDetectorRef, private enums: Enums) { }

  reserveList: Transaction[] = new Array;

  isDirty: boolean = false;

  @Input() totalReserveAmount: number;
  // @Input() selectedCustomerID: number;
  @Input() selectedProfileID: number;
  @Input() selectedProfile: Profile;

  @Input() profileList: Profile[];

  @Input() viewCustomer: Customer;

  @Input() sharedProfileId: number;

  reserveGroupList: ReserveGroup[] = new Array;

  ngOnInit(): void {
    this.getAllTransactionsOfReserve();
  }

  getAllTransactionsOfReserve() {

    var xhttp = this.restApi.getRequest(this.constructApi.getProfileReserveReceipts(this.selectedProfileID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var receiptArray = JSON.parse((xhttp.responseText));

        receiptArray.forEach(receipt => {

          var transactionArray = JSON.parse(JSON.stringify(receipt.transactions));

          transactionArray.forEach(transaction => {
            var record = new Transaction(transaction.id, transaction.remark, transaction.amount,
              transaction.closingBalance, transaction.accountId, transaction.date, transaction.type, transaction.handleBy,
              undefined, undefined, undefined, undefined, undefined, undefined, transaction.groupId, transaction.setId)

            this.reserveList.push(record);
          });

          // var receiptObj = new Receipt(receipt.id, receipt.number, loanTransactionList);
          // this.receiptList.push(receiptObj);
        }
        );


        //if there is shared profile, check whether there's any reserve group
        if (this.sharedProfileId != undefined)
          this.getAllProfileReserveGroups();
      }
    };

  }


  calculateTotalReserve(reserve: Transaction[]) {
    var sumOfReserve: number = 0;
    for (let i = 0; i < reserve.length; i++) {
      sumOfReserve += reserve[i].Amount;
    }
    return sumOfReserve;

  }

  checkAmountInOut(amount: number) {
    if (amount < 0)
      return 'negative';
    else
      return 'positive';
  }

  confirmDeleteTranscation(reserve: Transaction) {

    var timeDiff = new Date().getTime() - new Date(reserve.Date).getTime();
    var dateDiff = timeDiff / (1000 * 3600 * 24);
    if (dateDiff < 90 || this.sharedService.isBossAccount() == true) {
      const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
      modalRef.componentInstance.message = "Are you sure you want to delete SIMPAN RM " + reserve.Amount + "?";
      //get confirmation
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        var confirmation = entry;
        if (confirmation == true) {


          const modalRef = this.sharedService.openModal(DeleteReasonRemarkComponent);
          modalRef.componentInstance.transaction = reserve;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var remark = entry;

            //append added account to account list
            this.deleteTransaction(remark, reserve.ID);
          });

        }

      });
    }
    else
      this.sharedService.openAlert("This transaction is too old, please contact your admin to delete", this.enums.DANGER_ALERT)


  }

  deleteTransaction(remark: string, transactionID: number) {
    var data =
    {
      "reason": remark
    };


    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteReserveTransaction(transactionID), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            this.removeTransctionFromList(transactionID);
            this.isDirty = true;
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }

  }


  removeTransctionFromList(transactionId: number) {

    for (let i = 0; i < this.reserveList.length; i++) {
      if (Number(this.reserveList[i].ID) == transactionId) {
        this.reserveList.splice(i, 1);
        this.cdr.detectChanges();

        if (this.reserveList.length == 0)
          window.location.reload();
      }
    }
    return -1
  }


  reset() {

    if (this.isDirty == true) {
      window.location.reload();
    }
    else
      this.activeModal.close();

  }


  openReserveGroupModal() {
    const modalRef = this.sharedService.openModal(AddReserveGroupComponent, "regularModal");

    modalRef.componentInstance.sharedProfileId = this.sharedProfileId;
    modalRef.componentInstance.selectedProfileId = this.selectedProfileID;
  }

  getAllProfileReserveGroups() {
    this.reserveGroupList = [];
    var xhttp = this.restApi.getRequest(this.constructApi.getAllProfileReserveGroups(this.selectedProfileID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var rgArray = JSON.parse((xhttp.responseText));

        rgArray.forEach(rg => {
          var group = new ReserveGroup(undefined, rg.id, rg.requestedBy, rg.requestedOn, rg.sharedProfileId, rg.accepted, rg.total)
          this.reserveGroupList.push(group);
        }
        );
      }
    };
  }

  openReserveGroupDetailsModal(rgID: number) {
    const modalRef = this.sharedService.openModal(ViewReserveGroupsComponent, "regularModal");
    modalRef.componentInstance.reserveGroupId = rgID;
  }


  openDeleteReserveGroupDialog(rg: ReserveGroup) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete SIMPAN GROUP  " + rg.ID + "?";
    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;
      if (confirmation == true) {

        this.deleteReserveGroup(rg.ID);
      }

    });
  }

  openSetDialog(setId: number) {
    var modalRef = this.sharedService.openModal(ReceiptDetailsComponent);
    modalRef.componentInstance.receiptId = setId;
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });
  }

  deleteReserveGroup(id: number) {

    var xhr = this.restApi.deleteRequest(this.constructApi.getDeleteReserveGroup(id));
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          window.location.reload();
        }
        else {
          this.sharedService.openErrorMessage(xhr);
        }


      }

    }


  }

}
