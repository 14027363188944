import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Account } from 'src/model/account.model';
import { Customer } from 'src/model/customer.model';
import { Profile } from 'src/model/profile.model';

@Component({
  selector: 'app-expense-simpan',
  templateUrl: './expense-simpan.component.html',
  styleUrls: ['./expense-simpan.component.css']
})
export class ExpenseSimpanComponent implements OnInit {

  @Input() totalReserveAmount: number;
  @Input() selectedProfileID: number;
  @Input() viewCustomer: Customer;
  @Input() selectedProfile: Profile;

  withdrawAmount: number;
  selectedAccountId: number;
  companyBankAccountList: Account[] = new Array;
  cashAccountList: Account[] = new Array;

  paymentDate: Date = new Date();
  disabledSubmit = false;
  confirmationIC: string;
  confirmationUsername: string;
  remark: string;

  addSimpanExpenseSetId: number;


  constructor(private activeModal: NgbActiveModal, private restapi: RestApiService, private constructApi: ConstructAPI, public sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    var companyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(companyId);
  }
  checkSimpanLeftEnough() {
    if (this.calculateReserveLeft() < 0)
      return false;
    else
      return true;
  }

  private getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restapi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else if (account.Username == loggedInUsername)
            this.cashAccountList.push(account);

        }
        );

      }
    };

  }

  checkError() {

    var error = false;
    if (this.withdrawAmount == undefined) {
      this.sharedService.openAlert("Please fill in the return amount", this.enums.DANGER_ALERT);
      error = true;
    }
    else if (this.withdrawAmount > this.totalReserveAmount) {
      this.sharedService.openAlert("Return amount cannot be more than total simpan amount", this.enums.DANGER_ALERT);
      error = true;
    }

    if (this.confirmationIC == undefined || this.confirmationIC != this.viewCustomer.ICNumber) {
      this.sharedService.openAlert("Please fill in correct IC Number", this.enums.DANGER_ALERT);
      error = true;
    }

    var username = sessionStorage.getItem("username");
    if (this.confirmationUsername == undefined || this.confirmationUsername != username) {
      this.sharedService.openAlert("Please fill in correct username", this.enums.DANGER_ALERT);
      error = true;
    }

    if (this.selectedAccountId == undefined) {
      this.sharedService.openAlert("Please select an account", this.enums.DANGER_ALERT);
      error = true;
    }

    if (this.remark == undefined) {
      this.sharedService.openAlert("Please fill in remark", this.enums.DANGER_ALERT);
      error = true;
    }

    return error;
  }

  submit() {
    if (this.checkError() == false)
      this.expenseReserve();
  }

  expenseReserve() {
    this.disabledSubmit = true;

    var data = {
      "accountId": Number(this.selectedAccountId),
      "dateCreated": this.paymentDate,
      "reserve": -this.withdrawAmount,
      "remark": "Return simpan to customer = " + this.remark
    };

    var xhr = this.restapi.postRequest(this.constructApi.getExpenseReserve(this.selectedProfileID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          
          this.activeModal.close();
          var addedSimpanTransactionId = Number(json.id);
          this.addSimpanExpenseSet([addedSimpanTransactionId]);

        }
        else
          this.sharedService.openErrorMessage(xhr);
      }

    }
  }


  addSimpanExpenseSet(idsToAdd: number[]) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": "SIMPAN EXPENSE FROM CUSTOMER " + this.viewCustomer.Name,
      "name": "SIMPAN EXPENSE"
    }


    var xhr = this.restapi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          this.addSimpanExpenseSetId = Number(json.id);
        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

        this.coontinueSimpanExpenseAction();
      }

    }
  }


  coontinueSimpanExpenseAction() {

    if (this.addSimpanExpenseSetId != undefined) {
      var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "largeModal");
      modalRef.componentInstance.receiptId = Number(this.addSimpanExpenseSetId);
      modalRef.componentInstance.isModal = true;
      modalRef.componentInstance.passEntry.subscribe((entry) => {
        window.location.reload();
      });
    }
    else
      window.location.reload();

    this.sharedService.openAlert("Successfully return simpan!", this.enums.SUCCESS_ALERT);

  }
  isBalanceInsufficient(accountBalance: number) {
    if (accountBalance < this.withdrawAmount)
      return true;
    else
      return false
  }

  calculateReserveLeft() {
    return this.totalReserveAmount - this.withdrawAmount;
  }

  close() {
    this.activeModal.close();
  }
}
