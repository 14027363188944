<div class="modal-header">
    <h4 class="modal-title">Access List - {{adminName}} {{companyCode}} </h4>
    <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"
        aria-label="Close"></button>
</div>

<div class="modal-body">
    <h4>Normal Access</h4>
    @if(permanentAccessList!=undefined && permanentAccessList.length>0)
    {
    <table class="table table-responsive table-custom">
        <thead>
            <tr>
                <th *ngIf="adminName!=undefined">Company</th>
                <th *ngIf="adminName==undefined">Admin</th>
                <th>Priority</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let access of permanentAccessList;let i =index">
                <td *ngIf="adminName!=undefined">{{access.CompanyCode}}</td>
                <td *ngIf="adminName==undefined">{{access.Username}}</td>
                <td>{{access.Priority}}</td>
                <td> <i class="fas fa-exchange-alt" style="cursor:pointer;color:#878787"
                        (click)="openChangeAccessModal(access)"></i>
                </td>
            </tr>
        </tbody>
    </table>

    } @else{
    <span>You do not have any essential company access now</span>
    }

    <hr />
    <h4>Temporary Access</h4>
    @if(temporaryAccessList!=undefined && temporaryAccessList.length>0)
    {

    <table class="table table-responsive table-custom">
        <thead>
            <tr>
                <th *ngIf="adminName!=undefined">Company</th>
                <th *ngIf="adminName==undefined">Admin</th>
                <th>Priority</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let access of temporaryAccessList;let i =index">
                <td *ngIf="adminName!=undefined">{{access.CompanyCode}}</td>
                <td *ngIf="adminName==undefined">{{access.Username}}</td>
                <td>{{access.Priority}}</td>
                <td>
                    <i class="fas fa-times" style="cursor:pointer;color:#878787"
                        (click)="openRevokePriorityModal(access,i)"></i>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="d-grid gap-2">
        <button class="btn btn-outline-danger" (click)="openRevokeTemporaryAccessModal()"><i class="fas fa-exclamation-triangle mr-2" ></i> Revoke all
            temporary access</button>
    </div>

    }@else{
    <span>You do not have any temporary company access now</span>
    }

</div>