<h3 class="text-start" id="pageTitle" style="margin: 15px;">Commission List</h3>
<div class="content m-3">
    <!-- <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter searching criteria">
    </mat-form-field> -->

    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link active" role="tab" data-bs-toggle="tab" href="#tabPayCommission">
                <p>Pay Commission</p>
            </a>
        </li>

        <li class="nav-item" (click)="getAllCommission()">
            <a class="nav-link " role="tab" data-bs-toggle="tab" href="#tabAssignCommission">
                <p>Assign Commission</p>

            </a>
        </li>

    </ul>

    <div class="tab-content">
        <div class="tab-pane " role="tabpanel" id="tabAssignCommission">
            <div *ngIf="commissionList.length>0">
                <app-assign-commission [commissionList]="commissionList"></app-assign-commission>
            </div>



        </div>
        <div class="tab-pane active" role="tabpanel" id="tabPayCommission">

            <ng-container *ngIf="unpaidStaffCommissionList.length==0;else unpaidStaffDatas">
                <p class="m-2">You do not have any unpaid commission in the list</p>
            </ng-container>

            <ng-template #unpaidStaffDatas>
                <div class="row">
                    <div class="col d-flex justify-content-end align-items-end m-1">
                        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">Show Details</button>
                    </div>
                </div>
                <ng-container *ngFor="let unpaidCommission of unpaidStaffCommissionList;let i=index">
                    <div class="card shadow m-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row">
                                        <div class="col-4">
                                            <p class="fw-bold">{{unpaidCommission.StaffUsername}}</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="fw-bold text-info">Total: RM
                                                {{calculateUnpaidAmount(unpaidCommission.UnpaidComissionList)}} |
                                                <span class="text-success"> Balance: RM
                                                    {{calculateBalanceAmount(unpaidCommission.UnpaidComissionList)||0}}
                                                </span>
                                            </p>

                                        </div>
                                        <div class="col-2">
                                            <button class="btn btn-primary"
                                                (click)="fillInAllPayment(unpaidCommission.StaffUsername)"
                                                *ngIf="isCollapsed==false">Pay All</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                <div class="card mt-2" style="border:1px purple solid">
                                    <div class="card-body">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Customer</th>
                                                    <th>Code</th>
                                                    <th>Principal</th>
                                                    <th>Commission</th>
                                                    <th>Balance</th>
                                                    <th>Rate</th>
                                                    <th>Payment</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let commission of unpaidCommission.UnpaidComissionList;let j =index">
                                                <tr *ngIf="commission!=undefined">
                                                    <td>
                                                        <!-- <button class="btn btn-warning"
                                                            (click)="openEditCommissionModal(commission)">
                                                            <i class="fas fa-pencil-alt"></i>
                                                        </button> -->
                                                        @if(isAssignedByLoggedInUser(commission)==true){
                                                        <button class="btn btn-danger"
                                                            (click)="confirmBeforeDeletingCommission(commission,i,j)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                        }

                                                    </td>
                                                    <td> {{commission.CustomerName}}</td>
                                                    <td> {{commission.CustomerCode}}</td>
                                                    <td> RM {{commission.Principal}}</td>
                                                    <td>
                                                        <ng-template #tooltipContent>
                                                            <div>
                                                                <span>Assigned by: {{commission.Assignor}}</span>
                                                            </div>
                                                        </ng-template>
                                                        RM {{commission.Amount}}
                                                        <i class="far fa-question-circle ms-2" placement="top"
                                                            [ngbTooltip]="tooltipContent" style="cursor:pointer;"></i>
                                                    </td>
                                                    <td> RM {{commission.Amount - commission.Paid}}</td>
                                                    <td> {{((commission.Amount/commission.Principal)*100) |
                                                        number:'1.0-2'}}%
                                                    </td>
                                                    <td>
                                                        <input type="number" required class="textInputShort"
                                                            name="Payment" [(ngModel)]="commission.PaymentAmount"
                                                            placeholder="Payment">
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th class="text-end">Total: </th>
                                                    <th> RM
                                                        {{calculatePaymentAmount(unpaidCommission.UnpaidComissionList)||0}}
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <div class="row"
                                            *ngIf="calculatePaymentAmount(unpaidCommission.UnpaidComissionList) >0">
                                            <div class="col  d-flex justify-content-end align-items-end"> <button
                                                    class="btn btn-success"
                                                    (click)="submitPayment(unpaidCommission)">Pay</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </ng-container>
            </ng-template>


        </div>
    </div>




</div>