<div class="p-4 h-100">
    <div class="d-flex mb-2">
        <div class="input-group w-auto rounded me-1" style="background-color: #f5f5f5;">
            <input type="text" placeholder="Ticket No." class="border-0 form-control bg-transparent" [(ngModel)]="searchText"
                (keypress)="sharedService.isNumberKey($event)" (keyup.enter)="addBookmark(false)">
            <button class="btn text-secondary border-0" [ngClass]="{'disabled' : searchText===''}" type="button" (click)="searchText='';resetFilter()"><i class="fas fa-times"></i></button>
        </div>
        <button class="btn btn-primary" type="button" (click)="applyFilter()" *ngIf="bookmarks.length>0"><i class="fas fa-search"></i></button>
        <button class="btn btn-warning mx-1" type="button" (click)="addBookmark(false)"><i class="fas fa-bookmark"></i></button>
        <button class="btn btn-light" type="button" (click)="addBookmark()"><i class="fas fa-search-plus"></i></button>
    </div>
    <div class="d-grid grid-800">
        <app-bookmark-item [adminAccess]="companyAccess" [searching]="searching" [approved]="true" [bookmarkList]="getBookmarkListByStatus([true])" (itemRemoved)="removeBookmark($event)"></app-bookmark-item>
        <div class="opacity-80">
            <app-bookmark-item [adminAccess]="companyAccess" [searching]="searching" [approved]="false" [bookmarkList]="getBookmarkListByStatus([false, undefined])" (itemRemoved)="removeBookmark($event)"></app-bookmark-item>
            <div class="mt-4" *ngIf="deletedTickets.length > 0">
                <div class="d-flex gap-3 align-items-center mb-2">
                    <h3 class="fw-bold">Deleted Tickets</h3>
                    <button *ngIf="!searching" class="btn btn-outline-danger" (click)="removeBookmark(deletedTickets)"><i class="fas fa-trash-alt"></i> Clear All</button>
                </div>
                <div class="d-flex gap-3 flex-wrap">
                    <div class="text-secondary" *ngIf="filteredDeletedTickets.length == 0">
                        No search result.
                    </div>
                    <div class="card bg-light" *ngFor="let item of filteredDeletedTickets">
                        <div class="card-body d-flex justify-content-between align-items-center gap-3">
                            <h4 class="mb-0">
                                <strong>Ticket #{{item}}</strong>
                            </h4>
                            <i class="mb-0 text-secondary fas fa-times pointer" style="font-size: 1.25rem;" (click)="removeBookmark(item)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>