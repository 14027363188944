import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Loan } from 'src/model/loan.model';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Account } from 'src/model/account.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AddSimpanComponent } from 'src/app/Reserve/add-simpan/add-simpan.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ReceiptDetailsComponent } from 'src/app/Sets/receipt-details/receipt-details.component';

@Component({
  selector: 'app-loan-reserve',
  templateUrl: './loan-reserve.component.html',
  styleUrls: ['./loan-reserve.component.css']
})
export class LoanReserveComponent implements OnInit {

  @Input() selectedLoan: Loan;
  @Input() paymentAmount: number;



  //reserveOption
  reserveOption: number;

  //2 way binding vars
  principalAmount: number = 0;
  duitLebihAmount: number = 0;
  // interestChargeAmount: number = 0;
  interestChargeAmount: number = 0;


  companyAccountList: Account[] = new Array;
  selectedAccountId: number;
  stampAmount: number = 0;
  scAmount: number = 0;
  loggedInCompanyId: number;

  isPayPrincipal: boolean = false;
  isReserveNegative: boolean = false;

  paymentDate: Date = new Date();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isDirty: boolean = false;

  disabledSubmit = false;
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI,
    public enums: Enums, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.loggedInCompanyId = Number(sessionStorage.getItem('companyId'));
    this.getAllAccountsOfCompany(this.loggedInCompanyId);


    if (this.selectedLoan.Status == this.enums.SETTLED_LOAN)
      this.reserveOption = this.enums.WITHDRAW_INTEREST_CHARGE;

    else if (this.selectedLoan.Scheme == "HUTANG")
      this.reserveOption = this.enums.PAY_PRINCIPAL;


    else
      this.reserveOption = this.enums.PAY_MONTHLY;
    // this.calculateStampAndServiceCharge(this.paymentAmount);

  }

  isCP(loanStatus: number) {
    if (Number(loanStatus) == Number(this.enums.CP_LOAN))
      return true;
    else
      return false;

  }

  changeReserveOption(option: number) {
    this.reserveOption = option;
    this.stampAmount = 0;
    this.scAmount = 0;
    this.duitLebihAmount = 0;
  }


  topUpSimpan() {
    var modalRef = this.sharedService.openModal(AddSimpanComponent);
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.selectedProfileID = this.selectedLoan.ProfileId;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var addedSimpan = entry;

      this.selectedLoan.Reserve += addedSimpan;
      this.isDirty = true;
    });
  }

  isSchemeA() {

    if (this.selectedLoan.Scheme == "A")
      return true;
    else
      return false;
  }

  setActive(type: string) {
    if (this.selectedLoan.Status == this.enums.SETTLED_LOAN) {
      if (type == 'InterestCharge') {
        return 'active';
      }
      else
        return ''
    }
    else if (this.selectedLoan.Scheme == "HUTANG") {

      if (type == 'Principal') {
        return 'active';
      }
      else
        return ''

    } else {
      if (type == 'Monthly') {
        return 'active';
      }

      else
        return ''
    }

  }

  private getAllAccountsOfCompany(companyId: number) {

    var xhttp = this.restApi.getRequest(this.constructApi.getAccountsOfCompany(companyId));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          let account = new Account(json.id, json.number, json.name, json.holderName, json.balance, json.companyId, json.username);
          var loggedInUser = sessionStorage.getItem("username");
          this.companyAccountList.push(account);
        }
        );

      }
    };

  }

  setIsPayPrincipal(principal: boolean) {
    this.isPayPrincipal = principal;
    if (principal == true)
      this.reserveOption = this.enums.PAY_PRINCIPAL
    else
      this.reserveOption = this.enums.PAY_MONTHLY
  }


  setSelectedAccount(accountId: number) {
    if (accountId === this.selectedAccountId)
      return true
    else
      return false;
  }

  // getDefaultMonthlyPayment() {
  //   var defaultMonthly = 0;

  //   if (this.selectedLoan.Scheme == 'A') {
  //     defaultMonthly = this.selectedLoan.InterestRate / 100 * this.selectedLoan.Principal;
  //   } else {
  //     defaultMonthly = this.selectedLoan.InterestRate / 100 * this.selectedLoan.Principal + (this.selectedLoan.Principal / this.selectedLoan.Term);
  //   }

  //   return this.roundTo2Dec(defaultMonthly);

  // }

  roundTo2Dec(num: number) {
    return Math.round(num * 100) / 100
  }


  assignPrincipalAndCharges(assignAmount: number) {
    this.principalAmount = assignAmount;
    //REMEMBER TO ADD THIS BACK
    // this.calculateStampAndServiceCharge(this.principalAmount);
  }

  calculateStampAndServiceCharge(amount: number) {
    if (this.selectedLoan.Scheme == "A") {
      if (amount > 2000) {
        this.stampAmount = 20;
        this.scAmount = 6
      }
      else if (amount > 500) {
        this.stampAmount = 10;
        this.scAmount = 3;
      } else if (amount >= 50) {
        this.stampAmount = 3;
        this.scAmount = 5;
      }
      else {
        this.stampAmount = 0;
        this.scAmount = 0;
      }
    } else if (this.selectedLoan.Scheme == "P") {
      this.stampAmount = 20;
      this.scAmount = 6;
    } else {
      this.stampAmount = 10;
      this.scAmount = 0;
    }
  }

  checkPaymentFieldValid() {
    var errorMsg = "";

    if (this.selectedAccountId == undefined) {
      errorMsg += "Please select account!\n"
    }

    if (this.reserveOption != this.enums.WITHDRAW_INTEREST_CHARGE) {

      //STAMP AND SC LIMIT ONLY RESTRICT NON CP LOAN
      if (this.selectedLoan.Status != this.enums.CP_LOAN) {
        if (this.stampAmount > this.enums.MAXIMUM_STAMP_NORMAL_SCHEME)
          errorMsg += "Stamp Amount cannot more than " + this.enums.MAXIMUM_STAMP_NORMAL_SCHEME + "!\n"

        if (this.scAmount > this.enums.MAXIMUM_SC_NORMAL_SCHEME)
          errorMsg += "SC Amount cannot more than " + this.enums.MAXIMUM_SC_NORMAL_SCHEME + "!\n"
      }

      if (this.reserveOption == this.enums.PAY_MONTHLY) {
        if (this.stampAmount + this.scAmount + this.duitLebihAmount + this.paymentAmount > this.selectedLoan.Reserve) {
          errorMsg += "Payment amount more than simpan amount!\n"
        }
      }


      else if (this.reserveOption == this.enums.PAY_PRINCIPAL) {
        if (this.stampAmount + this.scAmount + this.duitLebihAmount + this.principalAmount > this.selectedLoan.Reserve) {
          errorMsg += "Payment amount more than simpan amount!\n"
        } else if (this.principalAmount == 0 || this.principalAmount == undefined) {
          errorMsg += "Please fill in your principal amount!\n";
        }

        else if (this.principalAmount > this.selectedLoan.Balance)
          errorMsg += "Payment amount more than loan balance!\n";

      }
    }
    else {
      if (this.interestChargeAmount == undefined || this.interestChargeAmount == 0)
        errorMsg += "Please fill in your interest charge amount!\n";

      //STAMP AND SC LIMIT ONLY RESTRICT NON CP LOAN
      if (this.selectedLoan.Status != this.enums.CP_LOAN) {

        // make sure total charges not more than interest charge
        if (this.stampAmount + this.scAmount >= this.interestChargeAmount) {
          errorMsg += "Stamp + SC Charge cannot more than Interest Charge amount!\n"
        }
        else {

          // if total charges lesser than interest charge
          // make sure total charges follow defined ratio of charges 
          var stampRatio = this.enums.MINIMUM_STAMP / (this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC);
          var scRatio = this.enums.MINIMUM_SC / (this.enums.MINIMUM_STAMP + this.enums.MINIMUM_SC);
          var totalCharge = this.stampAmount + this.scAmount;

          if (this.stampAmount > totalCharge * stampRatio)
            errorMsg += "Stamp Amount cannot more than RM" + this.sharedService.roundTo2Dec(totalCharge * stampRatio) + "!\n"
          if (this.scAmount > totalCharge * scRatio)
            errorMsg += "SC Amount cannot more than RM " + this.sharedService.roundTo2Dec(totalCharge * scRatio) + "!\n"
        }

      }

    }


    return errorMsg;
  }

  checkSimpanLeftEnough() {
    switch (this.reserveOption) {
      case this.enums.PAY_MONTHLY:
        if (this.sharedService.roundTo2Dec(this.calculateReserveLeft(this.paymentAmount)) < 0)
          return false;
        break;
      case this.enums.PAY_PRINCIPAL:
        if (this.sharedService.roundTo2Dec(this.calculateReserveLeft(this.principalAmount)) < 0)
          return false;
        break;
      case this.enums.WITHDRAW_INTEREST_CHARGE:
        if (this.sharedService.roundTo2Dec(this.calculateReserveLeft(this.interestChargeAmount)) < 0)
          return false;
        break;
    }
    return true;
  }

  checkPrincipalEmpty() {
    if (this.principalAmount == 0 || this.principalAmount == undefined) {
      this.stampAmount = 0;
      this.scAmount = 0;
      this.duitLebihAmount = 0;
    }
  }

  checkInterstChargeEmpty() {
    if (this.interestChargeAmount == 0 || this.interestChargeAmount == undefined) {
      this.stampAmount = 0;
      this.scAmount = 0;
    }
  }

  submit() {
    var errorMsg = this.checkPaymentFieldValid();

    if (errorMsg == "") {
      if (this.reserveOption == this.enums.PAY_PRINCIPAL || this.reserveOption == this.enums.PAY_MONTHLY)
        this.payWithReserve();

      else if (this.reserveOption == this.enums.WITHDRAW_INTEREST_CHARGE) {

        //if loan is still NOT SETTLED,and interest charge entered is more than loan monthly interest amount, need remind user
        // make sure remind user to do monthly payment first if got sufficient money instead of straight pay interest charge

        if (this.selectedLoan.Status != this.enums.SETTLED_LOAN && this.interestChargeAmount >= this.selectedLoan.Principal * (this.selectedLoan.InterestRate / 100)) {
          var modalRef = this.sharedService.openModal(ConfirmationDialogComponent, "regularModal");
          modalRef.componentInstance.message = "Interest Charge amount is more than monthly interest amount! Please make sure monthly interest paid before paying interest charge! Continue payment?"
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            var confirmation = entry;
            if (confirmation == true)
              this.withdrawInterestChargeWithReserve();
          });
        }
        else
          this.withdrawInterestChargeWithReserve();
      }

    } else
      this.sharedService.openAlert(errorMsg, this.enums.DANGER_ALERT);
  }


  calculateReserveLeft(amount: number) {

    return this.selectedLoan.Reserve - amount - this.stampAmount - this.scAmount - this.duitLebihAmount;
  }

  withdrawInterestChargeWithReserve() {

    this.disabledSubmit = true;
    var data = {
      "accountId": Number(this.selectedAccountId),
      "dateCreated": this.paymentDate,
      "extraInterest": this.interestChargeAmount,
      "stamp": this.stampAmount,
      "serviceCharge": this.scAmount,
      "remark": "Interest Charge with Simpan=Loan " + this.selectedLoan.LoanCode
    };

    var xhr = this.restApi.postRequest(this.constructApi.getAddReserveWithdrawal(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.sharedService.openAlert("Successfully withdraw interest charge with reserve!", this.enums.SUCCESS_ALERT);


          if (this.selectedLoan.Scheme == 'EPF' || this.selectedLoan.IsEPF == true) {
            var json = JSON.parse(xhr.responseText);
            var addedTransactionId = Number(json.id);
            this.addExtraInterestSet([addedTransactionId]);
          }
          else {
            this.passEntry.emit(this.selectedLoan.ID);
            this.reset();
          }



        } else
          this.sharedService.openErrorMessage(xhr);
      }
    }
  }


  addExtraInterestSet(idsToAdd: number[]) {
    this.disabledSubmit = true;

    var companyId = Number(sessionStorage.getItem("companyId"))
    var data = {
      "transactionIds": idsToAdd,
      "remark": "INTEREST CHARGE USING SIMPAN",
      "name": "EPF CUSTOMER INTEREST CHARGE"
    }


    var xhr = this.restApi.postRequest(this.constructApi.getAddNewCompanySet(companyId), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;

        if (xhr.status == 200) {

          var json = JSON.parse(xhr.responseText);
          var addedSetId = Number(json.id);

          this.activeModal.close();
          var modalRef = this.sharedService.openModal(ReceiptDetailsComponent, "largeModal");
          modalRef.componentInstance.receiptId = Number(addedSetId);
          modalRef.componentInstance.isModal = true;
          modalRef.componentInstance.passEntry.subscribe((entry) => {
            window.location.reload();
          });

        }
        else {
          var json = JSON.parse(xhr.responseText);
          this.sharedService.openAlert(json.error, this.enums.DANGER_ALERT)
        }

      }

    }
  }

  payOnlySCAndServiceCharge() {
    var data = {
      "term": 0,
      "accountId": Number(this.selectedAccountId),
      "dateCreated": new Date(),
      "reserve": - this.stampAmount - this.scAmount,
      "stamp": this.stampAmount,
      "serviceCharge": this.scAmount,
      "remark": "Pay with Simpan=Loan " + this.selectedLoan.LoanCode
    };

    var xhr = this.restApi.postRequest(this.constructApi.getAddPaymentWithReserve(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          this.disabledSubmit = false;
          this.passEntry.emit(this.selectedLoan.ID)
          this.reset();
          this.sharedService.openAlert("Successfully withdraw interest charge with reserve!", this.enums.SUCCESS_ALERT);
        }
        else {
          this.disabledSubmit = false;
          this.sharedService.openAlert("Opps,some error occured. Please try again later", this.enums.DANGER_ALERT);
        }


      }
    }
  }
  payWithReserve() {
    this.disabledSubmit = true;

    var data;
    var extras;
    if (this.duitLebihAmount > 0) {
      extras = [{ "amount": this.duitLebihAmount }];
    }

    // if (this.isSchemeA()) {
    if (this.isPayPrincipal && this.isSchemeA()) {
      data = {
        "principal": this.principalAmount,
        "accountId": Number(this.selectedAccountId),
        "stamp": this.stampAmount,
        "serviceCharge": this.scAmount,
        "extras": extras,
        "dateCreated": this.paymentDate,
        "reserve": -this.principalAmount - this.stampAmount - this.scAmount - this.duitLebihAmount,
        "remark": "Pay Principal with Simpan=Loan " + this.selectedLoan.LoanCode
      };
    }
    // else {
    //   data = {
    //     "interestCharge": this.paymentAmount,
    //     "accountId": Number(this.selectedAccountId),
    //     "stamp": this.stampAmount,
    //     "serviceCharge": this.scAmount,
    //     "extras": extras,
    //     "dateCreated": this.paymentDate,
    //     "reserve": -this.paymentAmount - this.stampAmount - this.scAmount - this.duitLebihAmount,
    //     "remark": "Pay with Simpan=Loan " + this.selectedLoan.LoanCode

    //   };
    // }

    // } 
    else {
      var term = (this.selectedLoan.Scheme == "HUTANG") ? (this.principalAmount / this.selectedLoan.Balance) : 1;
      data = {
        "term": term,
        "accountId": Number(this.selectedAccountId),
        "dateCreated": this.paymentDate,
        "reserve": -this.paymentAmount - this.stampAmount - this.scAmount,
        "stamp": this.stampAmount,
        "extras": extras,
        "serviceCharge": this.scAmount,
        "remark": "Pay with Simpan=Loan " + this.selectedLoan.LoanCode
      };
    }
    var xhr = this.restApi.postRequest(this.constructApi.getAddPaymentWithReserve(this.selectedLoan.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          this.disabledSubmit = false;
          this.passEntry.emit(this.selectedLoan.ID)
          this.reset();
          this.sharedService.openAlert("Successfully made payment!", this.enums.SUCCESS_ALERT);
        }
        else {
          this.disabledSubmit = false;
          this.sharedService.openAlert("Opps,some error occured. Please try again later", this.enums.DANGER_ALERT);
        }


      }
    }
  }
  reset() {

    if (this.isDirty == true)
      window.location.reload();
    else
      this.activeModal.close();
  }


}
