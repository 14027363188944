<h3 class="fw-bold">@if (approved) { Approved } @else { Pending / Denied }</h3>
<ng-container *ngIf="bookmarkList!=undefined && bookmarkList.length > 0; else notExist">
    <div *ngFor="let request of bookmarkList" class="card mb-2" [ngClass]="{ 'bg-danger-subtle': request.Approved === false, 'approvedCard': request.Approved === true }">
        <div class="card-body py-3 d-flex flex-column">
            <div class="d-flex align-items-center justify-content-between gap-3">
                <div>
                    <div class="d-flex flex-wrap gap-1 gap-md-3 align-items-center">
                        <div class="d-flex gap-2 align-items-center">
                            <h4 class="mb-0">
                                <strong>{{request.LoanInfo.Scheme=='HUTANG' ? request.LoanInfo.Scheme : ''}} Ticket #{{request.ID}} {{request.LoanInfo.Scheme!='EPF' && request.LoanInfo.Scheme!='HUTANG' ? '(' + request.LoanInfo.Scheme + ')' : ''}} - {{request.CompanyCode}}</strong>
                            </h4>
                            @if (request.SharedProfileId && request.SharedProfileId == request.ProfileId) { <div class="badge text-bg-primary text-uppercase">Main</div> }
                        </div>
                        <ng-container *ngIf="request.Approved === false">
                            <span class="badge text-bg-danger">
                                <h6 class="mb-0">
                                    <i class="fas fa-times me-2"></i>Denied
                                </h6>
                            </span>
                        </ng-container>
                    </div>

                    <div class="d-flex gap-2 align-items-center">
                    @if (adminAccess[request.CompanyId] !== undefined) {
                        <ng-template #popContent>
                        @for (a of adminAccess[request.CompanyId]; track a;) {
                            <div class="fs-6">{{ a.Priority }}: <b>{{ a.Username }}</b></div>
                        }
                        </ng-template>
                        <ng-template #popTitle>Admins</ng-template>
                        <a role="button" class="badge text-decoration-none text-info-emphasis bg-info-subtle border-info-subtle" [ngbPopover]="popContent" [popoverTitle]="popTitle" autoClose="outside" placement="bottom-start auto" container="body"><i class="fas fa-user-friends me-2"></i>{{adminAccess[request.CompanyId][0].Username}}</a>
                    }
                        <div class="fw-semibold text-body-secondary text-uppercase">{{ request.RequestOn | date:'dd MMM yyyy' }}</div>
                        <ng-container *ngIf="request.LoanID !== undefined">
                            <span class="badge" [ngClass]="ticketDateWarning(request.RequestOn, 3) ? 'text-bg-danger': 'text-bg-secondary opacity-70'"><i class="fas fa-exclamation-triangle"></i> Has Loan</span>
                        </ng-container>
                        <span *ngIf="ticketDateWarning(request.RequestOn, 15)" class="badge text-bg-warning"><i class="far fa-calendar-times"></i> More than 15 days</span>
                    </div>
                </div>
                <i class="mb-0 text-secondary fas fa-times pointer" style="font-size: 1.25rem;" (click)="removeBookmark(request.ID)"></i>
            </div>
            <hr>
            <div class="flex-grow-1 d-flex flex-column flex-md-row justify-content-between">
                <div>
                    <h5 class="p-0 m-0 viewCustomerLink pointer" style="color: darkslategray;" (click)="goToCustomer(request.CustomerId, request.ProfileId)">
                        <b>{{request.CustomerName}} | {{sharedService.processICNum(request.CustomerIC)}}</b>
                    </h5>
                    <ng-container *ngIf="request.Guarantors.length==0; else guarantor">
                        <span class="badge" [ngClass]="[request.LoanInfo.Scheme!=='EPF' ? 'text-bg-secondary opacity-70' : 'text-bg-danger']"><i class="fas fa-times"></i>
                            Guarantor</span>
                    </ng-container>
                    <ng-template #guarantor>
                        <div *ngFor="let g of request.Guarantors">
                            <div>{{g.Name}}</div>
                        </div>
                    </ng-template>
                </div>
                <div class="text-end align-self-end pointer" (click)="openLoanRequestModal(request)">
                    <div class="text-secondary small fw-bold">Total On Hand:</div>
                    <div class="fw-bold h5 mb-0">RM
                        {{this.sharedService.calculateTotalOnHand(request.LoanInfo) |
                        number:'1.0-2'}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #notExist>
    <div class="text-secondary">
    @if (searching) {
        No search result.
    }
    @else {
        No ticket.
    }
    </div>
</ng-template>