import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ActivatedRoute } from '@angular/router';
import { Enums } from 'src/app/Shared/enums';
import { CheckIn } from 'src/model/checkIn.model';
import { DatePipe } from '@angular/common';
import { MidMonthService } from 'src/services/mid-month.service';

@Component({
  selector: 'app-mid-month-report',
  templateUrl: './mid-month-report.component.html',
  styleUrls: ['./mid-month-report.component.css']
})

export class MidMonthReportComponent implements OnInit, OnDestroy {

  advanced: boolean = false;
  previousEndDates: Date[] = new Array();
  disabledSubmit: boolean = false;
  startDate: Date;
  endDate: Date;
  selectedFile: File;
  @Input() selectedCompanyID: number;
  routeSubscription: any;
  isCollapsed = true;

  willOmitSchemeA = false;
  useVersion1 = false;

  allowGeneration = true;

  constructor(private activatedRoute: ActivatedRoute, public sharedService: SharedService, private activeModal: NgbActiveModal,private enums:Enums, private datePipe: DatePipe, private midMonthService: MidMonthService) { }

  ngOnInit(): void {
    this.getPreviousEndDates();
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompanyID = + params.get('companyId');
      let eDate = params.get('endDate');
      if (eDate) {
        this.setRange(new Date(eDate));
        if (!this.getEndDateInRange()) this.advanced = true;
        this.submit();
      }
    });
  }

  getPreviousEndDates() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const currentMonth = today.getMonth();
    const todayDate = today.getDate();

    const startMonth = todayDate < this.midMonthService.defaultEndDate ? 1 : 0;

    for (let i = startMonth; i < startMonth + this.midMonthService.noOfPreviousMonth; i++) {
      const endDate = new Date(today);
      endDate.setMonth(currentMonth - i, this.midMonthService.defaultEndDate);
      this.previousEndDates.push(endDate);
    }
    
    this.setRange(this.previousEndDates[0]);
  }

  getEndDateInRange() {
    return (this.previousEndDates.some(d => d.getTime() === this.endDate.getTime()));
  }

  setRange(endDate: Date) {
    this.endDate = new Date(endDate);
    this.endDate.setHours(0, 0, 0, 0);
    const startDate = new Date(endDate);
    startDate.setMonth(startDate.getMonth()-1, this.midMonthService.defaultStartDate);
    startDate.setHours(0, 0, 0, 0);
    this.startDate = startDate;
  }

  toggleAdvanced() {
    if (!this.advanced && !this.getEndDateInRange()) {
      this.startDate = undefined;
      this.endDate = undefined;
    }
  }

  isFieldValid() {
    return (this.startDate !== undefined && this.endDate !== undefined)
  }

  async submit() {
    if (this.isFieldValid()) {
      let err: string[] = new Array();
      if (! await this.compareLastCheckInDate(this.startDate)) 
        err.push(`START DATE (${this.datePipe.transform(this.startDate, 'yyyy/MM/dd')})`);
      
      if (! await this.compareLastCheckInDate(this.endDate)) 
        err.push(`END DATE (${this.datePipe.transform(this.endDate, 'yyyy/MM/dd')})`);
      
      if (err.length > 0)
        this.sharedService.openAlert(`Please verify your account on ${err.join(' and ')}`, this.enums.DANGER_ALERT); 
      else {
        await this.generateMidMonthNew();
        this.midMonthService.saveMidMonthRange(this.startDate, this.endDate);
      }
    } else
      this.sharedService.openAlert("Please fill in all neccessary fields!",this.enums.DANGER_ALERT);    
  }

  

  async generateMidMonthNew() {
    this.disabledSubmit = true;
    let startDateStr = this.sharedService.createLocalISODateEncodedString(this.startDate);
    let endDate = this.sharedService.getLastMsOfDate(this.endDate);
    let endDateStr = this.sharedService.createLocalISODateEncodedString(endDate);
    try {
      let d = await this.midMonthService.getMidMonth(this.selectedCompanyID, startDateStr, endDateStr, this.willOmitSchemeA);
      this.midMonthService.downloadFile(d);
      this.sharedService.openAlert("Successfully generated MidMonth!",this.enums.SUCCESS_ALERT);
    } finally {
      this.disabledSubmit = false;
    }
  }

  async compareLastCheckInDate(aDate: Date): Promise<boolean> {
    aDate = this.sharedService.getLastMsOfDate(aDate);
    let checkIns: CheckIn[] = await this.midMonthService.getCompaniesLatestCheckIns(this.selectedCompanyID, aDate.toISOString());    
    let found: CheckIn = checkIns.find(c => c.Date.getTime() === aDate.getTime());
    return (found !== undefined); 
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  reset() {
    this.activeModal.close();
  }

}
