import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Loan } from 'src/model/loan.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as M from '../../../assets/materialize/js/materialize.min';
import { LoanDetailsComponent } from '../loan-details/loan-details.component';
import { LoanReserveComponent } from '../loan-reserve/loan-reserve.component';
import { Enums } from 'src/app/Shared/enums';
import { LoanArrearComponent } from '../loan-arrear/loan-arrear.component';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { AddPrincipalComponent } from 'src/app/AddThings/add-principal/add-principal.component';
import { AddCpPaymentComponent } from 'src/app/AddThings/add-cp-payment/add-cp-payment.component';
import { AddCommissionComponent } from 'src/app/Comission/add-commission/add-commission.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { AddPaymentModalComponent } from 'src/app/AddThings/add-payment-modal/add-payment-modal.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ChangeLoanStatusComponent } from '../change-loan-status/change-loan-status.component';
import { LegacyEpfSettlementComponent } from '../legacy-epf-settlement/legacy-epf-settlement.component';
import { Customer } from 'src/model/customer.model';
import { MoveLoanComponent } from '../move-loan/move-loan.component';
import { Profile } from 'src/model/profile.model';
import { UploadImageComponent } from 'src/app/Images/upload-image/upload-image.component';
import { ViewThumbnailsComponent } from 'src/app/Images/view-thumbnails/view-thumbnails.component';

declare var $: any;

@Component({
  selector: 'app-loan-card',
  templateUrl: './loan-card.component.html',
  styleUrls: ['./loan-card.component.css']
})
export class LoanCardComponent implements OnInit {

  @Input() loan: Loan;
  @Input() selectedCustomer: Customer;
  @Input() reserveAmount: number;
  @Input() selectedProfile: Profile;
  @Input() needRestrictionCustomer: boolean;

  options = { hoverEnabled: false };
  tooltipOptions = {};

  @Input() isWithTransation: boolean = false;
  @Input() isSimplifiedView: boolean = false;


  loanTotalIncludeInterest: number = 0;

  loanBalance: number = 0;

  isEditing: boolean = false;

  loggedInCompanyId: number;

  constructor(private cdr: ChangeDetectorRef, private modalService: NgbModal, public enums: Enums, private restapi: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService) {


  }

  ngOnInit(): void {

    var elems = document.querySelectorAll('.fixed-action-btn');
    var instances = M.FloatingActionButton.init(elems, this.options);

    // var tooltipElems = document.querySelectorAll('.tooltipped');
    // var tooltipInstances = M.Tooltip.init(tooltipElems, this.tooltipOptions);

    this.loanTotalIncludeInterest = this.calculateTotalIncludeInterest();


    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));


    this.sharedService.calculateSettlementToDate(this.loan);
  }

  changeIsEditing(editing: boolean) {
    this.isEditing = editing;
  }


  updateLoan() {
    var data = {
      "principal": this.loan.Principal,
      "profileId": this.loan.ProfileId,
      "status": this.loan.Status,
      "interestRate": this.loan.InterestRate,
      "term": this.loan.Term,
      "scheme": this.loan.Scheme,
      "loanCode": this.loan.LoanCode
    }

    var xhr = this.restapi.putRequest(this.constructApi.getLoanDetails(this.loan.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.isEditing = false;
        this.sharedService.openAlert("Successfully updated loan code", this.enums.SUCCESS_ALERT)
      }

    }
  }

  setBalanceColor(loanBalance: number) {
    if (loanBalance <= 0)
      return 'green'
    else
      return 'red'
  }

  getLoanStatus(loanStatus: number) {

    if (loanStatus == this.enums.ACTIVE_LOAN)
      return "ACTIVE"
    else if (loanStatus == this.enums.WARNING_LOAN)
      return "WARNING"
    else if (loanStatus == this.enums.SETTLED_LOAN)
      return "SETTLED"
    else if (loanStatus == this.enums.BAD_DEBT_LOAN)
      return "BAD DEBT"
    else if (loanStatus == this.enums.LBD_LOAN)
      return "LEGAL"
    else
      return "CP";
  }

  isStaff() {
    var roleId = Number(sessionStorage.getItem('roleId'));

    if (roleId == this.enums.STAFF)
      return true
    else
      return false;
  }
  getStatusColor(status: number) {

    var color: string;

    switch (status) {
      case this.enums.ACTIVE_CUST:
        color = "bg-primary";
        break;
      case this.enums.WARNING_CUST:
        color = "bg-warning";
        break;
      case this.enums.BAD_DEBT_CUST:
        color = "bg-dark";
        break;
      case this.enums.CP_CUST:
        color = "bg-info";
        break;

      case this.enums.LBD_CUST:
        color = "bg-light";
        break;
      default:
        color = "bg-success";
        break;
    }
    return color;
  }

  calculateBalance() {
    var balance = 0;

    if (this.loan.Scheme == "A") {
      balance = this.loan.Principal - this.loan.PrincipalPaid
    }
    else {
      balance = this.loanTotalIncludeInterest - this.loan.Repayment;
    }

    return this.roundTo2Dec(balance)
  }

  calculateMonthly() {
    var monthly = 0;

    // if (this.loan.Scheme == 'A') {

    //   var balance = this.calculateBalance();
    //   monthly = balance * (this.loan.InterestRate / 100);
    // }
    // else
    //   monthly = this.loanTotalIncludeInterest / this.loan.Term;

    if (this.loan.Scheme == 'A') {
      monthly = this.loan.InterestRate / 100 * this.loan.Principal;
    } else {
      monthly = this.loan.InterestRate / 100 * this.loan.Principal + (this.loan.Principal / this.loan.Term);
    }

    return monthly;
  }



  getIfEarning(earning: number) {

    if (earning > 0)
      return "bg-success"
    else
      return "bg-danger"

  }

  calculateTotalIncludeInterest() {
    // this.loanTotalIncludeInterest = Number((this.loan.Principal * (this.loan.InterestRate / 100) * this.loan.Term).toFixed(2));
    // return this.loanTotalIncludeInterest;


    // this.loanTotalIncludeInterest = this.roundTo2Dec((this.loan.Principal * (this.loan.InterestRate / 100) * this.loan.Term))

    // this.loanTotalIncludeInterest = this.roundTo2Dec((this.loan.InterestRate / 100 * this.loan.Principal * this.loan.Term) + this.loan.Principal);
    return this.roundTo2Dec((this.loan.InterestRate / 100 * this.loan.Principal * this.loan.Term) + this.loan.Principal);
  }

  roundTo2Dec(num: number) {

    return Math.round(num * 100) / 100

  }


  calculateProfitAndLoss(loan: Loan) {

    var pnl = 0;
    if (loan.Scheme == 'A')
      pnl = loan.PrincipalPaid + loan.InterestCharge + loan.ArrearPaid + loan.MonthlyInterest + loan.ProcessingCharge - loan.Principal + loan.Discount;
    else
      pnl = loan.Repayment + loan.InterestCharge + loan.ArrearPaid + loan.MonthlyInterest + loan.ProcessingCharge - loan.Principal + loan.Discount;

    return pnl;
  }


  isSharedLoan() {
    if (this.selectedCustomer != undefined)
      return this.selectedCustomer.CompanyId != this.loan.CompanyId;
    else
      return false;
  }

  openPaymentModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(AddPaymentModalComponent);
    // modalRef.componentInstance.selectedLoanId = loanId;
    // modalRef.componentInstance.selectedLoanReceiptNo = loanReceipt;
    modalRef.componentInstance.selectedLoan = loan;
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;
  }

  openAddPrincipalModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(AddPrincipalComponent, "largeModal");
    modalRef.componentInstance.selectedLoan = loan;
    modalRef.componentInstance.availableReserve = this.reserveAmount;
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });

    modalRef.result.then(() => {

    })

  }

  openChangeStatusModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(ChangeLoanStatusComponent, "largeModal");
    modalRef.componentInstance.selectedLoan = loan;
  }

  openLoanDetailsModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(LoanDetailsComponent, "largeModal");
    modalRef.componentInstance.selectedLoan = loan;

  }

  openPayWithReserveModal(loan: Loan, monthlyAmount: number) {

    if (this.reserveAmount <= 0) {
      this.sharedService.openAlert('You do not have SIMPAN for this customer', this.enums.DANGER_ALERT)
    } else {
      const modalRef = this.sharedService.openModal(LoanReserveComponent, "regularModal");

      this.loan.Reserve = this.reserveAmount;
      modalRef.componentInstance.selectedLoan = loan;
      modalRef.componentInstance.paymentAmount = this.roundTo2Dec(monthlyAmount);

      modalRef.componentInstance.passEntry.subscribe((entry) => {

        // this.loan = undefined;
        // var updatedLoanId = Number(entry);
        // this.getCustomerLoanInfo(updatedLoanId);

        this.refresh();

      });

      modalRef.result.then(() => {

        //detect changes
        this.cdr.detectChanges();

      })

    }

  }

  openLoanArrear(loan: Loan) {
    if (this.loan.Arrear <= 0) {
      this.sharedService.openAlert('You do not have ARREAR for this customer', this.enums.DANGER_ALERT)
    }
    else {
      const modalRef = this.sharedService.openModal(LoanArrearComponent);
      modalRef.componentInstance.selectedLoan = loan;

      modalRef.componentInstance.passEntry.subscribe((entry) => {
        // var updatedLoanId = Number(entry);
        // this.getCustomerLoanInfo(updatedLoanId);

        this.refresh();

      });

      modalRef.result.then(() => {

        //detect changes
        this.cdr.detectChanges();
      })
    }


  }
  confirmBeforeMoveCents() {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You sure you want to move all the cents in settlement charges into interest charge?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.moveCentsToInterestCharge();
    });

    return proceed;
  }

  moveCentsToInterestCharge() {
    var xhr = this.restapi.patchRequest(this.constructApi.getMoveCentsToInterest(this.loan.ID), {});
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.sharedService.openAlert("Successfully move all charges cents into interest charge", this.enums.SUCCESS_ALERT);
        window.location.reload();
      }

    }
  }

  confirmBeforeRemoveCP() {

    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You sure you want to remove this customer from CP?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.removeFromCP();
    });

    return proceed;

  }

  removeFromCP() {

    var xhr = this.restapi.putRequest(this.constructApi.getRemoveFromCP(this.loan.ID), {});
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {

        this.sharedService.openAlert("Successfully remove from CP", this.enums.SUCCESS_ALERT);
        window.location.reload();
      }

    }

  }

  openPaymentForBadDebt(loan: Loan) {

    const modalRef = this.sharedService.openModal(AddCpPaymentComponent);
    modalRef.componentInstance.selectedProfileId = loan.ProfileId;

    modalRef.componentInstance.passEntry.subscribe((entry) => {

      // this.loan = undefined;
      // var updatedLoanId = Number(entry);
      // this.getCustomerLoanInfo(updatedLoanId);

      this.refresh();

    });

    modalRef.result.then(() => {

      //detect changes
      this.cdr.detectChanges();

    })


  }

  openAddLegacySettlement(loan: Loan) {

    const modalRef = this.sharedService.openModal(LegacyEpfSettlementComponent, "regularModal");
    modalRef.componentInstance.loanList = [loan];

    //if completed
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var success = entry;

      //append added loan to loan list
      if (success)
        window.location.reload();
    });

  }

  confirmBeforeDeleteLegacySettlement(loanID: number) {
    var proceed = false;
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "You sure you want to remove the legacy settlement?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true)
        this.deleteLegacySettlement(loanID);
    });

    return proceed;
  }

  deleteLegacySettlement(loanID: number) {

    var xhr = this.restapi.deleteRequest(this.constructApi.getAddLegacyEPFSettlement(Number(loanID)));

    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4 && xhr.status == 200) {

          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openAlert("Opps, some errors occured. Please try again later", this.enums.DANGER_ALERT);
          }

        }
      }

    }
  }



  isSettled(loanStatus: number) {
    if (Number(loanStatus) == Number(this.enums.SETTLED_LOAN))
      return true;
    else
      return false;

  }

  isCP(loanStatus: number) {
    if (Number(loanStatus) == Number(this.enums.CP_LOAN))
      return true;
    else
      return false;

  }

  isBadDebt(loanStatus: number) {
    if (Number(loanStatus) == Number(this.enums.BAD_DEBT_LOAN))
      return true;
    else
      return false;

  }

  refresh(): void {
    window.location.reload();
  }

  openAddCommissionModal(loan: Loan) {
    const modalRef = this.sharedService.openModal(AddCommissionComponent);
    modalRef.componentInstance.loan = loan;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      window.location.reload();
    });


    modalRef.result.then(() => {
      //detect changes
      this.cdr.detectChanges();

    })
  }

  defineLoanScheme() {
    if (this.loan.Scheme == 'EPF' || this.loan.IsEPF == true)
      return 'epfCard';
    else if (this.loan.Scheme == 'HUTANG')
      return 'hutangCard';
    else
      return 'loanCard';
  }


  openMoveLoanModal() {
    var modalRef = this.sharedService.openModal(MoveLoanComponent, "largeModal");
    modalRef.componentInstance.profileList = this.selectedCustomer.Profiles;

    modalRef.componentInstance.selectedProfile = this.selectedProfile;
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;
    modalRef.componentInstance.selectedLoan = this.loan;

  }


  openUploadLoanDocModal(loanId: number, index: number) {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.selectedCustomer.ID;
    modalRef.componentInstance.isLoanDocument = true;
    modalRef.componentInstance.loanId = loanId;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var loanId = Number(entry);

      if (loanId != undefined)
        this.loan.LoanDocExisted = true;

    });


  }

  openViewLoanDocsModal(loanId: number) {

    const modalRef = this.sharedService.openModal(ViewThumbnailsComponent);

    modalRef.componentInstance.customerID = this.selectedCustomer.ID;
    modalRef.componentInstance.loanId = loanId;

  }

  isNonSettledEPFLoan() {
    return (this.loan.Status != this.enums.SETTLED_LOAN && (this.loan.Scheme == 'EPF' || this.loan.IsEPF == true));
  }


}
