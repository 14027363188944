<div class="card shadow-sm my-2">
    <div class="card-body">
        <div>
            <h5 class="mb-1">{{adminAccessItem.username}}</h5>
            <div class="text-secondary">
                <a class="accessLink" (click)="openAdminCompanyListModal()"><span *ngIf="isOnDuty==false">
                        {{adminAccessItem.companyCount + adminAccessItem.tempAccessCount +
                        adminAccessItem.onDutyAccessCount}}
                        total</span>
                </a>
            </div>
        </div>
        @if(adminAccessItem.companyCount + adminAccessItem.tempAccessCount + adminAccessItem.onDutyAccessCount>0)
        {
        <div class="d-flex flex-gap align-items-end">
            <button class="btn btn-outline-secondary me-1" (click)="openCloneAdminModal()"
                *ngIf="isOnDuty==false">Clone</button>

            <button class="btn btn-outline-danger" (click)="openRevokeAdminModal()">Revoke</button>


        </div>
        }
    </div>

</div>