<div class="companies">
    <h3 class="opacity-60 mb-3">Companies</h3>
    <div class="d-flex flex-gap">
        <!-- <input type="text" name="search" class="form-control w-auto mb-2" placeholder="Search Company">
        <input type="text" name="admin" class="form-control w-auto mb-2" placeholder="Search Admin"> -->

        <form class="company-form">
            <mat-form-field class="example-full-width">
                <mat-label>Company</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="companyFC"
                    [matAutocomplete]="company">
                <mat-autocomplete #company="matAutocomplete" (optionSelected)="applyFilter($event)">
                    <mat-option value=""></mat-option>
                    <mat-option [value]="company.CompanyCode"
                        *ngFor="let company of filteredCompany |async">{{company.CompanyCode}}</mat-option>

                </mat-autocomplete>
            </mat-form-field>
        </form>


        <form class="admin-form ms-2">
            <mat-form-field class="example-full-width">
                <mat-label>Admin</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="adminFC"
                    [matAutocomplete]="admin">
                <mat-autocomplete #admin="matAutocomplete" (optionSelected)="applyFilter($event)">
                    <mat-option value=""></mat-option>
                    <mat-option [value]="admin.Username"
                        *ngFor="let admin of filteredAdmin |async">{{admin.Username}}</mat-option>

                </mat-autocomplete>
            </mat-form-field>
        </form>

    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="companyCode" matSortDirection="asc"
        class="w-100 rounded">
        <ng-container matColumnDef="companyCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Company </th>
            <td mat-cell *matCellDef="let comp" class="companyCol">
                {{ comp.companyCode }}
            </td>
        </ng-container>

        <ng-container matColumnDef="admin1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Admin 1 </th>
            <td mat-cell *matCellDef="let comp" class="admin1Col">
                <i class="fas fa-exchange-alt" *ngIf="comp.admin1!=''" style="cursor:pointer;color:#878787"
                    (click)="openChangeAccessModal(comp.companyCode,enums.FIRST_PRIORITY)"></i>
                <i class="fas fa-user-plus" *ngIf="comp.admin1==''" style="cursor:pointer;color:#878787"
                    (click)="openAssignAccessModal(comp.companyCode,enums.FIRST_PRIORITY)"></i>
                {{ comp.admin1 }}

            </td>
        </ng-container>

        <ng-container matColumnDef="admin2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Admin 2 </th>
            <td mat-cell *matCellDef="let comp" class="admin2Col">
                <i class="fas fa-exchange-alt" *ngIf="comp.admin2!=''" style="cursor:pointer;color:#878787"
                    (click)="openChangeAccessModal(comp.companyCode,enums.SECOND_PRIORITY)"></i>
                <i class="fas fa-user-plus" *ngIf="comp.admin2==''" style="cursor:pointer;color:#878787"
                    (click)="openAssignAccessModal(comp.companyCode,enums.SECOND_PRIORITY)"></i>
                {{ comp.admin2 }}


            </td>
        </ng-container>

        <ng-container matColumnDef="admin3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Admin 3 </th>
            <td mat-cell *matCellDef="let comp" class="admin3Col">
                <i class="fas fa-exchange-alt" *ngIf="comp.admin3!=''" style="cursor:pointer;color:#878787"
                    (click)="openChangeAccessModal(comp.companyCode,enums.THIRD_PRIORITY)"></i>
                <i class="fas fa-user-plus" *ngIf="comp.admin3==''" style="cursor:pointer;color:#878787"
                    (click)="openAssignAccessModal(comp.companyCode,enums.THIRD_PRIORITY)"></i>
                {{ comp.admin3 }}

            </td>
        </ng-container>
        <ng-container matColumnDef="others">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="position:sticky"> Others </th>
            <td mat-cell *matCellDef="let comp" class="othersCol">
                @if(comp.others!=undefined && comp.others.length>0){
                <button class="btn btn-sm  text-info-emphasis bg-info-subtle border-info-subtle"
                    (click)="openOtherPriorityModal(comp.others,comp.companyCode)">Manage Priorities</button>


                }

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSize]="200" [pageSizeOptions]="[25,50, 100,200]" showFirstLastButtons>
    </mat-paginator>
</div>