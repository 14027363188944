import { DialogItem } from '../../dialog-item';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoanRequestDetailsComponent } from 'src/app/LoanRequest/loan-request-details/loan-request-details.component';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanRequest } from 'src/model/loanRequest.model';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { LoanRequestService } from 'src/services/loan-request.service';
import { AdminAccessService } from 'src/services/admin-access.service';
import { CompanyAccess } from 'src/model/companyAccess.model';
import { Enums } from 'src/app/Shared/enums';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.css']
})
export class BookmarkComponent implements OnInit {
  bookmarkStorage: string = 'bookmarks';

  bookmarkedList: LoanRequest[] = new Array();
  filteredBookmarkedList: LoanRequest[] = new Array();
  bookmarks: number[] = new Array();
  deletedTickets: number[] = new Array();
  filteredDeletedTickets: number[] = new Array();
  searchText: string = "";
  searching: boolean = false;

  companyAccess: {[key: string]: CompanyAccess[]} = {};

  constructor(private adminAccess: AdminAccessService, private loanRequestService: LoanRequestService, public sharedService: SharedService, private enums: Enums, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.bookmarks = (localStorage.bookmarks != undefined) ? JSON.parse(localStorage.bookmarks).filter(x => x !== null) : [];
    this.refresh();
    this.getUserCompanyAccess();
  }

  async applyFilter() {
    if (!this.isSearchTextEmpty()) {
      try {
        this.resetFilter();
        this.filteredBookmarkedList = this.filteredBookmarkedList.filter(
          (r) => r.ID === parseInt(this.searchText, 10)
        )
        this.filteredDeletedTickets = this.deletedTickets.filter(
          (r) => r === parseInt(this.searchText, 10)
        );
        this.searching = true;
      } catch (error) {
        console.error(error);
      }
    } else {
      this.resetFilter();
    }
  }

  resetFilter() {
    this.searching = false;
    this.filteredBookmarkedList = this.bookmarkedList;
    this.filteredDeletedTickets = this.deletedTickets;
  }

  isSearchTextEmpty(): boolean {
    let result = this.searchText.length == 0;
    if (result) {
      this._snackBar.open('Please insert ticket number.', '', {
        duration: 3000,
        verticalPosition: 'top',
        panelClass: ['top-snackbar', 'bg-light', 'text-dark']
      });
    }
    return result;
  }

  async addBookmark(confirm: Boolean = true) {
    if (!this.isSearchTextEmpty()) {
      let ticketId = parseInt(this.searchText, 10);

      if (!this.bookmarks.includes(ticketId)) {
        try {
          const t = await this.loanRequestService.getLoanApprovalRequestById(ticketId);
          if (confirm) this.openConfirmationModal(t);
          else this.saveBookmark();
        } catch (error) {
          if (error.status == 404) {
            this._snackBar.open('Ticket not found.', '', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['top-snackbar', 'bg-light', 'text-dark']
            });
          }
        }
      } else {
        this._snackBar.open('Ticket already bookmarked.', '', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['top-snackbar', 'bg-light', 'text-dark']
        });
      }
    }
  }

  saveBookmark() {
    this.bookmarks.push(parseInt(this.searchText));
    localStorage.setItem(this.bookmarkStorage, JSON.stringify(this.bookmarks));
    this.refresh();
  }

  removeBookmark(id: number | number[]) {
    if (Array.isArray(id)) {
      var l: Set<number> = new Set(id);     
      this.bookmarks = this.bookmarks.filter( (r) => !l.has(r) ) 
    } else this.bookmarks = this.bookmarks.filter( (r) => r !== id )
    localStorage.setItem(this.bookmarkStorage, JSON.stringify(this.bookmarks));
    this.searchText = '';
    this.refresh();
  }

  openConfirmationModal(req: LoanRequest) {
    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent, 'largeModal');
    modalRef.componentInstance.dialogItem = new DialogItem(LoanRequestDetailsComponent, { request: req, viewOnly: true, onlyViewLoan: false, isExpanded: true });
    modalRef.componentInstance.message = 'Are you sure to bookmark this loan request?';
    modalRef.componentInstance.passEntry.subscribe((confirm) => {
      if (confirm == true) this.saveBookmark();
    });
  }

  async refresh() {
    try {
      if (this.bookmarks.length > 0) {
        // this.bookmarkedList = await this.getBookmarked(this.bookmarks);
        this.bookmarkedList = await this.loanRequestService.getLoanApprovalRequestByIds(this.bookmarks);
        this.deletedTickets = this.ticketNotExists();
      } else {
        this.bookmarkedList = [];
        this.deletedTickets = [];
      }
      this.resetFilter();
    } catch (error) {
      console.error(error);
    }
  }

  getBookmarkListByStatus(approved: boolean[]): LoanRequest[] {
    return this.filteredBookmarkedList.filter(obj => approved.includes(obj.Approved));
  }

  ticketNotExists(): number[] {
    let list = new Set(this.bookmarkedList.map(x => x.ID));
    var result: number[] = result = this.bookmarks.reduce((a, t) => {
      if (!list.has(t)) a.push(t);
      return a;
    }, new Array());
    return result;
  }

  async getUserCompanyAccess() {
    const priorities: number[] = [this.enums.FIRST_PRIORITY, this.enums.SECOND_PRIORITY, this.enums.THIRD_PRIORITY];
    const a = await this.adminAccess.getCompaniesAccess();
    const r = this.sharedService.groupBy(a, 'CompanyId');
    Object.keys(r).forEach(key => {
      this.companyAccess[key] = r[key].filter(a => priorities.includes(a.Priority));
      this.companyAccess[key].sort((a, b) => a.Priority - b.Priority);
    });
  }
}
