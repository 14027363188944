import { RestApiService } from 'src/app/API/restapi';
import { Blacklist, IAPIBlacklist } from './../model/blacklist';
import { Injectable } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlacklistAdapter {

  adapt(item: any): Blacklist {
    return {
      Name: item.name,
      ICNumber: item.icNumber,
      OtherID: item.otherId,
      Remark: item.remark,
      Status: item.status,
      CreatedBy: item.created_by,
      CreatedOn: new Date(item.created_on),
      ID: item.id,
      ReleasedBy: item.releasedBy,
      ReleasedOn: new Date(item.releasedOn)
    }
  }

  toApiModel(blacklist: Blacklist): IAPIBlacklist {
    return {
      name: blacklist.Name,
      icNumber: blacklist.ICNumber,
      otherId: blacklist.OtherID,
      remark: blacklist.Remark,
      status: blacklist.Status
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  constructor(private api: RestApiService, private constructApi: ConstructAPI, private adapter: BlacklistAdapter) { }

  getAllBlacklist(): Promise<Blacklist[]> {
    return new Promise((resolve, reject) => {
      this.api.get<Blacklist[]>(this.constructApi.getAllBlacklist()).pipe(
        map(data => data.map(d => this.adapter.adapt(d)))
      ).subscribe({
        next: response => resolve(response),
        error: (err) => {
          this.api.handleError(err);
          reject(err);
        }
      });
    })
  }

  getPatchReleaseCustomer(id: number): Promise<Blacklist> {
    return new Promise<Blacklist>((resolve, reject) => {
      this.api.patch<Blacklist>(this.constructApi.getPatchReleaseCustomer(id)).subscribe({
        next: response => resolve(this.adapter.adapt(response)),
        error: (err) => {
          this.api.handleError(err);
          reject(err);
        }
      });
    })
  }

  addBlacklistCustomer(blacklist: Blacklist): Promise<Blacklist> {
    return new Promise<Blacklist>((resolve, reject) => {
      this.api.post<Blacklist>(this.constructApi.getAddBlacklistCustomer(), this.adapter.toApiModel(blacklist)).subscribe({
        next: response => {
          resolve(this.adapter.adapt(response));
        },
        error: err => {
          this.api.handleError(err);
          reject(err);
        }
      })
    })
  }

  updateBlacklistCustomer(blacklist: Blacklist): Promise<Blacklist> {
    return new Promise<Blacklist>((resolve, reject) => {
      this.api.put<Blacklist>(this.constructApi.updateBlacklistCustomer(blacklist.ID), this.adapter.toApiModel(blacklist)).subscribe({
        next: response => resolve(this.adapter.adapt(response)),
        error: (err) => {
          this.api.handleError(err);
          reject(err);
        }
      });
    })
  }
}
