import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { Enums } from 'src/app/Shared/enums';
import { AlertDialogComponent } from 'src/app/Shared/alert-dialog/alert-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ServerService } from 'src/services/server.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private cookieService: CookieService,
    private _auth: AuthService, private enums: Enums, private sharedService: SharedService, private serverService: ServerService) { }

  username: string;
  password: string;
  disabledSubmit = true;
  returnUrl: string;

  ngOnInit(): void {

    this.checkLogin();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  checkEnableSubmit() {
    if (this.username == undefined || this.username == "" || this.password == undefined || this.password == "")
      this.disabledSubmit = true;
    else
      this.disabledSubmit = false;

    return this.disabledSubmit;
  }

  checkLogin() {
    if (this._auth.loggedIn()) {
      this.redirectBasedOnRole();
    }

  }

  login() {
    this.disabledSubmit = true;

    let xhr = this._auth.login({ "username": this.username, "password": this.password });
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        this._auth.userLoggedIn.next(true);

        this.disabledSubmit = false;

        var json = JSON.parse(xhr.responseText);
        sessionStorage.setItem("username", json.username);
        sessionStorage.setItem("companyId", json.companyId);
        sessionStorage.setItem("companyName", json.companyCode);
        sessionStorage.setItem("roleId", json.roleId);
        sessionStorage.setItem("roleName", json.roleName);
        sessionStorage.setItem("companyUid", json.companyUid)

        this.redirectBasedOnRole();

      }

      else if (xhr.readyState == 4 && xhr.status != 200) {
        this.disabledSubmit = false;
        this.sharedService.openAlert("Oops! Something went wrong!", this.enums.DANGER_ALERT)
      }
    }
  }


  async checkServerStatus() {
    if (!await this.serverService.compareServerVersion()) this.sharedService.openAlert("🚨 Your Website Version is Outdated! 🚨 \nPlease press `Ctrl+F5` on your keyboard to refresh the page so that you can access the latest features and improvements.", this.enums.DANGER_ALERT);

  }


  async redirectBasedOnRole() {

    //check website version first
    this.checkServerStatus();

    //if return url is set, go to return url after login
    if (this.returnUrl != undefined)
      this.router.navigateByUrl(this.returnUrl);

    //if return url not set, default go dashboard
    else {
      //if staff / manager
      if ((this.sharedService.isOperatingUser()))
        this.router.navigateByUrl('dashboard-calendar');

      //if admin / boss
      else if ((this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance()))
        this.router.navigateByUrl('dashboard-admin');


      else if (this.sharedService.isExpenseAdmin())
        this.router.navigateByUrl('receiptList');

      else if (this.sharedService.isAuditor())
        this.router.navigateByUrl('companyList');
    }
  }



}
