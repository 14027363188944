<form>
    <!-- Modal Header -->
    <div class="modal-header" *ngIf="isModal==true">
        <h4 class="modal-title">Create Simpan Group</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="accent" [(ngModel)]="isContraAccount"
            name="isContraAccount">Return to
            HQ
        </mat-slide-toggle>

        <div class="card mt-2" style="background-color: lightblue;border: 2px solid blue;border-radius: 5px;"
            *ngIf="isContraAccount==false">
            <div class="card-body">
                <h3> <b>Total Simpan : RM {{totalReserve |number:'1.0-2'}} </b>
                    <button class="btn btn-outline-dark" (click)="topUpSimpan()"
                        *ngIf="isContraAccount==false && sharedService.checkIsMainShareProfile(selectedProfile)==true">Top
                        Up</button>
                </h3>

            </div>


        </div>

        <hr />
        <p><strong>Please fill in the CUSTOMER PAYMENT for each company</strong></p>
        <table class="table table-bordered">
            <ng-container *ngFor="let distribution of reserveDistributionList">
                <tr [hidden]="distribution.isMain==true && isContraAccount==false">
                    <th> <i class="fas fa-handshake text-primary" *ngIf="distribution.isMain ==true"></i>
                        {{distribution.companyName}} </th>
                    <td> <input type="number" required class="textInputLong" name="amount"
                            [(ngModel)]="distribution.amount" min=0
                            (keypress)="sharedService.isNumberKeyAllowNegative($event)"></td>

                </tr>

            </ng-container>
        </table>
        <p class="text-danger" *ngIf="isContraAccount==false"><b>Main Company will out total of RM
                {{calculateTotalMainDistribution()}}</b></p>



        <!--Account Number-->
        <div class="row p-3 d-flex justify-content-center align-items-center" *ngIf="isContraAccount==false">
            <div class="col-12">
                <p>From Account</p>
            </div>
            <div class="col-12">
                <select class="browser-default form-select" name="selectedAccount" [(ngModel)]="selectedAccountId">
                    <optgroup label="Company Account">
                        <option *ngFor="let account of companyBankAccountList" [value]="account.ID">
                            {{account.Name}}
                        </option>
                    </optgroup>

                    <optgroup label="Cash Account">
                        <option *ngFor="let account of cashAccountList" [value]="account.ID">
                            {{account.Name}}
                        </option>
                    </optgroup>

                </select>
            </div>

        </div>

        <!--Date-->
        <mat-form-field class="full-width-formField bar">
            <input matInput class="textInputLong" [matDatepicker]="myDatepicker" name="simpanDate"
                [(ngModel)]="simpanDate" placeholder="Simpan Date" [max]="sharedService.getTodayDate()"
                onkeydown="return false">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker name="simpanDate"></mat-datepicker>
        </mat-form-field>

        <div class="col-12 d-flex justify-content-end align-items-right">
            <button class="btn btn-outline-success" [disabled]="disabledSubmit" (click)="checkValidity()">
                Submit
            </button>
        </div>
    </div>

</form>