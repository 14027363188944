import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { CPDetails, CPRemark } from 'src/model/cpDetails';

@Component({
  selector: 'app-view-cp-details',
  templateUrl: './view-cp-details.component.html',
  styleUrls: ['./view-cp-details.component.css']
})
export class ViewCpDetailsComponent implements OnInit {

  @Input() customerId: number;
  @Input() customerCode: string;
  customerRemarks: CPRemark[] = new Array;

  cpDetails: CPDetails;
  constructor(private activeModal: NgbActiveModal, private restApi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService, private enums: Enums) { }

  ngOnInit(): void {
    this.getCPDetails(this.customerId);
  }



  getCPDetails(customerId: number) {
    var xhr = this.restApi.getRequest(this.constructApi.getCPCustomerDetails(customerId));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          var json = JSON.parse((xhr.responseText));

          this.cpDetails = new CPDetails(json.customerID, json.AdditionalInfo, json.CustAltNumber, json.CustomerCode,
            json.CustomerIC, json.CustomerName, json.DebtAmount, json.DebtTerm, json.caseStatus, json.charges,
            json.custOccupation, json.custStatus, json.defaultPhone, json.officeAmount, undefined, json.EmployeeName);


        }
        else {
          this.sharedService.openAlert("Error! Cannot show details!  " + JSON.parse((xhr.responseText)).error, this.enums.DANGER_ALERT);
          this.activeModal.close();
        }

      }
    }

  }


  getCPRemarks(customerId: number) {
    this.customerRemarks = [];
    var xhr = this.restApi.getRequest(this.constructApi.getCPCustomerRemarks(customerId));

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {

        if (xhr.status == 200) {
          var jsonArray = JSON.parse((xhr.responseText));

          if (jsonArray.length > 0) {
            jsonArray.forEach(json => {
              let remark = {
                HandlerName: json.handlerName, RemarkDate: new Date(json.remarkDate), RemarkDesc: json.remarkDesc, RemarkID: json.remarkID
              }

              this.customerRemarks.push(remark);
            }


            );
          }

          this.customerRemarks.sort((a, b) => (a.RemarkDate > b.RemarkDate) ? -1 : 1);


        }
        else {
          this.sharedService.openAlert("Error! " + JSON.parse((xhr.responseText)).error, this.enums.DANGER_ALERT)
        }

      }
    }

  }
  closeModal() {
    this.activeModal.close();
  }
}
