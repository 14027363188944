<div class="container-fluid">
    
    <div class="d-grid">
    <!-- @if (compSetList.length > 0) { -->
        <div class="p-4">
            <h3 class="fw-bold text-secondary mb-4">Your Companies</h3>
        @for (c of compSetList; track c) {            
            <div (click)="filterComp(c.CompanyCode)" class="p-3 rounded bg-white mb-3 light-drop-shadow text-secondary" 
            [ngClass]="{'bg-primary-subtle bg-opacity-10 text-primary-emphasis fw-bold border border-primary-subtle': compFilterList.includes(c.CompanyCode)}">
                <h5 class="mb-1">{{c.CompanyCode}}</h5>
                <div>@if (c.DoneCount === c.TotalCount) { <i class="fas fa-check-circle text-success fs-5"></i> } {{c.DoneCount}} / {{c.TotalCount}}</div>
            </div>
        }
        </div>
    <!-- } -->
    
        <div class="p-4" style="width: 100%;">
            <h2 class="fw-bold">Receipt List</h2>
    
            <div class="my-3 p-3 border rounded">
                <h5 class="fw-bold text-secondary">Filter</h5>
                <div class="mb-2 d-flex gap-2 align-items-center">
                    <div>Frequently used: </div>
                    <mat-chip-listbox multiple>
                    @for (d of dateShortcut; track d) {
                        <mat-chip-option class="m-1" selectable="false" [selected]="aDate.toDateString() == d.value.toDateString()" (click)="changeDate(d.value)">{{d.label}}</mat-chip-option>
                    }
                    @for (f of flattenedShortcut; track f) {
                        <mat-chip-option class="m-1" [selected]="inFilter(f.name, f.value)" (click)="filterBy(f.name, f.value)">{{f.label}}</mat-chip-option>
                    }
                    </mat-chip-listbox>
                </div>
                
                <div class="d-flex gap-3 align-items-center">
                    <div class="d-flex align-items-baseline">
                        <button class="btn btn-link text-secondary" (click)="changeDate(aDate, -1)" title="Previous Day"><i class="fas fa-chevron-left"></i></button>
                        <mat-form-field>
                            <mat-label>Date</mat-label>
                            <input matInput readonly [matDatepicker]="aDatePicker" name="date" [(ngModel)]="aDate" (dateChange)="this.refresh();" required>
                            <mat-datepicker-toggle matSuffix [for]="aDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #aDatePicker name="date"></mat-datepicker>
                        </mat-form-field>
                        <button class="btn btn-link text-secondary" [disabled]="isToday()" (click)="changeDate(aDate, 1)" title="Next Day"><i class="fas fa-chevron-right"></i></button>
                    </div>

                @if (transTypeList.length > 0) {
                    <mat-form-field>
                        <mat-label>Type</mat-label>
                        <mat-select multiple [(value)]="setFilterList[TransTypeFilter]">
                        @for (t of transTypeList; track t;) {
                            <mat-option [value]="t" (click)="filterSets()">{{allTransTypeDict[t]}}</mat-option>
                        }
                        </mat-select>
                    </mat-form-field>
                }
                
                @if (getObjectKey(subtypeList).length > 0) {
                    <mat-form-field>
                        <mat-label>Subtype</mat-label>
                        <mat-select multiple [panelWidth]="null" [(value)]="setFilterList[TransSubTypeFilter]">
                        @for (t of getObjectKey(subtypeList); track t) {
                            <mat-optgroup class="fw-semibold" [label]="allTransTypeDict[t]">
                            @for (s of subtypeList[t]; track s) {
                                <mat-option [value]="s.ID" (click)="filterSets()">{{ s.Name }}</mat-option>
                            }
                            </mat-optgroup>
                        }
                        </mat-select>
                    </mat-form-field>
                }
                </div>
                
            @if (compFilterList.length > 0) {
                <div class="mb-2">
                    <div>Filter from:</div>
                    <mat-chip-list>
                        <mat-chip class="m-1 bg-danger-subtle" (click)="compFilterList.length = 0; filterSets()"><span class="text-danger-emphasis"><i class="far fa-trash-alt"></i> Clear All</span></mat-chip>
                    @for (c of compFilterList; track c; let last = $last) {
                        <mat-chip class="m-1" (removed)="filterComp(c)">
                            {{c}}
                            <button matChipRemove><svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 5L5 19M5.00001 5L19 19" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg></button>
                        </mat-chip>
                    }
                    </mat-chip-list>
                </div>
            }
                
            @if (flattenedFilters.length > 0) {
                <div>Filters:</div>
                <mat-chip-list>
                    <mat-chip class="m-1 bg-danger-subtle" (click)="setFilterList = {}; filterSets()"><span class="text-danger-emphasis"><i class="far fa-trash-alt"></i> Clear All</span></mat-chip>
                @for (f of flattenedFilters; track f; let last = $last) {
                    <mat-chip class="m-1" (removed)="filterBy(f.name, f.value)">
                        {{f.name}}={{f.label}}
                        <button matChipRemove><svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 5L5 19M5.00001 5L19 19" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg></button>
                    </mat-chip>
                    @if (!last) { <span>OR</span> }
                }
                </mat-chip-list>
            }
            </div>

            
        @if(setList.length>0){
            <div class="input-group w-25 rounded mb-3 focus-ring-primary" style="background-color: #f5f5f5;">
                <input type="text" placeholder="Search" class="form-control bg-transparent border-0 focus-ring focus-ring-light" [(ngModel)]="searchText" (keyup)="filterSets()">
                <button class="btn text-secondary border-0" [ngClass]="{'disabled' : searchText===''}" type="button" (click)="searchText=''; filterSets()"><i class="fas fa-times"></i></button>
            </div>
            <div class="d-flex justify-content-between align-items-baseline">
                <h6>{{filteredSetList.length}} of {{setList.length}} results</h6>
                <mat-slide-toggle class="mat-slide-toggle-lg" [hideIcon]="true" color="primary" [(ngModel)]="pendingOnly" (change)="filterSets()">Show Pending Only</mat-slide-toggle>
            </div>            
            <div class="masonry-col-2">
                <div *ngFor="let set of filteredSetList">
                    <app-receipt-details [receipt]="set" [expenseSubtypeList]="expenseSubtypeList" [dlSubtypeList]="dlSubtypeList" (passEntry)="refresh()"></app-receipt-details>
                </div>
            </div>
            @if (allDone) {
                <div class="my-3">
                    <h3 class="text-center">All Done!</h3>
                    <div class="text-center mb-3"><img class="me-3" [src]="doneImgLink" style="width: 250px;"></div>
                </div>
            }
        } @else { 
            <h6>You do not have any receipt to check on {{ aDate | date: 'dd/MM/yyyy' }}.</h6>

            @if (this.prevSetList.length > 0) {
                <ng-container *ngTemplateOutlet="prevReceipt"></ng-container>
            }

        }
        </div>
    </div>
</div>

<ng-template #prevReceipt>
    <h4 class="mb-0">Check previous receipt: </h4>
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex my-2">
            <button class="btn btn-sm btn-outline-secondary rounded-pill m-1" (click)="getPreviousReceipt()"><i class="fas fa-redo-alt"></i> Reset</button>
        @for (s of SubtypeShortcut; track s) {
            @if (allTransSubTypeDict[s]) { <button class="btn btn-sm btn-outline-secondary rounded-pill m-1" (click)="getPreviousReceipt({subtype: s})">{{allTransSubTypeDict[s]}}</button> }
        }
        </div>
        <div class="progress w-25" role="progressbar">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success overflow-visible" [style.width.%]="prevDonePercentage">{{prevDonePercentage}}%</div>
        </div>
    </div>
    @if (prevDonePercentage == 100) {
        <div class="text-center"><img class="me-3" [src]="doneImgLink" style="width: 250px;"></div>
    }
    <div class="masonry-col-2">
        <div *ngFor="let set of prevSetList">
            <app-receipt-details [receipt]="set" [expenseSubtypeList]="expenseSubtypeList" [dlSubtypeList]="dlSubtypeList" (passEntry)="prevRefresh(set.ID)"></app-receipt-details>
        </div>
    </div>
</ng-template>
