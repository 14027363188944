import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConstructAPI } from "src/app/API/constructAPI";
import { RestApiService } from "src/app/API/restapi";
import { BatchPaymentComponent } from "src/app/LoanPayment/batch-payment/batch-payment.component";
import { Enums } from "src/app/Shared/enums";
import { SharedService } from "src/app/Shared/shared-service.service";
import { Account } from "src/model/account.model";
import { Customer } from "src/model/customer.model";
import { Loan } from "src/model/loan.model";
import { Profile } from "src/model/profile.model";
import { AddSimpanComponent } from "../add-simpan/add-simpan.component";
import { UploadImageComponent } from "src/app/Images/upload-image/upload-image.component";
import { ViewReserveGroupsComponent } from "../view-reserve-groups/view-reserve-groups.component";
import { DatePipe } from "@angular/common";

interface ReserveDistribution {
  companyId: number;
  companyName: string;
  amount: number;
  isMain: boolean;
}

@Component({
  selector: "app-add-reserve-group",
  templateUrl: "./add-reserve-group.component.html",
  styleUrls: ["./add-reserve-group.component.css"],
  providers: [DatePipe]
})
export class AddReserveGroupComponent implements OnInit {
  @Input() sharedProfileId: number;
  @Input() selectedCustomer: Customer;
  @Input() selectedProfile: Profile;
  @Input() isModal: boolean = true;

  isContraAccount: boolean = false;
  reserveDistributionList: ReserveDistribution[] = new Array();

  totalReserve: number = 0;

  disabledSubmit = false;
  companyBankAccountList: Account[] = new Array();
  cashAccountList: Account[] = new Array();

  selectedAccountId: number;
  simpanDate: Date;
  loggedInCompanyId: number;

  @Input() selectedProfileId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    public sharedService: SharedService,
    private restApi: RestApiService,
    private constructApi: ConstructAPI,
    private enums: Enums, private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getSharedProfileDetails();
    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));
    this.getAllAccountsOfCompany(this.loggedInCompanyId);
  }

  async checkNull() {
    var errorStr = "";

    var validCheckIn = await this.validateCheckInBeforeSubmit();


    if (validCheckIn) {
      this.reserveDistributionList.forEach((distribution) => {
        if (distribution.amount == undefined || distribution.amount == null)
          errorStr +=
            "The payment amount for company " +
            distribution.companyName +
            " cannot leave empty!\n";
      });
    }
    else {
      errorStr += "Cannot submit reserve group on " + this.datepipe.transform(this.simpanDate, "dd-MM-yyyy") + ", please check your verify date!"
    }

    return errorStr;
  }

  checkValidity() {
    if (this.isContraAccount == false) {
      var totalMainOutAmount = this.calculateTotalMainDistribution();
      if (this.selectedAccountId == undefined || this.simpanDate == undefined)
        this.sharedService.openAlert(
          "Please select account and date",
          this.enums.DANGER_ALERT
        );
      else if (this.calculateTotalDistribution() == 0) {
        this.sharedService.openAlert(
          "Please key in each company payment",
          this.enums.DANGER_ALERT
        );
      } else if (this.totalReserve + totalMainOutAmount < 0) {
        this.sharedService.openAlert(
          "Main company out simpan amount is more than total simpan customer have, please check amount!",
          this.enums.DANGER_ALERT
        );
      } else {
        var main = this.reserveDistributionList.findIndex(
          (distribution) => distribution.isMain == true
        );
        this.reserveDistributionList[main].amount = totalMainOutAmount;

        this.submit();
      }
    } else this.submit();
  }

  async submit() {
    this.disabledSubmit = true;
    var errorStr = await this.checkNull();

    var data = {
      isContra: this.isContraAccount,
      distribution: this.reserveDistributionList.filter(rg => rg.amount != 0),
    };
    if (errorStr == "") {
      var xhr = this.restApi.postRequest(
        this.constructApi.getAddProfileReserveGroup(this.sharedProfileId),
        data
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disabledSubmit = false;
          if (xhr.status == 200) {
            var json = JSON.parse(xhr.responseText);


            //check if logged in company have any distributed amount, if not no need to accept
            var ownDistribution = this.reserveDistributionList.find(rg => rg.companyId == this.loggedInCompanyId);
            if (ownDistribution.amount != 0 && ownDistribution != undefined)
              this.acceptReserveGroup(Number(json.id));
            else
              this.openViewReserveGroupModal(Number(json.id));

          }
          else this.sharedService.openErrorMessage(xhr);
        }
      };
    } else {
      this.disabledSubmit = false;
      this.sharedService.openAlert(errorStr, this.enums.DANGER_ALERT);
    }
  }

  acceptReserveGroup(reserveGroupId: number) {
    var isoDate = new Date(Number(this.simpanDate)).toISOString();

    var remark =
      this.isContraAccount == true
        ? "RETURN TO HQ= "
        : "TRANSFER TO SHARED BRANCHES= ";
    var data = {
      remark:
        remark +
        this.selectedCustomer.Name +
        "(" +
        this.sharedService.processICNum(this.selectedCustomer.ICNumber) +
        ")",
      accountId: Number(this.selectedAccountId),
      dateCreated: isoDate,
    };
    var xhr = this.restApi.postRequest(
      this.constructApi.getAcceptReserveGroup(reserveGroupId),
      data
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          this.sharedService.openAlert(
            "Successfully added simpan distribution!",
            this.enums.SUCCESS_ALERT
          );
          // window.location.reload();
          this.activeModal.close();

          var addedSimpanIndex = this.reserveDistributionList.findIndex(
            (distribution) => distribution.companyId == this.loggedInCompanyId
          );

          this.passEntry.emit(
            this.reserveDistributionList[addedSimpanIndex].amount
          );
          // this.openUploadReserveGroupDocModal(reserveGroupId);

        } else this.sharedService.openErrorMessage(xhr);

        this.openViewReserveGroupModal(reserveGroupId);

      }
    };
  }


  openViewReserveGroupModal(reserveGroupId: number) {
    const modalRef = this.sharedService.openModal(ViewReserveGroupsComponent);

    modalRef.componentInstance.reserveGroupId = reserveGroupId;

  }

  topUpSimpan() {
    var modalRef = this.sharedService.openModal(AddSimpanComponent);
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.selectedProfileID = this.sharedProfileId;
    modalRef.componentInstance.selectedProfile = this.selectedProfile;
    modalRef.componentInstance.selectedCustomer = this.selectedCustomer;

    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var addedSimpan = entry;

      this.totalReserve += addedSimpan;
    });
  }

  reset() {
    this.activeModal.close();
  }



  addSimpan() {
    this.disabledSubmit = true;
    var isoSimpanDate = new Date(Number(this.simpanDate)).toISOString();

    var reserveAmount = 0;
    this.reserveDistributionList.forEach((distribution) => {
      reserveAmount += distribution.amount;
    });

    var data = {
      reserve: reserveAmount,
      accountId: Number(this.selectedAccountId),
      dateCreated: isoSimpanDate,
      remark: "RESERVE GROUP",
    };

    var xhr = this.restApi.postRequest(
      this.constructApi.getAddReserve(this.sharedProfileId),
      data
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disabledSubmit = false;
        if (xhr.status == 200) {
          //deduct amount of distribution to other companies from main company distribution
          var index = this.reserveDistributionList.findIndex(
            (distribution) =>
              Number(distribution.companyId) == Number(this.loggedInCompanyId)
          );
          if (index != -1) {
            this.reserveDistributionList[index].amount =
              this.reserveDistributionList[index].amount - reserveAmount;
            this.submit();
          }
        } else this.sharedService.openErrorMessage(xhr);
      }
    };
  }

  getAllAccountsOfCompany(companyId: number) {
    var loggedInUsername = sessionStorage.getItem("username");
    var xhttp = this.restApi.getRequest(
      this.constructApi.getAccountsOfCompany(companyId)
    );
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse(xhttp.responseText);

        jsonArray.forEach((json) => {
          let account = new Account(
            json.id,
            json.number,
            json.name,
            json.holderName,
            json.balance,
            json.companyId,
            json.username
          );

          if (account.Username == undefined)
            this.companyBankAccountList.push(account);
          else {
            if (this.sharedService.isStaff()) {
              if (account.Username == loggedInUsername)
                this.cashAccountList.push(account);
            } else {
              this.cashAccountList.push(account);
            }
          }
        });
      }
    };
  }

  getSharedProfileDetails() {
    this.reserveDistributionList = [];

    var xhttp = this.restApi.getRequest(
      this.constructApi.getSharedProfile(this.sharedProfileId)
    );
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse(xhttp.responseText);
        var profilesArr = json.profiles;

        profilesArr.forEach((profileJson) => {
          var distribution = {
            companyId: profileJson.companyId,
            companyName: profileJson.companyCode,
            amount: 0,
            isMain: profileJson.profileId == this.sharedProfileId,
          };
          this.reserveDistributionList.push(distribution);

          this.totalReserve += profileJson.reserve;
        });
      }
    };
  }



  calculateTotalMainDistribution() {
    var total = 0;
    this.reserveDistributionList.forEach((distribution) => {
      var amount = distribution.amount == undefined ? 0 : distribution.amount;
      total -= amount;
    });

    return total;
  }

  calculateTotalDistribution() {
    var total = 0;
    this.reserveDistributionList.forEach((distribution) => {
      var amount = distribution.amount == undefined ? 0 : distribution.amount;
      total += amount;
    });

    return total;
  }


  getCompanyLatestCheckIn(companyId: number) {

    return new Promise<Date>(resolve => {
      var xhttp = this.restApi.getRequest(this.constructApi.getCompaniesLatestCheckIns(companyId))
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          var jsonArray = JSON.parse((xhttp.responseText));
          jsonArray.forEach(json => {


            resolve(new Date(json.date));
          }
          );

        }
      }
    });



  }


  async validateCheckInBeforeSubmit() {
    var latestCheckInDate = await this.getCompanyLatestCheckIn(this.loggedInCompanyId)

    return this.sharedService.isAcctCheckedInBeforeAction(this.simpanDate, latestCheckInDate);
  }
}
