import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface Report {
  DateCreated: Date,
  Outstanding: number,
  CustomerName: string,
  CustomerId: number,
  ReceiptNumber: string,
  Principal: number,
  RemainingTerm: number,
  Status: number
}

@Component({
  selector: 'app-loan-scheme-report',
  templateUrl: './loan-scheme-report.component.html',
  styleUrls: ['./loan-scheme-report.component.css']
})
export class LoanSchemeReportComponent implements OnInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource;
  showRemark: string;

  @Input() selectedCompany: number;
  @Input() selectedReport: number;
  @Input() schemeName: string;

  reportList: Report[] = new Array;

  routeSubscription: any;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private router: Router, private restapi: RestApiService, private activatedRoute: ActivatedRoute,
    private constructApi: ConstructAPI, private enums: Enums, private sharedService: SharedService) { }

  ngOnInit(): void {

    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = + params.get('companyId');
      this.selectedReport = + params.get('reportId');
      this.schemeName = params.get('scheme');

      this.getReport();

      if (this.schemeName == 'A')
        this.displayedColumns = ['DateCreated', 'ReceiptNumber', 'CustomerName', 'Principal', 'Outstanding', 'Status'];
      else
        this.displayedColumns = ['DateCreated', 'ReceiptNumber', 'CustomerName', 'Principal', 'Outstanding', 'RemainingTerm', 'Status'];


    });

  }

  refreshDataSource() {
    this.dataSource.data = this.reportList;
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  convertStatusString(loanStatus: number) {

    if (loanStatus == this.enums.ACTIVE_LOAN)
      return "ACTIVE"
    else if (loanStatus == this.enums.WARNING_LOAN)
      return "WARNING"
    else if (loanStatus == this.enums.SETTLED_LOAN)
      return "SETTLED"
    else if (loanStatus == this.enums.BAD_DEBT_LOAN)
      return "BAD DEBT"
    else
      return "CP";
  }

  getStatusColor(status: number) {

    var color: string;

    switch (status) {
      case this.enums.ACTIVE_CUST:
        color = "bg-primary";
        break;
      case this.enums.WARNING_CUST:
        color = "bg-warning";
        break;
      case this.enums.BAD_DEBT_CUST:
        color = "bg-dark";
        break;
      case this.enums.CP_CUST:
        color = "bg-info";
        break;
      default:
        color = "bg-success";
        break;
    }
    return color;
  }

  resetList() {
    this.reportList.splice(0, this.reportList.length);
  }


  calculateTotalPrincipal() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++)
      sum += this.reportList[i].Principal;

    return sum;
  }

  calculateTotalOutstanding() {
    var sum = 0;
    for (let i = 0; i < this.reportList.length; i++)
      sum += this.reportList[i].Outstanding;

    return sum;
  }

  goToCustomer(customerId: number) {
    this.router.navigate(['/manageCustomer', customerId]);
  }

  getReport() {
    this.resetList();

    var schemes: string[] = [];
    // if (this.schemeName == 'P' && this.sharedService.isBranchAccountHandler())
    //   schemes = ['P', 'EPF'];
    // else
    schemes.push(this.schemeName);

    var xhttp = this.restapi.getRequest(this.constructApi.getCompanyLoanScehmeLoans(this.selectedCompany, schemes));

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var report;

          if (Number(json.balance) != 0) {

            var remainingTerm = Number(json.balance) / Number(json.monthlyPayment);
            report = { CustomerName: json.customerName, CustomerId: json.customerId, Outstanding: json.balance, Principal: json.principal, ReceiptNumber: json.receiptNumber, DateCreated: json.dateCreated, RemainingTerm: remainingTerm, Status: json.status }
            this.reportList.push(report);
          }
        }
        );

        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<Report>(this.reportList);
        //  this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    };
  }
}
