import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { CompanyAccess } from 'src/model/companyAccess.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { LoanGroupRequest } from 'src/model/sharedLoan.model';
import { TransactionRequest } from 'src/model/transactionRequest.model';
import { LoanRequestService } from 'src/services/loan-request.service';
import { AdminAccessService } from 'src/services/admin-access.service';
import { SetService } from 'src/services/set.service';

interface Usage {
  CompanyCode: string,
  CompanyId: number,
  LastInsertedDate: Date,
  LatestDate: Date,
  Group: number
}

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {

  loanRequestList: LoanRequest[] = new Array;
  approvedWithoutloanRequestList: LoanRequest[] = new Array;
  wholeApprovedLoanRequestList: LoanRequest[] = new Array;
  wholeLoanRequestList: LoanRequest[] = new Array;
  normalLoanRequestList: LoanRequest[] = new Array;
  wholeNormalLoanRequestList: LoanRequest[] = new Array;
  sharedLoanRequestList: LoanGroupRequest[] = new Array;
  wholeSharedLoanRequestList: LoanGroupRequest[] = new Array;

  transactionRequestList: TransactionRequest[] = new Array;

  usageList: Usage[] = new Array;

  searchText: string = "";
  searchApprovedText: string = "";
  searchNormalText: string = "";

  setCountToday = 0;
  setCountPending = 0;
  setCountDone = 0;
  setDonePercentage = 0;
  img = '';

  prevSetDate: Date;

  clipboardCompareMessage = "";
  compareRequest: LoanRequest;

  companyAccess: {[key: string]: CompanyAccess[]} = {};
  constructor(private adminAccess: AdminAccessService, private router: Router, private restApi: RestApiService, private constructApi: ConstructAPI, private cdr: ChangeDetectorRef, public sharedService: SharedService, private enums: Enums, private setService: SetService, private loanRequestService: LoanRequestService) { }

  ngOnInit() {
    if (this.sharedService.isAdmin()) this.getTodayReceiptCount();
    this.getAllLoanRequests();
    this.getAllUsageStats();
    this.getAllAssignedTransactionList();
    if (this.sharedService.isAdmin() || this.sharedService.isBossAccount()) this.getUserCompanyAccess();
  }

  getAllUsageStats() {
    var xhttp = this.restApi.getRequest(this.constructApi.getAllUsageStats(false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          let usage = { CompanyCode: json.companyCode, CompanyId: json.companyId, LastInsertedDate: json.lastInserted, LatestDate: json.latestDate, Group: json.group };

          var timeDiff = new Date().getTime() - new Date(usage.LatestDate).getTime();
          var dateDiff = timeDiff / (1000 * 3600 * 24); 

          // if this branch never do account for more than 5 days then only show on dashboard
          if (dateDiff > 5 || usage.LatestDate == undefined)
            this.usageList.push(usage);

        });

        this.usageList.sort((a, b) => (a.LatestDate > b.LatestDate) ? 1 : -1);
      }
    };
  }

  async getAllLoanRequests() {
    const loanList = await this.loanRequestService.getAllLoanApprovalRequest({ withLoan: false, approved: true, includeUnprocessed: true, authorized: true });

    this.normalLoanRequestList = loanList.filter(x => (x.LoanInfo.Scheme !== 'EPF' && x.Approved == undefined));
    this.approvedWithoutloanRequestList = loanList.filter(x => (x.LoanInfo.Scheme == 'EPF' && x.Approved !== undefined));
    this.loanRequestList = loanList.filter(x => x.LoanInfo.Scheme == 'EPF' && x.Approved == undefined && x.LoanGroupId == undefined);

    this.wholeNormalLoanRequestList = [...this.normalLoanRequestList];
    this.wholeApprovedLoanRequestList = [...this.approvedWithoutloanRequestList];
    this.wholeLoanRequestList = [...this.loanRequestList];

    const sharedLoanList = loanList.filter(x => x.LoanInfo.Scheme == 'EPF' && x.Approved == undefined && x.LoanGroupId !== undefined);
    const loanGroupList = this.sharedService.groupBy(sharedLoanList, 'LoanGroupId');
    this.sharedLoanRequestList = Object.keys(loanGroupList).map<LoanGroupRequest>(key => ({
      loanGroup: Number(key),
      requestList: loanGroupList[key]
    }));
    this.wholeSharedLoanRequestList = [...this.sharedLoanRequestList];

    this.cdr.detectChanges();

    this.loanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);
    this.wholeLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);

    this.approvedWithoutloanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);
    this.wholeApprovedLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn) ? 1 : -1);

    this.normalLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn || a.LoanInfo.Scheme != "HUTANG") ? 1 : -1);
    this.wholeNormalLoanRequestList.sort((a, b) => (a.Priority > b.Priority || a.UpdatedOn > b.UpdatedOn || a.LoanInfo.Scheme != "HUTANG") ? 1 : -1);
  }

  applyFilter() {
    if (this.searchText == "") {
      this.loanRequestList = this.wholeLoanRequestList;
      this.sharedLoanRequestList = this.wholeSharedLoanRequestList;
    } else {
      this.loanRequestList = this.wholeLoanRequestList.filter(request => request.ID == Number(this.searchText));
      this.sharedLoanRequestList = this.wholeSharedLoanRequestList.filter(group => group.requestList.find(request => request.ID == Number(this.searchText)));
    }
  }

  applySearchNormalFilter() {
    if (this.searchNormalText == "")
      this.normalLoanRequestList = this.wholeNormalLoanRequestList;
    else
      this.normalLoanRequestList = this.wholeNormalLoanRequestList.filter(request => request.CustomerIC.toUpperCase().includes(this.searchNormalText.toUpperCase()) || request.CustomerName.toUpperCase().includes(this.searchNormalText.toUpperCase()));
  }

  applyApprovedFilter() {
    if (this.searchApprovedText == "")
      this.approvedWithoutloanRequestList = this.wholeApprovedLoanRequestList;
    else
      this.approvedWithoutloanRequestList = this.wholeApprovedLoanRequestList.filter(request => request.ID == Number(this.searchApprovedText));
  }

  async getTodayReceiptCount() {
    const [allReceipt, pendingReceipt] = await Promise.all([
      this.setService.getTodayReceiptCount({ priority: this.enums.FIRST_PRIORITY }),
      this.setService.getTodayReceiptCount({ pending: true, priority: this.enums.FIRST_PRIORITY })
    ]);
    this.setCountToday = allReceipt.reduce((sum, current) => sum + current.count, 0);
    this.setCountPending = pendingReceipt.reduce((sum, current) => sum + current.count, 0);
    this.setCountDone = this.setCountToday - pendingReceipt.reduce((sum, current) => sum + current.count, 0);
    this.setDonePercentage = Math.round(this.setCountDone / this.setCountToday * 100);

    if (this.setCountToday == 0) {
      this.img = this.sharedService.getImage(this.enums.emptyImages);
      const a = await this.setService.getAllTxSet({ priority: this.enums.FIRST_PRIORITY, limit: 1, sortASC: false, pending: true });
      if (a.length > 0) this.prevSetDate = a[0].CreatedOn;
    } else this.img = (this.setDonePercentage == 100) ? this.sharedService.getImage(this.enums.doneImages) : this.sharedService.getImage(this.enums.pendingImages);
  }

  checkPreviousReceipt() {
    this.router.navigate(['/receiptTodo'], { queryParams: { date: this.prevSetDate.toISOString().split('T')[0] } })
  }

  goToTodayReceipts() {
    this.router.navigate(['/receiptTodo']);
  }

  goToReceipts() {
    this.router.navigate(['/receiptList']);
  }

  goToSettlementInfoCheck() {
    this.router.navigate(['/settlementInfoCheck']);
  }

  getAllAssignedTransactionList() {
    //only get trasactionRequest without transaction
    var xhttp = this.restApi.getRequest(this.constructApi.getAllTransactionRequests(false))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var itemsArray = json.items;
          var itemList = new Array;
          itemsArray.forEach(itemJson => {
            var item = { "amount": itemJson.amount, "type": itemJson.type, "subtype": itemJson.subtype }

            itemList.push(item);
          });

          var transactionRequest = new TransactionRequest(json.id, json.companyCode, json.companyid, json.remark, json.requestedBy, json.requestedOn, itemList);
          this.transactionRequestList.push(transactionRequest);
        });
      }
    };
  }

  clickPaste() {
    navigator.clipboard.readText().then(
      text => this.clipboardCompareMessage = text
    ).catch(error => {
      console.error('Cannot read clipboard text: ', error);
    });
  }

  filterAndCompareMessage() {
    if (this.clipboardCompareMessage != '' && this.clipboardCompareMessage != undefined) {
      let regexp: RegExp = /\#(\d+)/g;

      //will get ticket number with leading #
      var ticketNo = this.clipboardCompareMessage.match(regexp)

      if (ticketNo != undefined && ticketNo != null) {
        //remove # to get ticket number and do filter
        this.searchText = ticketNo[0].replace('#', "");
        this.applyFilter();

        //search the request in request list
        //if found, add the request into whatsapp clipboard component
        //if not found, search in shared loan request list
        var index = this.wholeLoanRequestList.findIndex(loanRequest => Number(loanRequest.ID) == Number(this.searchText));
        if (index != -1) {
          this.compareRequest = this.wholeLoanRequestList[index];

          var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent, "regularModal");
          modalRef.componentInstance.isEPFRequest = true;
          modalRef.componentInstance.requestList = [this.compareRequest];
          modalRef.componentInstance.compareString = this.clipboardCompareMessage;
        } else {
          //if the ticket number is not found in loan request list, find in shared loan request list
          var groupIndex = this.wholeSharedLoanRequestList.findIndex(group => group.requestList.find(request => Number(request.ID) == Number(this.searchText)));

          if (groupIndex != -1) {
            var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent, "regularModal");
            modalRef.componentInstance.isEPFRequest = true;

            var list = this.wholeSharedLoanRequestList[groupIndex].requestList.sort((a, b) => ((a.ID > b.ID)) ? -1 : 1);
            modalRef.componentInstance.requestList = list;
            modalRef.componentInstance.compareString = this.clipboardCompareMessage;
            modalRef.componentInstance.loanGroupId = this.wholeSharedLoanRequestList[groupIndex].loanGroup;
          } else {
            this.sharedService.openAlert("Ticket with this ticket number not existed!", this.enums.DANGER_ALERT);
          }
        }
      } else this.sharedService.openAlert("No record found! Please check pasted messsage!", this.enums.DANGER_ALERT);
    } else {
      this.searchText = "";
      this.applyFilter();
    }
  }

  async getUserCompanyAccess() {
    const priorities: number[] = [this.enums.FIRST_PRIORITY, this.enums.SECOND_PRIORITY, this.enums.THIRD_PRIORITY];
    const a = await this.adminAccess.getCompaniesAccess();
    const r = this.sharedService.groupBy(a, 'CompanyId');
    Object.keys(r).forEach(key => {
      this.companyAccess[key] = r[key].filter(a => priorities.includes(a.Priority));
      this.companyAccess[key].sort((a, b) => a.Priority - b.Priority);
    });
  }
}