import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { DeployWebappComponent } from '../deploy-webapp/deploy-webapp.component';
import { VersionStatus } from 'src/app/Shared/versionStatus';
import { MaintenanceModeToggleComponent } from '../maintenance-mode-toggle/maintenance-mode-toggle.component';
import { ServerService, ServerStatus } from 'src/services/server.service';

@Component({
  selector: 'app-server-status-page',
  templateUrl: './server-status-page.component.html',
  styleUrls: ['./server-status-page.component.css']
})
export class ServerStatusPageComponent implements OnInit {

  serverStatus: ServerStatus;
  signedInUserList: string[] = new Array;

  errorCode: string;
  errorLogList: string[] = new Array;

  time: number = 10;
  constructor(public sharedService: SharedService,public versionStatus:VersionStatus, private serverService: ServerService) { }

  ngOnInit(): void {
    this.getServerStatus();
    this.getSignedInUsers();
    this.getErrorLog();
  }



  refreshEverything() {
    this.serverStatus = undefined;
    this.errorLogList = undefined;
    this.signedInUserList.splice(0);

    this.getServerStatus();
    this.getSignedInUsers();
    this.getErrorLog();
  }

  async getServerStatus() {
    this.serverStatus = await this.serverService.getServerStatus();
  }

  async getErrorLog(errorCode?: string) {
    this.errorLogList = await this.serverService.getErrorLog(errorCode);
  }

  async getSignedInUsers() {
    this.signedInUserList = await this.serverService.getSignedInUsername("30s");    
  }
  
  openMaintenaceModeModal(value: boolean) {
    const modalRef = this.sharedService.openModal(MaintenanceModeToggleComponent);
    modalRef.componentInstance.turn_on = value;
    modalRef.result.then(r => this.getServerStatus());
  }

  openDeployWebAppModal() {
    var modalRef = this.sharedService.openModal(DeployWebappComponent, "regularModal");
  }


}
