export class CheckIn {
    constructor(private _id?: number, private _date?: Date, private _dateCreated?: Date, private _companyID?: number, private _remark?: string, private _username?: string, private _role?: string, private _roleID?: number) { }

    set ID(id: number) { this._id = id }
    get ID(): number { return this._id }

    set CompanyID(companyID: number) { this._companyID = companyID }
    get CompanyID(): number { return this._companyID }

    set Date(date: Date) { this._date = date }
    get Date(): Date { return this._date }

    set DateCreated(dateCreated: Date) { this._dateCreated = dateCreated }
    get DateCreated(): Date { return this._dateCreated }

    set Remark(remark: string) { this._remark = remark }
    get Remark(): string { return this._remark }

    set Username(username: string) { this._username = username }
    get Username(): string { return this._username }

    set Role(role: string) { this._role = role }
    get Role(): string { return this._role }

    set RoleID(roleID: number) { this._roleID = roleID }
    get RoleID(): number { return this._roleID }
}