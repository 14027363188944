import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core'; import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { DuitLebih } from 'src/model/duitLebih.model';


import { Loan } from 'src/model/loan.model';
import { LoanRequest } from 'src/model/loanRequest.model';
import { Enums } from '../enums';
import { SharedService } from '../shared-service.service';
import { ReviewLoanRequestComponent } from 'src/app/LoanRequest/review-loan-request/review-loan-request.component';
import { SettlementCharges } from 'src/model/settlementCharges.model';


@Component({
  selector: 'app-show-whatsapp-message-clipboard',
  templateUrl: './show-whatsapp-message-clipboard.component.html',
  styleUrls: ['./show-whatsapp-message-clipboard.component.css'],
  providers: [DatePipe]
})
export class ShowWhatsappMessageClipboardComponent implements OnInit {

  @Input() requestList: LoanRequest[] = new Array;
  @Input() loanGroupId: number;
  // @Input() previousApprovedRequestList: LoanRequest[];
  @Input() isDirty = false;

  @Input() previousEPFLoanList: Loan[];
  @Input() isEPFRequest: boolean;

  settlement = 0;
  settlementWithCharges = 0;
  ratio = 0;
  value: string;
  @Input() compareString: string = "";

  previousSettlement: number = 0;
  previousSettlementRatio: number = 0;
  constructor(public sharedService: SharedService, private activeModal: NgbActiveModal,
    private constructApi: ConstructAPI, private restApi: RestApiService, private datepipe: DatePipe, private enums: Enums) { }

  ngOnInit(): void {
    //sort request list by request ID
    this.requestList.sort((a, b) => ((a.ID > b.ID)) ? -1 : 1);

    //if epf request need to load all previous epf loan lists
    if (this.isEPFRequest == true) {
      if (this.previousEPFLoanList != undefined) {
        if (this.previousEPFLoanList.length > 0) {
          for (let i = 0; i < this.previousEPFLoanList.length; i++) {
            var termLeft = this.sharedService.roundTo2Dec(this.previousEPFLoanList[i].Balance / this.previousEPFLoanList[i].MonthlyPayment);

            if (this.previousEPFLoanList[i].Scheme == 'EPF') {
              var previousSettlementWithoutCharge = this.previousEPFLoanList[i].Principal + (this.previousEPFLoanList[i].Term *
                this.previousEPFLoanList[i].Principal * (this.previousEPFLoanList[i].InterestRate / 100));

              if (this.previousEPFLoanList[i].SettlementCharges == undefined)
                this.previousSettlement += previousSettlementWithoutCharge + this.previousEPFLoanList[i].SettlementExtra +
                  this.previousEPFLoanList[i].SettlementServiceCharge + this.previousEPFLoanList[i].SettlementStamp;
              else
                this.previousSettlement += previousSettlementWithoutCharge + (this.previousEPFLoanList[i].SettlementCharges.Extras) +
                  (this.previousEPFLoanList[i].SettlementCharges.ServiceCharge) + (this.previousEPFLoanList[i].SettlementCharges.Stamp);
            }

            else
              this.previousSettlement += this.previousEPFLoanList[i].LegacySettlement

            this.previousSettlementRatio = ((this.previousSettlement / this.requestList[0].EpfAmount) * 100);
          }
          this.constructEPFMessage();
        } else {
          this.getAllEPFLoans();
        }

      }
      else {
        this.getAllEPFLoans();
      }
    }

    //if normal loan straight construct message;
    else
      this.constructNormalLoanRequestMessage();
  }



  processDuitLebih() {
    var duitLebih = 0;
    this.requestList.forEach(request => {
      if (request.LoanInfo.Extras != undefined && request.LoanInfo.Extras.length > 0) {
        for (let i = 0; i < request.LoanInfo.Extras.length; i++) {
          duitLebih += request.LoanInfo.Extras[i].Amount;
        }
      }
    });

    return duitLebih;
  }

  constructNormalLoanRequestMessage() {
    var duitLebih = this.processDuitLebih();

    var str =
      "*COMPANY NAME: " + this.requestList[0].CompanyCode + "*\n" +
      "1) NAMA : " + this.requestList[0].CustomerName + "\n" +
      "2)  I/C : " + this.sharedService.processICNum(this.requestList[0].CustomerIC) + "\n" +
      "3) PROCESSING FEE : " + this.requestList[0].LoanInfo.ProcessingRate + "%\n" +
      "4) TEMPOH LOAN : " + this.requestList[0].LoanInfo.Term + " bulan (" + this.sharedService.roundTo2Dec(this.requestList[0].LoanInfo.InterestRate) + "%)\n" +
      "5) DUIT LEBIH : " + duitLebih + "\n" +
      "------------------------------------------------------\n\n" +
      "8) TOTAL LOAN : " + this.requestList[0].LoanInfo.Principal + "\n" +
      "9) SKIM: " + this.requestList[0].LoanInfo.Scheme + "\n" +
      "10) DPT TANGAN BERAPA : RM " + this.calculateTotalOnHand() + "\n" +
      "11) REMARK: " + this.requestList[0].Remark + "\n" +
      "------------------------------------------------------\n\n";
    this.value = "*Loan Request #" + this.requestList[0].ID + "*\n\n" + str;

  }

  processRemark() {
    var remark = "";
    this.requestList.forEach(request => {
      remark += request.Remark + "\n";
    });

    return remark;
  }

  combineAllRequestIds() {
    var ids = "";
    for (let i = 0; i < this.requestList.length; i++) {
      ids += "#" + this.requestList[i].ID;

      if (i != this.requestList.length - 1)
        ids += " | ";

    }

    return ids;
  }

  constructEPFMessage() {
    var duitLebih = this.processDuitLebih();
    var loanInfo = this.combineAllLoans();
    var remark = this.requestList[0].Remark;
    var requestIds = this.combineAllRequestIds();

    var iAccount = (this.requestList[0].HasIAccount == true) ? "YES" : "NO";

    var guarantorString = "";

    this.requestList[0].Guarantors.sort((a, b) => (a.Name > b.Name) ? 1 : -1);

    if (this.requestList[0].Guarantors.length > 0) {
      for (let i = 0; i < this.requestList[0].Guarantors.length; i++) {
        guarantorString +=
          "*GUARANTOR " + (i + 1) + "*\n" +
          "NAMA: " + this.requestList[0].Guarantors[i].Name + "\n" +
          "I/C: " + this.sharedService.processICNum(this.requestList[0].Guarantors[i].ICNumber) + "\n" +
          "Hubungan: " + this.requestList[0].Guarantors[i].Relation + "\n" +
          "---------------------------------------------------------\n";
      }
    }
    else
      guarantorString = "*NO GUARANTOR* \n" +
        "---------------------------------------------------------\n";


    var previousString = "";


    if (this.previousEPFLoanList.length > 0) {
      //List all previous loans
      for (let i = 0; i < this.previousEPFLoanList.length; i++) {
        var settlementString = "";
        if (this.previousEPFLoanList[i].Scheme == 'EPF') {
          settlementString = this.sharedService.roundTo2Dec(this.sharedService.calculateEPFSettlementAmountWithCharges(this.previousEPFLoanList[i])) + "\n"
        }
        else
          settlementString = this.previousEPFLoanList[i].LegacySettlement + "\n";

        var date = this.datepipe.transform(this.previousEPFLoanList[i].DateCreated, 'dd-MM-yyyy');
        previousString += date + " : RM" + this.sharedService.roundTo2Dec(this.previousEPFLoanList[i].Principal) + "➡️ RM" + settlementString

      }
    }




    var companyCodeStr = "";

    for (let i = 0; i < this.requestList.length; i++) {

      companyCodeStr += this.requestList[i].CompanyCode;
      if (i != this.requestList.length - 1)
        companyCodeStr += " | "

    }

    var str =
      "*COMPANY NAME: " + companyCodeStr + "*\n" +
      "1) NAMA : " + this.requestList[0].CustomerName + "\n" +
      "2)  I/C : " + this.sharedService.processICNum(this.requestList[0].CustomerIC) + "\n" +
      "3) DOB : " + this.datepipe.transform(this.requestList[0].DOB, "dd-MM-yyyy") + "\n" +
      "4) Other ID : " + (this.requestList[0].OtherId || "") + "\n" +
      "5) PROCESSING FEE : " + this.requestList[0].LoanInfo.ProcessingRate + "%\n" +
      "6) TEMPOH LOAN : " + this.requestList[0].LoanInfo.Term + " bulan (" + this.sharedService.roundTo2Dec(loanInfo.InterestRate) + "%)\n" +
      "7) DUIT LEBIH : " + this.sharedService.roundTo2Dec(duitLebih) + "\n" +
      "8) AMOUNT EPF : RM" + this.sharedService.roundTo2Dec(this.requestList[0].EpfAmount) + "\n" +
      "9) I-ACCOUNT KWSP: " + iAccount + "\n" +
      "------------------------------------------------------\n\n";

    var settlementStampString;
    var settlementSCString;
    var settlementDLString;
    var settlementHandlingChargeString;
    var individualLoanInfo = "";
    this.requestList.forEach(request => {

      if (request.LoanInfo.SettlementCharges != undefined) {
        settlementStampString = this.sharedService.roundTo2Dec(request.LoanInfo.SettlementCharges.Stamp / request.LoanInfo.Term) + "x" + request.LoanInfo.Term + "=RM " + request.LoanInfo.SettlementCharges.Stamp;
        settlementSCString = this.sharedService.roundTo2Dec(request.LoanInfo.SettlementCharges.ServiceCharge / request.LoanInfo.Term) + "x" + request.LoanInfo.Term + "=RM " + request.LoanInfo.SettlementCharges.ServiceCharge;
        settlementDLString = (request.LoanInfo.SettlementCharges.Extras == undefined) ? "0" : this.sharedService.roundTo2Dec(request.LoanInfo.SettlementCharges.Extras / request.LoanInfo.Term) + "x" + request.LoanInfo.Term + "=RM " + request.LoanInfo.SettlementCharges.Extras;
        settlementHandlingChargeString = (request.LoanInfo.SettlementCharges.HandlingCharge == undefined) ? "" : "- HANDLING CHARGE :  RM" + this.sharedService.roundTo2Dec(request.LoanInfo.SettlementCharges.HandlingCharge);
      } else {
        settlementStampString = request.LoanInfo.SettlementStamp;
        settlementSCString = request.LoanInfo.SettlementServiceCharge
        settlementDLString = request.LoanInfo.SettlementExtra
      }

      var mainCompanyIcon = (request.SharedProfileId == request.ProfileId && request.SharedProfileId != undefined)
        ? "*COMPANY:" + request.CompanyCode + " 🤝*\n"
        : "*COMPANY:" + request.CompanyCode + "*\n";

      individualLoanInfo +=
        mainCompanyIcon +
        "PRINCIPAL: " + request.LoanInfo.Principal + "\n" +
        "DPT TANGAN BERAPA : RM " + this.sharedService.roundTo2Dec(this.sharedService.calculateTotalOnHand(request.LoanInfo)) + "\n" +
        "*BAYAR BALIK  :RM " + this.sharedService.roundTo2Dec(this.sharedService.calculateEPFSettlementAmount(request.LoanInfo.Principal, request.LoanInfo.InterestRate, request.LoanInfo.Term)) + "*\n\n" +
        " SETTLEMENT CHARGES : \n" +
        "- SETTLEMENT STAMP : RM" + settlementStampString + " \n" +
        "- SETTLEMENT SC :  RM" + settlementSCString + " \n" +
        "- SETTLEMENT DL :  RM" + settlementDLString + " \n" +
        settlementHandlingChargeString + " \n\n" +
        "===================================\n\n";
    });


    var settlementWithCharges = this.sharedService.calculateEPFSettlementAmountWithCharges(loanInfo);
    var previousLoanSettlement = this.sharedService.calculatePreviousEPFLoanSettlement(this.previousEPFLoanList);

    var ratio = this.sharedService.calculateSettlementRatio(settlementWithCharges, this.requestList[0].EpfAmount);
    var previousLoanRatio = this.sharedService.calculateSettlementRatio(previousLoanSettlement, this.requestList[0].EpfAmount);

    var totalSummary =
      "*10) TOTAL LOAN : " + this.sharedService.roundTo2Dec(loanInfo.Principal) + "*\n" +
      "*11) TOTAL ON HAND : " + this.sharedService.roundTo2Dec(this.calculateTotalOnHand()) + "*\n" +
      "*12) ADDED SETTLEMENT (+CHARGES): RM" + this.sharedService.roundTo2Dec(settlementWithCharges) + "*\n" +
      "13) ADDED SETTLEMENT RATIO: " + ratio + "%\n" +
      "*14) TOTAL SETTLEMENT (+CHARGES): RM" + this.sharedService.roundTo2Dec(previousLoanSettlement + settlementWithCharges) + "*\n" +
      "*15) TOTAL SETTLEMENT RATIO: " + this.sharedService.roundTo2Dec(previousLoanRatio + ratio) + "%*\n" +
      "16) REMARK : " + (remark || "") + "\n" +
      "------------------------------------------------------\n\n" +
      "*GUARANTOR:*\n" + guarantorString + "\n"
    // +"VIEW MORE IN : https://" + window.location.host + "/manageCustomer/" + this.request.CustomerId + "/" + this.request.ProfileId




    if (this.previousEPFLoanList.length > 0) {

      if (this.loanGroupId != undefined) {
        this.value = "*EXTRA SHARE ⓢ" + this.loanGroupId + " - TICKET " + requestIds + "*\n\n" + str + individualLoanInfo + totalSummary
          + "*PREVIOUS APPROVED LOANS:*\n" + previousString + "\n\n"

        "------------------------------------------------------\n";
      }
      else {
        this.value = "*EXTRA - TICKET " + requestIds + "*\n\n" + str + individualLoanInfo + totalSummary
          + "*PREVIOUS APPROVED LOANS:*\n" + previousString + "\n\n"

        "------------------------------------------------------\n";
      }

    }

    else {
      if (this.loanGroupId != undefined)
        this.value = "*SHARE ⓢ" + this.loanGroupId + "- TICKET " + requestIds + "*\n\n" + str + individualLoanInfo + totalSummary;
      else
        this.value = "*TICKET " + requestIds + "*\n\n" + str + individualLoanInfo + totalSummary;
    }


  }





  combineAllLoans() {
    var loan: Loan = new Loan(0, 0, 0, 0, "", 0, new Date(), this.requestList[0].CompanyId, this.requestList[0].CompanyCode, this.requestList[0].LoanInfo.Scheme,
      0, this.requestList[0].LoanInfo.Term, undefined, undefined, 0, "", "", 0, 0, 0, 0, 0, 0);
    loan.Extras = new Array;
    loan.SettlementCharges = new SettlementCharges(0, 0, 0, 0);
    loan.SettlementExtra = 0;
    loan.SettlementServiceCharge = 0;
    loan.SettlementStamp = 0;


    this.requestList.forEach(request => {
      loan.Principal += request.LoanInfo.Principal;
      loan.Balance += request.LoanInfo.Balance;
      loan.InterestCharge += request.LoanInfo.InterestCharge;
      loan.Extras.concat(request.LoanInfo.Extras);
      loan.ServiceCharge += request.LoanInfo.ServiceCharge;
      loan.StampAmount += request.LoanInfo.StampAmount;
      loan.MonthlyPayment += this.sharedService.calculateMonthlyPayment(request.LoanInfo.InterestRate, request.LoanInfo.Principal, request.LoanInfo.Term)


      var requestSettlementInfo = (request.LoanInfo.SettlementCharges == undefined) ? new SettlementCharges(0, 0, 0, 0) : request.LoanInfo.SettlementCharges;
      loan.SettlementCharges.Stamp += requestSettlementInfo.Stamp || 0;
      loan.SettlementCharges.ServiceCharge += requestSettlementInfo.ServiceCharge || 0;
      loan.SettlementCharges.Extras += requestSettlementInfo.Extras || 0;
      loan.SettlementCharges.HandlingCharge += requestSettlementInfo.HandlingCharge || 0;


      loan.SettlementExtra += request.LoanInfo.SettlementExtra;
      loan.SettlementStamp += request.LoanInfo.SettlementStamp;
      loan.SettlementServiceCharge += request.LoanInfo.SettlementServiceCharge;

    });

    loan.InterestRate = this.sharedService.calculateInterestRate(loan.MonthlyPayment, loan.Principal, loan.Term);

    return loan;
  }


  generateWhatsappLink() {

    var encoded = encodeURIComponent(this.value);

    window.open("https://api.whatsapp.com/send?text=" + encoded, "_blank");

  }



  getAllEPFLoans() {
    this.previousEPFLoanList = new Array;

    var includedStatus = [this.enums.ACTIVE_LOAN, this.enums.WARNING_LOAN, this.enums.BAD_DEBT_LOAN]
    var profileId = (this.requestList[0].SharedProfileId == undefined) ? this.requestList[0].ProfileId : this.requestList[0].SharedProfileId;
    var xhttp = this.restApi.getRequest(this.constructApi.getAllProfileLoans(profileId, "EPF", includedStatus, true, true))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        var jsonArray = JSON.parse((xhttp.responseText));
        jsonArray.forEach(json => {
          var stampAmount = (json.stamp == undefined) ? 0 : json.stamp;
          var serviceChargeAmount = (json.serviceCharge == undefined) ? 0 : json.serviceCharge;
          var reserve = (json.reserve == undefined) ? 0 : json.reserve;
          var extras = (json.extras == undefined) ? undefined : this.processDuitLebihJsonArr(json.extras);

          var settlementCharges = (json.settlementCharges.stamp == undefined) ? undefined : new SettlementCharges(json.settlementCharges.serviceCharge, json.settlementCharges.stamp, json.settlementCharges.extras, json.settlementCharges.handlingCharge);

          var monthlyPayment = this.sharedService.calculateLoanMonthlyPayment(json.scheme, json.principal, json.principalPaid, json.term, json.interestRate);

          var loan = new Loan(json.id, json.profileId, json.principal, stampAmount,
            json.receiptNumber, json.status, json.dateCreated, json.companyId, json.companyName, json.scheme, json.interestRate, json.term,
            undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
            reserve, json.balance, monthlyPayment, json.arrearPaid, serviceChargeAmount, extras, undefined, undefined,
            undefined, json.processingRate, undefined, undefined, undefined, json.settlementStamp || 0, json.settlementServiceCharge || 0, json.settlementExtras || 0
            , undefined, undefined, undefined, undefined, json.legacySettlement, undefined, settlementCharges);


          if (loan.ID != this.requestList[0].LoanID) {
            this.previousEPFLoanList.push(loan);
          }

        }
        );

        this.constructEPFMessage();


      }
    };
  }


  processDuitLebihJsonArr(jsonArray: any) {
    var duitLebihList: DuitLebih[] = new Array;
    jsonArray.forEach(json => {
      var extra = new DuitLebih(json.amount, json.subtype)
      duitLebihList.push(extra);
    });

    return duitLebihList;
  }






  calculateTotalOnHand() {
    var total = 0;

    this.requestList.forEach(request => {
      var duitLebihTotal = 0;
      if (request.LoanInfo.Extras != undefined && request.LoanInfo.Extras.length > 0) {

        for (let j = 0; j < request.LoanInfo.Extras.length; j++)
          duitLebihTotal += request.LoanInfo.Extras[j].Amount;
      }

      var processingCharge = (request.LoanInfo.ProcessingRate / 100) * request.LoanInfo.Principal;
      var reserve = (request.LoanInfo.Reserve == undefined) ? 0 : request.LoanInfo.Reserve;

      total += request.LoanInfo.Principal - (reserve) - duitLebihTotal - processingCharge - request.LoanInfo.StampAmount -
        request.LoanInfo.ServiceCharge


    });

    return total;
  }


  reset() {

    if (this.isDirty == true)
      window.location.reload();
    else
      this.activeModal.close()


  }
  needSplitScreen() {
    if ((this.sharedService.isAdmin() || this.sharedService.isBossAccount()) && this.compareResult() != true)
      return 'col-12 col-md-6 d-none d-md-block';
    else
      return 'col-12';
  }


  formatString(string: string) {
    return string.replace("*", "");
  }

  compareResult() {
    if (this.compareString != "" && this.value != undefined) {

      var valueStr = this.value.replace(/[^a-zA-Z0-9\.\%]/g, "");
      var compareStr = this.compareString.replace(/[^a-zA-Z0-9\.\%]/g, "");

      if (valueStr == compareStr)
        return true;
    }
    return false;
  }

  openReviewLoanRequestModal(request: Request) {
    var modalRef = this.sharedService.openModal(ReviewLoanRequestComponent, 'largeModal');
    modalRef.componentInstance.requestList = [request];
  }
}
