<div class="container-fluid p-4">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-info" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <i class="fas fa-chevron-circle-down" *ngIf="isCollapsed"></i>
            <i class="fas fa-chevron-circle-up" *ngIf="isCollapsed==false"></i>
        </button>
    </div>
    
    <div class="collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
        <app-report-generator [selectedCompany]="selectedCompanyID"></app-report-generator>
    </div>
    <div class="card p-4 shadow-sm border-light">
        <div class="d-flex justify-content-between">
            <div class="h3 fw-bold">Generate Mid Month Report</div>
            <div class="form-check form-switch fs-5 fw-bold">
                <input class="form-check-input" type="checkbox" id="advanced" name="advanced" [(ngModel)]="advanced" (change)="toggleAdvanced()">
                <label class="form-check-label" for="advanced">Advanced</label>
            </div>
        </div>
        <hr class="opacity-30">
        <div class="my-3">
            @if (!advanced && previousEndDates.length > 0) {
                <div class="d-flex align-items-baseline fw-bold fs-5">
                    <div class="me-2 fw-bold fs-5">Month:</div>
                    <select class="form-select w-auto fs-5" name="endMonth" id="endMonth" [(ngModel)]="endDate" (change)="setRange(endDate)" required>
                        <option *ngFor="let d of previousEndDates" [value]="d">
                            {{d | date: 'MMM'}} {{d | date: 'yyyy'}}
                        </option>
                    </select>
                </div>
            } @else {
               <div class="fw-bold fs-5">Please select mid month date range: </div>
               <div class="mt-3 d-flex gap-4">
                   <mat-form-field class="fs-5">
                        <mat-label>Start Date</mat-label>
                       <input matInput readonly [matDatepicker]="startDatePicker" name="startDate"
                           [(ngModel)]="startDate" [max]="endDate" required>
                       <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                       </mat-datepicker-toggle>
                       <mat-datepicker #startDatePicker name="startDate">
                       </mat-datepicker>
                   </mat-form-field>
                   <mat-form-field class="fs-5">
                        <mat-label>End Date</mat-label>
                        <input matInput readonly [matDatepicker]="endDatePicker" name="endDate"
                            [(ngModel)]="endDate" required [min]="startDate">
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker name="endDate"></mat-datepicker>
                   </mat-form-field>
               </div>
            }
        </div>
        <div class="form-check form-switch fs-5 fw-medium">
            <input class="form-check-input" type="checkbox" id="willOmitSchemeA" name="willOmitSchemeA" [(ngModel)]="willOmitSchemeA">
            <label class="form-check-label" for="willOmitSchemeA">Hide Scheme A</label>
        </div>
        @if (sharedService.isBossAccount()==true) {
            <div class="form-check form-switch fs-5 fw-medium">
                <input class="form-check-input" type="checkbox" id="useVersion1" name="useVersion1" [(ngModel)]="useVersion1">
                <label class="form-check-label" for="useVersion1">Show accurate interest with VERSION 1</label>
            </div>
        }
        <div class="col d-flex justify-content-end align-items-right">
            <button class="btn btn-success" (click)="submit()" [disabled]="disabledSubmit">Generate</button>
            <button class="btn btn-danger ms-2">Cancel</button>
        </div>
    </div>
</div>