import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';

import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { TxSet } from 'src/model/set.model';

@Component({
  selector: 'app-receipt-add-expense-admin-remark',
  templateUrl: './receipt-add-expense-admin-remark.component.html',
  styleUrls: ['./receipt-add-expense-admin-remark.component.css']
})
export class ReceiptAddExpenseAdminRemarkComponent implements OnInit {
  disabledSubmit = false;
  remark: string;
  tagName: string;

  @Input() set: TxSet;
  @Input() editMode = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private activeModal: NgbActiveModal, private sharedService: SharedService, private enums: Enums, private cdr: ChangeDetectorRef, private restApi: RestApiService, private constructApi: ConstructAPI) { }


  ngOnInit(): void {
    this.remark = this.set.SetRemark;
    if (this.set.Tags != undefined) {
      this.tagName = "";
      for (let i = 0; i < this.set.Tags.length; i++) {
        if (i != this.set.Tags.length - 1)
          this.tagName += this.set.Tags[i] + ","
        else
          this.tagName += this.set.Tags[i]
      }

    }
  }




  submit() {
    if (this.remark == undefined || this.remark == "") {
      this.sharedService.openAlert("Please make sure you key in remark before can bookmark it", this.enums.DANGER_ALERT);
    } else {
      var tagList = (this.tagName == undefined) ? undefined : this.tagName.split(',');
      var saved = (this.editMode == true) ? this.set.Saved : true;
      var data = {
        "remark": this.remark,
        "saved": saved,
        "tags": tagList
      }

      var xhttp = (this.set.SetRemark != undefined)
        ? this.restApi.putRequest(this.constructApi.getAddSetRemark(this.set.CompanyId, this.set.ID), data)
        : this.restApi.postRequest(this.constructApi.getAddSetRemark(this.set.CompanyId, this.set.ID), data)


      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          this.reset();
          this.passEntry.emit();
        }
      }

    }


  }
  reset() {
    this.activeModal.close();
  }

}
