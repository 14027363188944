import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

interface StockReport {
  schemeName: string,
  activeOutstanding: number,
  bdOutstanding: number,
  cpOutstanding: number,
  lbdOutstanding: number,
  stock: number,
  stockReceived: number,
  breakdown?: StockReport[]
}

@Component({
  selector: 'app-stock-component',
  templateUrl: './stock-component.component.html',
  styleUrls: ['./stock-component.component.css']
})
export class StockComponentComponent implements OnInit, OnChanges {
  @Input() selectedCompany: number;
  @Input() endDate: Date = new Date();

  @Input() needStatusOutstanding: boolean;
  @Output() passActualCashbookBalance: EventEmitter<any> = new EventEmitter();

  stockList: StockReport[] = new Array;
  cashBookAmount: number;

  isCollapsed: boolean = false;

  constructor(private router: Router, private restapi: RestApiService, private constructApi: ConstructAPI, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.isCollapsed = this.sharedService.isOperatingUser();
  }

  ngOnChanges() {
    if (this.selectedCompany != undefined) {
      this.getSchemeSummary(this.selectedCompany);
      this.getCashBookBalance(this.selectedCompany);
    }
  }

  getCashBookBalance(companyId: number) {
    this.cashBookAmount = 0;
    const endDateStr = this.sharedService.getLastMsOfDate(this.endDate).toISOString();

    this.restapi.get<any>(this.constructApi.getCompanyCashBookBalance(companyId, endDateStr)).subscribe({
      next: res => {
        this.cashBookAmount = res.balance;
        this.passActualCashbookBalance.emit(this.cashBookAmount);
      },
      error: err => {
        this.restapi.handleError(err);
      }
    });
  }

  getSchemeSummary(companyId: number) {
    const adaptStockReport = (item: any): StockReport => {
      return {
        schemeName: item.scheme,
        activeOutstanding: item.activeOutstanding,
        bdOutstanding: item.bdOutstanding,
        cpOutstanding: item.cpOutstanding,
        lbdOutstanding: item.lbdOutstanding,
        stock: item.stock,
        stockReceived: item.stockReceived,
      }
    };

    //clear stock list first
    this.stockList.splice(0, this.stockList.length);
    const endDateStr = this.sharedService.getLastMsOfDate(this.endDate).toISOString();

    this.restapi.get<StockReport[]>(this.constructApi.getSchemeSummaries(companyId, endDateStr)).pipe(
      map(data => data.map(p => adaptStockReport(p)))
    ).subscribe({
      next: res => {
        this.stockList = res;
        this.groupScheme(['EPF', 'P']);
        this.stockList.sort((a, b) => (a.schemeName > b.schemeName) ? 1 : -1);
      },
      error: err => {
        this.restapi.handleError(err);
      }
    })
  }

  groupScheme(schemeName: string[]) {
    const schemes = this.stockList.filter(s => schemeName.includes(s.schemeName));
    if (schemes?.length > 1) {
      schemes.sort((a, b) => (a.schemeName > b.schemeName) ? 1 : -1);
      let result: StockReport = {
        schemeName: schemes.map(s => s.schemeName).join(' & '),
        activeOutstanding: 0,
        bdOutstanding: 0,
        cpOutstanding: 0,
        lbdOutstanding: 0,
        stock: 0,
        stockReceived: 0,
        breakdown: []
      };
      schemes.forEach(scheme => {
        Object.keys(scheme).forEach((key) => {
          if (typeof scheme[key] === 'number') {
            result[key] += scheme[key];
          }
        });
        result.breakdown.push(scheme);
      });
      this.stockList = this.stockList.filter(s => !schemeName.includes(s.schemeName));
      this.stockList.push(result);
    }
  }

  roundTo2Dec(num: number) {
    return Math.round(num * 100) / 100
  }

  calculateTotalStock() {
    return this.stockList.reduce((a, b) => a + b.stock - b.stockReceived, 0);
  }

  calculateTotalOutstanding() {
    return this.stockList.reduce((a, b) => a + b.activeOutstanding + b.bdOutstanding + b.cpOutstanding + b.lbdOutstanding, 0)
  }

  calculateTotalActiveOutstanding() {
    return this.stockList.reduce((a, b) => a + b.activeOutstanding, 0);
  }

  calculateTotalBDOutstanding() {
    return this.stockList.reduce((a, b) => a + b.bdOutstanding, 0);
  }


  calculateTotalCPOutstanding() {
    return this.stockList.reduce((a, b) => a + b.cpOutstanding, 0);
  }


  calculateTotalLBDOutstanding() {
    return this.stockList.reduce((a, b) => a + b.lbdOutstanding, 0);
  }

  goToLoanSchemeReport(schemeName: string) {
    this.router.navigate(['/loanSchemeReport', this.selectedCompany, schemeName]);
  }

}
