import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { VersionStatus } from 'src/app/Shared/versionStatus';
import { environment } from 'src/environments/environment';

export interface ServerStatus {
  databaseConnected: boolean,
  databasePing: string,
  redisConnected: boolean,
  redisPing: string,
  storageConnected: boolean,
  storagePing: string,
  maintenanceMode: boolean,
  version: string,
  websiteVersion: string,
  cpdbConnected: boolean,
  cpdbPing: string
}

interface UserToken {
  id: string,
  lastUsed: Date,
  staleAfter: Date,
  roleId: number,
  username: string
}

interface LoggedInUser {
  username: string,
  tokens: UserToken[]
}

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(private api: RestApiService, private constructApi: ConstructAPI, private versionStatus: VersionStatus) { }

  adaptServerStatus(item: any): ServerStatus {
    return {
      databaseConnected: item.databaseConnected,
      databasePing: item.databasePing,
      redisConnected: item.redisConnected,
      redisPing: item.redisPing,
      storageConnected: item.storageConnected,
      storagePing: item.storagePing,
      maintenanceMode: item.maintenanceMode,
      version: item.version,
      websiteVersion: item.websiteVersion,
      cpdbConnected: item.cpDatabaseConnected,
      cpdbPing: item.cpDatabasePing
    }
  }

  adaptLoggedInUser(item: any): LoggedInUser {
    return {
      username: item.username,
      tokens: item.tokens
    }
  }

  getServerStatus(): Promise<ServerStatus> {
    return new Promise((resolve, reject) => {
      this.api.get<ServerStatus>(this.constructApi.getServerStatus()).pipe(
        map(data => this.adaptServerStatus(data)),
      ).subscribe({
        next: res => resolve(res),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    });
  }

  async compareServerVersion(): Promise<boolean> {
    const serverStatus = await this.getServerStatus();

    // if on local dev environment, no need to compare server
    if ((environment.environment == "local"))
      return true;
    else
      return this.versionStatus.clientVersion == serverStatus.websiteVersion;
  }

  getErrorLog(errorCode?: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.api.get<string[]>(this.constructApi.getErrorLog(errorCode)).subscribe({
        next: res => resolve(res),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    });
  }

  getSignedInUsername(activeWithin?: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.api.get<LoggedInUser[]>(this.constructApi.getSignedInUserList(activeWithin)).pipe(
        map(data => data.map(p => this.adaptLoggedInUser(p).username)),
      ).subscribe({
        next: res => { resolve(res) },
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    });
  }
}
