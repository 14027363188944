<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Edit Blacklist Customer</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 (click)="reset()"></button>
    </div>

    <div class="modal-body">
        <div class="container-fluid p-0">
            <!-- <div class="mt-4 d-flex justify-content-center align-items-center"> -->
                <div class="mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" required class="form-control" name="name" id="name" [(ngModel)]="updatedBlacklist.Name">
                </div>
                <div class="mb-3">
                    <label for="icNumber" class="form-label">IC Number</label>
                    <input type="text" required class="form-control" name="icNumber" id="icNumber" [(ngModel)]="updatedBlacklist.ICNumber" (keypress)="sharedService.isNumberKey($event)">
                    @if (this.sharedService.checkIfICValid(updatedBlacklist.ICNumber) == false) { <p class="text-danger my-1 mx-0 p-0"> IC Number format
                    invalid </p> }
                </div>
                <div class="mb-3">
                    <label for="otherId" class="form-label">Other ID</label>
                    <input type="text" class="form-control" name="otherId" id="otherId" [(ngModel)]="updatedBlacklist.OtherID">
                </div>
                <div class="mb-3">
                    <label for="status" class="form-label">Status</label>
                    <input type="text" required class="form-control" name="status" id="status" [(ngModel)]="updatedBlacklist.Status">
                </div>
                <div class="mb-2">
                    <label for="remark" class="form-label">Remark</label>
                    <textarea class="form-control" rows="10" required [(ngModel)]="updatedBlacklist.Remark" name="remark"></textarea>
                </div>
            <!-- </div> -->
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-success" type="button" (click)="submit()" [disabled]="disabledSubmit">Update</button>
        <button class="btn btn-outline-danger" type="button" data-dismiss="modal" (click)="reset()">Cancel</button>
    </div>
</form>