import { Injectable } from '@angular/core';

export type Download = {
  url: string;
  filename: string;
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor() { }

  getFilenameFromContentDisposition(content: string): string {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(content);
    
    if (matches != null && matches[1]) {
      // matches[1] is the first caturing group which is regex ((['"]).*?\2|[^;\n]*)
      // Remove any surrounding quotes from the filename
      const filename = matches[1].replace(/['"]/g, '');
      return filename;
    }

    return 'defaultFilename';
  }

  downloadFile(d: Download) {
    let a = document.createElement('a');
    a.href = d.url;
    a.download = d.filename;

    // Append to the body (required for Firefox compatibility)
    document.body.appendChild(a);
    a.click(); // Trigger the download
    
    // Clean up: remove the link and revoke the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(d.url);
  }

}