import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';


export interface AuthLog {
  date: Date;
  ip: string;
  reason: string;
  remark: string;
  success: boolean;
  userAgent: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthLogService {

  constructor(private api: RestApiService, private constructApi: ConstructAPI) { }

  adaptAuthLog(item: any): AuthLog {
    return {
      date: item["date"],
      ip: item["ip"],
      reason: item["reason"],
      remark: item["remark"],
      success: item["success"],
      userAgent: item["user-agent"],
      username: item["username"]
    }
  }


  getAuthLog(username?: string, ip?: string, userAgent?: string, reason?: string, success?: boolean, startDate?: string, endDate?: string): Promise<AuthLog[]> {
    return new Promise((resolve, reject) => {
      this.api.get<AuthLog[]>(this.constructApi.getAuthLog(username, ip, userAgent, reason, success, startDate, endDate)).pipe(
        map(data => { return data.map(d => this.adaptAuthLog(d)) })
      ).subscribe({
        next: response => { resolve(response) },
        error: (err) => {
          this.api.handleError(err);
          reject(err);
        }
      });
    })
  }

}
