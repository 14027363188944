import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { IAPITransaction, Transaction } from 'src/model/transaction.model';
import { TransactionSubType } from 'src/model/transactionSubType.model';
import { TransactionType } from 'src/model/transactionType.model';

export type SubType = {
  assignable: boolean,
  enabled: boolean,
  id: number,
  name: string,
  type: number
}

@Injectable({
  providedIn: 'root'
})
export class TransactionAdapter {
  
  constructor() {}

  adaptType(item: any): TransactionType {
    return new TransactionType(item.id, item.name);
  }

  adaptSubtype(item: any): TransactionSubType {
    return new TransactionSubType(
      item.id,
      item.name,
      item.type,
      item.assignable,
      item.enable
    )
  }

  // toTypeDict(items: any[]): {[key: number]: string} {
  //   return items.reduce((acc, item) => {
  //     acc[item.id] = item.name;
  //     return acc;
  //   }, {} as { [key: number]: string });
  // }

  // toSubtypeDict(items: any): {[key: number]: string} {
  //   return items.reduce((acc, item) => {
  //     acc[item.id] = item.name;
  //     return acc;
  //   }, {} as { [key: number]: string });
  // }

  adapt(item: any): Transaction {
    return new Transaction(
      item.id,
      item.remark,
      item.amount,
      item.closingBalance,
      item.accountId,
      new Date(item.date),
      item.type,
      item.handleBy,
      item.receiptID,
      item.customerID,
      item.customerName,
      item.customerIC,
      item.customerOccupation,
      item.subtype,
      item.groupId,
      item.setId
    )
  }

  toApiModel(trans: Transaction): IAPITransaction {
    return {
      id: trans.ID,
      remark: trans.Remark,
      amount: trans.Amount,
      closingBalance: trans.ClosingBalance,
      accountId: trans.AccountId,
      date: trans.Date.toISOString(),
      type: trans.Type,
      handleBy: trans.HandleBy,
      receiptID: trans.ReceiptID,
      customerID: trans.CustomerID,
      customerName: trans.CustomerName,
      customerIC: trans.CustomerIC,
      customerOccupation: trans.CustomerOccupation,
      subtype: trans.Subtype,
      groupId: trans.GroupId,
      setId: trans.SetId
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private api: RestApiService, private constructAPI: ConstructAPI, private adapter: TransactionAdapter) {}

  // getAllTransactionTypesDict() {
  //   return new Promise<{ [key: number]: string }> ((resolve, reject) => {
  //     this.api.get<TransactionType[]>(this.constructAPI.getAllTransactionTypes()).pipe(
  //       map(data => this.adapter.toTypeDict(data)),
  //     ).subscribe({
  //       next: res => resolve(res),
  //       error: err => {
  //         this.api.handleError(err);
  //         console.error(err);
  //         reject(err);
  //       }
  //     })
  //   });
  // }

  getAllTransactionTypes(): Promise<TransactionType[]> {
    return new Promise<TransactionType[]> ((resolve, reject) => {
      this.api.get<TransactionType[]>(this.constructAPI.getAllTransactionTypes()).pipe(
        map(data => data.map(d => this.adapter.adaptType(d))),
      ).subscribe({
        next: res => resolve(res),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    });
  }

  // getAllTransactionSubTypesDict() {
  //   return new Promise<{ [key: number]: string }> ((resolve, reject) => {
  //     this.api.get<TransactionSubType[]>(this.constructAPI.getAllTransactionSubTypes()).pipe(
  //       map(data => this.adapter.toSubtypeDict(data)),
  //     ).subscribe({
  //       next: res => resolve(res),
  //       error: err => {
  //         this.api.handleError(err);
  //         console.error(err);
  //         reject(err);
  //       }
  //     })
  //   });
  // }

  getAllTransactionSubTypes(): Promise<TransactionSubType[]> {
    return new Promise<TransactionSubType[]> ((resolve, reject) => {
      this.api.get<TransactionSubType[]>(this.constructAPI.getAllTransactionSubTypes()).pipe(
        map(data => data.map(d => this.adapter.adaptSubtype(d))),
      ).subscribe({
        next: res => resolve(res),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    });
  }

  getTransactionSubTypes(type: number): Promise<TransactionSubType[]> {
    return new Promise<TransactionSubType[]> ((resolve, reject) => {
      this.api.get<TransactionSubType[]>(this.constructAPI.getTransactionSubTypes(type)).pipe(
        map(data => data.map(d => this.adapter.adaptSubtype(d))),
      ).subscribe({
        next: res => resolve(res),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    });
  }
}
