import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../Shared/shared-service.service';
import { Enums } from '../Shared/enums';
import { BlacklistService } from 'src/services/blacklist.service';
import { Blacklist } from 'src/model/blacklist';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-edit-blacklist',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './edit-blacklist.component.html',
  styleUrl: './edit-blacklist.component.css'
})
export class EditBlacklistComponent {

  @Input() blacklist: Blacklist;
  updatedBlacklist: Blacklist;
  disabledSubmit = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, private enums: Enums, private blacklistService: BlacklistService) { }

  ngOnInit(): void {
    this.updatedBlacklist = Object.assign({}, this.blacklist);
  }

  requiredValidator(value: string | null): boolean {
    return value && value.trim() !== '';
  }

  validFields() {
    return this.requiredValidator(this.updatedBlacklist.Name) && this.requiredValidator(this.updatedBlacklist.ICNumber) && this.requiredValidator(this.updatedBlacklist.Remark) && this.requiredValidator(this.updatedBlacklist.Status);
  }

  async updateBlacklistCustomer() {
    this.disabledSubmit = true;
    this.updatedBlacklist.ICNumber = this.sharedService.sanitizeICNumInput(this.updatedBlacklist.ICNumber);
    try {
      await this.blacklistService.updateBlacklistCustomer(this.updatedBlacklist);
      this.reset();
      this.sharedService.openAlert("Successfully updated profile",this.enums.SUCCESS_ALERT);
      this.passEntry.emit(this.updatedBlacklist);
    } finally {
      this.disabledSubmit = false;
    }
  }

  submit() {
    this.validFields() ? this.updateBlacklistCustomer() : this.sharedService.openAlert("Please fill in all the fields correctly", this.enums.DANGER_ALERT);
  }

  reset() {
    this.activeModal.close();
  }
}
