import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ShowWhatsappMessageClipboardComponent } from 'src/app/Shared/show-whatsapp-message-clipboard/show-whatsapp-message-clipboard.component';
import { LoanRequest } from 'src/model/loanRequest.model';
import { AuthorizeLoanRequestComponent } from '../authorize-loan-request/authorize-loan-request.component';
import { ReviewLoanRequestComponent } from '../review-loan-request/review-loan-request.component';
import { DatePipe } from '@angular/common';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { CompanyAccess } from 'src/model/companyAccess.model';

@Component({
  selector: 'app-all-loan-request-card',
  templateUrl: './all-loan-request-card.component.html',
  styleUrls: ['./all-loan-request-card.component.css'],
  providers: [DatePipe]
})
export class AllLoanRequestCardComponent implements OnInit {

  @Input() request: LoanRequest;
  @Input() adminAccess: CompanyAccess[];

  constructor(public sharedService: SharedService, private router: Router, public enums: Enums, private datePipe: DatePipe, private restApi: RestApiService, private constructApi: ConstructAPI) { }

  ngOnInit(): void {
  }

  calculateSettlement(request: LoanRequest) {
    var settlement = request.LoanInfo.Principal + (request.LoanInfo.Term *
      request.LoanInfo.Principal * (request.LoanInfo.InterestRate / 100)) +
      request.LoanInfo.SettlementStamp + request.LoanInfo.SettlementServiceCharge + request.LoanInfo.SettlementExtra;
    return settlement;

  }

  calculateRatio(request: LoanRequest) {
    var settlement = this.calculateSettlement(request)

    var ratio = (settlement / request.EpfAmount) * 100;
    return ratio;

  }

  goToCustomer() {
    this.router.navigate(['/manageCustomer', this.request.CustomerId, this.request.ProfileId]);
  }

  openReviewLoanRequestModal() {
    var modalRef = this.sharedService.openModal(ReviewLoanRequestComponent, 'largeModal');
    modalRef.componentInstance.requestList = [this.request];
  }

  openAuthorizeLoanRequestModal() {
    var modalRef = this.sharedService.openModal(AuthorizeLoanRequestComponent, 'largeModal');
    modalRef.componentInstance.request = this.request;
  }

  openSendWhatsapp(requestList: LoanRequest[]) {

    var modalRef = this.sharedService.openModal(ShowWhatsappMessageClipboardComponent);
    modalRef.componentInstance.requestList = requestList;
    modalRef.componentInstance.isEPFRequest = requestList[0].LoanInfo.Scheme == 'EPF';
    modalRef.componentInstance.loanGroupId = this.request.LoanGroupId;
    // modalRef.componentInstance.previousEPFLoanList = this.previousEPFLoanList;

  }

  checkOtherLoanRequests(request: LoanRequest) {

    if (request.LoanGroupId != undefined)
      this.getAllSharedReqeusts();
    else
      this.openSendWhatsapp([request]);
  }

  getAllSharedReqeusts() {
    var xhttp;
    xhttp = this.restApi.getRequest(this.constructApi.getLoanGroupApprovalRequests(this.request.LoanGroupId))

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4) {

        if (xhttp.status == 200) {
          var jsonArray = JSON.parse((xhttp.responseText));

          var loanRequestList: LoanRequest[] = new Array;
          jsonArray.forEach(json => {

            var loanRequest = this.sharedService.processLoanRequest(json);
            loanRequestList.push(loanRequest);

            loanRequestList.sort((a, b) => ((a.ID > b.ID)) ? -1 : 1);

          }
          );
          this.openSendWhatsapp(loanRequestList);
        }
        else
          //if somehow cant get share loan requests, at least need to be able to generate own request
          this.openSendWhatsapp([this.request]);

      }
    };
  }

  constructVerifiedMessage(request: LoanRequest) {

    var verifiedBy = (request.VerifiedBy == undefined) ? "-" : request.VerifiedBy;
    return verifiedBy + " | " + this.datePipe.transform(new Date(this.request.VerifiedOn), "dd-MM-yyyy hh:mm:ss");

  }
}
