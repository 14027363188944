import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/model/customer.model';
import { Status } from 'src/model/status.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCustomerComponent } from 'src/app/AddThings/add-customer/add-customer.component';
import { RestApiService } from 'src/app/API/restapi';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { Location } from '@angular/common';
import { ViewCustomerRemarkComponent } from '../view-customer-remark/view-customer-remark.component';
import { Loan } from 'src/model/loan.model';
import { ViewThumbnailsComponent } from 'src/app/Images/view-thumbnails/view-thumbnails.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AddAdminRemarkComponent } from 'src/app/AddThings/add-admin-remark/add-admin-remark.component';
import { AdminRemark } from 'src/model/customerAdminRemark';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  customerList: Customer[] = new Array;


  displayedColumns: string[];
  dataSource = new MatTableDataSource;

  innerWidth: any;
  showRemark: string;

  selectedStatus: number;
  customerLoansList: Loan[] = new Array;

  isVerified: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  routeSubscription: any;
  selectedCompany: number;

  filteredValues = { filterStr: '', status: 0 };

  isLoanListReady = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private modalService: NgbModal,
    private restapi: RestApiService, private constructApi: ConstructAPI, public enums: Enums, public sharedService: SharedService, private location: Location) {


  }

  ngOnInit() {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => {
      this.selectedCompany = (params.get('companyId') == undefined) ? undefined : +params.get('companyId');
      this.selectedStatus = (params.get('statusCode') == undefined) ? undefined : +params.get('statusCode');
      this.isVerified = (params.get('verified') == undefined) ? undefined : params.get('verified') == "true";

    });

    // get customer
    this.getAllCustomers();
    this.innerWidth = window.innerWidth;
    this.manageColumnsShown(this.innerWidth);
  }

  //detect screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.innerWidth;
    this.manageColumnsShown(this.innerWidth);

  }


  manageColumnsShown(innerWidth: number) {
    //if inner width < small digital device, hide remark column
    if (innerWidth <= 450)
      this.displayedColumns = ['Name', 'ICNumber'];
    else {
      if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance() || this.sharedService.isAuditor())
        this.displayedColumns = ['Name', 'ICNumber', 'Status', 'Remark', 'Action'];
      else
        this.displayedColumns = ['Name', 'ICNumber', 'Status'];

    }
  }

  getCustomerLoans(status: number, customerId: number) {

    this.isLoanListReady = false;
    this.customerLoansList = [];
    var xhttp = this.restapi.getRequest(this.constructApi.getCustomerAllLoans(customerId, [status]))
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {

        this.isLoanListReady = true;


        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {

          var monthlyPayment = this.sharedService.calculateLoanMonthlyPayment(json.scheme, json.principal, json.principalPaid, json.term, json.interestRate);

          // create customer instance with all response data
          let loan = new Loan(json.id, json.profileId, json.principal, json.stampAmount, json.receiptNumber,
            json.status, json.dateCreated, json.companyId, json.companyName,
            json.scheme, json.interestRate, json.term, json.customerName,
            json.icNumber, json.extraInterest, json.issuer, json.loanCode, json.arrearCount,
            json.principalPaid, json.repayment, json.reserve, json.balance, monthlyPayment, json.arrearPaid, undefined, undefined, undefined, json.monthlyInterest, json.discount, undefined, json.processingCharge)

          //add customer instance into customer list
          this.customerLoansList.push(loan);
        }
        );

      }
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filteredValues['filterStr'] = filterValue;
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  filterByStatus() {

    this.filteredValues['status'] = this.selectedStatus;
    this.dataSource.filter = JSON.stringify(this.filteredValues);

    //construct activated route url -> append selectedStatusCode behind
    var urls = window.location.pathname.split("/");

    if (this.selectedStatus != null) {
      if (this.selectedCompany == undefined)
        this.location.replaceState(urls[1] + "/" + this.selectedStatus);
      else
        this.location.replaceState(urls[1] + "/" + urls[2] + "/" + this.selectedStatus);
    }

  }



  customFilterPredicate() {
    const myFilterPredicate = function (data: Customer, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let searchFound = (data.Name.toString() + data.ICNumber.toString()).toLowerCase().trim().indexOf(searchString.filterStr.toLowerCase()) !== -1
      let statusFound;

      if (searchString.status != 0 && data.Status != undefined) {
        for (let i = 0; i < data.Status.length; i++) {

          statusFound = data.Status[i].StatusCode.toString().trim().indexOf(searchString.status) !== -1
          if (statusFound == true)
            break;
        }
      }
      else
        statusFound = true;



      return searchFound && statusFound

    }
    return myFilterPredicate;
  }

  goToCustomer(selectedID: number) {
    // console.log("selcted:" + selectedID);
    // this.router.navigateByUrl('/manageCustomer', { state: { id: selectedID } });
    this.router.navigate(['/manageCustomer', selectedID]);
  }

  convertStatusString(status: number) {

    if (status == this.enums.ACTIVE_CUST)
      return "ACTIVE";
    else if (status == this.enums.WARNING_CUST)
      return "WARNING";
    else if (status == this.enums.SETTLED_CUST)
      return "SETTLED";
    else if (status == this.enums.BAD_DEBT_CUST)
      return "BAD DEBT";
    else if (status == this.enums.LBD_CUST)
      return "LEGAL";
    else
      return "CP";

  }

  openAddCustomerDialog() {

    const modalRef = this.sharedService.openModal(AddCustomerComponent, "largeModal");

    //get company that was created
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var customer = entry;

      //append added customer to customer list
      this.customerList.push(customer);
      // console.log(JSON.stringify(this.customerList));
    });

    modalRef.result.then(() => {
      // console.log("added task")
      // this.cdr.detectChanges();
      this.refreshDataSource();
    })
  }

  getStatusColor(status: number) {

    var color: string;

    switch (status) {
      case this.enums.ACTIVE_CUST:
        color = "bg-primary";
        break;
      case this.enums.WARNING_CUST:
        color = "bg-warning";
        break;
      case this.enums.BAD_DEBT_CUST:
        color = "bg-dark";
        break;
      case this.enums.CP_CUST:
        color = "bg-info";
        break;
      case this.enums.LBD_CUST:
        color = "bg-secondary";
        break;
      default:
        color = "bg-success";
        break;
    }
    return color;
  }

  refreshDataSource() {
    this.dataSource.data = this.customerList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSourceSort();
  }

  dataSourceSort() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Status':
          // if status is empty
          if (item[property] == undefined)
            return ""
          //if contains status code
          else
            return item[property][0].StatusCode;

        case 'Action':
          //if sort by action column, sort VERIFIED
          return item["Verified"]
        default:
          return item[property];
      }
    };
  }

  checkNewCustomer(customer: Customer) {
    var dateCreated = new Date(customer.DateCreated);
    var today = new Date();
    if (dateCreated.getUTCMonth() == today.getUTCMonth() && dateCreated.getUTCFullYear() == today.getUTCFullYear())
      return true;
  }


  processCustomerStatus(statusJSON: any) {

    var statusList: Status[] = new Array;
    statusJSON.forEach(statusCode => {

      var index = statusList.findIndex(status => status.StatusCode === statusCode);
      if (index != -1)
        statusList[index].Occurrence = ++statusList[index].Occurrence;
      else {
        var status = new Status(statusCode, 1);
        statusList.push(status);
      }
    }
    );
    statusList.sort((a, b) => (a.StatusCode > b.StatusCode) ? 1 : -1);
    return statusList;
  }

  viewCustomerRemark(customerID: number, customerName: string) {

    const modalRef = this.sharedService.openModal(ViewCustomerRemarkComponent, "largeModal");
    modalRef.componentInstance.viewCustomerId = customerID;
    modalRef.componentInstance.viewCustomerName = customerName;

  }

  getAllCustomers() {
    // console.log(this.isVerified)
    if (this.isVerified != undefined) {
      if (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance())
        var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllCustomers(this.selectedCompany, this.isVerified));
    }
    else {
      if (this.selectedCompany == undefined)
        var xhttp = this.restapi.getRequest(this.constructApi.getAllCustomers())
      else
        var xhttp = this.restapi.getRequest(this.constructApi.getCompanyAllCustomers(this.selectedCompany));
    }


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:

        var jsonArray = JSON.parse((xhttp.responseText));
        // console.log(jsonArray);

        jsonArray.forEach(json => {

          if (json.status != undefined)
            var customerStatus = this.processCustomerStatus(JSON.parse(JSON.stringify(json.status)));

          var verified = (json.verifiedOn == undefined) ? false : true;
          // create customer instance with all response data
          let customer = new Customer(json.id, json.name, json.occupation, json.salary, json.salaryday,
            json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus, customerStatus, json.dateCreated, json.handlerId, json.companyId, json.customerCode, undefined, undefined,
            undefined, undefined, undefined, undefined, json.needAttention, undefined, undefined, verified, undefined, json.verifiedBy);

          //add customer instance into customer list
          this.customerList.push(customer);
        }
        );

        //make customer list as the datasource
        this.dataSource = new MatTableDataSource<Customer>(this.customerList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dataSourceSort();

        if (this.selectedStatus != null)
          this.filterByStatus();

      }
    };
    // xhttp.open("GET", url, true);
    // xhttp.send();

  }

  viewCustomerImages(customerID: number) {
    var modalRef = this.sharedService.openModal(ViewThumbnailsComponent, "largeModal");
    modalRef.componentInstance.customerID = customerID;
  }

  confirmBeforeVerifyCustomer(isVerify: boolean, customerID: number) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    var verifyMessage = (isVerify == true) ? "VERIFY" : "UNVERIFY";
    modalRef.componentInstance.message = "Confirm to " + verifyMessage + " this customer?";
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something\

      if (confirmation == true) {
        if (isVerify == true) {
          this.verifyCutsomer(customerID);
        }
        else {
          this.unverifyCutsomer(customerID);
        }
      }
    });

  }


  verifyCutsomer(customerID: number) {

    var xhr = this.restapi.postRequest(this.constructApi.getVerifyCustomer(customerID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        // var json = JSON.parse(xhr.responseText);

        if (xhr.status == 200) {
          // window.location.reload();

          var index = this.customerList.findIndex(customer => Number(customer.ID) == Number(customerID));

          this.customerList[index].Verified = true;
          this.customerList[index].VerifiedBy = sessionStorage.getItem("username");
        } else
          this.sharedService.openErrorMessage(xhr);

      }
    }
  }


  unverifyCutsomer(customerID: number) {

    var xhr = this.restapi.postRequest(this.constructApi.getUnverifyCustomer(customerID));
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        // var json = JSON.parse(xhr.responseText);

        if (xhr.status == 200) {
          // window.location.reload();
          var index = this.customerList.findIndex(customer => Number(customer.ID) == Number(customerID));
          this.customerList[index].Verified = false;
          this.customerList[index].VerifiedBy = undefined;
        } else
          this.sharedService.openErrorMessage(xhr);

      }
    }
  }

  addCustomerRemark(customerId: number, index: number) {
    const modalRef = this.sharedService.openModal(AddAdminRemarkComponent);
    modalRef.componentInstance.viewCustomerId = customerId;
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var remark: AdminRemark = entry;

      this.customerList[index].NeedAttention = remark.NeedAttention;
    });

  }
}
