import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, TimeoutError } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from '../Shared/shared-service.service';
import { Enums } from '../Shared/enums';

@Injectable({
    providedIn: 'root'
})
export class RestApiService {

    //Define API
    // apiUrl = 'http://localhost:10080/api/v1/';
    // apiUrl = 'http://206.189.87.13:10080/api/v1/';
    // apiUrl = 'http://188.166.224.170:10080/api/v1/';
    // apiUrl = 'https://credsys.al-fikrah.com/api/v1/';

    apiUrl: string;

    constructor(private http: HttpClient, private sharedService: SharedService, private enums: Enums) {

        if (environment.production == true || environment.environment == "local")
            this.apiUrl = environment.apiUrl;

        else {
            var protocol = window.location.protocol;
            var hostname = window.location.host;
            hostname = hostname.replace("app.", "api.");

            this.apiUrl = protocol + "//" + hostname + "/api/v1/";
        }

    }
    
    httpOptions = {
        headers: new HttpHeaders({
          'Content-type': 'application/json'
        }),
        withCredentials: true
    }
    
    post<T>(apiAddress: string, body?: any): Observable<T> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.post<T>(url, body, this.httpOptions);
    }

    get<T>(apiAddress: string): Observable<T> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.get<T>(url, this.httpOptions).pipe(
            timeout(15000), // Timeout after 15 seconds
            retry(3)
        );
    }

    getFile<T>(apiAddress: string): Observable<HttpResponse<T>> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.get<T>(url, {...this.httpOptions, observe: 'response', responseType: 'blob' as 'json'}).pipe(
            // timeout(15000), // Timeout after 15 seconds
            // retry(3)
        );
    }

    getResponse<T>(apiAddress: string): Observable<HttpResponse<T>> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.get<T>(url, {...this.httpOptions, observe: 'response'}).pipe(
            timeout(15000), // Timeout after 15 seconds
            retry(3)
        );
    }

    head<T>(apiAddress: string): Observable<T> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.head<T>(url, this.httpOptions);
    }

    put<T>(apiAddress: string, body?: any): Observable<T> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.put<T>(url, body, this.httpOptions);
    }

    patch<T>(apiAddress: string, body?: any): Observable<T> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.patch<T>(url, body, this.httpOptions);
    }

    delete<T>(apiAddress: string): Observable<T> {
        const url = `${this.apiUrl}${apiAddress}`;
        return this.http.delete<T>(url);
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'An unknown error occurred!';
        if (error instanceof TimeoutError) {
            errorMessage = `${error.name}: ${error.message}`;
        } else if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side error
            errorMessage = `Error ${error.status}: ${error.statusText}\nMessage: ${error.error?.error}`;
        }
        if (error.status === 0) {
            // Error due to network connectivity
            errorMessage = 'Network error: Please check your internet connection.';
        }
        this.sharedService.openAlert(errorMessage, this.enums.DANGER_ALERT);
    }

    postRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();

        xhr.open("POST", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        var data = JSON.stringify(jsonData);
        xhr.send(data);
        return xhr
    }

    getRequest(apiAddress: string) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        xhr.send();
        return xhr
    }

    putRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        var data = JSON.stringify(jsonData);
        xhr.send(data);
        return xhr
    }

    patchRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();
        xhr.open("PATCH", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;
        var data = JSON.stringify(jsonData);
        xhr.send(data);
        return xhr
    }

    deleteRequest(apiAddress: string, jsonData?: any) {
        var xhr = new XMLHttpRequest();
        xhr.open("DELETE", this.apiUrl + apiAddress, true);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.withCredentials = true;

        if (jsonData != undefined) {
            var data = JSON.stringify(jsonData);
            xhr.send(data);
        }
        else
            xhr.send();

        return xhr
    }



    postFormDataRequest(apiAddress: string, formData: FormData) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", this.apiUrl + apiAddress, true);
        xhr.withCredentials = true;
        xhr.send(formData);
        return xhr;
    }

    postFileRequest(apiAddress: string, file: File) {
        var xhr = new XMLHttpRequest();

        xhr.open("POST", this.apiUrl + apiAddress, true);
        xhr.withCredentials = true;

        let formData = new FormData();

        var processedFileName = file.name.replace("'", "");
        formData.append("file", file, processedFileName);
        xhr.send(formData);
        return xhr
    }

}