<div class="container-fluid m-2">
    <h2><b>Admin Access Management</b></h2>
    <div class="row my-4">
        <div class="col-12 col-lg-2">
            <h4 class="text-muted">On Duty</h4>
            <ng-container *ngFor="let onDuty of adminList">
                @if(onDuty.onDutyAccessCount>0)
                { <app-admin-access-item [adminAccessItem]="onDuty"
                    [companyAccessList]="companyAccessList" [isOnDuty]="true"></app-admin-access-item>}
            </ng-container>


            <div class="d-grid gap-2">
                <button class="btn btn-outline-secondary " (click)="openAddOnDutyAdminModal()"><i
                        class="fas fa-plus mr-2"></i> Add
                    admin</button>

            </div>


            <h4 class="text-muted">Admins</h4>
            <ng-container *ngFor="let admin of adminList">
                <app-admin-access-item [adminAccessItem]="admin"
                    [companyAccessList]="companyAccessList"></app-admin-access-item>
            </ng-container>





        </div>
        <div class="col-12 col-lg-10">
            <div *ngIf="companyAccessList!=undefined && companyAccessList.length>0">
                <app-admin-access-table [companyAccessList]="companyAccessList"></app-admin-access-table>
            </div>

        </div>
    </div>


</div>