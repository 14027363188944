import { Component, Input, OnInit } from '@angular/core';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { SharedService } from 'src/app/Shared/shared-service.service';
import { LoanRequest } from 'src/model/loanRequest.model';
import { SharedLoanRequest } from 'src/model/sharedLoan.model';
import { AuthorizeLoanRequestComponent } from '../../LoanRequest/authorize-loan-request/authorize-loan-request.component';

@Component({
  selector: 'app-shared-loan-agreement',
  templateUrl: './shared-loan-agreement.component.html',
  styleUrls: ['./shared-loan-agreement.component.css']
})
export class SharedLoanAgreementComponent implements OnInit {

  constructor(public sharedService: SharedService, private restapi: RestApiService, private cosntructApi: ConstructAPI) { }

  @Input() sharedLoanRequest: SharedLoanRequest;
  loggedInCompanyId: number;
  loggedInCompanyLoanRequest: LoanRequest;
  isAgreed = false;

  ngOnInit(): void {


    this.loggedInCompanyId = Number(sessionStorage.getItem("companyId"));
    this.loggedInCompanyLoanRequest = this.getLoggedInCompanyLoanRequest();
    this.isAgreed = this.checkIfLoggedInCompanyAgreed();
  }

  checkIfLoggedInCompanyAgreed() {
    var compIndex = this.sharedLoanRequest.Companies.findIndex(company => company.CompanyId == this.loggedInCompanyId);
    return this.sharedLoanRequest.Companies[compIndex].Agreed;
  }
  getLoggedInCompanyLoanRequest() {
    var index = this.sharedLoanRequest.LoanRequestsInfo.findIndex(request => request.CompanyId === this.loggedInCompanyId)
    return this.sharedLoanRequest.LoanRequestsInfo[index];


  }

  calculateSharedLoanTotal() {
    var sum = 0;

    for (let i = 0; i < this.sharedLoanRequest.LoanRequestsInfo.length; i++) {
      sum += this.sharedLoanRequest.LoanRequestsInfo[i].LoanInfo.Principal;
    }
    return sum;
  }

  findCompanyPrincipal(companyId: number) {
    var index = this.sharedLoanRequest.LoanRequestsInfo.findIndex(loan => loan.CompanyId == companyId);

    return this.sharedLoanRequest.LoanRequestsInfo[index].LoanInfo.Principal;

  }

  calculateSharedLoanTotalSettlement() {
    var sum = 0;

    for (let i = 0; i < this.sharedLoanRequest.LoanRequestsInfo.length; i++) {
      sum += this.sharedService.calculateEPFSettlementAmount(this.sharedLoanRequest.LoanRequestsInfo[i].LoanInfo.Principal, this.sharedLoanRequest.LoanRequestsInfo[i].LoanInfo.InterestRate, this.sharedLoanRequest.LoanRequestsInfo[i].LoanInfo.Term);
    }
    return sum;
  }


  processShareLoan(loanGroupId: number, action: string) {
    var data = {
      "remark": ""
    };


    var xhr = this.restapi.postRequest(this.cosntructApi.getSharedLoanProcess(loanGroupId, action), data);
    xhr.onreadystatechange = () => {

      if (xhr.readyState == 4) {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            window.location.reload();
          }
          else {
            this.sharedService.openErrorMessage(xhr);
          }
        }
      }

    }
  }

  openAuthorizeLoanRequestModal() {
    var modalRef = this.sharedService.openModal(AuthorizeLoanRequestComponent, 'largeModal');
    modalRef.componentInstance.requestList = this.sharedLoanRequest.LoanRequestsInfo;
    modalRef.componentInstance.onlyViewLoan = true;

    // console.log(this.sharedLoanRequest.LoanRequestsInfo)
  }


}
