<form>
    <!-- Modal Header -->
    <div class="modal-header">
        <h4 class="modal-title">Simpan History</h4>
        <button type="button" class="btn-close" data-dismiss="modal" tabindex=-1 aria-label="Close" aria-label="Close"
            (click)="reset()"></button>
    </div>

    <div class="modal-body">

        <div class="container-fluid" style="padding: 2%;">

            <div style="margin: 2%;">
                <ul class="nav nav-tabs">
                    <li class="nav-item ">
                        <a class="nav-link active" role="tab" data-bs-toggle="tab" href="#tabHistory">
                            <i class="fas fa-history"></i> History
                        </a>
                    </li>
                    <li class="nav-item "
                        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance()">
                        <a class="nav-link " role="tab" data-bs-toggle="tab" href="#tabAdd">
                            <i class="fas fa-piggy-bank"></i> Add Simpan
                        </a>
                    </li>
                    <li class="nav-item "
                        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false && !sharedService.isFinance() && totalReserveAmount>0">
                        <a class="nav-link " role="tab" data-bs-toggle="tab" href="#tabExpense">
                            <i class="fas fa-envelope-open-text"></i> Return Simpan
                        </a>
                    </li>
                    <li class="nav-item " *ngIf="profileList.length>1 && sharedService.isBranchAccountHandler()==true">
                        <a class="nav-link " role="tab" data-bs-toggle="tab" href="#tabTransfer">
                            <i class="fas fa-exchange-alt"></i> Transfer Simpan
                        </a>
                    </li>
                </ul>
                <div class="tab-content">

                    <!--tabHistory-->
                    <div class="tab-pane active" role="tabpanel" id="tabHistory">


                        <!--Show Reserve group list-->
                        <ng-container *ngIf="reserveGroupList!=undefined && reserveGroupList.length >0 ">
                            <div class="m-1 p-1" *ngFor="let rg of reserveGroupList"
                                style="background-color: lightskyblue;">
                                <button class="btn btn-danger me-1" (click)="openDeleteReserveGroupDialog(rg)"
                                    *ngIf="(sharedService.isBossAccount() || rg.RequestedBy == sharedService.getLoggedInUsername()) && rg.Accepted==0">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                <span>Simpan Group {{rg.ID}} - <b>({{rg.Accepted}}/{{rg.Total}} accepted)</b></span>
                                <div class="d-flex justify-content-end align-items-right">
                                    <button class="btn btn-info d-flex align-items-right"
                                        (click)="openReserveGroupDetailsModal(rg.ID)">View Details</button>
                                </div>

                            </div>
                        </ng-container>


                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th
                                        *ngIf="this.sharedService.isAdmin()==true || sharedService.isBossAccount()==true">
                                    </th>
                                    <th>Date</th>
                                    <th>Remark</th>
                                    <th>Amount</th>
                                    <!-- <th>Type</th> -->
                                    <!-- <th>Closing Balance</th> -->
                                    <th>HandleBy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reserve of reserveList">
                                    <td
                                        *ngIf="this.sharedService.isAdmin()==true|| sharedService.isBossAccount()==true">
                                        <button class="btn btn-danger" (click)="confirmDeleteTranscation(reserve)"><i
                                                class="fas fa-trash-alt"></i></button>
                                    </td>
                                    <td>
                                        {{reserve.Date | date:'dd-MM-yyyy'}}
                                    </td>
                                    <td>
                                        {{reserve.Remark}}
                                        <span *ngIf="reserve.SetId!=undefined " class="text-info"
                                            style="cursor: pointer;" (click)="openSetDialog(reserve.SetId)"><i
                                                class="fas fa-file-invoice-dollar"></i></span>
                                    </td>
                                    <td [ngClass]=" checkAmountInOut(reserve.Amount)">
                                        {{reserve.Amount}}
                                    </td>
                                    <!-- <td>
                                    {{reserve.Type}}
                                </td> -->
                                    <!-- <td>
                                    {{transaction.ClosingBalance}}
                                </td> -->
                                    <td>
                                        {{reserve.HandleBy}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td><strong>Total:</strong></td>
                                    <td><strong>{{calculateTotalReserve(reserveList)}}</strong></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <!--tabAddSimpan-->
                    <div class="tab-pane" role="tabpanel" id="tabAdd"
                        *ngIf="sharedService.isAdmin()==false && sharedService.isBossAccount()==false">
                        @if(sharedProfileId==undefined){
                        <app-add-simpan [selectedProfileID]="selectedProfileID" [selectedProfile]="selectedProfile"
                            [selectedCustomer]="viewCustomer"></app-add-simpan>
                        }
                        @else{
                        <app-add-reserve-group [sharedProfileId]="sharedProfileId" [selectedProfile]="selectedProfile"
                            [selectedCustomer]="viewCustomer" [isModal]="false"></app-add-reserve-group>
                        }

                    </div>
                    <!--tabExpenseSimpan-->
                    <div class="tab-pane" role="tabpanel" id="tabExpense"
                        *ngIf="(sharedService.isAdmin()==false && sharedService.isBossAccount()==false) && totalReserveAmount>0">
                        <app-expense-simpan [selectedProfileID]="selectedProfileID" [profileList]="profileList"
                            [selectedProfile]="selectedProfile" [viewCustomer]="viewCustomer"
                            [totalReserveAmount]="totalReserveAmount">
                        </app-expense-simpan>
                    </div>
                    <!--tabTransfer-->
                    <div class="tab-pane" role="tabpanel" id="tabTransfer" *ngIf="profileList.length>1">
                        <app-transfer-simpan [profileList]="profileList" [selectedProfileID]="selectedProfileID">
                        </app-transfer-simpan>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>